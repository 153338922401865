import * as actionTypes from '../actions';

export const initialState = {
  uploads: []
}

export default function uploadReducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_UPLOAD: {
      let newUploads = [ ...state.uploads ]
      const index = newUploads.findIndex(x => x.id === action.uid)

      if (index === -1) {
        newUploads = [action.payload, ...state.uploads]
      } else {
        newUploads[index] = action.payload
      }

      return {
        ...state,
        uploads: newUploads
      }
    }
    case actionTypes.CANCEL_UPLOAD: {
      // Filter the canceled upload out of state
      const newUploads = [ ...state.uploads ].filter(x => x.id !==  action.id);

      return {
        ...state,
        uploads: newUploads
      }
    }
    default: {
      return state
    }
  }
}