import palette from './palette';
const typography={
  fontFamily: [
    "NeueHaasDisplayLight",
  ].join(','),
  h1: {
    color: palette.primary.main,
    fontWeight: 900,
    fontSize: '40px',
    letterSpacing: '0px',
    lineHeight: '40px',
    fontFamily: 'NeueHaasDisplayBold'
  },
  h2: {
    color: palette.primary.main,
    fontWeight: 900,
    fontSize: '35px',
    letterSpacing: '0.95px',
    lineHeight: 1.14,
    fontFamily: 'NeueHaasDisplayMedium'
  },
  h3: {
    color: palette.primary.main,
    fontWeight: 800,
    fontSize: '30px',
    letterSpacing: 'normal',
    lineHeight: 2.08,
    fontFamily: 'NeueHaasDisplayMedium'
  },
  h4: {
    color: palette.primary.main,
    fontSize: '18px',
    fontWeight: 700,
    letterSpacing: '0px',
    lineHeight: '24px',
    fontFamily: 'NeueHaasDisplayMedium'
  },
  h5: {
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    fontFamily: 'NeueHaasDisplayMedium'
  },
  h6: {
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: 12,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    fontFamily: 'NeueHaasDisplayMedium'
  },
  subtitle1: {
    color: palette.primary.dark,
    fontSize: '16px',
    letterSpacing: '0px',
    lineHeight: '25px',
    wordBreak: 'break-word',
    fontFamily: 'NeueHaasDisplayLight'
  },
  subtitle2: {
    color: palette.text.main,
    fontSize: 16,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    wordBreak: 'break-word',
    fontFamily: 'NeueHaasDisplayLight'
  },
  body1: {
    color: palette.text.main,
    fontSize: '16px',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    fontFamily: 'NeueHaasDisplayLight'
  },
  body2: {
    color: palette.text.main,
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    wordBreak: 'break-word',
    fontFamily: 'NeueHaasDisplayLight'
  },
  button: {
    color: palette.text.contrastText,
    fontSize: '14px',
    fontFamily: 'NeueHaasDisplayLight'
  },
  caption: {
    color: palette.text.main,
    fontSize: 12,
    letterSpacing: '0.95px',
    lineHeight: '13px',
    fontFamily: "NeueHaasDisplayLight"
  },
  overline: {
    color: palette.text.main,
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    textTransform: "none",
    fontFamily: 'NeueHaasDisplayLight'
  },
  divider: {
    borderBottom: `1px solid ${palette.border}`,
  }
}
export default typography;
