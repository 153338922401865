import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import React from 'react';
import OrganizationNameForm from './OrganizationNameForm';
import TeamMembers from './TeamMembers';
import StripePayment from './StripePayment';
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../../contextComponent/viewportContext";
import OwnerForm from './OwnerForm'


const useStyles = makeStyles((theme) => ({
    title: {
        paddingBottom: theme.spacing(1)
    },
    divider: {
        width: "60%",
        borderColor: theme.palette.text.light,
        [`${portraitBreakPoint(theme)}`]: {
            width: "100%",
        },
        [`${landscapeBreakPoint(theme)}`]: {
            width: "100%",
        },
    },
}))

const OrganizationPage = () => {
    const classes = useStyles();
    const { isMobile } = useViewport();

    return (
        <Box id="org-setting-page" p={4} pt={6} pb={isMobile ? 0 : 2} height="100%" style={{ overflow: 'auto' }}>
            <Typography variant='h4' className={classes.title}>Organization</Typography>
            <Divider varient="middle" className={classes.divider} />
            <OrganizationNameForm />
            <OwnerForm />
            {false && <StripePayment /> }
            <TeamMembers />
        </Box>
    )
}

export default OrganizationPage;
