import React from 'react';
import { theme } from '../theme';


export default function LoadingSpinner (props) {

  let stroke = theme.palette.primary.main;
  if (window.location.pathname.includes("/register/demo-account")) {
    stroke = theme.palette.background.dark;
  }

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div className="loader"
        style={{
          width: '200px',
          height: '200px',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          zIndex: 1000
        }}
      >
        <svg id="b-rotate" className="circular-loader" viewBox="25 25 50 50" >
          <circle id="buffer" className="loader-path" cx="50" cy="50" r="12" stroke={stroke} fill="none" strokeWidth="1.2" />
        </svg>
      </div>
    </div>
  )
}
