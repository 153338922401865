import { ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import PersonIcon from '@mui/icons-material/Person';
import CustomTooltip from "../../../components/CustomTooltip";


const useStyles = makeStyles((theme) => ({
    titleTeamMember: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        alignItems: 'center',
        '& p': {
            fontFamily: 'NeueHaasDisplayRoman'
        }
    },
    item: {
        paddingBottom: theme.spacing(1),
        paddingLeft: 0
    },
    titleText: {
        width:'calc(50% - 10px)',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        wordBreak: 'break-all',
        userSelect: 'unset'
    }
}))


const Member = ({ member }) => {
    const classes = useStyles();
    const [ isEllipsis, setIsEllipsis ] = React.useState(true);
    const handleMouseEnter=(event)=>{
        setIsEllipsis(event.target.scrollWidth > event.target.offsetWidth);
    }

    return (
        <ListItem className={classes.item}>
            <ListItemAvatar>
                <PersonIcon color='primary' />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <div className={classes.titleTeamMember}>
                        <CustomTooltip title={`${member.first_name} ${member.last_name}`} isEllipsis={isEllipsis}>
                            <Typography className={classes.titleText} onMouseEnter={handleMouseEnter}>{member.first_name} {member.last_name}</Typography>
                        </CustomTooltip>
                        <CustomTooltip title={member.email} isEllipsis={isEllipsis}>
                            <Typography className={classes.titleText} align="right" onMouseEnter={handleMouseEnter}>{member.email}</Typography>
                        </CustomTooltip>
                    </div>
                }
            />
        </ListItem>
    )
}

export default Member;
