import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ErrorIcon from "../../assets/images/errorDisconnectIcon.png";
import { useHistory } from "react-router";
import useInterval from "../../hooks/useInterval";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
    },
    oops: {
        fontSize: "6vw",
        fontFamily: "NeueHaasDisplayBold",
        fontWeight: theme.typography.fontWeightBold,
    },
    textContainer: {
        textAlign: "center",
        maxWidth: "500px",
    },
    info: {
        fontSize: "22px",
        fontFamily: "NeueHaasDisplayBold",
        fontWeight: theme.typography.fontWeightBold,
        marginTop: "10px",
    },
    connectionMessage: {
        fontSize: "22px",
        fontFamily: "NeueHaasDisplayBold",
        fontWeight: theme.typography.fontWeightBold,
        marginTop: "10px",
    },
    homeButton: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "33px",
        padding: "12px 32px 12px 32px",
        marginTop: "20px",
        marginLeft: "14px",
        "& .MuiTypography-root": {
            color: theme.palette.primary.contrastText,
            fontSize: "20px",
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

export default function ErrorPage({ online }) {
    const history = useHistory();
    const classes = useStyles();
    const [disconnected, setDisconnected] = useState(false);

    const backToHomePage = () => {
        history.push("/");
        history.go(0);
    };

    // tests internet connection every 2 seconds
    // if their internet disconnects and then reconnects, this will try to redirect user away from the error page
    useInterval(() => {
        if (window.navigator.onLine === true && disconnected === true) {
            setDisconnected(false);
            backToHomePage();
            online(true);
        } else if (window.navigator.onLine === false) {
            setDisconnected(true);
        }
    }, 2000);

    return (
        <div className={classes.root}>
            <Box className={classes.textContainer}>
                <Typography className={classes.oops}>Oops!</Typography>
                <img style={{ width: "270px" }} alt="Error" src={ErrorIcon} />
                <Typography className={classes.info}>We're sorry, something went wrong.</Typography>
                {disconnected && (
                    <Typography className={classes.connectionMessage}>
                        It looks like your internet is disconnected. You will return to the home page if your
                        connection comes back online.
                    </Typography>
                )}
            </Box>
            {!disconnected && (
                <Button variant="text" className={classes.homeButton} onClick={backToHomePage}>
                    <Typography variant="h4">Back to Home Page</Typography>
                </Button>
            )}
        </div>
    );
}
