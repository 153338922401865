import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Autocomplete } from '@mui/material';
import { makeStyles, styled } from "@mui/styles";
import { onUpdateTimezone } from '../../../actions';
import { getTimeZone } from '../../../actions/api/userAPI';
import momentTZ from 'moment-timezone';
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: `0px`,
        width: '70%',
        [`${portraitBreakPoint(theme)}`]: {
            width: '95%'
        },
        [`${landscapeBreakPoint(theme)}`]: {
            width: '95%'
        },
    },
    form: {
        display: 'flex',
        alignItems: 'flex-end',
        ".MuiInputLabel-formControl": {
            transition: 'none'
        },
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        [`${landscapeBreakPoint(theme)}`]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
    },
    formLabel: {
        transform: 'none'
    },
    divider: {
        display: 'flex',
        alignSelf: "center",
        borderColor: "black",
        height: '15px',
        marginRight: "5px",
        marginLeft: "5px",

    },
    editButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        }
    },
    upcomingTitle: {
        color: theme.palette.text.blue,
        '&:hover': {
            cursor: 'pointer',
        }
    },
    container: {
        display: 'flex',
        width: "100%",
        justifyContent: "space-between",
        alignItems: 'flex-end',
        gap: "15px",
        paddingBottom: "15px",
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: "column",
            alignItems: "start"
        },
    },
    timezoneChange: {
        display: 'flex',
        gap: "15px",
        width: "70%",
        padding: "2px 15px"
    },
    editTimezone: {
        display: "flex",
        margin: "10px 0px 50px 0px",
        width: "100%",
        alignItems: "center",
        gap: "10px",
        "& .MuiTypography-body1":{
            fontFamily: "NeueHaasDisplayRoman",
            fontSize: "18px"
        },
        [`${portraitBreakPoint(theme)}`]: {
            "& .MuiTypography-body1":{
                fontFamily: "NeueHaasDisplayRoman",
                fontSize: "16px"
            }
        },
        [`${landscapeBreakPoint(theme)}`]: {
            "& .MuiTypography-body1":{
                fontFamily: "NeueHaasDisplayRoman",
                fontSize: "16px"
            }
        }
    },
    label: {
        display: 'flex',
        justifyContent: "space-between",
        paddingBottom: "4%"
    }
}))

const StyledAutoComplete = styled(Autocomplete)((props) => ({
    fontFamily: "NeueHaasDisplayRoman",
    width: "300px",
    marginTop: "10px",
    marginRight: "15px",
    color: "black",
}));

const SaveButton = styled(Button)(({ theme }) => ({
    background: theme.palette.text.contrastText,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    padding: '2px 35px',
    lineHeight: "1.4",
    "&:hover": {
        background: theme.palette.primary.main,
        color: theme.palette.text.contrastText
    }
}));

const TimeZoneForm = () => {
    const classes = useStyles();
    const { isMobile } = useViewport();
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [timezone, setTimezone] = useState(null);
    const [oldTimezone, setOldTimezone] = useState(null);
    const [allTimeZones, setAllTimeZones] = useState(null);
    const [isDisabled,setIsDisabled] = React.useState(true);

    const handleOnClickEdit = () => {
        setEdit(true);
    }

    const handleCloseEdit = () => {
        setEdit(false);
        setTimezone(oldTimezone)
    }

    const onTimezoneChange = (event, value) => {
        const newTimezone = value;
        setTimezone(newTimezone);
        setIsDisabled(oldTimezone === newTimezone);
    }  

    const onSubmit = async (e) => {
        e.preventDefault();
        setEdit(false);
        await dispatch(onUpdateTimezone({timezone: timezone})).then(res => {
            enqueueSnackbar({ message: 'You successfully changed your timezone!', variant: 'success' });
        })
        setOldTimezone(timezone)
    }

    useEffect(() => {
        getTimeZone().then(res=> {
            if(res.data.timezone){
                setTimezone(res.data.timezone)
                setOldTimezone(res.data.timezone)
            }else{
                dispatch(onUpdateTimezone({timezone: momentTZ.tz.guess()}))
                setTimezone(momentTZ.tz.guess())
                setOldTimezone(momentTZ.tz.guess())
            }
        });
    }, [dispatch])

    useEffect(() => {
        setAllTimeZones(momentTZ.tz.names)
    }, [timezone])
    



    return (
        <Box id="timezone-change" pb={isMobile ? 1 : 2} paddingBottom={1}>
        <form onSubmit={onSubmit} className={classes.form}>
            <FormControl variant="standard" className={classes.margin}>
                {edit ?
                    <div className={classes.container}>
                        <StyledAutoComplete
                            id="timezone-id"
                            options={allTimeZones}
                            autoHighlight
                            defaultValue={timezone}
                            onChange={onTimezoneChange}
                            renderInput={(params) => <TextField variant="standard" {...params} label="Timezone" />}
                        >
                        </StyledAutoComplete>
                        <div id="confim-cancel-buttons" className={classes.timezoneChange} >
                            <SaveButton type='submit' variant="outlined" disabled={isDisabled}>Confirm</SaveButton>
                            <SaveButton variant="outlined" onClick={handleCloseEdit}>Cancel</SaveButton>
                        </div>
                    </div>
                    :
                    <div className={classes.editTimezone}>
                        <Typography color='textSecondary'>{timezone}</Typography>
                        <Divider orientation="vertical" flexItem className={classes.divider} />
                        <Typography className={classes.upcomingTitle} fontWeight={'bold'} onClick={handleOnClickEdit} >Edit</Typography>
                    </div>
                }
            </FormControl>
        </form>
    </Box>
    );
}


export default TimeZoneForm;
