import { useCallback, useEffect, useRef, useState } from 'react';

export function useDimensions(el) {
  const [value, setValue] = useState({ width: 0, height: 0, orientation: 'landscape' });
  const ref = useRef();
  const orientationQuery = useRef(window.matchMedia('(orientation: portrait)'));

  const update = useCallback(() => {
    const orientation = orientationQuery.current.matches ? 'portrait' : 'landscape';
    if (el) {
      const { width, height } = el.getBoundingClientRect();
      setValue({ width, height, orientation });
    }

    setValue({
      width: window.innerWidth,
      height: window.innerHeight,
      orientation,
    });
  }, [el]);

  useEffect(() => {
    let currentQuery = orientationQuery.current;
    if (el) {
      ref.current = new ResizeObserver(update);
      ref.current.observe(el);
      currentQuery.onchange = () => update();
      return () => {
        ref.current?.disconnect();
        currentQuery.onchange = null;
      };
    }

    // Set the initial window dimensions first
    // since the handlers don't fire on register
    update();
    window.addEventListener('resize', update);
    if (currentQuery) currentQuery.onchange = () => update();
    return () => {
      window.removeEventListener('resize', update);
      currentQuery.onchange = null;
    };
  }, [el, update]);

  return value;
}
