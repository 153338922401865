import React, { useEffect } from 'react'
import { Button, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import Logo from '../../assets/images/view_labs_logo.png'
import { useLocation } from "react-router-dom";
import { onConfirmChangeEmail } from '../../actions';
import { useDispatch } from 'react-redux';
import { portraitBreakPoint, landscapeBreakPoint} from "../../views/contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        height: '100vh',
        display: "flex",
        gap: '10px',
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "600px",
        "& *": {
            fontSize: "18px",
        },
        [`${portraitBreakPoint(theme)}`]: {
            width: '300px'
        }
    },
    logo: {
        width: "400px",
        marginBottom: "30px",
        [`${portraitBreakPoint(theme)}`]: {
            width: '200px'
        },
        [`${landscapeBreakPoint(theme)}`]: {
            width: '200px'
        }
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        padding: "6px 70px",
        color: theme.palette.primary.contrastText,
        fontSize: "16px",
        margin: "20px 0px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    text:{
        fontWeight: '600',
    }
}));

export default function ChangeEmailConfirmation() {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const search = useLocation().search;
    const key = new URLSearchParams(search).get("k");
    const [errorMessage, setErrorMessage] = React.useState(null);

    const toLogin = () => {
        history.push("/login");
    };

    useEffect(()=>{
        dispatch(onConfirmChangeEmail(key))
            .catch(err => {
                setErrorMessage(err.data.message);
            })
    },[key, dispatch])

    return (
        <Box className={classes.root}>
            <img src={Logo} className={classes.logo} alt="Logo" />
            {errorMessage ?
                <Typography variant="subtitle1" gutterBottom  className={classes.text}>
                    {errorMessage}
                </Typography>
            :
                <>
                    <Typography variant="subtitle1" gutterBottom  className={classes.text}>
                        You have successfully updated the email address associated with your account.
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom  className={classes.text}>
                        You may now use your new credentials to log in.
                    </Typography>
                </>
            }

            <Button variant="text" className={classes.button} onClick={toLogin}>
                Go to the Lab
            </Button>
        </Box>
    );
}
