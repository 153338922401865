/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Helmet , HelmetProvider } from 'react-helmet-async';
import PropTypes from 'prop-types';

const { NODE_ENV } = process.env;

function Page({ title, description,children, ...rest }) {

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return;
    }

    // eslint-disable-next-line
  }, []);

  return (
    <HelmetProvider>
      <div {...rest}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        {children}
      </div>
    </HelmetProvider>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description:PropTypes.string
};

export default Page;
