import React, { useEffect } from 'react'
import { Button, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import Logo from '../../assets/images/view_labs_logo.png'
import { portraitBreakPoint, landscapeBreakPoint} from "../../views/contextComponent/viewportContext";
import { useLocation } from "react-router-dom";
import { emailOpt } from '../../actions/api/userAPI';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        height: '100vh',
        display: "flex",
        gap: '10px',
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "465px",
        "& *": {
            fontSize: "18px",
        },
        "& h4": {
            color: "black",
        },
        [`${portraitBreakPoint(theme)}`]: {
            width: '300px'
        }
    },
    logo: {
        width: "400px",
        marginBottom: "30px",
        [`${portraitBreakPoint(theme)}`]: {
            width: '200px'
        },
        [`${landscapeBreakPoint(theme)}`]: {
            width: '200px'
        }
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        padding: "6px 70px",
        color: theme.palette.primary.contrastText,
        fontSize: "16px",
        margin: "20px 0px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

export default function OptInPage() {
    const history = useHistory();
    const classes = useStyles();
    const search = useLocation().search;
    const choice = new URLSearchParams(search).get("choice");
    const email = new URLSearchParams(search).get("email");
    const toLogin = () => {
        history.push("/login");
    };
    const backToHomePage = () => {
        history.push("/");
    };
    useEffect(() => {
        emailOpt(email, choice);
    }, [choice, email]);
    return (
        <Box className={classes.root}>
            <img src={Logo} className={classes.logo} alt="Logo" />
            {choice === "true" ? (
                <>
                    <Typography variant="subtitle1" gutterBottom>
                        We're glad you are as excited as we are about our platform and are delighted to have
                        you on board.
                    </Typography>
                    <Button variant="text" className={classes.button} onClick={toLogin}>
                        Login Now
                    </Button>
                    <Typography variant="subtitle1" gutterBottom>
                        Stay tuned for more!
                    </Typography>
                    <Typography variant="h4">The View Labs team</Typography>
                </>
            ) : (
                <>
                    <Typography variant="subtitle1" gutterBottom>
                        You have successfully updated your communication preferences. We will no longer be
                        sending you automated emails.
                    </Typography>
                    <div>
                        <Typography variant="subtitle1" style={{ marginTop: '20px' }}>You will be missed!</Typography>
                        <Typography variant="h4" gutterBottom>
                            The View Labs team
                        </Typography>
                    </div>
                    <Button variant="text" className={classes.button} style={{ marginTop: '30px' }} onClick={backToHomePage}>
                        View Website
                    </Button>
                </>
            )}
        </Box>
    );
}
