import {
    loginAPI,
    requestDemoAPI,
    firstTimeSetupAPI,
    resetPasswordAPI,
    loginAuthAPI,
    setNewPasswordAPI,
    changeTimeZone,
    revertChangeEmail,
    confirmChangeEmail,
} from "../actions/api/userAPI";
import { generateSession, inviteUsers, sessionHistoryAPI, upcomingSessionAPI, sessionUpdateAPI } from "../actions/api/SessionAPI";
import { logoutAPI, updateUserName, changePasswordAPI, updateEmail } from "./api/userAPI";
import { sessionNoteAPI, sessionDeleteAPI } from "./api/SessionAPI";
import axios from "../utils/axios";

export const SESSION_LOGIN = "SESSION_LOGIN";
export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const CONFERENCE_DATA_LOADING = "CONFERENCE_DATA_LOADING";
export const CONFERENCE_DATA_LOADED = "CONFERENCE_DATA_LOADED";
export const CONFERENCE_DATA_ERROR = "CONFERENCE_DATA_ERROR";
export const ON_INCORRECT_PASSWORD = "ON_INCORRECT_PASSWORD";
export const ON_LOADING_START = "ON_LOADING_START";
export const ON_LOADING_FINISHED = "ON_LOADING_FINISHED";
export const ON_LOGIN_LOADING = "ON_LOGIN_LOADING";
export const ON_VEHICLE_LIST_RECEIVED = "ON_VEHICLE_LIST_RECEIVED";
export const ON_FILTER_DATA_RECEIVED = "ON_FILTER_DATA_RECEIVED";
export const ON_VEHICLE_DETAIL_RECEIVED = "ON_VEHICLE_DETAIL_RECEIVED";
export const ON_OPEN_NAVBAR_MOBILE = "ON_OPEN_NAVBAR_MOBILE";
export const ON_ENABLE_REGISTER_SCREEN = "ON_ENABLE_REGISTER_SCREEN";
export const ON_ENABLE_LOGIN_SCREEN = "ON_ENABLE_LOGIN_SCREEN";
export const ON_GOTO_BOOKING = "ON_GOTO_BOOKING";
export const ON_OPEN_RIGHT_MENU = "ON_OPEN_RIGHT_MENU";
export const ON_ENABLE_MY_PROFILE_SCREEN = "ON_ENABLE_MY_PROFILE_SCREEN";
export const ON_PRICE_BREAKUP = "ON_PRICE_BREAKUP";
export const ON_USER_IDENTITY_RECEIVED = "ON_USER_IDENTITY_RECEIVED";
export const ON_USER_DATA_UPDATE = "ON_USER_DATA_UPDATE";
export const ON_CANCEL_BOOKING = "ON_CANCEL_BOOKING";
export const ON_PASSWORD_CHANGED = "ON_PASSWORD_CHANGED";
export const ON_ENABLE_TEST_RIDE_POPUP = "ON_ENABLE_TEST_RIDE_POPUP";
export const ON_CANCEL_TESTRIDE = "ON_CANCEL_TESTRIDE";
export const ON_ENABLE_FEEDBACK = "ON_ENABLE_FEEDBACK";
export const ON_TEST_RIDE_ENABLE_FEEDBACK = "ON_TEST_RIDE_ENABLE_FEEDBACK";
export const ON_TEST_RIDE_SUCCESS_ENABLED = "ON_TEST_RIDE_SUCCESS_ENABLED";
export const ON_UPDATE_SESSION_LINK = "ON_UPDATE_SESSION_LINK";
export const ON_INFO_ENABLED_SET = "ON_INFO_ENABLED_SET";
export const ON_SESSION_LINK_ID = "ON_SESSION_LINK_ID";
export const ON_SESSION_HISTORY_RECEIVED = "ON_SESSION_HISTORY_RECEIVED";
export const ON_UPCOMING_SESSION_RECEIVED = "ON_UPCOMING_SESSION_RECEIVED";
export const ON_STORE_USERNAME = "ON_STORE_USERNAME";
export const ON_SET_DEVICE_INFO = "ON_SET_DEVICE_INFO";
export const ON_CONTENT_SELECT = "ON_CONTENT_SELECT";
export const ON_RESET_REQUEST = "ON_RESET_REQUEST";
export const ON_MEETING_VIEW = "ON_MEETING_VIEW";
export const ON_DELETE_SESSION = "ON_DELETE_SESSION";
export const ON_USER_DETAILS = "ON_USER_DETAILS";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";

export const loading = () => {
    return {
        type: ON_LOGIN_LOADING,
        payload: null,
    };
};

export const login = () => {
    return {
        type: SESSION_LOGIN,
        payload: null,
    };
};

export const loginFail = () => (dispatch) =>
    dispatch({
        type: ON_INCORRECT_PASSWORD,
    });

export const onContentSelect = (selectedContents) => (dispatch) =>
    dispatch({
        type: ON_CONTENT_SELECT,
        payload: selectedContents,
    });

export function checkIfLoggedIn() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            loginAuthAPI()
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(login());
                        dispatch(loading());
                        dispatch({
                            type: ON_USER_DETAILS,
                            data: response.data
                        })
                        resolve(true);
                    }
                })
                .catch((error) => {
                    dispatch(loginFail());
                    dispatch(loading());
                    reject(error.response);
                });
        });
    };
}

export const logout = () => (dispatch) =>{
    dispatch({ type: SESSION_LOGOUT });
    dispatch({ type: CLEAR_USER_DETAILS });
}

/**
 * A thunk to login with the backend server with a federated identity provider.
 * @param {Object} credentials A credentials object
 * @param {String} credentials.provider The identity provider. Currently only 'google' is supported.
 * @param {String} credentials.id A JWT token or other identifier used to authenticate a user.
 * @returns a Promise resolving to a login success/fail
 */
export function loginFederatedIdentity(credentials) {
    return async (dispatch) => {
        const response = await axios.post("/api/user/login-federated-identity", credentials);
        if (response.status === 200) {
            dispatch(login());
        } else throw Error("Login with Federated Identity failed.");
    };
}

export function loginUser(email, password) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            loginAPI(email, password)
                .then((response) => {
                    console.log("Response:", response.response);
                    if (response.status === 200) {
                        dispatch(login());
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error.response);
                    dispatch(loginFail());
                });
        });
    };
}

export function logoutUser() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            logoutAPI()
                .then((response) => {
                    console.log("Response:", response);
                    if (response.status === 200) {
                        dispatch(logout());
                        resolve(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
}

export function requestDemo(email, name, phone) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            requestDemoAPI(email, name, phone)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    };
}

export function resetPassword(email) {
    return (dispatch) => {
        resetPasswordAPI(email)
            .then((response) => {
                if (response.status === 204 || 200) {
                    console.log(response);
                    dispatch({
                        type: ON_RESET_REQUEST,
                        payload: {
                            status: response.status,
                            email: email,
                            message: response.message,
                        },
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch({
                    type: ON_RESET_REQUEST,
                    payload: {
                        status: error.response.status,
                        email: email,
                        message: error.message,
                    },
                });
            });
    };
}

export function setNewPassword(key, password) {
    return (dispatch) =>
        new Promise(async (resolve, reject) => {
            setNewPasswordAPI(key, password)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(onChangePassword(true));
                        resolve(response);
                    }
                })
                .catch((error) => {
                    dispatch(onChangePassword(false));
                    reject(error);
                });
        });
}

export function createSession(session_name, start_datetime, duration, disable_participants_menu) {
    return (dispatch) =>
        new Promise(async (resolve, reject) => {
            generateSession(session_name, start_datetime, duration, disable_participants_menu)
                .then((response) => {
                    if (response.data.code === 200) {
                        if (window.gtagThelab) {
                            window.gtagThelab('event', 'create_session', {
                                session_name: session_name,
                                session_start_datetime: start_datetime,
                                session_duration: duration,
                                session_url: `${window.location.origin}/session?id=${response.data.id}`
                            });
                        }
                        dispatch(onUpdateSessionLink(`${window.location.origin}/session?id=${response.data.id}`));
                        dispatch({ type: ON_SESSION_LINK_ID, payload: response.data.id });
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function updateSession(sessionId, session_name, start_datetime, duration, disable_participants_menu) {
    return (dispatch) =>
        new Promise(async (resolve, reject) => {
            sessionUpdateAPI(sessionId, session_name, start_datetime, duration, disable_participants_menu)
                .then((response) => {
                    if (response.data.code === 200) {
                        dispatch(onUpdateSessionLink(`${window.location.origin}/session?id=${sessionId}`));
                        dispatch({ type: ON_SESSION_LINK_ID, payload: sessionId });
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function firstTimeSetup(key, firstName, lastName, password) {
    return (dispatch) => new Promise(async (resolve, reject) => {
        firstTimeSetupAPI(key, firstName, lastName, password)
            .then(response => {
                if (response.status === 200) {
                    resolve(response);
                }
            })
            .catch(error => {
                reject(error);
            })
    })
}

export function createSessionAsync() {
    return (dispatch) => { };
}

export function inviteUsersToSession(sessionId, invitees) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            inviteUsers(sessionId, invitees)
                .then((response) => {
                    if (response.status === 201) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
}

export const onUpdateSessionLink = (payload) => {
    return {
        type: ON_UPDATE_SESSION_LINK,
        payload,
    };
};

export const onChangePassword = (payload) => {
    return {
        type: ON_PASSWORD_CHANGED,
        payload,
    };
};

export const setOpenNavBarMobile = (value) => (dispatch) =>
    dispatch({
        type: ON_OPEN_NAVBAR_MOBILE,
        payload: value,
    });

export const setOpenRightMenu = (value) => (dispatch) =>
    dispatch({
        type: ON_OPEN_RIGHT_MENU,
        payload: value,
    });

export const getSessionHistory = (pageSize, pageNumber, search, sortBy, sortType) => (dispatch) => {
    return new Promise((resolve, reject) => {
        sessionHistoryAPI(pageSize, pageNumber, search, sortBy, sortType)
            .then((response) => {
                if (response.status === 200) {
                    console.log("history response", response);
                    dispatch({ type: ON_SESSION_HISTORY_RECEIVED, payload: response.data });
                    resolve(response);
                }
            })
            .catch((error) => {
                console.log(error.response);
                reject(error);
            });
    });
};

export const getUpcomingSession = (upComingPageSize, upComingPageNumber, search, sortBy, sortType) => (dispatch) => {
    return new Promise((resolve, reject) => {
        upcomingSessionAPI(upComingPageSize, upComingPageNumber, search, sortBy, sortType)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: ON_UPCOMING_SESSION_RECEIVED, payload: response.data });
                    resolve(response);
                }
            })
            .catch((error) => {
                console.log(error.response);
                reject(error);
            });
    });
};

export const postSessionNote = (sessionID, note) => (dispatch) => {
    return new Promise((resolve, reject) => {
        sessionNoteAPI(sessionID, note)
            .then((response) => {
                if (response.status === 200) {
                    console.log("note response", response);
                    resolve(response);
                }
            })
            .catch((error) => {
                console.log(error.response);
                reject(error);
            });
    });
};

export const setUserNameOfCurrentSessionUser = (username) => (dispatch) => {
    dispatch({
        type: ON_STORE_USERNAME,
        payload: username,
    });
};

export const setDeviceInfo = (payload) => (dispatch) => {
    dispatch({
        type: ON_SET_DEVICE_INFO,
        payload,
    });
};

export const fetchConferenceData = (sessionId, displayName) => {
    return async (dispatch) => {
        dispatch({ type: CONFERENCE_DATA_LOADING });
        const response = await fetch(`/api/session/${sessionId}/conference?name=${displayName}`);

        // The callbridge account doesn't exist, create one
        if (!response.ok) {
            const json = await response.json();
            const code = json.error.code;

            if (code === "no-callbridge-account") {
                dispatch(createCallbridgeAccount(displayName));
                return;
            } else {
                dispatch({ type: CONFERENCE_DATA_ERROR, error: json.error });
            }
        } else {
            const json = await response.json();
            dispatch({
                type: CONFERENCE_DATA_LOADED,
                payload: json,
            });
        }
    };
};

export const createCallbridgeAccount = (displayName) => {
    return async (dispatch) => {
        const createResponse = await fetch("/api/user/conference", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                name: displayName,
            }),
        });
        const json = await createResponse.json();
        if (!createResponse.ok) dispatch({ type: CONFERENCE_DATA_ERROR, error: json.error });
        else
            dispatch({
                type: CONFERENCE_DATA_LOADED,
                payload: json,
            });
    };
};

export const isMeetingView = (status) => {
    return {
        type: ON_MEETING_VIEW,
        payload: status,
    };
};

export const deleteSession = (id) => {
    return async (dispatch) => {
        const response = await sessionDeleteAPI(id);
        if (response.status === 200) {
            dispatch(removeSessionFromRedux(id));
        }
    };
};

export const removeSessionFromRedux = (id) => {
    return {
        type: ON_DELETE_SESSION,
        payload: id,
    };
};

export const onUpdateUserName = (userName) => {
    return async (dispatch) => {
        dispatch({ type: ON_LOADING_START });
        updateUserName(userName)
        .then((response) => {
            dispatch({
                type: ON_USER_DETAILS,
                data: response.data,
            });
        })
        .finally(() => {
            dispatch({ type: ON_LOADING_FINISHED });
        })       
    };
}

export const onUpdatePassword = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({ type: ON_LOADING_START });
            changePasswordAPI(data).then((response) => {
                // dispatch({
                //     type: ON_USER_DETAILS,
                //     data: response.data,
                // });
                resolve(response);            
            })
            .catch((error) => {
                reject(error);
            })
            .finally(() => {
                dispatch({ type: ON_LOADING_FINISHED });
            }) 
        });       
    };
}

export const onUpdateEmail = (email) => {
    return async (dispatch) => {
        dispatch({ type: ON_LOADING_START });
        updateEmail(email).then((response) => {

        }).finally(() => {
            dispatch({ type: ON_LOADING_FINISHED });
        })       
    };
}

export const onUpdateTimezone = (timezone)=>{
    return async (dispatch) =>{
        dispatch({ type: ON_LOADING_START });
        changeTimeZone(timezone).then((response)=>{

        }).finally(()=>{
            dispatch({ type: ON_LOADING_FINISHED });

        })
    }
}

export const onConfirmChangeEmail = (key) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({ type: ON_LOADING_START });
        confirmChangeEmail(key).then((response)=>{
            resolve(response);
        }).catch(err => {
            reject(err.response);
        }).finally(()=>{
            dispatch({ type: ON_LOADING_FINISHED });
        })
    })
}

export const onRevertChangeEmail = (key) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({ type: ON_LOADING_START });
        revertChangeEmail(key).then((response)=>{
            resolve(response);
        }).catch(err => {
            reject(err.response);
        }).finally(()=>{
            dispatch({ type: ON_LOADING_FINISHED });
        })
    })
}
