import {AUTH_DATA, LOGGED_IN_DATA} from "../constant";
import jwt_decode from "jwt-decode";

export const getAuthToken = () => {
    const authStr = localStorage.getItem(LOGGED_IN_DATA);
    if(authStr) {
        const authJSON = JSON.parse(authStr);
        return authJSON.Authorization;
    }
    return null;
}

export const getUserAuthToken = () => {
    const authStr = localStorage.getItem(AUTH_DATA);
    if(authStr) {
        const authJSON = JSON.parse(authStr);
        return authJSON.Authorization;
    }
    return null;
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const getTokenData = () => {
    const token = getCookie('token');
    if(token) {
        const decoded = jwt_decode(token);
        if (Date.now() < decoded.exp * 1000) {
            return decoded;
        }else{
            return null;
        }
    }
    return null;
}


export const fallbackSpeedTest = (numReqs, cb) => {
        console.log("...using fallback");
        let done = 0;
        let size = 0;
        let times = [];

        function finishCheck() {
            done++;
            if (done !== numReqs) return;
            let min = Number.POSITIVE_INFINITY;
            let max = Number.NEGATIVE_INFINITY;
            times.forEach( function (t) {
                min = Math.min(t.start, min);
                max = Math.max(t.end, max);
            });
            const time = ((max-min)/1000.0);
            const total = ((size*8)/time)/(1<<20);
            console.log(size);
            console.log(time + "m, bytes- " + (size*8)/(1<<20).toFixed(4));
            console.log("Perf test: " + total);
            window.finalSpeed = total;
            cb(window.finalSpeed);
        }

        function onDone(req, idx) {
            times[idx].end = performance.now();
            const headers = req.getAllResponseHeaders();
            const arr = headers.trim().split(/[\r\n]+/);
            const headerMap = {};
            arr.forEach(function(line) {
                const parts = line.split(': ');
                const header = parts.shift();
                const value = parts.join(': ');
                headerMap[header] = value;
            });
            if (headerMap["Content-Length"]) size += parseInt(headerMap['Content-Length']);
            else size += parseInt(headerMap['content-length']);
            finishCheck();
        };

        function generateCallback(req, index) {
            return function () {
                if (req.readyState === XMLHttpRequest.HEADERS_RECEIVED) {
                    times[index].start = performance.now();
                }
                else if (req.readyState === XMLHttpRequest.DONE) {
                    console.log("req done", index);
                    onDone(req, index);
                }
            }
        }

        for(let i = 0; i < numReqs; i++) {
            const salt = Date.now() + i;
            const req = new XMLHttpRequest();
            req.onreadystatechange = generateCallback(req, i); // to pass correct index
            req.open('GET', '/speed/1mb.bin?$' + salt);
            req.send();
            times.push({ start: performance.now(), end: 0});
        }
}



export const thousands_separators = (num) =>
{
    let numberString = num.toString();
    // if(numberString.indexOf('.') == -1){
    //     numberString = numberString+'.0';
    // }
    const num_parts = numberString.split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts[0];
}

export const gotoLogin = () => {
    localStorage.clear();
    window.location.replace('/login');
};

export const gotoDashboard = () => {
    localStorage.clear();
    window.location.replace('/');
};

export const deleteTokenCookie = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

        if (name === 'token') {
            document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT";
        }
    }
    return true
} 

export const MAX_CHARACTERS_FEEDBACK = 500;
