import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as Content1} from '../assets/images/iconsArtboard 1.svg';
import {ReactComponent as Content2} from '../assets/images/iconsArtboard 2.svg';
import {ReactComponent as Meet1} from '../assets/images/iconsArtboard 3.svg';
import {ReactComponent as Meet2} from '../assets/images/iconsArtboard 4.svg';
import {ReactComponent as Analytics1} from '../assets/images/iconsArtboard 5.svg';
import {ReactComponent as Analytics2} from '../assets/images/iconsArtboard 6.svg';
import {ReactComponent as Settings1} from '../assets/images/iconsArtboard 7.svg';
import {ReactComponent as Settings2} from '../assets/images/iconsArtboard 9.svg';
import {ReactComponent as Help1} from '../assets/images/iconsArtboard 10.svg';
import {ReactComponent as Help2} from '../assets/images/iconsArtboard 11.svg';
import {ReactComponent as Folder} from '../assets/images/iconsArtboard 12.svg';
import {ReactComponent as PanoVideo} from '../assets/images/iconsArtboard 13.svg';
import {ReactComponent as StandardVideo} from '../assets/images/iconsArtboard 14.svg';
import {ReactComponent as Gallery} from '../assets/images/iconsArtboard 15.svg';
import {ReactComponent as PDF} from '../assets/images/iconsArtboard 16.svg';
import {ReactComponent as Profile1} from '../assets/images/iconsArtboard 19.svg';
import {ReactComponent as Profile2} from '../assets/images/iconsArtboard 20.svg';
import {ReactComponent as Notifications1} from '../assets/images/iconsArtboard 21.svg';
import {ReactComponent as Notifications2} from '../assets/images/iconsArtboard 22.svg';
import {ReactComponent as Filter1} from '../assets/images/iconsArtboard 23.svg';
import {ReactComponent as Filter2} from '../assets/images/iconsArtboard 24.svg';
import {ReactComponent as Menu1} from '../assets/images/iconsArtboard 25.svg';
import {ReactComponent as Menu2} from '../assets/images/iconsArtboard 26.svg';
import {ReactComponent as Favorite1} from '../assets/images/dropshadowiconsArtboard 1.svg';
import {ReactComponent as Favorite2} from '../assets/images/dropshadowiconsArtboard 2.svg';
import {ReactComponent as StartSession} from '../assets/images/iconsArtboard 29.svg';
import {ReactComponent as Search} from '../assets/images/iconsArtboard 30.svg';
import {ReactComponent as Upload} from '../assets/images/iconsArtboard 31.svg';
import {ReactComponent as Logout} from '../assets/images/logouticon-7_26_22.svg';
import {ReactComponent as Delete} from '../assets/images/contentmenuArtboard 7.svg';
import {ReactComponent as Invite1} from '../assets/images/iconsArtboard 38.svg';
import {ReactComponent as Invite2} from '../assets/images/iconsArtboard 39.svg';
import {ReactComponent as DropDownArrow} from '../assets/images/iconsArtboard 40.svg';
import {ReactComponent as Disable} from '../assets/images/contentArtboard 8.svg';
import {ReactComponent as ErrorHelp} from '../assets/images/contentArtboard 9.svg';
import {ReactComponent as AddToCollection} from '../assets/images/contentmenuArtboard 1.svg';
import {ReactComponent as RemovefromCollection} from '../assets/images/contentmenuArtboard 2.svg';
import {ReactComponent as CopyLink} from '../assets/images/contentmenuArtboard 3.svg';
import {ReactComponent as Download} from '../assets/images/contentmenuArtboard 4.svg';
import {ReactComponent as Rename} from '../assets/images/contentmenuArtboard 5.svg';
import {ReactComponent as Version} from '../assets/images/contentmenuArtboard 6.svg';
import {ReactComponent as Feedback} from '../assets/images/feedbackicon-7_26_22.svg';
import {ReactComponent as BoldSettings} from '../assets/images/settingsicon-7_26_22.svg';
import {ReactComponent as PointCloud} from '../assets/images/pointcloudicon-08_29_2022.svg';
import {ReactComponent as VirtualSpace} from '../assets/images/virtualspaceicon-08_29_2022.svg';


const ImgIcons = ({iconName,selected,...rest}) => {
  let svgIcon;
  switch (iconName){
    case 'Content':
      svgIcon=<SvgIcon component={selected?Content1:Content2} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Meet':
      svgIcon=<SvgIcon component={selected?Meet1:Meet2} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Analytics':
      svgIcon=<SvgIcon component={selected?Analytics1:Analytics2} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Settings':
      svgIcon=<SvgIcon component={selected?Settings1:Settings2} viewBox="0 0 64 64" {...rest}/>;
      break; 
    case 'Help':
      svgIcon=<SvgIcon component={selected?Help1:Help2} viewBox="0 0 64 64" {...rest}/>;
      break; 
    case 'Filter':
      svgIcon=<SvgIcon component={selected?Filter1:Filter2} viewBox="0 0 64 64" {...rest}/>;
      break; 
    case 'Profile':
      svgIcon=<SvgIcon component={selected?Profile1:Profile2} viewBox="0 0 64 64" {...rest}/>;
      break; 
    case 'Search':
      svgIcon=<SvgIcon component={Search} viewBox="0 0 64 64" {...rest}/>;
      break; 
    case 'CopyLink':
      svgIcon=<SvgIcon component={CopyLink} viewBox="0 0 64 64" {...rest}/>;
      break; 
    case 'Rename':
      svgIcon=<SvgIcon component={Rename} viewBox="0 0 64 64" {...rest}/>;
      break; 
    case 'Invite':
      svgIcon=<SvgIcon component={selected?Invite1:Invite2} viewBox="0 0 64 64" {...rest}/>;
      break; 
    case 'Delete':
      svgIcon=<SvgIcon component={Delete} viewBox="0 0 64 64" {...rest}/>;
      break; 
    case 'Menu':
      svgIcon=<SvgIcon component={selected?Menu1:Menu2} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'StartSession':
      svgIcon=<SvgIcon component={StartSession} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Notifications':
      svgIcon=<SvgIcon component={selected?Notifications1:Notifications2} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Favorite':
      svgIcon=<SvgIcon component={selected?Favorite1:Favorite2} viewBox="0 0 64 64" {...rest}/>;
      break; 
    case 'PanoVideo':
      svgIcon=<SvgIcon component={PanoVideo} viewBox="0 0 64 64" {...rest}/>;
      break; 
    case 'StandardVideo':
      svgIcon=<SvgIcon component={StandardVideo} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'PDF':
      svgIcon=<SvgIcon component={PDF} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Gallery':
      svgIcon=<SvgIcon component={Gallery} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Upload':
      svgIcon=<SvgIcon component={Upload} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Disable':
      svgIcon=<SvgIcon component={Disable} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'ErrorHelp':
      svgIcon=<SvgIcon component={ErrorHelp} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Download':
      svgIcon=<SvgIcon component={Download} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'AddToCollection':
      svgIcon=<SvgIcon component={AddToCollection} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Version':
      svgIcon=<SvgIcon component={Version} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Folder':
      svgIcon=<SvgIcon component={Folder} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'BoldSettings':
      svgIcon=<SvgIcon component={BoldSettings} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Feedback':
      svgIcon=<SvgIcon component={Feedback} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Logout':
      svgIcon=<SvgIcon component={Logout} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'DropDownArrow':
      svgIcon=<SvgIcon component={DropDownArrow} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'Remove': 
      svgIcon=<SvgIcon component={RemovefromCollection} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'PointCloud': 
      svgIcon=<SvgIcon component={PointCloud} viewBox="0 0 64 64" {...rest}/>;
      break;
    case 'VirtualSpace': 
      svgIcon=<SvgIcon component={VirtualSpace} viewBox="0 0 64 64" {...rest}/>;
      break;
    default:
      svgIcon=<></>;
  }
  return svgIcon
}
export default ImgIcons;
