import React from 'react'
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    borderRadius: '4px',
    border: `2px solid ${theme.palette.primary.contrastText}`,
    marginRight: '7px',
    padding: '3px 6px 0px 6px',
  },
  label: {
    fontSize: '14px',
    color: theme.palette.text.contrastText,
    fontWeight: 'bolder'
  }
}));

export default function CustomNewIcon () {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.label} variant="body1">NEW</Typography>
    </div>
  )
}
