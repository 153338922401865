import palette from "../palette";
const MuiAlert={
    filledSuccess: {
        color: palette.text.primary,
        backgroundColor: palette.background.main,
        fontSize: 16,
        fontFamilY: 'NeueHaasDisplayLight'
    },
    filledError: {
        fontSize: 16,
        fontFamily: 'NeueHaasDisplayLight'
    }
}
export default MuiAlert;
