import LoadingSpinner from "../../components/LoadingSpinner";
import React, { Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Page from "../../components/Page";
import MainDashboard from "../Dashboard/MainDashboard";
import MeetComponent from "../../views/Dashboard/360-meet";
import ContentDashboard from "./../../views/Dashboard/contents/ContentDashboard";
import CollectionDashboard from "../../views/Dashboard/collections/CollectionDashboard";
import ErrorPage from "../../views/Error/ErrorPage";
import Uploader from "../../views/Dashboard/UploadContent";
import Setting from '../../views/Settings/Setting';
import Organizations from '../../views/Dashboard/Organizations/Organizations';
import WelcomeBanner from '../../views/DemoRegister/WelcomeBanner';
import { useDispatch } from "react-redux";
import { fetchOrgDetails } from '../../actions';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Dashboard = () => {
    const dispatch = useDispatch();
    let location = useLocation();
    let query = useQuery();
    let background = location.state && location.state.background;
    const orgId = query.get('orgId');

    React.useEffect(() => {
        if(orgId){
            dispatch(fetchOrgDetails(orgId,true));
        }  
    }, [orgId,dispatch])

    return (
        <Page
            id="page-dashboard"
            style={{
                height: "100%",
                display: "flex",
                width: "100%",
                overflow: "hidden",
            }}
            title="The Lab"
        >
            <Switch location={background || location}>
                <Route
                    path={"/"}
                    render={() => (
                        <MainDashboard>
                            <Suspense fallback={<LoadingSpinner />}>
                                <Switch>
                                    <Route path={"/collections/:id/contents"} component={ContentDashboard} />
                                    <Route
                                        path={"/uploadContent"}
                                        component={Uploader}
                                    />
                                    <Route
                                        path={"/360-meet"}
                                        component={MeetComponent}
                                    />
                                    <Route
                                        path={"/view-analytics"}
                                        component={React.lazy(() =>
                                            import("../../views/Dashboard/Analytics")
                                        )}
                                    />
                                    <Route path={"/settings"} component={Setting} />
                                    <Route
                                        path={"/help"}
                                        component={React.lazy(() =>
                                            import("../../views/Dashboard/FAQsComponent")
                                        )}
                                    />
                                    <Route path={"/organizations"} component={Organizations} />
                                    <Route
                                        path={"/"}
                                        component={CollectionDashboard}
                                    />
                                    <Route path="*" component={ErrorPage} />
                                </Switch>
                            </Suspense>
                        </MainDashboard>
                    )}
                />
            </Switch>
            <WelcomeBanner />
        </Page>
    );
};

export default Dashboard;
