import { Box, Button, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles, styled } from "@mui/styles";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrgTitle } from '../../../actions';
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import { useViewport, portraitBreakPoint } from "../../contextComponent/viewportContext";
import { ORG_NAME_MAX_CHARS } from "../../../constant";

const useStyles = makeStyles((theme) => ({
    margin: {
        maxWidth: "450px",
        width: '95%',
    },
    form: {
        marginTop: "15px",
        display: 'flex',
        alignItems: 'flex-end',
        gap: '20px',
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    formLabel: {
        transform: 'none',
        position: 'relative',
        marginBottom: "15px"
    },
    nameHolder: {
        display: 'flex',
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "15px",
    },
}))


const StyledInput = styled(Input)((props) => ({
    border: props.error ? '2px solid red' : '1px solid',
    borderRadius: 25,
    fontFamily: "NeueHaasDisplayRoman",
    width: "100%",
    color: "black",
    height: "auto",
    '& .MuiInput-underline': {
        border: 'none',
        '&:hover, &:focus': {
            border: 'none',
        }
    },
    '& input': {
        padding: "5px 20px"
    },
    "&:after, &:before": {
        borderBottom: 'none !important',
    },
    "&:hover": {
        border: props.error ? '2px solid red' : '2px solid',
    }
}));

const SaveButton = styled(Button)(({ theme }) => ({
    background: theme.palette.text.contrastText,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    fontWeight: "bold",
    padding: '0px 35px',
    "&:hover": {
        background: theme.palette.primary.main,
        color: theme.palette.text.contrastText
    }
}));


const OrganizationNameForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isMobile } = useViewport();
    const { enqueueSnackbar } = useSnackbar();
    const currentOrg = useSelector(state => state.collections?.currentOrganisation);
    const [name, setName] = React.useState("");
    const [error, setError] = React.useState(false);


    const onNameChange = (e) => {
        const maxChars = ORG_NAME_MAX_CHARS;
        if (e.target.value.length > maxChars) {
            enqueueSnackbar({message:`Organization name cannot be more than ${maxChars} characters.`, variant:'error'});
        } else {
            const nameValue = e.target.value;
            setName(nameValue);
            validattion(nameValue);
        }
    }


    const isDisableButton = () => {
        return currentOrg?.title === name;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (validattion(name)) {
            const data = {
                title: name
            }
            dispatch(updateOrgTitle(currentOrg?.id, data))
                .then(res => {
                    enqueueSnackbar({ message: "Name updated successfully!", variant: 'success' });
                })
                .catch(error=>{
                    enqueueSnackbar({ message: error.response.data.message, variant: 'error' });
                })
            
        }
    }

    const validattion = (name) => {
        setError(!name);
        return !!name;
    }

    React.useEffect(() => {
        setName(currentOrg ? currentOrg.title : '');
    }, [currentOrg]);


    return (
        <Box id="org-name-form" pt={isMobile ? 1 : 2} pb={isMobile ? 1 : 2}>
            <form onSubmit={onSubmit} className={classes.form}>
                <FormControl variant="standard" className={classes.margin}>
                    <InputLabel htmlFor="full-name" className={classes.formLabel}>
                        <Typography variant='h4' color='textSecondary'>Organization Name</Typography>
                    </InputLabel>
                    <div className={classes.nameHolder}>
                        <StyledInput id="full-name" error={error} value={name} onChange={onNameChange} />
                        <SaveButton type='submit' variant="outlined" disabled={isDisableButton()}>Save</SaveButton>
                    </div>
                </FormControl>
            </form>
        </Box>
    );
}

export default OrganizationNameForm;
