import { Box, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from 'react';
import ContentHeader from "../../views/Dashboard/ContentHeader";
import UsersPage from "./User/UsersPage";
import OrganizationPage from './Organization/OrganizationPage';
import { getTokenData } from "../../actions/util";
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../contextComponent/viewportContext";
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrgDetails } from '../../actions';

const useStyles = makeStyles((theme) => ({
    tabs: {
        "& .MuiTabs-indicator": {
            height: "0px",
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: 'space-around',
        },
        "& .MuiTab-root": {
            minWidth: "180px",
            [`${landscapeBreakPoint(theme)}`]: {
                minWidth: "140px",
            },
            [`${portraitBreakPoint(theme)}`]: {
                minWidth: "80px",
            }
        }
    },
    tabsLableActive: {
        textTransform: "none",
        padding:"0",
        "& .MuiTypography-root": {
            padding: "10px 0",
            borderBottom: `4px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
        },
    },
    tabsLableInActive: {
        textTransform: "none",
        padding:"0",
        "& .MuiTypography-root": {
            color: theme.palette.primary.dark,
        },
    },
    divider: theme.typography.divider,
}));

const Setting = () => {
    const classes = useStyles();
    const { isMobile, isMobileLandscape } = useViewport();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [isOwner, setIsOwner] = React.useState(false);
    const userData = getTokenData();
    const orgOwner = useSelector(state => state.organization.orgOwner);
    const dispatch = useDispatch();
    const selectedOrg = useSelector(state => state.collections.selectedOrganisation);

    React.useEffect(() => {
        dispatch(fetchOrgDetails( (selectedOrg?.id) ? (selectedOrg?.id) : (userData?.org_id) ));
    }, [selectedOrg?.id,userData?.org_id,dispatch]);

    React.useEffect(() => {
        setIsOwner(orgOwner.email === userData.email);
    },[orgOwner,userData])

    function a11yProps(tabIndex) {
        return {
            id: `simple-tab-${tabIndex}`,
            "aria-controls": `simple-tabpanel-${tabIndex}`,
        };
    }

    const handleChangeTab = (event, newTab) => {
        setSelectedTab(newTab);
    };

    return (
        <Box height="100%" id="settings" display={"flex"} flexDirection={"column"}>
            <Box
                className={classes.divider}
                display="flex"
                justifyContent="space-between"
                flexWrap={isMobile || isMobileLandscape ? "wrap" : null}
                flexDirection={isMobile || isMobileLandscape ? "column-reverse" : "row"}
            >

                <Box
                    display="flex"
                    alignItems="flex-end"
                    flexWrap=""
                    flexDirection="row"
                    // width={'100%'}
                    >
                    <Tabs
                        className={classes.tabs}
                        style={{ width: isMobile ? "100%" : "" }}
                        value={selectedTab}
                        onChange={handleChangeTab}
                        aria-label="simple tabs"
                        centered={isMobile}
                    >
                        <Tab
                            className={selectedTab === 0 ? classes.tabsLableActive : classes.tabsLableInActive}
                            label={<Typography variant="h4">User</Typography>}
                            {...a11yProps(0)}
                        />
                        {
                            isOwner && <Tab
                                className={selectedTab === 1 ? classes.tabsLableActive : classes.tabsLableInActive}
                                label={<Typography variant="h4">Organization</Typography>}
                                {...a11yProps(1)}
                            />
                        }
                    </Tabs>
                </Box>

                <ContentHeader>
                    {(isMobile || isMobileLandscape) && (
                        <Typography>
                            Settings
                        </Typography>
                    )}
                </ContentHeader>
            </Box>
            {
                selectedTab === 0 ? <UsersPage /> : <OrganizationPage />
            }
        </Box>
    )
}

export default Setting;
