import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from "@mui/styles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { updateCollections } from "../../../actions";
import Collection from "./Collection";
import NewCollection from './NewCollection';
import { useViewport, portraitBreakPoint, landscapeBreakPoint} from "../../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflow: "unset !important",
        margin: "0 30px",
        [`${portraitBreakPoint(theme)}`]: {
            margin: '0 5px',
        },
        [`${landscapeBreakPoint(theme)}`]: {
            margin: '0 15px',
        }
    },
    gridList: {
        flexWrap: "wrap",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
        height: "100%",
        width: "100%",
        margin: "0 !important",
    },
    uploadButton: {
        height: "30px",
        borderRadius: 30,
        margin: "0 5px 0 0",
        fontWeight: "bold",
        color: theme.palette.text.main,
    },
    container: {
        display: "flex",
        flex: 1,
        overflowY: "auto",
    },
    sortButton: {
        padding: "3px 12px",
        borderRadius: "25px",
        color: theme.palette.text.main,
        fontWeight: "bold",
        margin: "0 5px",
        backgroundColor: theme.palette.secondary.contrastText,
    },
    activeButton: {
        borderRadius: "25px",
        color: theme.palette.text.contrastText,
        fontWeight: "bold",
        margin: "0 5px",
        padding: "3px 12px",
        backgroundColor: theme.palette.primary.main,
        "&.MuiButton-text:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const Collections = ({ isSessionView, setEnableContentViewId, selectedTab }) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    
    const { isMobileLandscape} = useViewport();
    const collections = useSelector((state) => state.collections.collections);
    const isMeetingView = useSelector(state => state.session.isMeetingView);

    const onClickCollection = (collection) => {
        if (isSessionView) {
            setEnableContentViewId(collection.id);
        } else {
            history.push({
                pathname: "/collections/" + collection.id + "/contents",
                search: location.search
            });
        }
    };

    const loadMore = () => {
        dispatch(updateCollections({ page: { ...collections.page, size: collections.page.size + 10 } }));
    };

    return !collections.items || collections.items.length === 0 ? (
        <Typography variant={"h3"} style={{ textAlign: "center", margin: "auto" }}>No results found.</Typography>
    ) : (
        <div style={{flexGrow:1,height:0}}>
            <div
                style={{
                    margin: "0",
                    width: "calc(100%- 40px)",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div id="scrollDiv" style={{ overflowY: "auto", overflowX: "hidden", height: "100%"}}>
                    <InfiniteScroll
                        className={classes.root}
                        dataLength={collections.items?.length} //This is important field to render the next data
                        next={loadMore}
                        hasMore={collections.count !== collections.items?.length}
                        loader={<Typography align="center">Loading...</Typography>}
                        // below props only if you need pull down functionality
                        scrollableTarget="scrollDiv"
                        refreshFunction={() => {}}
                        pullDownToRefresh={false}
                        pullDownToRefreshThreshold={50}
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>&#8595; Pull down to load data</h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>&#8593; Release to load data</h3>
                        }
                    >
                        {!isMeetingView && (
                            <Grid container spacing={2} className={classes.container}>
                                { selectedTab === "" &&
                                    <NewCollection />
                                }

                                {collections.items.length > 0 &&
                                    collections.items.map((collection) => (
                                        <Collection
                                            key={collection?.id}
                                            collection={collection}
                                            fromMeeting={false}
                                            onSelectCollection={() => onClickCollection(collection)}
                                            selectedTab={selectedTab}
                                        />
                                    ))}
                            </Grid>
                        )}
                        {isMeetingView && (
                            <Grid container className={classes.containerSession}>
                                {collections.items.length > 0 &&
                                    collections.items.map((collection) => (
                                        <Grid item xs={isMobileLandscape ? 3 : 6} sm={isMobileLandscape ? 3 : 4} md={isMobileLandscape ? 3 : 3} lg={isMobileLandscape ? 3 : 3} key={collection?.id}>
                                            <Collection
                                                collection={collection}
                                                fromMeeting={true}
                                                onSelectCollection={() => onClickCollection(collection)}
                                                selectedTab={selectedTab}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        )}
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
};

export default Collections;
