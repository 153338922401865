import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import React from 'react';
import EmailForm from './EmailForm';
import UserNameForm from './UserNameForm';
import PasswordChange from './PasswordChange';
import TimeZoneForm from './TimeZoneForm';
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    title: {
        paddingBottom: theme.spacing(1),
        fontSize: "22px",
        [`${portraitBreakPoint(theme)}`]: {
            fontSize: "18px",
        },
        [`${landscapeBreakPoint(theme)}`]: {
            fontSize: "18px",
        },
    },
    divider: {
        width: "60%",
        borderColor: theme.palette.text.light,
        [`${portraitBreakPoint(theme)}`]: {
            width: "100%",
        },
        [`${landscapeBreakPoint(theme)}`]: {
            width: "100%",
        },
        marginBottom:'10px'
    },
    root:{
        display:'flex',
        flexDirection: 'column',
        margin:"0px"
    },
    form:{
      marginBottom: "40px"
    }
}))

const UsersPage = () => {
    const classes = useStyles();
    const { isMobile, isMobileLandscape } = useViewport();

    return (
        <Box id="user-page" className={classes.root} p={4} pt={3} pb={isMobile ? 0 : 2} height="100%" style={{ overflowY: 'auto', overflowX: isMobileLandscape ? 'auto' : 'hidden' }}>
            <Box className={classes.form}>
                <Typography variant='h4' className={classes.title}>Account</Typography>
                <Divider varient="middle" className={classes.divider} />
                <UserNameForm />
                <EmailForm />
            </Box>
            <Box className={classes.form}>
                <Typography variant='h4' className={classes.title}>Password</Typography>
                <Divider varient="middle" className={classes.divider} />
                <PasswordChange />
            </Box>
            <Box className={classes.form}>
                <Typography variant='h4' className={classes.title}>Timezone</Typography>
                <Divider varient="middle" className={classes.divider} />
                <TimeZoneForm />
            </Box>
        </Box>
    )
}

export default UsersPage;
