import { Box, Tab, Tabs, Typography, Button, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import React from 'react';
import { useDispatch, useSelector} from "react-redux";
import ContentHeader from "../../Dashboard/ContentHeader";
import OrganizationPage from '../../Settings/Organization/OrganizationPage';
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../../contextComponent/viewportContext";
import OrganisationFilter from "../collections/OrganisationFilter"
import DialogMigrate from "../../../components/DialogMigrate";
import { RENAME_CONTENT_MAX_CHARS } from "../../../constant";
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import { addNewOrganization } from "../../../actions/OganizationActions";
import { fetchOrgDetails } from '../../../actions';
import { getTokenData } from "../../../actions/util";

const useStyles = makeStyles((theme) => ({
    tabs: {
        "& .MuiTabs-indicator": {
            height: "0px",
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: 'space-around',
        },
        "& .MuiTab-root": {
            minWidth: "180px",
            [`${landscapeBreakPoint(theme)}`]: {
                minWidth: "140px",
            },
            [`${portraitBreakPoint(theme)}`]: {
                minWidth: "80px",
            }
        }
    },
    tabsLableActive: {
        textTransform: "none",
        padding:"0",
        "& .MuiTypography-root": {
            padding: "10px 0",
            borderBottom: `4px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
        },
    },
    tabsLableInActive: {
        textTransform: "none",
        padding:"0",
        "& .MuiTypography-root": {
            color: theme.palette.primary.dark,
        },
    },
    divider: theme.typography.divider,
    filter:{
        width: "450px",
        marginTop: "40px",
        marginLeft: "20px",
        display: 'flex',
        justifyContent: 'space-between',
        "& button": {
            padding: "2px 20px"
        }
    },
    dialogButtons: {
        borderRadius: 30,
        height: 32,
        fontFamily: "NeueHaasDisplayBold",
        "&:hover": {
            borderRadius: 30,
        },
    },
    renameDialog: {
        border: '2px solid gray',
        borderRadius: 30,
        height: 25,
        width: '97%',
        padding: '1px 0px 7px 10px'
    },
    buttonContainer: {
        height: 50,
        paddingRight: '5%',

    },
    dialogDivider: {
        display: 'flex',
        width: '90%',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'unset',
        gap: '10px',
        padding: '8px 30px'
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    background: theme.palette.text.contrastText,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    padding: '4px 35px',
    fontWeight: "bold",
    "&:hover": {
        background: theme.palette.primary.main,
        color: theme.palette.text.contrastText
    }
}));

const Organization = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isMobile, isMobileLandscape } = useViewport();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [show, setShow] = React.useState(false);
    const [newOrganizationName, setNewOrganizationName] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();
    const isViewlabsUser = useSelector(state => state.application.userDetails?.is_viewlabs_user);
    const selectedOrg = useSelector(state => state.collections.selectedOrganisation);
    const currentOrg = useSelector(state => state.collections.currentOrganisation);
    let userData = getTokenData();

    React.useEffect(() => {
        dispatch(fetchOrgDetails( (selectedOrg?.id) ? (selectedOrg?.id) : (userData?.org_id) ));
    }, [selectedOrg?.id,userData?.org_id,dispatch]);

    function a11yProps(tabIndex) {
        return {
            id: `simple-tab-${tabIndex}`,
            "aria-controls": `simple-tabpanel-${tabIndex}`,
        };
    }

    const handleChangeTab = (event, newTab) => {
        setSelectedTab(newTab);
    };

    const toggleShow = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShow(!show);
        setNewOrganizationName("");
    }

    const handleChange = (event) => {

        const maxChars = RENAME_CONTENT_MAX_CHARS;
        if (event.target.value.length > maxChars) {
            enqueueSnackbar({message:`Organization name cannot be more than ${maxChars} characters.`, variant:'error'});
        } else {
        setNewOrganizationName(event.target.value);
        }
    }

    const handleCreateOrganization = async (e) => {
        e.preventDefault();

        if(newOrganizationName !== ''){
            await dispatch(addNewOrganization(newOrganizationName));
            enqueueSnackbar({message:"New organization created successfully!", variant:'success'});
            setShow(!show);
        }else{
            enqueueSnackbar({message:'Organization name cannot be empty.', variant:'error'});
        }
    }

    return (
        <>
        {isViewlabsUser &&
        <Box height="100%" id="organizations" display={"flex"} flexDirection={"column"}>
            <Box
                className={classes.divider}
                display="flex"
                justifyContent="space-between"
                flexWrap={isMobile || isMobileLandscape ? "wrap" : null}
                flexDirection={isMobile || isMobileLandscape ? "column-reverse" : "row"}
            >

                <Box
                    display="flex"
                    alignItems="flex-end"
                    flexWrap=""
                    flexDirection="row"
                    // width={'100%'}
                    >
                    <Tabs
                        className={classes.tabs}
                        style={{ width: isMobile ? "100%" : "" }}
                        value={selectedTab}
                        onChange={handleChangeTab}
                        aria-label="simple tabs"
                        centered={isMobile}
                    >
                        <Tab
                            className={selectedTab === 0 ? classes.tabsLableActive : classes.tabsLableInActive}
                            label={<Typography variant="h4">Organizations</Typography>}
                            {...a11yProps(0)}
                        />
                    </Tabs>
                </Box>

                <ContentHeader>
                    {(isMobile || isMobileLandscape) && (
                        <Typography>
                            Organizations
                        </Typography>
                    )}
                </ContentHeader>
            </Box>
            <div className={classes.filter}>
                <OrganisationFilter />
                <Button variant="outlined" onClick={toggleShow}>Add Organization</Button>          
            </div>
            {selectedOrg && currentOrg &&
                <OrganizationPage />
            }
        </Box>
        }
        <DialogMigrate open={show} onClose={toggleShow} fullWidth>
            <div>
                <DialogTitle variant={"h4"} color="primary" id="create-organization-dialog" style={{ padding: '24px 24px 2px 30px',fontSize: '24px' }}>
                    Add New Organization Card
                </DialogTitle>
                <hr className={classes.dialogDivider} />
                <DialogContent id="create-organization-dialog-content" className={classes.dialogContent}>
                    <Typography variant={"h4"} color="textSecondary">Organization Name</Typography>
                    <form id="create-organization-from" onSubmit={handleCreateOrganization} style={{ width: '100%' }}>
                        <TextField
                            variant="standard"
                            className={classes.renameDialog}
                            fullWidth
                            value={newOrganizationName}
                            onChange={handleChange}
                            autoComplete="off"
                            InputProps={{
                                disableUnderline: true,
                                max: 3,
                            }} />
                    </form>
                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <StyledButton variant="outlined" onClick={toggleShow} className={classes.dialogButtons}>
                        Cancel
                    </StyledButton>
                    <StyledButton
                        variant="outlined"
                        className={classes.dialogButtons}
                        type={'submit'}
                        form={"create-organization-from"}
                    >
                        Create
                    </StyledButton>
                </DialogActions>
            </div>
        </DialogMigrate>
        </>
    )
}

export default Organization;
