import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

const loggerMiddleware = createLogger();
function noop() {}
export default function configureStore(preloadedState = {}) {
  // const middlewares = [thunkMiddleware,loggerMiddleware]; // loggerMiddleware
  let middlewares = [thunk,loggerMiddleware];  // loggerMiddleware
  if (process.env.REACT_APP_STAGE === 'dev') {
    middlewares = [thunk,loggerMiddleware];
  }else{
    middlewares = [thunk];
    console.log = noop;
    console.warn = noop;
    console.error = noop;
  }
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);
  window.store = store;

  return store;
}
