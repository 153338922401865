import { Box, Button, IconButton, TextField, Typography, InputLabel } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { makeStyles } from "@mui/styles";
import axios, { isCancel } from "axios";
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { addUpload, removeProgressBar } from "../../../actions";
import {
    getContentTypesFromDB, signedUrl, uploadComplete
} from "../../../actions/uploadActions";
import infoImg from "../../../assets/images/infoIcon.png";
import UploadStatus from './UploadStatus';
import ContentHeader from '../ContentHeader';
import { v4 as uuidv4 } from 'uuid';
import CollectionSelector from "../contents/CollectionSelector";
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../../contextComponent/viewportContext";
import { setRouterState } from "../../../actions/ApplicationActions";
import { RENAME_CONTENT_MAX_CHARS } from "../../../constant";
import { TYPEID } from "../../../constant";
import { getTokenData } from '../../../actions/util';

const useStyles = makeStyles((theme) => ({
    backToHub: {
        display: "inline-flex",
        whiteSpace: 'nowrap',
        alignItems: "center",
        "& .MuiIconButton-root": {
            padding: '4px 10px 4px 0px',
            marginLeft: 0,
            margin: 0,
            borderRadius: 5,
            fontFamily: "NeueHaasDisplayMedium",
            fontSize: "1rem",
            userSelect: 'none',
            color: theme.palette.text.navy,
        },
        "& .MuiSvgIcon-root": {
            fontSize: "1.8rem"
        },
        "&:hover":{
            cursor: "pointer"
        }
    },
    divider: theme.typography.divider,
    backIcon: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: "33px",
        marginRight: "14px",
    },
    faqIcon: {
        marginRight: "4%",
    },
    faqImg: {
        height: "22px",
        opacity: "0",
        transition: "0.2s ease-in-out",
        "&:hover": {
            opacity: "1",
        },
    },
    uploadHeading: {
        color: theme.palette.secondary.dark,
        marginBottom: "20px",
        marginTop: "10px",
    },
    uploadForm: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: 10,
        padding: '0 10px'
    },
    defaultSelected: {
        color: theme.palette.primary.main,
        opacity: "1 !important",
    },
    selectField: {
        border: "none",
        cursor: "pointer",
        fontSize: "18px",
    },
    select: {
        "& ul": {
            padding: "0px",
        },
        "& li": {
            "&.MuiMenuItem-root": {
                backgroundColor: `${theme.palette.background.paper}!important`,
            },
        },
    },
    selectIcon: {
        "&.MuiSelect-icon": {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.primary.contrastText,
            borderRadius: "33px",
            fontSize: "large",
        },
    },
    textField: {
        border: "none",
        fontSize: "18px",
        WebkitTextFillColor: theme.palette.secondary.dark,
    },
    textFieldTyping: {
        border: "none",
        fontSize: "18px",
        WebkitTextFillColor: theme.palette.primary.main,
    },
    dropzone: {
        border: `2px dashed ${theme.palette.secondary.dark}`,
        margin: "0px 0px 15px 0px",
        padding: "7px 10px 0px 10px",
        minHeight: "130px",
        height: "130px",
        overflowY: "auto",
        overflowX: "hidden",
        "&:hover": {
            cursor: "pointer",
        },
    },
    addFilePromt: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "24px",
        textAlign: 'center',
    },
    addAnotherFile: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "12px",
    },
    addFileIcon: {
        marginLeft: "15px",
        fontSize: "large",
    },
    removeFileIcon: {
        fontSize: "medium",
        margin: "0px 12px 0px -9px",
    },
    browseForFileText: {
        display: "flex",
        alignItems: "center",
    },
    maxOneFile: {
        marginBottom: "12px",
    },
    fileName: {
        fontSize: "14px",
        textAlign: "left",
        lineHeight: 1.2,
        marginLeft: "7px",
    },
    removeIcon: {
        "&:hover": {
            color: "red",
            transition: "0.5s",
        },
    },
    acceptedTypes: {
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: "11px",
        lineHeight: "0.2",
        textAlign: "left",
        marginTop: "5px",
    },
    startUploadButton: {
        height: "32px",
        borderRadius: 30,
        backgroundColor: theme.palette.primary.main,
        marginRight: "auto",
        marginLeft: "auto",
        fontWeight: "bold",
        color: theme.palette.primary.contrastText,
        // width: "326px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        [`${landscapeBreakPoint(theme)}`]: {
            marginBottom: "30px",
            maxWidth: "300px",
            minWidth: "200px",
        },
        [`${portraitBreakPoint(theme)}`]: {
            maxWidth: "326px",
            minWidth: "120px",
        },
    },
    mainSection: {
        display: 'flex',
        flex: 1,
        overflow: 'auto',
        margin: '10px',
        [`${landscapeBreakPoint(theme)}`]: {
            overflowY: 'auto',
            overflowX: 'hidden',
            marginBottom: "20px"
        },
    },
    mainContainer: {
        display: 'flex', 
        height: '100%', 
        width: '100%',
        flexWrap: 'nowrap',
        [`${portraitBreakPoint(theme)}`]: {
            flexWrap: 'wrap',
        },
    },
}));

const UploadContent = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { isMobile, isMobileLandscape } = useViewport();
    const [userFiles, setUserFiles] = useState([]);
    const [contentTypes, setContentTypes] = useState();
    const [contentType, setContentType] = useState(-1);
    const [contentTypeData, setContentTypeData] = useState(undefined);
    const [contentTitle, setContentTitle] = useState("");
    const [initRouterState, setInitRouterState] = useState(true);
    const selectedCollection = useSelector(state => state.collections.selectedCollection);
    const routerState = useSelector(state => state.application.routerState);
    const userTokenData = getTokenData();
    const selectedOrg = useSelector(state => state.collections.selectedOrganisation);


    //TODO: If user accesses uploader from 'Add PDF' button on session page, the content type state is hardcoded as '3'.
    // The content type value needs to be set dynamically to adjust for any changes that are made in the backend.

    useEffect(() => {
            // Gets the content types to display in select menu. Will display a loading spinner if the API call is in progress.
        const getContentTypes = async () => {
            dispatch(getContentTypesFromDB())
                .then((res) => {
                    setContentTypes(res.data);
                })
                .catch((err) => {
                    console.log(err);
                    enqueueSnackbar({ message: "Sorry, there may have been an error when loading this page. Please refresh and check your internet connection if you are having issues.", variant: 'error' });
                })
        };
        getContentTypes();
    }, [dispatch]);

    useEffect(() => {
        if (window.location.href.includes("/session/")) {
            setContentType(3);
        }
        if(initRouterState){
            setInitRouterState(false);
            dispatch(setRouterState({
                ...routerState,
                page: history.location.pathname
            }));
        }
    }, [dispatch, history.location.pathname, initRouterState, routerState]);

    useEffect(() => {
        if (contentTypes) {
            setContentTypeData(contentTypes.find(ct => ct.id === contentType));
        }
    }, [contentType, contentTypes]);

    const goBackToHub = () => {
        history.push(routerState.uploaderBack);
    };

    const resetForm = () => {
        setContentTitle("");
        setUserFiles([]);
    }

    // When content type is selected from the drop down menu
    const contentSelected = (e) => {
        setContentType(e.target.value);
        // return true;
    };

    const listFileTypes = (fileTypes) => {
        let acceptedTypesArray = fileTypes.replace(/['"]+/g, ""); // Removes quotes from string.
        acceptedTypesArray = acceptedTypesArray.replace(/[[\]']+/g, ""); // Removes set of brackets in string.
        acceptedTypesArray = acceptedTypesArray.replace(/,/g, ', '); // Adds space after comma
        return `( ${acceptedTypesArray} )`
    }

    // Step 5. Sending 'complete = true' param.
    const getCompletedUrl = async (data) => {
        dispatch(uploadComplete(selectedCollection.id, contentType, data.uuid))
    };

    // Step 4. Attaching the file and sending to s3 using the second presigned url.
    const uploadFileToS3 = (data) => {
        return new Promise((resolve, reject) => {
            let percent = 0;
            let uid = uuidv4();
            let source = axios.CancelToken.source();

            axios({
                url: data.url,
                method: "PUT",
                data: data.file,
                headers: { "Access-Control-Allow-Origin": "*" },
                cancelToken: source.token,
                onUploadProgress: (progressEvent) => {
                    percent = Math.round((100 * progressEvent.loaded) / progressEvent.total);
                    let newBar = {
                        id: uid,
                        date: new Date(),
                        type: contentTypeData.label,
                        title: contentTitle,
                        name: data.fileName,
                        bgcolor: "#33B0FF",
                        percentage: percent,
                        cancelSource: source,
                    };
                    dispatch(addUpload(newBar, uid));
                },
            })
                .then((res) => {
                    resolve(data)
                })
                .catch((err) => {
                    console.log(err);
                    reject(err)
                    if (isCancel(err)) {
                        enqueueSnackbar({ message: err.message, variant: 'error' });
                        // getCompletedUrlCancelled(data);
                    }
                    dispatch(removeProgressBar({ id: uid }));
                });
        })
    };

    // Step 1. Initial API call that receives the aws signed url, and sets the url as well as the upload ID.
    const getSignedUrl = async () => {
        const data = {
            contentTypeID: contentType,
            title: contentTitle,
            fileNames: userFiles.map(x => x.name),
            upload_method: 'thelab'
        }

        dispatch(signedUrl(selectedCollection.id, data)).then(response => {
            const filesData = [...response.data].map(data => {
                const fileIndex = userFiles.findIndex(x => x.name === data.fileName)
                return {
                    ...data,
                    file: userFiles[fileIndex]
                }
            })


            Promise.all(filesData.map(file => uploadFileToS3(file)))
                .then(async () => {
                    console.log("All Upload Done")
                    await getCompletedUrl(filesData[0]);
                    enqueueSnackbar({ message: "File(s) Uploaded!", variant: "success" });
                }).catch(err => {
                    console.log(err)
                    enqueueSnackbar({ message: "Oops! Something went wrong while uploading.", variant: "error" })
                }).finally(() => {

                })
        }).catch(error => {
            enqueueSnackbar({ message: "Oops! Something went wrong while uploading.", variant: "error" });

        });
        resetForm();
    };



    // Removes the file that is stored in state.
    const handleRemoveFile = (val) => {
        const newFiles = userFiles.filter((file) => file !== val);
        setUserFiles(newFiles);
    };


    // Opens the FAQ page or component
    const onClickFAQ = () => {
        history.push("/help")
    };

    // Checks that file types are acceptable for the selected content type.
    const validateAndStartUpload = () => {
        let acceptedTypesArray = contentTypeData.request_types.replace(/['"]+/g, ""); // Removes quotes from string.
        acceptedTypesArray = acceptedTypesArray.replace(/[[\]']+/g, ""); // Removes set of brackets in string.
        acceptedTypesArray = acceptedTypesArray.split(","); // Turns into an array of strings.
        let userTypesArray = [];
        userFiles.map((file) => userTypesArray.push(file.type));
        userTypesArray = [...new Set(userTypesArray)]; // Removes duplicate types in the array
        let filteredAcceptedTypes = acceptedTypesArray.filter((type) => userTypesArray.includes(type));

        if (filteredAcceptedTypes.length !== userTypesArray.length) {
            enqueueSnackbar({ message: "Sorry, some or all of the submitted files do not match the accepted file types.", variant: 'error' });
        }

        filteredAcceptedTypes.sort();
        userTypesArray.sort();

        for (let i = 0; i < filteredAcceptedTypes.length; i++) {
            if (filteredAcceptedTypes[i] !== userTypesArray[i]) {
                enqueueSnackbar({ message: "Sorry, some or all of the submitted files do not match the accepted file types.", variant: 'error' });
            } else if (contentTypeData.id === TYPEID.video360) {
                
                // This makes sure that 360 videos have a 2:1 aspect ratio
                let video = document.createElement("video");
                video.onloadedmetadata = () => {
                    let aspectRatio = 0;
                    aspectRatio = video.videoWidth / video.videoHeight;

                    if (aspectRatio > 1.996 && aspectRatio < 2.004) {
                        return getSignedUrl(); // This starts the api calls to upload the file
                    } else {
                        enqueueSnackbar({ message: "Sorry, with '360 Video' only videos with an aspect ratio of 2:1 are accepted.", variant: 'error' });
                    }
                };
                video.src = URL.createObjectURL(userFiles[0]);
            } else {
                return getSignedUrl(); // This starts the api calls to upload the file
            }
        }
    };

    // Executes when user hits submit or what is currently labeled as 'Start Upload' button.
    const handleContentSubmit = e => {
        e.preventDefault();

        const fileNamesArray = userFiles.map((file) => file.name);

        const checkDuplicates = (arr) => {
            return new Set(arr).size !== arr.length;
        };

        if (contentType === -1 || !contentTitle || !selectedCollection) {
            enqueueSnackbar({ message: "Please fill out the content type, title, and assign it to a collection.", variant: 'error' });
        } else if (contentTypeData.id !== TYPEID.imageGallery && userFiles.length > 1) {
            enqueueSnackbar({ message: "For this content type, please submit one file at a time.", variant: 'error' });
        } else if (userFiles.length <= 0) {
            enqueueSnackbar({ message: "Please select a file to upload.", variant: 'error' });
        } else if (checkDuplicates(fileNamesArray)) {
            enqueueSnackbar({ message: "Please make sure there are no duplicate file names.", variant: 'error' });
        } else {
            validateAndStartUpload(); // This starts the process to make sure file type(s) submitted are ok and match up with the accepted file types on the backend.
        }
    };

    const handleChange = (event) => {

        const maxChars = RENAME_CONTENT_MAX_CHARS;
        if (event.target.value.length > maxChars) {
            enqueueSnackbar({message:`Content name cannot be more than ${maxChars} characters.`, variant:'error'});
        } else {
            setContentTitle(event.target.value)
        }
    }

    return <>
        <Box id="uploader" height={"100%"} display="flex" flexDirection={"column"}>
            <div className={!isMobile && !isMobileLandscape ? classes.divider : null}>
                <ContentHeader>
                    <div className={classes.backToHub} onClick={goBackToHub}>
                        <IconButton size="large">
                            <ChevronLeftIcon />
                            Back to Hub
                        </IconButton>
                    </div>
                </ContentHeader>
            </div>
            <Box height={"100%"} className={classes.mainSection}>
                <Grid container className={classes.mainContainer}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Grid container justifyContent="center">
                            <Grid item xs={10} lg={10} xl={10} className={classes.uploadForm}>
                            {selectedOrg && userTokenData.org_id !== selectedOrg.id &&
                            <div style={{marginTop: '10px'}}>
                                <Typography variant={"h4"} color="textSecondary">Organization</Typography>
                                <Typography variant={"subtitle2"} style={{marginTop: "10px"}} color="textSecondary">{selectedOrg.title}</Typography>
                            </div>
                            }
                                <Typography className={classes.uploadHeading} variant="h4">
                                    Upload Content to {selectedCollection?.title}
                                </Typography>


                                <FormControl variant="standard">
                                    <TextField
                                        variant="standard"
                                        className={contentTitle ? classes.textFieldTyping : classes.textField}
                                        name="content title"
                                        label="Title"
                                        value={contentTitle}
                                        type="text"
                                        placeholder="Enter Content Title..."
                                        autoComplete="off"
                                        onChange={handleChange} />
                                </FormControl>

                                    <FormControl variant="standard">
                                        <InputLabel htmlFor="uncontrolled-native">Content Type</InputLabel>
                                        <Select
                                            variant="standard"
                                            className={classes.selectField}
                                            name="content type"
                                            defaultValue={-1}
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            inputProps={{
                                                classes: { icon: classes.selectIcon },
                                                name: 'name',
                                                id: 'uncontrolled-native'
                                            }}
                                            onChange={contentSelected}
                                            value={contentType}
                                        >
                                            <MenuItem
                                                className={classes.defaultSelected}
                                                value={-1}
                                                disabled
                                            >
                                                Select Content Type
                                            </MenuItem>
                                            {contentTypes ? (
                                                contentTypes.map((type) => (
                                                    <MenuItem key={type.id} value={type.id}>{type.label}</MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem>Error, No Content Types To Display</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard">
                                        <CollectionSelector showCanUploadContent />
                                    </FormControl>

                                <Dropzone
                                    disabled={!contentType}
                                    accept={
                                        contentTypes
                                            ? contentTypeData?.request_types.replace(
                                                /[[\]']+/g,
                                                ""
                                            ) // Displays the file types set by the backend and then removes array brackets so React Dropzone can read the strings
                                            : null
                                    }
                                    onDrop={(acceptedFiles) => {
                                        if (contentType === 6) {
                                            if (acceptedFiles.filter(f => (f.name.split('.').pop() !== 'ply' && f.name.split('.').pop() !== 'drc')).length > 0) {
                                                enqueueSnackbar({ message: "Sorry, cannot accept this file. Please review the accepted file types.", variant: 'error' })
                                                return;
                                            }                                            
                                        } 

                                        setUserFiles([...userFiles, ...acceptedFiles]);
                                    }}
                                    onDropRejected={() => (
                                        enqueueSnackbar({ message: "Sorry, cannot accept this file. Please review the accepted file types.", variant: 'error' })
                                    )}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className={classes.dropzone}>
                                            <div {...getRootProps()} style={{ height: "100%" }}>
                                                <input {...getInputProps()} />
                                                {userFiles.length < 1 ? (
                                                    <div className={classes.addFilePromt}>
                                                        <div className={classes.browseForFileText}>
                                                            <Typography variant="body1">
                                                                Browse for File
                                                            </Typography>
                                                            <AddCircleIcon
                                                                className={classes.addFileIcon}
                                                            />
                                                        </div>
                                                        <Typography variant="body1">
                                                            Or Drag-n-Drop Here
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {contentTypes && contentType > 0 &&
                                                                listFileTypes(contentTypeData ? (contentTypeData.file_types || "") : "" )
                                                            }
                                                        </Typography>
                                                    </div>
                                                ) 
                                                : 
                                                contentTypeData?.id === TYPEID.imageGallery ? (
                                                    <>
                                                        <div className={classes.addAnotherFile}>
                                                            <Typography variant="body1">
                                                                Add Another Image
                                                            </Typography>
                                                            <AddCircleIcon
                                                                className={classes.addFileIcon}
                                                            />
                                                        </div>
                                                        <div className={classes.fileName}>
                                                            {userFiles.map((file, index) => (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-start",
                                                                        marginBottom: "12px",
                                                                    }}
                                                                >
                                                                    {/* This span element that is wrapped around the remove button prevents the file menu from popping up. */}
                                                                    <span
                                                                        {...getRootProps({
                                                                            onClick: (e) =>
                                                                                e.stopPropagation(),
                                                                        })}
                                                                        className={classes.removeIcon}
                                                                    >
                                                                        <RemoveCircleIcon
                                                                            className={
                                                                                classes.removeFileIcon
                                                                            }
                                                                            onClick={() =>
                                                                                handleRemoveFile(file)
                                                                            }
                                                                        />
                                                                    </span>
                                                                    <span>{file.name}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div
                                                        {...getRootProps({
                                                            onClick: (e) => e.stopPropagation(),
                                                        })}
                                                    >
                                                        <Typography
                                                            className={classes.maxOneFile}
                                                            variant="body1"
                                                        >
                                                            Max 1 file per upload
                                                        </Typography>
                                                        <div className={classes.fileName}>
                                                            {userFiles.map((file, index) => (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-start",
                                                                        marginBottom: "12px",
                                                                    }}
                                                                >
                                                                    {/* This span prevents file upload on click just in case code above fails.*/}
                                                                    <span
                                                                        {...getRootProps({
                                                                            onClick: (e) =>
                                                                                e.stopPropagation(),
                                                                        })}
                                                                        className={classes.removeIcon}
                                                                    >
                                                                        <RemoveCircleIcon
                                                                            className={
                                                                                classes.removeFileIcon
                                                                            }
                                                                            onClick={() =>
                                                                                handleRemoveFile(file)
                                                                            }
                                                                        />
                                                                    </span>
                                                                    <span>{file.name}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className={classes.acceptedTypes}>
                                                    {contentTypes && (
                                                        <>
                                                            <p>
                                                                Accepted File Types for{" "}
                                                                {contentTypeData?.label}:
                                                            </p>
                                                            <p>
                                                                {contentTypeData?.request_types
                                                                    .replace(/['",]+/g, "$&\n")
                                                                    .replace(/[[\]']+/g, "")
                                                                    .replaceAll(/image[/]/gi, ".")
                                                                    .replaceAll(/application[/]/gi, ".")
                                                                    .replaceAll(/video[/]/gi, ".")}
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>

                                <Button
                                    className={classes.startUploadButton}
                                    type="submit"
                                    onClick={handleContentSubmit}
                                    fullWidth
                                    // disabled={isLoading}
                                >
                                    Start Upload
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} xl={8} lg={8}>
                        <Grid container justifyContent="center" style={{ height: '90%' }}>
                            <UploadStatus />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </>;
};


export default UploadContent;
