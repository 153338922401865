import {
    IconButton,
    Typography,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
    makeContentFavorite,
    removeContentFromStore,
} from "../../../actions/CollectionAction";
import ImgIcons from "../../../components/ImgIcons";
import "./content.css";
import ContentCard from "./ContentCard";
import ContentRenameDialog from "./ContentRenameDialog";
import AssignContentDialog from './AssignContentDialog'
import { useSnackbar } from 'notistack';
import { useViewport, portraitBreakPoint } from "../../contextComponent/viewportContext";
import ContentMenu from './ContentMenu';
import ContentDrawer from './ContentDrawer';
import ContentDeleteDialog from './ContentDeleteDialog';
import ContentRemoveDialog from './ContentRemoveDialog';
import CustomTooltip from "../../../components/CustomTooltip";

const FAILED_STATUS = ['FAILED','TRANSCODER_ABORTED'];

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        color: theme.palette.text.secondary,
        margin: "5px",
        cursor: "pointer",
        flexDirection: "column",
        WebkitTapHighlightColor: 'transparent',
    },
    imageSection: {
        flexBasis: "85%",
        border: "1px solid",
        borderRadius: "5px",
        borderColor: theme.palette.text.secondary,
    },
    title: {
        width: 'calc(100% - 30px)',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontWeight: "bold",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.primary.dark,
    },
    error: {
        width: 'calc(100% - 30px)',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontWeight: "bold",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.error.main,
    },
    meetingTitle: {
        width: 'calc(100% - 30px)',
        fontSize: "14px",
        marginLeft: "12px",
        "&.MuiTypography-h4": {
            lineHeight: "16px",
        },
    },
    selectedTitle: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontWeight: "bold",
    },
    contentPreview: {
        height: "100%",
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    previewText: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        fontFamily: "NeueHaasDisplayMedium",
    },
    previewTitle: {
        fontSize: "18px",
        color: theme.palette.primary.dark,
        fontFamily: "NeueHaasDisplayBold",
    },
    content: {
        position: "relative",
        height: 'calc(100% - 160px)',
        overflow: 'hidden',
    },
    dialogRoot: {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.text.secondary,
        borderRadius: "11px",
        width: '80%',
        height: '90%',
        maxWidth: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        [`${portraitBreakPoint(theme)}`]: {
            width: "96%"
        },
    },
    actionButtons: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.contrastText,
        borderRadius: 30,
        padding: "10px 55px",
        height: 32,
        fontFamily: "NeueHaasDisplayBold",
        "&:hover": {
            backgroundColor: theme.palette.button.hover,
        },
    },
    activeBorder: {
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        boxShadow: `1px 1px 2px ${theme.palette.text.medium}`,
    },
    contentImageHolder: {
        display: "flex",
        cursor: "pointer",
        flexDirection: "column",
        margin: "5px",
        position: "relative",
    },
    favoriteIcon: {
        color: theme.palette.primary.main,
        fontSize: "30px",
        position: "absolute",
        bottom: "12px",
        right: "12px",
    },
    loader: {
        position: "absolute",
        top: "calc(50% - 20px)",
        left: "calc(50% - 20px)",
    },
    menuItemIcon: {
        height: "20px",
        position: "absolute",
    },
    menuItem: {
        
    },
    disabledMenuItem: {
        backgroundColor:  theme.palette.secondary.light,
        "& .MuiTypography-root":{
            color: theme.palette.text.medium
        }
    },
    noteButton: {
        cursor: 'pointer',
        marginRight: '10px',
    }
}));

export const Content = ({ 
    content, selectedContents, fromMeeting, selectedTab, 
    setCurrentVersion, loadPlayerContent, loadContent, setOpen, setOpenNotes, handleClose, showContent
}) => {
    const classes = useStyles();
    const { isMobile } = useViewport();
    const [isContentLiked, setIsContentLiked] = React.useState(content.isFavMarked);
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();
    const [openToggle, setOpenToggle] = useState(false);
    const anchorRef = React.useRef(null);
    const meetingView = useSelector((state) => state.session.isMeetingView);
    const [showRenameDialogBox, setShowRenameDialogBox] = React.useState(false);
    const [showDeleteDialogBox, setShowDeleteDialogBox] = React.useState(false);
    const [showRemoveDialogBox, setShowRemoveDialogBox] = React.useState(false);
    const [addCollectionPopup, setAddCollectionPopup] = React.useState(false);
    const uploadFailed = FAILED_STATUS.includes(content.status);
    const [transcoderEnd,setTranscoderEnd] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [ isEllipsis, setIsEllipsis ] = React.useState(true);

    let count=0;

    const handleMouseEnter=(event)=>{
        setIsEllipsis(event.target.scrollWidth > event.target.offsetWidth);
    }

    // When in meeting view this communicates with the video player.
    const onContentClickSession = () => {
        window.top.postMessage({ message: content, sender: "ON_CONTENT_CLICK" }, "*");
    };

    const onContentClick = () => {
        showContent(content);
    }

    React.useEffect(() => {
        setIsContentLiked(content.isFavMarked);
    }, [content]);

    const onClickFavorateIcon = async (e) => {
        e.stopPropagation();
        setIsLoading(true);
        dispatch(makeContentFavorite(content.id))
            .then(() => {
                if (content.isFavMarked && selectedTab === "favourite") {
                    dispatch(removeContentFromStore(content.id));
                }
                setIsContentLiked(!isContentLiked);
            })
            .catch(err => {
                enqueueSnackbar({message: err.data.message, variant: "error"});
            })
            .finally(() => {
                setIsLoading(false);
            })

    };

    const handleToggle = (event) => {
        event.stopPropagation();
        setOpenToggle(!openToggle);
    };

    const closeRenamePopUp = () => {
        setShowRenameDialogBox(false);
    };

    const closeDeletePopUp = () => {
        setShowDeleteDialogBox(false);
    };

    const closeRemovePopUp = () => {
        setShowRemoveDialogBox(false);
    };

    const closeCollectionPopUp = () => {
        setAddCollectionPopup(false);
    };



    const handleContentClick = (event)=>{
        count=event.detail;
        setTimeout(()=>{
            if(count === 1){
                if(!uploadFailed){
                    if(transcoderEnd){
                        closeSnackbar();
                        if(!meetingView) {
                            onContentClick(event);
                        }else{
                            onContentClickSession(event);
                        } 
                    }else{
                        enqueueSnackbar({message:"This content is being processed. It will be available shortly.", variant:'error'});
                    }
                }
            }else{
                onClickFavorateIcon(event);
            }
            count=0;
        },300);
    }

    let isVideoSelected = selectedContents.includes(content.id);

    return (
        <React.Fragment>
            <Grid
                item
                key={content?.id}
                id={content?.id}
                onClick={handleContentClick}
                xs={12}
                sm={meetingView ? 12 : 6}
                md={meetingView ? 12 : 4}
                lg={meetingView ? 12 : 3}
                style={{ padding: !meetingView ? isMobile && "16px 16px 0px 16px" : "2px" }}
            >
                <div className={classes.root}>
                    <div className={classes.contentImageHolder}>
                        <ContentCard content={content} type={content.type_id} fromMeeting={meetingView} uploadFailed={uploadFailed} transcoderEnd={transcoderEnd} setTranscoderEnd={setTranscoderEnd} />

                        {isLoading && <CircularProgress className={classes.loader} />}

                        {!uploadFailed && <ImgIcons iconName="Favorite" selected={isContentLiked} className={classes.favoriteIcon} onClick={onClickFavorateIcon} />}

                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <CustomTooltip title={content?.title} isEllipsis={isEllipsis}>
                            <Typography
                                variant="h4"
                                onMouseEnter={handleMouseEnter}
                                className={uploadFailed? classes.error : (fromMeeting ? classes.meetingTitle : classes.title)}
                                color={isVideoSelected ? "textPrimary" : "textSecondary"}
                                style={{ paddingLeft: 5 }}
                            >
                                {content?.title}
                            </Typography>
                        </CustomTooltip>
                        {/* bottom right popup menu */}
                        {
                            <div>
                                <IconButton
                                    key={content?.id}
                                    ref={anchorRef}
                                    color="primary"
                                    size="small"
                                    onClick={handleToggle}
                                >
                                    <ImgIcons iconName="Menu" className={classes.menuItemIcon} selected={openToggle} />
                                </IconButton>
                                { !isMobile ?
                                    <ContentMenu anchorRef={anchorRef} content={content} loadContent={loadContent} loadPlayerContent={loadPlayerContent} setCurrentVersion={setCurrentVersion} handleClose={handleClose} transcoderEnd={transcoderEnd} uploadFailed={uploadFailed} openToggle={openToggle} setOpenToggle={setOpenToggle} setAddCollectionPopup={setAddCollectionPopup} setShowDeleteDialogBox={setShowDeleteDialogBox} setShowRemoveDialogBox={setShowRemoveDialogBox} setShowRenameDialogBox={setShowRenameDialogBox} setOpenContent={setOpen} setOpenNotes={setOpenNotes} />
                                    :
                                    <ContentDrawer anchorRef={anchorRef} content={content} loadContent={loadContent} loadPlayerContent={loadPlayerContent} setCurrentVersion={setCurrentVersion} handleClose={handleClose} transcoderEnd={transcoderEnd} uploadFailed={uploadFailed} openToggle={openToggle} setOpenToggle={setOpenToggle} setAddCollectionPopup={setAddCollectionPopup} setShowDeleteDialogBox={setShowDeleteDialogBox} setShowRemoveDialogBox={setShowRemoveDialogBox} setShowRenameDialogBox={setShowRenameDialogBox} setOpenContent={setOpen} setOpenNotes={setOpenNotes} />
                                }
                            </div>
                        }
                    </div>
                </div>
            </Grid>
            <ContentRenameDialog
                content={content}
                showRenameDialogBox={showRenameDialogBox}
                closeRenamePopUp={closeRenamePopUp}
            />
            <ContentDeleteDialog
                content={content}
                showDeleteDialogBox={showDeleteDialogBox}
                closePopUp={closeDeletePopUp}
            />
            <ContentRemoveDialog
                content={content}
                showRemoveDialogBox={showRemoveDialogBox}
                closePopUp={closeRemovePopUp}
            />
            <AssignContentDialog
                addToCollectionVisible={addCollectionPopup}
                closeCollectionPopUp={closeCollectionPopUp}
                content={content}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    videoPath: state.meeting.changedVideoParameters?.videoPath ?? "",
    selectedContents: state.session.selectedContents,
});

export default connect(mapStateToProps)(Content);
