import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { SnackbarContent, useSnackbar } from 'notistack';
import React, { forwardRef, useCallback } from 'react';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        justifyContent:'center',
        alignContent:'center'
    },
    cardSuccess: {
        borderRadius: 15,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        padding:10,
        backgroundColor: theme.palette.background.main,
        fontSize: '16px',
        fontFamily: "NeueHaasDisplayRoman",
    },
    cardError: {
        borderRadius: 15,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        padding:10,
        backgroundColor:'red',
        fontSize: '16px',
        fontFamily: "NeueHaasDisplayRoman",
        webkitBoxShadow: ' 0px 7px 30px -4px rgba(0,0,0,0.75)',
        boxShadow: '0px 7px 30px -4px rgba(0,0,0,0.75)',
    },
    iconButtonError: {
        padding: 0,
        textTransform: 'none',
        pointerEvents:'all',
        color:'white'
    },
    iconButtonSuccess:{
        padding: 0,
        textTransform: 'none',
        pointerEvents:'all',
        color: theme.palette.text.primary,
    },
    typographyHolder: {
        textAlign:'center'
    },
    messageStyleError:{
        color:'white',
        paddingLeft:5,
        paddingRight:5
    },
    messageStyleSuccess:{
        color: theme.palette.text.primary,
        paddingLeft:10,
        paddingRight:15
    },
    errorIcon:{
        color:'white',
        margin:0
    },
    successIcon:{
        color: theme.palette.text.primary,
    }
}));

const SnackMessage = forwardRef((props, ref) => { 
    const classes = useStyles(props);
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
        closeSnackbar(props.id);
    }, [props.id, closeSnackbar]);

    const createHtml = () => {
        return {__html: props.message};
    }

    return (
        <SnackbarContent ref={ref} className={classes.root}>
            {props.variant === 'error' && 
            <Card className={classes.cardError}>
                <ErrorOutlineOutlinedIcon className={classes.errorIcon}></ErrorOutlineOutlinedIcon>
                <div className={classes.typographyHolder}>
                    <div className={classes.messageStyleError} dangerouslySetInnerHTML={createHtml()}></div>
                </div>
                <IconButton onClick={handleDismiss} className={classes.iconButtonError} size="large">
                    <CancelRoundedIcon />
                </IconButton>
            </Card>
            }
            {props.variant === 'success' &&
            <Card className={classes.cardSuccess}>
                <CheckCircleOutlineRoundedIcon className={classes.successIcon}></CheckCircleOutlineRoundedIcon>
                <div className={classes.typographyHolder}>
                    <div className={classes.messageStyleSuccess} dangerouslySetInnerHTML={createHtml()}></div>
                </div>                
                <IconButton
                    onClick={handleDismiss}
                    className={classes.iconButtonSuccess}
                    size="large">
                    <CancelRoundedIcon />
                </IconButton>
            </Card>
            }
        </SnackbarContent>
    );
});

export default SnackMessage;
