import React, { useState, useEffect } from 'react';
import Page from "../../components/Page";
import MiddleForm from '../LoginScreen/MiddleForm';
import { makeStyles, styled } from '@mui/styles';
import { Typography, Button, TextField } from '@mui/material';
import { useSnackbar } from "../../hooks/useCustomSnackbar";
import { useSelector, useDispatch } from 'react-redux';
import { registerDemoUser } from '../../actions/index';
import { useHistory } from 'react-router-dom';
import FooterImage from '../../assets/images/view_labs_logo-02.png';
import { portraitBreakPoint, landscapeBreakPoint} from "../../views/contextComponent/viewportContext";
import { getTokenData } from "../../actions/util";
import Logo from '../../assets/images/360MEET_White.png'

const useStyles = makeStyles((theme) => ({
    demoRegisterPage: {
        height: '100%',
        minHeight: '-webkit-fill-available',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        overflow: "overlay",
        "&::-webkit-scrollbar-thumb": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,.7)",
        },
    },
    container: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        maxWidth: "500px",
        margin: "auto"
    },
    textField: {
        backgroundColor: theme.palette.background.main,
        borderRadius: '12px',
        border: 'none',
        "&:hover": {
            border: 'none',
        }
    },
    textFieldInput: {
        color: theme.palette.text.secondary
    },
    backToLoginText: {
        margin: '12px 0px 0px 0px',
        textAlign: 'center',
        textDecoration: 'underline',
        cursor: 'pointer',
        color: theme.palette.text.contrastText,
    },
    titleText: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        color: theme.palette.text.contrastText,
        fontSize: "45px",
        margin: theme.spacing(2),
        lineHeight: '50px',
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: 'column'
        },
        [`${landscapeBreakPoint(theme)}`]: {
            flexDirection: 'row',
        },
    },
    instructionsText: {
        color: theme.palette.text.contrastText,
        fontSize: "18px",
        fontWeight: "bold",
        padding: theme.spacing(1),
        wordSpacing: '4px',
        fontFamily: 'NeueHaasDisplayLight',
        [`${portraitBreakPoint(theme)}`]: {
            padding: `${theme.spacing(1)} 0`,
        },
    },
    emailLabel: {
        display: 'flex',
        alignSelf: 'center',
    },
    formText: {
        color: theme.palette.text.contrastText,
        fontSize: "18px",
        fontWeight: "bolder",
        padding: theme.spacing(1),
        wordSpacing: '2px',
        marginTop: '20px'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: "25px",
        margin: "10px",
        marginTop: '12px',
    },
    whiteText: {
        color: theme.palette.text.contrastText,
        paddingBottom: '5px',
        fontFamily: 'NeueHaasDisplayLight'
    },
    footer: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#061d63",
        padding: '20px 0',
        [`${portraitBreakPoint(theme)}`]: {
            padding: '10px 0',
        },
    },
    logo:{
        maxWidth: '200px',
        margin: '0',
      }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.text.contrastText,
    borderColor: theme.palette.background.main,
    border: '1px solid',
    fontWeight: "bold",
    "&:hover": {

    }
}));


const DemoRegister = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [emailAddress, setEmailAddress] = useState('');
    const [disableEnter, setDisableEnter] = useState(false);
    const qrCode = useSelector(state => state.application.qrCode);
    const userData = getTokenData();

    if(userData) {
        history.push('/');
    }

    const handleInput = (e) => {
        setEmailAddress(e.target.value.toLowerCase());
    }

    const submitEmail = (e) => {
        e.preventDefault();

        setDisableEnter(true);

        const email = emailAddress.trim();
        const atSymbol = email.includes('@');
        const noSpaces = (email.indexOf(' ') === -1);

        if (noSpaces && atSymbol) {
            // API call
            dispatch(registerDemoUser({
                email: email,
                qr_code_uuid: qrCode
            })).then(() => {
                history.push("/")
            }).catch(() => {
                enqueueSnackbar({ message: 'Something went wrong. Please retry!.', variant: 'error' });
                setDisableEnter(false);
            })
        } else if (!noSpaces || !atSymbol) {
            enqueueSnackbar({ message: 'Sorry, not a valid email address. Please check formatting.', variant: 'error' });
            setDisableEnter(false);
        }
    }


    return (
        <Page id='page-demo-register' className={classes.demoRegisterPage} title="Demo Registration">
            <MiddleForm title="Demo Registration">
                <div className={classes.container}>
                    <Typography variant="h4" className={classes.titleText}>
                        Welcome to <img src={Logo} className={classes.logo} alt="Logo" />
                    </Typography >
                    <Typography align='center' className={classes.instructionsText} variant="body1">
                        A new way to store and organize content as well as host collaborative virtual meetings without
                        latencies or sacrificed picture quality.
                    </Typography>
                    <div className={classes.emailLabel}>
                        <Typography align='center' className={classes.formText} variant="h4">
                            Enter your email to access your free demo account
                        </Typography>
                    </div>


                    <form onSubmit={submitEmail} className={classes.form}>
                        <div>
                            <TextField
                                className={classes.textField}
                                InputProps={{ className: classes.textFieldInput }}
                                id="outlined-basic"
                                placeholder="E-mail"
                                variant="outlined"
                                size="small"
                                value={emailAddress}
                                onChange={handleInput}
                                fullWidth
                            />
                            <Typography className={classes.whiteText} variant='caption'>*Accounts not confirmed using the link emailed to you will be deleted within 7 days.</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography className={classes.whiteText} variant='caption'>
                                By signing up you agree to our&nbsp;
                                <a className={classes.whiteText} href="https://www.viewlabs.com/termsofservice" target="_blank" rel="noreferrer">Terms of Service</a>
                            </Typography>
                            <StyledButton type='submit' disabled={disableEnter}>Enter</StyledButton>
                        </div>
                    </form>
                </div>
            </MiddleForm>

            <div className={classes.footer}>
                <a href="https://www.viewlabs.com/" target="_blank" rel="noreferrer"><img src={FooterImage} style={{height: 60}} alt="View Labs logo"/></a>
            </div>
        </Page>
    )
}


export default DemoRegister;
