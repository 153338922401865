import React, { useState, useEffect} from "react";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import { downloadFile } from "../../../utils/download";
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import { useViewport } from "../../../views/contextComponent/viewportContext";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  popover: {
    "& ::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.dark
    },
    "& .MuiPopover-paper":{
      maxHeight:"120px",
      backgroundColor: theme.palette.background.gray,
    },
    "& .MuiMenuItem-root": {
      fontFamily: "NeueHaasDisplayBold",
      fontSize: "14px",
    }
  },
  mobileContainer: {
    width: '100%',
    height: '100%',
    overflowY: "auto",
    textAlign: 'center',
    background: theme.palette.background.default,
    '& li': {
        cursor: 'pointer',
        borderTop: '1px solid grey',
        justifyContent: 'center',
    },
    '& h4': {
        display: 'flex',
        fontFamily: 'NeueHaasDisplayMedium',
        alignItems: 'center',
        color: theme.palette.primary.dark,
        padding: '20px 0',
    }
  },
  drawer: {
      '& .MuiDrawer-paper': {
          borderRadius: '20px 20px 0 0',
          maxHeight: '70%',
          overflow: 'hidden',
      }
  },
  close:{
    color: `${theme.palette.text.darkRed} !important`
  }
}));

export default function ContentSubMenu({content, loadContent, loadPlayerContent, subMenuType, subMenuData, subMenuAnchorEl, setSubMenuAnchorEl, setOpenToggle, subMenuOpen, setSubMenuOpen, setCurrentVersion}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(subMenuAnchorEl);
    const open = Boolean(anchorEl);
    const id = open ? 'submenu_popover' : undefined;
    const { isMobile } = useViewport();

    useEffect(() => {
        setAnchorEl(subMenuAnchorEl);
    },[subMenuAnchorEl]);
  
    const handleClose = (event) => {
      event.stopPropagation();
      setSubMenuOpen(false);
      setOpenToggle(false);
      setSubMenuAnchorEl(null);
    };

    const handleButtonClose = (event) => {
      event.stopPropagation();
      setSubMenuOpen(false);
      setSubMenuAnchorEl(null);
    };

    const handleDownload = (location,title) => {
        setSubMenuOpen(false);
        setSubMenuAnchorEl(null);
        setOpenToggle();
        downloadFile(location,title);
      };

    const handleVersion = (contentVersion) => { 
      setCurrentVersion(contentVersion);
      switch (content.type_id) {
          case 1: // 360 Video
          case 2: {
              // Standard Video
              loadPlayerContent(content.uuid, contentVersion);
              break;
          }
          case 3: {
              // PDFs
              const options = {
                  ...content,
                  type: "pdf",
              };
              loadContent(options, contentVersion);
              break;
          }
          case 4: {
              // Image Galleries
              const options = {
                  ...content,
                  type: "gallery",
              };
              loadContent(options, contentVersion);
              break;
          }
          default:
              loadPlayerContent(content.uuid, contentVersion);
      }
    }

    const handleVersionsClick=(event,n,i)=>{ 
      handleClose(event);
      handleVersion(n.version); 
    }

    const handleDownloadClick=(event,n,i)=>{ 
      event.stopPropagation();
      handleClose(event);
      handleDownload(n.location, n.title ? `${content.title}${ content.type_id === 1 ? '-360' : content.type_id === 2 ? '-Standard' :''}-${n.shortform}` : `${content.title}-${i}`); 
    }
  
    return (
      <>
        {subMenuData && (subMenuData.length > 0) && 
          (<>
            { !isMobile ?
              <Popover
              id={id}
              className={classes.popover}
              open={subMenuOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {
                subMenuType === 'versions' ?
                subMenuData.map( (n,i)=> 
                    <MenuItem key={i} onClick={event => handleVersionsClick(event,n,i)}>{n.shortform}</MenuItem>
                )
                : 
                subMenuType === 'download' ?
                subMenuData.map( (n,i)=> 
                    <MenuItem key={i} onClick={event => handleDownloadClick(event,n,i)}>{n.shortform}</MenuItem>
                )
                :
                <></>
              }
            </Popover>
            :
            <Drawer
                className={classes.drawer}
                open={subMenuOpen}
                anchor='bottom'
                transitionDuration={500}
                onClose={handleClose}
            >
              <Paper className={classes.mobileContainer}>
              {
                subMenuType === 'versions' ?
                subMenuData.map( (n,i)=> 
                    <MenuItem key={i} onClick={event => handleVersionsClick(event,n,i)}>
                      <Typography
                          variant="h4"
                          color="textSecondary"
                      >
                        {n.shortform}
                      </Typography> 
                    </MenuItem>
                )
                : 
                subMenuType === 'download' ?
                subMenuData.map( (n,i)=> 
                    <MenuItem key={i} onClick={event => handleDownloadClick(event,n,i)}>
                      <Typography
                          variant="h4"
                          color="textSecondary"
                      >
                        {n.shortform}
                      </Typography>
                    </MenuItem>
                )
                :
                <></>
              }
                <MenuItem key={subMenuData.length} onClick={handleButtonClose}>
                  <Typography
                      variant="h4"
                      className={classes.close}
                  >
                    Close
                  </Typography>
                </MenuItem>
              </Paper>
            </Drawer>
          }
          </>)
        }
      </>
    );
};
