import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RouteComponent from "./RouteComponent";
import "./assets/scss/main.css";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import SnackMessage from './components/SnackMessage'
import { POPUP_DURATION } from "./constant";
import { ViewportProvider } from "./views/contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    snackBar: {
        maxWidth: '100%',
    },
    containerRoot: {
        zIndex: 9999,
        width: '100%'
    },
    customSuccess: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.main,
        minWidth:400,
        borderRadius: 25,
        fontSize: '15px',
    },
    customError: {
        minWidth:400,
        borderRadius: 25,
        fontSize: '15px',
    },
    customVariant: {
        minWidth:350, // if message doesn't fit on one line, x button will get pushed down
        borderRadius: 25,
        fontSize: '16px',
    },
    closeButton: {
        color: '#fff',
        cursor: 'pointer',
        padding: '4px',
    },
    iconButton: {
        padding: 0,
        textTransform: 'none',
        pointerEvents:'all',
    },
    iconVariantSuccess:{
        paddingRight:10,
        color:theme.palette.text.primary,
    },
    iconVariantError:{
        paddingRight:10,
        color:'white',
    },
    closeIcon:{
        color:'white',
        fontSize:22
    }
}));

// console.log("document.cookie:", document.cookie);
function App() {
    const classes = useStyles();

    // add action to all snackbars
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => { 
        notistackRef.current.closeSnackbar(key);
    }

    return (
        <ViewportProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <SnackbarProvider
                    ref={notistackRef}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    maxSnack={1}
                    classes={{
                        root: classes.snackBar,
                        containerRoot: classes.containerRoot,
                        variantSuccess: classes.customSuccess,
                        variantError: classes.customError,
                        variantWarning: classes.customVariant,
                        variantInfo: classes.customVariant,
                    }}
                    iconVariant={{
                        success: <CheckCircleOutlineRoundedIcon className={classes.iconVariantSuccess}></CheckCircleOutlineRoundedIcon>,
                        error: <ErrorOutlineOutlinedIcon className={classes.iconVariantError}></ErrorOutlineOutlinedIcon>,
                    }}
                    action={(key)=>(
                        <IconButton onClick={onClickDismiss(key)} className={classes.iconButton} size="large">
                            <CancelRoundedIcon className={classes.closeIcon} />
                        </IconButton>
                    )}
                    autoHideDuration={POPUP_DURATION}
                    preventDuplicate={true}
                    content={(key, message) => (
                        <SnackMessage message={message.message} variant={message.variant}></SnackMessage>
                    )}
                >
                    <Router basename="/dashboard/web">
                        {/* <ScrollReset /> */}
                        <RouteComponent />
                    </Router>
                </SnackbarProvider>
            </LocalizationProvider>
        </ViewportProvider>
    );
}

export default App;
