import React from "react";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
    },
    container: {
        objectFit: "cover",
        maxHeight: "100%",
        border: `1px solid ${theme.palette.border}`,
        width: "100%",
        padding:1,
        maxWidth: "calc(100vw - 38px)",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        borderRadius: 12,
        background: "#ddd",
    },
    text: {
        fontSize: "18px",
        fontWeight: "bold",
        textAlign: "center",
        fontFamily: "NeueHaasDisplayMedium",
        alignSelf: "center",
        maxWidth: '96%',
        padding: '10px',
        display: 'inline-flex'
    },
    linear: {
        height: "8px",
        borderRadius: "4px",
        backgroundColor: "#fff",
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: theme.palette.text.secondary,
            borderRadius: "4px",
        },
    },
}));

export default function ContentProgressBar(props) {
    const classes = useStyles();

    return (
        <div
            className={classes.container}
            style={{
                height: props.imageHeight,
            }}
        >
            <Box className={classes.root}>
                <Box minWidth={40}>
                    <Typography variant="body2" className={classes.text}>
                        {`Processing ${Math.round(props.value)}%`}
                    </Typography>
                </Box>
                <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" {...props} className={classes.linear} />
                </Box>
            </Box>
        </div>
    );
}
