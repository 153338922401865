import axios from '../../utils/axios';


export const verifyQrCode = (id) => {
    return axios.get("/api/qr/" + id + "/validate");
}


export const registerDemoUserAPI = (data) => {
    return axios.post("/api/user/demoAccount", data);
}