import React, { useEffect, useState } from "react";
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import CustomNewIcon from "../../../components/CustomNewIcon";
import ContentTypeIcon from "./ContentTypeIcon";
import UpdateIcon from "@mui/icons-material/Update";
import ContentProgressBar from "./ContentProgressBar";
import { fetchContent } from "../../../actions/CollectionAction";
import { useDispatch } from "react-redux";
import UploadContentFailed from "./UploadContentFailed";
import CustomTooltip from "../../../components/CustomTooltip";
import { TYPEID } from "../../../constant";

const useStyles = makeStyles((theme) => ({
    skeleton: {
        borderRadius: 12,
    },
    imageLoading: {
        height: "100%",
        width: 0,
        position: "absolute",
    },
    image: {
        objectFit: "cover",
        maxWidth: "100%",
        maxHeight: "100%",
        borderRadius: 12,
        border: `1px solid ${theme.palette.border}`,
        background: theme.palette.border,
        display: 'block',
        width: "100%",
        padding:1,
    },
    title: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontWeight: "bold",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.primary.dark,
    },
    topRightIcons: {
        color: theme.palette.primary.contrastText,
        position: "absolute",
        display: "flex",
        alignContent: "space-between",
        margin: "5px",
        flexDirection: "row",
        top: 3,
        right: 3,
    },
    bottomLeftIcons: {
        color: theme.palette.primary.contrastText,
        position: "absolute",
        display: "flex",
        alignContent: "space-between",
        margin: "5px",
        flexDirection: "row",
        bottom: 3,
        left: 4,
    },
    activeBorder: {
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        boxShadow: `1px 1px 2px ${theme.palette.text.medium}`,
    },
    contentTypeDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    iconDark:{
        color:theme.palette.primary.dark,
    },
    mask: theme.grid.mask
}));


export default function ContentCard({ content, isVideoSelected, type, fromMeeting, uploadFailed, transcoderEnd, setTranscoderEnd }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [newIconLabel, setNewIconLabel] = useState("New Content");
    const [contentIconLabel, setContentIconLabel] = useState("Content Type");
    const [imageHeight, setImageHeight] = useState(230);
    const [brokenImageLink, setBrokenImageLink] = useState(false); //watches for broken image links
    const [ timer, setTimer ] = useState(false);
    const [ updatedContent, setUpdatedContent] = useState(content);
    const [ isEllipsis, setIsEllipsis ] = useState(true);
    const [ percentage, setPercentage ] = useState(0);
    const handleMouseEnter=(event)=>{
        setIsEllipsis(event.target.scrollWidth > event.target.offsetWidth);
    }

    // Sets labels for tooltips
    useEffect(() => {
        switch (type) {
            case TYPEID.video360:
                setNewIconLabel("New 360 Video");
                setContentIconLabel("360 Video");
                break;
            case TYPEID.videoStandard:
                setNewIconLabel("New Standard Video");
                setContentIconLabel("Standard Video");
                break;
            case TYPEID.pdf:
                setNewIconLabel("New PDF");
                setContentIconLabel("PDF");
                break;
            case TYPEID.imageGallery:
                setNewIconLabel("New Image Gallery");
                setContentIconLabel("Image Gallery");
                break;
            case TYPEID.pointCloud:
                setNewIconLabel("New Point Cloud");
                setContentIconLabel("Point Cloud");
                break;
            case TYPEID.virtualSpace:
                setNewIconLabel("New Virtual Space");
                setContentIconLabel("Virtual Space");
                break;
            default:
                break;
        }
    }, [type]);

    const handleImageLoaded = () => {
        setImageLoaded(true);
    };

    const checkTranscoder=()=>{
        let data={};
        if(updatedContent.status !== 'NORMAL' && data.status !== 'NORMAL'){
            dispatch(fetchContent(updatedContent.uuid)).then((response)=>{
                data = response.data;
                setUpdatedContent(data);
                setPercentage(data.transcoder_percentage);
            })
        }else{
            setTranscoderEnd(true);
            setTimer(false);
        }
    }

    // Sets the thumbnail image height based on if user views from meeting or main dashboard
    useEffect(() => {
        if (fromMeeting) setImageHeight(100);
        if (!fromMeeting) setImageHeight(230);

        if(!uploadFailed && updatedContent.status !== 'NORMAL'){
            setTimer(true);
        }else{
            setTranscoderEnd(true);
        }

    }, [updatedContent?.status]);

    useEffect(() => {
        let interval;
        if (timer) {
          interval = setInterval(() => {
            setTranscoderEnd( updatedContent.status === 'NORMAL' );
            checkTranscoder();
          }, 5000);
        } else {
          clearInterval(interval);
        }
         return () => clearInterval(interval);
      }, [timer]);

    return <>
        {uploadFailed ?
            <UploadContentFailed uploadFailed={uploadFailed} imageHeight={imageHeight} />
            :
            <>
                { updatedContent.status !== 'NORMAL' ?
                    <ContentProgressBar value={percentage} imageHeight={imageHeight} />
                    : 
                    <>
                        {!imageLoaded && !brokenImageLink && updatedContent.thumbnail_url && (
                            <Skeleton variant="rectangular" className={classes.skeleton} style={{ height: imageHeight }} />
                        )}
                        {!updatedContent.thumbnail_url || brokenImageLink ? (
                            <div
                                className={classes.image}
                                style={{
                                    height: imageHeight,
                                    maxWidth: "calc(100vw - 38px)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    borderRadius: 12,
                                    background: 'white',
                                }}
                            >
                                <CustomTooltip title={content?.title} isEllipsis={isEllipsis}>
                                    <Typography style={{ alignSelf: "center" }} onMouseEnter={handleMouseEnter} className={uploadFailed?classes.error:classes.title}>
                                        {content?.title}
                                    </Typography>
                                </CustomTooltip>
                                
                                <div className={classes.topRightIcons}>
                                    {updatedContent.isNew && (
                                        <CustomTooltip title={newIconLabel}>
                                            <div>
                                                <CustomNewIcon />
                                            </div>
                                        </CustomTooltip>
                                    )}
                                    {(content && ((updatedContent.latest_version > updatedContent.final_version) ||
                                        !(typeof updatedContent.final_version === "number")) ) && (
                                        <CustomTooltip title="Work In Progress" placement="bottom-end">
                                            <div>
                                            <UpdateIcon className={classes.iconDark}  />
                                            </div>
                                        </CustomTooltip>
                                    )}
                                </div>
                            </div>
                            ) : (
                            <React.Fragment>
                                <img
                                    alt={content?.title}
                                    onLoad={handleImageLoaded}
                                    src={updatedContent.thumbnail_url}
                                    className={!imageLoaded ? classes.imageLoading : classes.image}
                                    style={{ height: !imageLoaded ? 0 : imageHeight }}
                                    onError={() => {
                                        setBrokenImageLink(true);
                                    }}
                                />
                                <div className={classes.mask}/>
                                {imageLoaded && (
                                    <div className={classes.topRightIcons}>
                                        {updatedContent.isNew && (
                                            <CustomTooltip title={newIconLabel}>
                                                <div>
                                                    <CustomNewIcon />
                                                </div>
                                            </CustomTooltip>
                                        )}
                                        {(content?.latest_version > content?.final_version ||
                                            !(typeof content?.final_version === "number")) && (
                                            <CustomTooltip title="Work In Progress" placement="bottom-end">
                                                <div>
                                                <UpdateIcon />
                                                </div>
                                            </CustomTooltip>
                                        )}
                                    </div>
                                )}
                                <div className={classes.bottomLeftIcons}>
                                    <CustomTooltip title={contentIconLabel} placement="top-start">
                                        <div className={classes.contentTypeDiv}>
                                            <ContentTypeIcon contentType={type} />
                                        </div>
                                    </CustomTooltip>
                                </div>
                            </React.Fragment>
                        )}  
                    </>
                }
            </>
        }
    </>;
}
