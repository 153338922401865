import { Button, TextareaAutosize } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveFeedback } from "../../../actions/index";
import { getTokenData, MAX_CHARACTERS_FEEDBACK } from "../../../actions/util";
import logo from "../../../assets/images/view_labs_logo.png";
import Page from "../../../components/Page";
import ServiceCheckbox from "./ServiceCheckbox";
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import { portraitBreakPoint, landscapeBreakPoint, useViewport } from "../../contextComponent/viewportContext";


const useStyles = makeStyles((theme) => ({
    page: {
        height: "100%",
        display: "flex",
        width: "100%",
    },
    root: {
        overflow: "hidden auto",
        width: "100%",
        height: "100%",
        position: "fixed",
    },
    holder: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },
    form: {
        display: 'flex', 
        flexDirection: 'column', 
        alignSelf: 'center', 
        width: '45%', 
        maxWidth: '700px',
        marginTop: '25px',
        [theme.breakpoints.down('lg')]: {
            width: "60%",
        },
        [`${portraitBreakPoint(theme)}`]: {
            width: "100%",
            marginTop: 0,
        },
        [`${landscapeBreakPoint(theme)}`]: {
            width: "100%",
        },
    },
    main: {
        display: 'flex', 
        flexDirection: 'column', 
        height: "calc(100% - 102px)", 
        alignItems: 'center', 
        padding:'0 15px', 
        width: '90%',
        [`${portraitBreakPoint(theme)}`]: {
            width: "75%",
            height: "100%", 
        },
    },
    feedbackTextHolder: {
        width: "100%",
        // maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginBottom: '20px',
        [`${landscapeBreakPoint(theme)}`]: {
            width: "100%",
        },
    },
    textFeedback: {
        width: '100%',
        textAlign: 'justify', 
        fontFamily: 'NeueHaasDisplayLight',
        borderRadius: '12px'
    },
    heading: {
        marginTop: "24px",
        [`${portraitBreakPoint(theme)}`]: {
            marginTop: "12px",
        },
    },
    headerLogo: {
        height: "50px",
        marginTop: "1%",
        width: "auto",
    },
    header: {
        height: '70px',
        [`${landscapeBreakPoint(theme)}`]: {
            margin: '0px !important',
            marginTop: '8px'
        }
    },
    submitContainer: {
        textAlign: "center",
    },
    submitButton: {
        color: theme.palette.text.contrastText,
        backgroundColor: theme.palette.primary.main,
        borderRadius: "30px",
        margin: "30px auto",
        minWidth: "240px",
        maxWidth: "300px",
        width: "84%",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            filter: "brightness(85%)",
        },
        [`${landscapeBreakPoint(theme)}`]: {
            width: "58%",
        }
    },
    
}));

export const Feedback = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState("");
    const [services, setServices] = useState([]);
    const [email, setEmail] = useState("");
    const { isMobile } = useViewport();

    useEffect(() => {
        let userData = getTokenData();
        if (userData) {
            setEmail(userData.email);
        }
    }, []);

    const changeFeedback = (e) => {
        setFeedback(e.target.value);
    };

    const submitFeedbackForm = async () => {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let speedTest = window.localStorage.getItem("speed");
        speedTest = Number(speedTest).toFixed(3);
        let today = new Date();

        if (!feedback) {
            enqueueSnackbar({ message: "Please fill out the text box with your feedback.", variant: 'error' });
        } else {
            const data = {
                email: email,
                name: `${window.name ?? ""}`,
                role: `${window.userRole ?? ""}`,
                localtime: `${today}, ${timezone}`,
                speedTest: speedTest,
                feedback: feedback,
                feedbackFor: services
            };
            try {
                await dispatch(saveFeedback(data));
                setFeedback("");
                enqueueSnackbar({ message: "Feedback submitted. Thank you for your input!", variant: 'success' });
            } catch (error) {
                enqueueSnackbar({ message: "Sorry, feedback did not submit successfully. Please try again.", variant: 'error' });
            }
        }
    };

    const handleCheckbox = (e) => {
        if (e.target.checked) {
            // When the checkbox is checked
            let newArr = [...services];
            newArr.push(e.target.value);
            // Remove duplicates in array
            let newServices = [...new Set(newArr)];
            setServices(newServices);
        } else if (!e.target.checked) {
            // When the checkbox is unchecked
            let newArr = [...services];
            let newServices = newArr.filter((service) => service !== e.target.value);
            setServices(newServices);
        }
    };

    return (
        <Page
            id="page-feedback"
            title="Feedback Form"
            className={classes.page}
        >
            <div className={classes.root}>
            <div className={classes.holder}>
                <header className={classes.header}>
                    <img className={classes.headerLogo} src={logo} alt="logo"></img>
                </header>
                <main className={classes.main}>
                    <Typography variant="h3" className={classes.heading}>
                        Feedback Form
                    </Typography>
                    <Typography variant="body1" align="center">
                        Please check the services you would like to give us feedback for:
                    </Typography>
                    <form className={classes.form}>

                        <Grid container spacing={isMobile ? 1 : 3} justifyContent="space-between" style={{ margin: '35px 0' }}>
                            <Grid item xs={6} sm={3}><ServiceCheckbox label="Dashboard" onClick={handleCheckbox} /></Grid>
                            <Grid item xs={6} sm={3}><ServiceCheckbox label="Conferencing" onClick={handleCheckbox} /></Grid>
                            <Grid item xs={6} sm={3}><ServiceCheckbox label="Player" onClick={handleCheckbox} /></Grid>
                            <Grid item xs={6} sm={3}><ServiceCheckbox label="Other" onClick={handleCheckbox} /></Grid>
                        </Grid>

                        <Typography variant="body1" align={isMobile ? "center" : "left"} style={{ marginBottom: '20px' }}>
                            Let us know how we're doing so we may continually improve our service.
                        </Typography>
                        <div className={classes.feedbackTextHolder}>
                            <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                <TextareaAutosize
                                    placeholder="Comments/Concerns..."
                                    onChange={changeFeedback}
                                    className={classes.textFeedback}
                                    aria-label="maximum height"
                                    minRows={7}
                                    maxLength={MAX_CHARACTERS_FEEDBACK}
                                />
                            </div>
                            <div>
                                {feedback.length}/{MAX_CHARACTERS_FEEDBACK}
                            </div>
                        </div>
                        <Button className={classes.submitButton} onClick={submitFeedbackForm}>
                            Submit
                        </Button>
                    </form>
                </main>
            </div>
            </div>
        </Page>
    );
};

export default Feedback;
