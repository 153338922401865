import React from 'react'

const VideoPreview = ({ videoUUID, videoVersion, open }) => {
	React.useEffect(() => {
		if (window.removepano) {
			window.removepano("ViewLabs");
		}
		document.getElementById('pano')?.remove();
		if (videoUUID) {
			document.getElementById('panoContainer').innerHTML += "<div id='pano' style='width:100%;height:100%;'></div>";
			window.loadJson({"uuid":videoUUID,"v":videoVersion});
		}
	}, [videoUUID, videoVersion, open]);

	return (
		<div style={{width: '100%',height: '100%'}} id={'panoContainer'}>
			<div id="buffer_wrapper">
				<div id="ani-block" className="profile-main-loader" style={{width:'200px', height:'200px', position: 'absolute', transform: 'translate(-50%, -50%)', top:'50%', left:'50%', zIndex: '1'}}>
					<div className="loader">
						<svg id="b-rotate" className="circular-loader" viewBox="25 25 50 50" >
							<circle id="buffer" className="loader-path" cx="50" cy="50" r="12" fill="none" stroke="#5100FF" strokeWidth="1.2" />
						</svg>
					</div>
				</div>
			</div>
		</div>
	)
}

export default VideoPreview
