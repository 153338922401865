import {
    Button, DialogActions, DialogContent, DialogTitle, Divider, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { removeContent } from "../../../actions";
import DialogMigrate from "../../../components/DialogMigrate";


const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            border: "1px solid",
            borderColor: theme.palette.border,
        },
        "& .MuiBackdrop-root": {
            background: "transparent"
        }
    },
    dialogRoot: {
        borderColor: theme.palette.text.light,
    },
    dialogButtons: {
        borderRadius: 30,
        height: 32,
        fontFamily: "NeueHaasDisplayBold",
        padding: "0px 35px",
        "&:hover": {
            borderRadius: 30,
        },
    },
    deleteButton: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        "&:hover": {
            borderColor: theme.palette.error.main,
            backgroundColor: theme.palette.error.main,
        },
    },
    buttonContainer: {
        height: 50,
        justifyContent: 'space-evenly'
    },
    divider: {
        borderColor: theme.palette.text.light,
    },
    contentText: {
        fontWeight: '900',
        fontFamily: 'NeueHaasDisplayRoman',
        wordSpacing: '2px'
    },
    titleText: {
        fontFamily: 'NeueHaasDisplayBold',
    }
}));

const ContentRemoveDialog = ({ content, showRemoveDialogBox, closePopUp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectedCollection = useSelector(state => state.collections.selectedCollection);

    const onRemove = async () => {
        await dispatch(removeContent(selectedCollection.id, content.id));
        closePopUp();
    }



    return (
        <DialogMigrate open={showRemoveDialogBox} onClose={closePopUp} fullWidth className={classes.dialogContainer} classes={{
            paper: classes.dialogRoot,
        }}>
            <div>
                <DialogTitle variant={"h4"} color="textSecondary" align="center" className={classes.titleText} id="delete-content-title-dialog">
                    Remove Item
                </DialogTitle>
                <Divider className={classes.divider} />
                <DialogContent>
                    <Typography variant={"h4"} color="textSecondary" align="center" className={classes.contentText}>
                        Remove content from this collections. <br />
                        Do you want to continue?
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button variant="outlined" onClick={closePopUp} className={classes.dialogButtons}>
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            className={`${classes.dialogButtons} ${classes.deleteButton}`}
                            onClick={onRemove}
                        >
                            Remove
                        </Button>
                    </div>
                </DialogActions>
            </div>
        </DialogMigrate>
    )

}

export default ContentRemoveDialog;
