import { Box, Button, Divider, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles, styled } from "@mui/styles";
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onUpdateEmail } from '../../../actions/sessionActions';
import { portraitBreakPoint, landscapeBreakPoint} from "../../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: `${theme.spacing(1)} 0px`,
        marginBottom: 0,
        maxWidth: "600px",
        width: '95%',
    },
    form: {
        display: 'flex',
        alignItems: 'flex-end',
        ".MuiInputLabel-formControl": {
            transition: 'none'
        },
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        [`${landscapeBreakPoint(theme)}`]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
    },
    formLabel: {
        transform: 'none',
        position: 'relative',
        padding: "15px 0",
        fontSize: "18px",
        "& .MuiTypography-h4":{
            [`${portraitBreakPoint(theme)}`]: {
                fontSize: "16px",
            },
            [`${landscapeBreakPoint(theme)}`]: {
                fontSize: "16px",
            }
        }
    },
    divider: {
        display: 'flex',
        alignSelf: "center",
        borderColor: "black",
        height: '15px',
        marginRight: "5px",
        marginLeft: "5px",

    },
    editButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        }
    },
    upcomingTitle: {
        color: theme.palette.text.blue,
        '&:hover': {
            cursor: 'pointer',
        }
    },
    emailHolder: {
        display: 'flex',
        width: "100%",
        justifyContent: "space-between",
        alignItems: "end",
        gap: "15px",
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: "column",
            alignItems: "start"
        },
    },
    emailId: {
        display: 'flex',
        gap: "15px",
        width: "70%",
        padding: "2px 15px 2px 0px",
    },
    editEmail: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        gap: "10px",
        "& .MuiTypography-body1":{
            fontFamily: "NeueHaasDisplayRoman",
            fontSize: "16px"
        },
        [`${portraitBreakPoint(theme)}`]: {
            "& .MuiTypography-body1":{
                fontFamily: "NeueHaasDisplayRoman",
                fontSize: "14px"
            }
        },
        [`${landscapeBreakPoint(theme)}`]: {
            "& .MuiTypography-body1":{
                fontFamily: "NeueHaasDisplayRoman",
                fontSize: "14px"
            }
        }
    },
    emailLabel: {
        display: 'flex',
        justifyContent: "space-between",
    }
}))

const StyledInput = styled(Input)((props) => ({
    border: props.error ? '2px solid red' : '1px solid',
    borderRadius: 25,
    fontFamily: "NeueHaasDisplayRoman",
    width: "100%",
    color: "black",
    height: 'auto',
    '& .MuiInput-underline': {
        border: 'none',
        '&:hover, &:focus': {
            border: 'none',
        }
    },
    '& input': {
        padding: "6px 20px",
    },
    "&:after, &:before": {
        borderBottom: 'none !important',
    },
    "&:hover": {
        border: props.error ? '2px solid red' : '2px solid',
    },
}));

const SaveButton = styled(Button)(({ theme }) => ({
    background: theme.palette.text.contrastText,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    padding: '2px 35px',
    lineHeight: "1.4",
    "&:hover": {
        background: theme.palette.primary.main,
        color: theme.palette.text.contrastText
    }
}));


const EmailForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const userDetails = useSelector(state => state.application?.userDetails);

    const [state, setState] = React.useState({
        email: ""
    });

    const [error, setError] = React.useState({
        email: false
    });
    const [isDisabled,setIsDisabled] = React.useState(true);

    const handleOnClickEdit = () => {
        setEdit(true);
    }

    const handleCloseEdit = () => {
        setState({
            ...state,
            email: userDetails?.email,
        })
        setEdit(false);
    }

    const onEmailChange = (e) => {
        const email = e.target.value;
        setState({
            ...state,
            email
        })
        validattion(email);
    }

    const isDisableButton = (email) => {
        const isDisabled = userDetails?.email === email;
        setIsDisabled(isDisabled);
    }

    const validattion = (email) => {
        isDisableButton(email);
        const reg = /^([a-zA-Z0-9]+[_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/; 
        if (reg.test(email)) {
            setError({
                email: false
            })
            return true
        } else {
            setError({
                email: true
            })
            return false
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (validattion(state.email)) {
            await dispatch(onUpdateEmail(state.email)).then(res => {
                setEdit(false);
                enqueueSnackbar({ message: 'You will receive a confirmation email shortly!', variant: 'success' });
            })
        }
    }

    React.useEffect(() => {
        setState({
            ...state,
            email: userDetails?.email
        })
    }, [userDetails]);


    return (
        <Box id="email-change" paddingBottom={1}>
            <form onSubmit={onSubmit} className={classes.form}>
                <FormControl variant="standard" className={classes.margin}>
                    <InputLabel htmlFor="email-id" className={classes.formLabel}>
                        <Typography variant='h4' color='textSecondary'>Email Address</Typography>
                    </InputLabel>
                    {edit ?
                        <div className={classes.emailHolder}>
                            <StyledInput id="email-id" error={error.email} value={state.email} type="email" onChange={onEmailChange} />
                            <div id="confim-cancel-buttons" className={classes.emailId} >
                                <SaveButton type='submit' variant="outlined" disabled={isDisabled}>Confirm</SaveButton>
                                <SaveButton variant="outlined" onClick={handleCloseEdit}>Cancel</SaveButton>
                            </div>
                        </div>
                        :
                        <div className={classes.editEmail}>
                            <Typography color='textSecondary'>{state.email}</Typography>
                            <Divider orientation="vertical" flexItem className={classes.divider} />
                            <Typography className={classes.upcomingTitle} fontWeight={'bold'} onClick={handleOnClickEdit} >Edit</Typography>
                        </div>
                    }
                </FormControl>
            </form>
        </Box>
    );
}

export default EmailForm;
