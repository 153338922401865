import { fetchOrgDetailsAPI, updateOrgTitleAPI, getOrgMemberAPI, getPaymentLinkAPI, addUsersAPI, addNewOrganizationAPI, updateOrgOwnerAPI, confirmChangeOwner, revertChangeOwner } from './api/OrganizationAPI';
import { setCurrentOrg, setSelectedOrg } from './CollectionAction';


export const FETCH_ORG_MEMBERRS = "FETCH_ORG_MEMBERRS";
export const FETCH_ORG_OWNER = "FETCH_ORG_OWNER";


export function fetchOrgDetails(orgId,selected) {
    return (dispatch) =>
        new Promise(async (resolve, reject) => {
            if (orgId) {
                fetchOrgDetailsAPI(orgId)
                    .then((response) => {
                        if (response) {
                            dispatch(setCurrentOrg(response));
                            if(selected){
                                dispatch(setSelectedOrg(response));
                            }
                       
                                dispatch(setOrgOwner({
                                    ...response.owner
                                }));
                            
                            resolve(response);
                        } else {
                            dispatch(setCurrentOrg(null));
                            reject({ msg: "Invalid Id" });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                dispatch(setCurrentOrg(null));
            }
        }
        );
}


export function updateOrgTitle(orgId, data) {
    return (dispatch, getState) =>
        new Promise(async (resolve, reject) => {
            let currentOrganisation = getState().collections?.currentOrganisation;
            updateOrgTitleAPI(orgId, data)
                .then((response) => {
                    if (response) {
                        currentOrganisation.title = data.title
                        dispatch(setCurrentOrg({ ...currentOrganisation }));
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        }
        );
}


export function fetchOrgMembers(orgId, data) {
    return (dispatch, getState) =>
        new Promise(async (resolve, reject) => {
            getOrgMemberAPI(orgId, data)
                .then((response) => {
                    if (response) {
                        dispatch(setOrgMember(response));
                        resolve(response);
                    }
                })
                .catch((error) => {
                    dispatch(setOrgMember({}));
                    reject(error);
                });
        }
        );
}

export const setOrgMember = (data) => {
    return {
        type: FETCH_ORG_MEMBERRS,
        payload: data
    }
};

export const setOrgOwner = (data) => {
    return {
        type: FETCH_ORG_OWNER,
        payload: data
    }
};

export function fetchPaymentLink() {
    return (dispatch, getState) =>
        new Promise(async (resolve, reject) => {
            let currentOrganisation = getState().collections?.currentOrganisation;
            getPaymentLinkAPI(currentOrganisation?.id)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        }
        );
}

export function addUsersInOrg(data) {
    return (dispatch, getState) =>
        new Promise(async (resolve, reject) => {
            let currentOrganisation = getState().collections?.currentOrganisation;
            addUsersAPI(currentOrganisation?.id, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        }
        );
}

export function updateOrgOwner(data) {
    return (dispatch, getState) =>
        new Promise(async (resolve, reject) => {
            let currentOrganisation = getState().collections?.currentOrganisation;
            updateOrgOwnerAPI( currentOrganisation.id, data)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    }
                })
            });
}

export function addNewOrganization(name) {
    return (dispatch) => {
     return new Promise(async (resolve, reject) => {
            addNewOrganizationAPI({title: name}).then(response => {
                if (response) {
                    dispatch(setCurrentOrg(response));
                    dispatch(setSelectedOrg(response));
                    resolve(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    }
}

export const onConfirmChangeOwner = (key) =>{
    return () => {
       return new Promise((resolve, reject) => {
                confirmChangeOwner(key)
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            });
    }
}

export const onRevertChangeOwner = (key) =>{
    return () => {
        return new Promise((resolve, reject) => {
            revertChangeOwner(key)
                 .then(response => {
                     resolve(response);
                 }).catch(error => {
                     reject(error);
                 })
             });
     }
}
