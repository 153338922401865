import { Button, Dialog, DialogActions, DialogContent, IconButton, Slide, Typography } from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from "@mui/material/FormControl";
import Grid from '@mui/material/Unstable_Grid2';
import Grow from '@mui/material/Grow';
import Input from "@mui/material/Input";
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/styles';
import moment from "moment";
import { useSnackbar } from '../../../../hooks/useCustomSnackbar';
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { deleteSession, inviteUsersToSession, postSessionNote } from "../../../../actions";
import Invite from "../../invite/Invite";
import ImgIcons from "../../../../components/ImgIcons";
import Drawer from '@mui/material/Drawer';
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../../../contextComponent/viewportContext";
import CustomTooltip from "../../../../components/CustomTooltip";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.text.main,
        border: '2px solid',
        color: theme.palette.secondary.main,
        borderRadius: '11px',
        overflowY: "auto",
    },
    menuContainer: {
        width: '100%',
        textAlign: 'center',
        background: theme.palette.background.default,
        '& .MuiTypography-body1': {
            alignItems: 'center',
            color: theme.palette.primary.dark,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            fontFamily: 'NeueHaasDisplayRoman',
            fontSize: "1rem"
        }
    },
    mobileMenuContainer: {
        width: '100%',
        textAlign: 'center',
        background: theme.palette.background.default,
        '& .MuiTypography-body1': {
            alignItems: 'center',
            color: theme.palette.primary.dark,
            padding: '20px 0',
            cursor: 'pointer',
            borderTop: '1px solid grey',
            display: 'flex',
            justifyContent: 'center',
            fontFamily: 'NeueHaasDisplayRoman',
            fontSize: "1.4rem"
        },
        '& svg': {
            fontSize: "1.4rem"
        }
    },
    drawer: {
        '& .MuiDrawer-paper': {
            borderRadius: '20px 20px 0 0',
        }
    },
    historyContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: 'auto',
        padding: '16px',
        margin: '15px',
        [`${portraitBreakPoint(theme)}`]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [`${landscapeBreakPoint(theme)}`]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    historyLinkGrid: {
        marginLeft: '0px',
        [`${portraitBreakPoint(theme)}`]: {
            minWidth: '314px',
            marginRight: '0px',
            paddingBottom: 0,
        },
        [`${landscapeBreakPoint(theme)}`]: {
            minWidth: '314px',
            marginRight: '0px',
            paddingBottom: 0,
        }
    },
    sessionName: {
        fontSize: '24px',
        flex: 1,
        padding: "4px 0",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    iconStyle: {
        height: 18,
        width: 25,
        margin: "0px 2px",
        color: theme.palette.secondary.main,
        cursor: "pointer",
    },
    iconButton: {
        height: 30,
        width: 30,
        backgroundColor: theme.palette.secondary.main,
        padding: '2px',
        color: 'black',
        margin: '0px 30px 0px 0px',
        fontWeight: 'bolder',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    addNoteForm: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%'
    },
    addNoteInput: {
        width: '100%'
    },
    addNoteClearIcon: {
        height: '14px'
    },
    typography: {
        padding: theme.spacing(1),
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    icons: {
        marginRight: '10px',
        verticalAlign: 'top',
        fontSize: '1rem',
    },
    menuItemIcon: {
        position: "absolute",
        fontSize: "2.0em"
    },
    generateButton: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignSelf: 'center',
        borderRadius: '33px',
        padding: '1px 16px',
        marginLeft: '8px',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 'bold',
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.dark,
        fontSize:"14px"
    },
    timeStampHolder: {
        display: "flex",
        whiteSpace: 'nowrap',
        marginTop: "6px",
        [`${portraitBreakPoint(theme)}`]: {
            alignItems: 'center',
            flexDirection: 'row',
        },
        [`${landscapeBreakPoint(theme)}`]: {
            alignItems: 'center',
            flexDirection: 'row',
        }
    },
    sessionNote: {
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        marginBottom: "2px",
    },
    noteCaption: {
        cursor: "pointer",
        padding: "8px 0px",
        width: '100%',
        fontSize: "16px",
        lineHeight: '20px',
        letterSpacing: "0px",
        color: theme.palette.text.medium,
        fontFamily: 'NeueHaasDisplayRoman',
        wordBreak: 'break-all',
        '&:hover': {
            borderBottom: '1px solid'
        }
    },
    upcomingTitle: {
        color: "#3ECBFF",
        letterSpacing: "0.2em",
    },
    menuIcon:{
        margin: 'auto 5px',
        height: '32px',
        width: '32px',
    },
    delete:{
        color: `${theme.palette.text.darkRed} !important`
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HistoryComponent = ({ item, setEnableEditMenuPopup, refreshCurrentData, isUpcoming, setSelectedItem }) => {
    const classes = useStyles();
    const { isMobile } = useViewport();
    const { enqueueSnackbar } = useSnackbar();
    const [enableTextField, setEnableTextField] = useState(0);
    const [noteState, setNoteState] = useState(item.note);
    const [openToggle, setOpenToggle] = useState(false);
    const anchorRef = React.useRef(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [enablePersonInvitePopup, setEnablePersonInvitePopup] = useState(false);
    const dispatch = useDispatch();

    const [ isEllipsis, setIsEllipsis ] = React.useState(true);
    const handleMouseEnter=(event)=>{
        setIsEllipsis(event.target.scrollWidth > event.target.offsetWidth);
    }

    const onSelectDelete = () => {
        handleCloseToggle();
        setOpenDeleteDialog(true);
        refreshCurrentData();
    };
    const onCancelDelete = () => {
        setOpenDeleteDialog(false);
        refreshCurrentData();
    };
    const onDeleteHandler = () => {
        dispatch(deleteSession(item.id))
        setOpenDeleteDialog(false);
        refreshCurrentData();
    }
    const onAddPersonClick = () => {
        setEnablePersonInvitePopup(true);
        setOpenToggle(false);
    };
    const onEditClick = (selectedItem) => {
        setEnableEditMenuPopup(true);
        setSelectedItem(selectedItem)
        setOpenToggle(false);
        refreshCurrentData();

    };
    const onSessionLinkClick = () => {
        window.open(sessionUrl);
    };
    const copyToClipboard = (event) => {
        enqueueSnackbar({ message: "Session URL copied successfully!", variant: 'success' });
        navigator.clipboard.writeText(sessionUrl);
        setOpenToggle(false);
    };
    const onSaveNoteClick = async () => {
        try {
            const note = {
                "note": noteState
            };
            const response = await dispatch(postSessionNote(item.id, note));
            if (response.status === 200) {
                refreshCurrentData();
            }
            setEnableTextField(0);
            enqueueSnackbar({ message: "Session note saved successfully!", variant: 'success' });
        } catch (e) {
            enqueueSnackbar({ message: "Something went wrong...Please try again!", variant: 'error' });
        }
    };
    const handleChange = (event) => {
        setNoteState(event.target.value)
    };
    const onNoteTextClick = () => {
        setEnableTextField(item.id);
    };
    const onEnter = (event, onSaveNoteClick) => {
        event.key === 'Enter' && onSaveNoteClick()
    };
    const clearNote = () => {
        setEnableTextField(0);
    }

    const sessionUrl = window.location.origin + '/session?id=' + item.id;
    const handleCloseToggle = (event) => {
        setOpenToggle(false);
    };
    const handleToggle = (event) => {
        setOpenToggle(!openToggle);
    };

    const inviteUsers = (invitees) => {
        const id = item.id;
        dispatch(inviteUsersToSession(id, invitees));
    };

    return (
        <Grid className={classes.historyContainer} container>
            <Grid item xs={12} sm={12} md={12} lg={5}>
                {isUpcoming ? <Typography variant="h6" className={classes.upcomingTitle} >UPCOMING</Typography> : ''}
                <Grid item style={{ display: 'flex', justifyContent: "flex-end", marginTop: "5px"  }}>
                    <CustomTooltip title={item.name} isEllipsis={isEllipsis}>
                        <Typography variant={"h4"} className={classes.sessionName} onMouseEnter={handleMouseEnter}>
                            {item.name}
                        </Typography>
                    </CustomTooltip>
                    <IconButton
                        ref={anchorRef}
                        color="primary"
                        size="small"
                        className={classes.menuIcon}
                        onClick={handleToggle}>
                        <ImgIcons iconName="Menu" className={classes.menuItemIcon} selected={openToggle} />
                    </IconButton>
                    {openToggle && isMobile ?
                        <Drawer
                            className={classes.drawer}
                            open={openToggle}
                            anchor='bottom'
                            transitionDuration={500}
                            onClose={handleCloseToggle}
                        >
                            <Paper className={classes.mobileMenuContainer}>
                                <Typography onClick={copyToClipboard}>
                                    <ImgIcons iconName="CopyLink" className={classes.icons} />Copy Session Link
                                </Typography>
                                <Typography onClick={() => { onEditClick(item) }}>
                                    <ImgIcons iconName="Rename" className={classes.icons} />Edit
                                </Typography>
                                <Typography onClick={() => { onAddPersonClick() }}>
                                    <ImgIcons iconName="Invite" className={classes.icons} />Invite
                                </Typography>
                                <Typography className={classes.delete} onClick={onSelectDelete}>
                                    <ImgIcons iconName="Delete" className={classes.icons} />Delete
                                </Typography>
                            </Paper>
                        </Drawer>
                    :
                        <Popper open={openToggle} anchorEl={anchorRef.current} role={undefined} transition placement="bottom-start">
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper className={classes.menuContainer}>
                                        <ClickAwayListener touchEvent="onTouchStart" onClickAway={handleCloseToggle} disableReactTree>
                                            <Paper>
                                                <MenuList id="split-button-menu">
                                                    <MenuItem text={sessionUrl} onClick={copyToClipboard}>
                                                        <Typography color="textSecondary"><ImgIcons iconName="CopyLink" className={classes.icons} />Copy Session Link</Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => { onEditClick(item) }}>
                                                        <Typography color="textSecondary"><ImgIcons iconName="Rename" className={classes.icons} />Edit</Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => { onAddPersonClick(item.id) }}>
                                                        <Typography color="textSecondary"><ImgIcons iconName="Invite" className={classes.icons} />Invite</Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={onSelectDelete}>
                                                        <Typography className={classes.delete}><ImgIcons iconName="Delete" className={classes.icons} />Delete</Typography>
                                                    </MenuItem>
                                                </MenuList>
                                            </Paper>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    }
                    <Button className={classes.generateButton} onClick={onSessionLinkClick} endIcon={<ImgIcons iconName="StartSession" />} variant='text'>
                        Start Session
                    </Button>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.timeStampHolder}>
                        <Typography variant={"body1"} color={"textSecondary"}
                            style={{ fontFamily: "NeueHaasDisplayBold", marginRight: 8 }}>
                            {moment.unix(item.start_datetime).format('MMMM Do YYYY')}</Typography>
                        <Typography variant={'body1'} style={{ fontWeight:600 }}
                            color={"textSecondary"}>{moment.unix(item.start_datetime).format('h:mm a')} - {moment.unix(item.start_datetime).add(item.duration_in_sec, "seconds").format('h:mm a')}</Typography>
                    </div>
                </Grid>
                <Grid className={classes.historyLinkGrid} item xs={12} sm={12} md={12}>
                    <div className={classes.sessionNote}>
                        {
                            enableTextField === item.id ?
                                <FormControl variant="standard" className={classes.addNoteForm}>
                                    <Input
                                        className={classes.addNoteInput}
                                        style={{ borderBottom: "2px solid #fff", marginBottom: 10 }}
                                        placeholder={"Please add session note here...."}
                                        value={noteState||''}
                                        onChange={handleChange}
                                        onKeyPress={event => onEnter(event, () => { onSaveNoteClick(item.id) })} />
                                    <ClearIcon
                                        className={classes.addNoteClearIcon}
                                        titleAccess="Close"
                                        onClick={clearNote}
                                    />
                                </FormControl>
                                :
                                <Typography variant={'caption'}
                                    onClick={onNoteTextClick}
                                    color={"textSecondary"}
                                    className={classes.noteCaption}
                                >{item.note ?? "Please add session note here...."}
                                </Typography>
                        }
                    </div>
                </Grid>
            </Grid>
            <Dialog
                open={openDeleteDialog}
                TransitionComponent={Transition}
                onClose={onCancelDelete}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this session?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelDelete} color="primary">
                        No
                    </Button>
                    <Button onClick={onDeleteHandler} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {enablePersonInvitePopup && <Invite
                inviteUsers={inviteUsers}
                disable={window.location.href?.length === 0}
                setEnablePersonInvitePopup={setEnablePersonInvitePopup}
                enablePersonInvitePopup={enablePersonInvitePopup}
                selectedSessionId={item.id}
            />}
        </Grid>
    );
};
export default HistoryComponent;
