import { Box, Divider, Typography } from "@mui/material";
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../../../contextComponent/viewportContext";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import HistoryComponent from "./HistoryComponent";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    sessionHistoryHeading: {
        marginLeft: "0px",
        "& h4": {
            fontSize: "20px",
            padding: "16px 0",
            [`${portraitBreakPoint(theme)}`]: {
                padding: "16px",
            },
            [`${landscapeBreakPoint(theme)}`]: {
                padding: "16px",
            },
        },
    },
    divider: {
        width: "50%",
        borderColor: theme.palette.text.light,
        [`${portraitBreakPoint(theme)}`]: {
            width: "100%",
        },
        [`${landscapeBreakPoint(theme)}`]: {
            width: "100%",
        },
    },
    noSessionsContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sessionScrollDiv: {
        overflow: "hidden auto",
        height: "100%",
        "& .infinite-scroll-component":{
            overflow: "hidden !important"
        }
    },
}));

const SessionListComponent = ({ title, sessionHistoryData, fetchData, refetchData, isUpcoming, height, setEnableEditMenuPopup, setSelectedItem, setEnablePersonInvitePopup, refreshData, refreshCurrentData, sort, search }) => {
    const classes = useStyles();
    const { isMobile, isMobileLandscape } = useViewport();
    const [pageSize, setPageSize] = React.useState(20);
    const pageNumber = 0;

    React.useEffect(() => {
        fetchData(pageSize, pageNumber, search);
    }, [pageSize, pageNumber, refreshData, refetchData, sort, search]);


    const loadMore = (page) => {
        setPageSize(pageSize + 10);
    };
    if (sessionHistoryData?.items?.length < 1) {
        return (
            <div className={classes.noSessionsContainer}>
                {isUpcoming ?
                    <Typography variant={"h3"}>No upcoming sessions.</Typography>
                    :
                    <Typography variant={"h3"}>No past sessions found.</Typography>
                }
            </div>
        )
    }

    return (
        <>
            <Box id='sessionListComponent' height={isMobile && !isMobileLandscape ? 'calc(100% - 105px)' : 'calc(100% - 145px)'}>
                <div id={"sessionScrollDiv-" + title} className={classes.sessionScrollDiv}>
                    <InfiniteScroll
                        dataLength={sessionHistoryData.items?.length} //This is important field to render the next data
                        next={loadMore}
                        hasMore={sessionHistoryData.count !== sessionHistoryData.items?.length}
                        loader={<Typography align="center">Loading...</Typography>}
                        scrollableTarget={"sessionScrollDiv-" + title}
                        refreshFunction={() => { }}
                        pullDownToRefresh={false}
                        pullDownToRefreshThreshold={50}
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>&#8595; Pull down to load data</h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>&#8593; Release to load data</h3>
                        }
                    >
                        {sessionHistoryData.items.length > 0 &&
                            _.map(sessionHistoryData.items, (item) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        <HistoryComponent
                                            key={item.id}
                                            item={item}
                                            setEnablePersonInvitePopup={setEnablePersonInvitePopup}
                                            setEnableEditMenuPopup={setEnableEditMenuPopup}
                                            setSelectedItem={setSelectedItem}
                                            refreshCurrentData={refreshCurrentData}
                                            isUpcoming={isUpcoming}
                                        />
                                        <Divider varient="middle" className={classes.divider} />
                                    </React.Fragment>
                                );
                            })}
                    </InfiniteScroll>
                </div>
            </Box>
        </>
    );
};

export default React.memo(SessionListComponent);
