import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import ContentSubMenu from './ContentSubMenu';
import {autoGeneratedFeedback} from "../../../utils/submitFeedback";
import MenuItem from "@mui/material/MenuItem";
import { getConfig, downloadFile } from "../../../utils/download";
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import ImgIcons from "../../../components/ImgIcons";
import { makeStyles } from "@mui/styles";
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import { portraitBreakPoint } from "../../../views/contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    icons: {
        marginRight: '6px',
    },
    mobileContainer: {
        width: '100%',
        height: '100%',
        overflowY: "auto",
        textAlign: 'center',
        background: theme.palette.background.default,
        '& li': {
            cursor: 'pointer',
            borderTop: '1px solid grey',
            justifyContent: 'center',
        },
        '& h4': {
            display: 'flex',
            fontFamily: 'NeueHaasDisplayMedium',
            alignItems: 'center',
            color: theme.palette.primary.dark,
            padding: '20px 0',
        }
    },
    drawer: {
        '& .MuiDrawer-paper': {
            borderRadius: '20px 20px 0 0',
            maxHeight: '66%',
            overflow: 'hidden',
        }
    },
    divider: {
        fontSize: '24px',
        borderColor: `${theme.palette.border}`,
        margin: '8px 20px',
        cursor: 'default',
        [`${portraitBreakPoint(theme)}`]: {
            display: 'none',
        }
    },
    delete:{
        color: `${theme.palette.text.darkRed} !important`
    }
}));

export default function ContentDrawer({content, anchorRef, loadContent, loadPlayerContent, setCurrentVersion, handleClose, transcoderEnd, uploadFailed, openToggle, setOpenToggle, setAddCollectionPopup, setShowDeleteDialogBox, setShowRemoveDialogBox, setShowRenameDialogBox, setOpenContent, setOpenNotes}) {
    const classes = useStyles();
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
    const [subMenuOpen,setSubMenuOpen] = useState(false);
    const [subMenuData,setSubMenuData] = useState(null);
    const [subMenuType,setSubMenuType] = useState();
    const selectedCollection = useSelector((state) => state.collections.selectedCollection);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        !openToggle && setSubMenuOpen(false);
    }, [openToggle]);

    const checkInProgree=()=>{
        if(!transcoderEnd) {
            enqueueSnackbar({message:"This action is not available at this time.", variant:'error'});
        }
        return !transcoderEnd;
    }

    const handleAddToCollection = (event) => {
        event.stopPropagation();
        setOpenToggle(false);
        setAddCollectionPopup(true);
    };

    const handleDeleteContent = (event) => {
        event.stopPropagation();
        setShowDeleteDialogBox(true);
        setOpenToggle(false);
    };

    const handleRemoveContent = (event) => {
        event.stopPropagation();
        setShowRemoveDialogBox(true);
        setOpenToggle(false);
    };

    const handleCloseToggle = (event) => {
        event.stopPropagation();
        setOpenToggle(false);
    };

    const handleContentDownload= (event) => {
        event.stopPropagation();
        if(!checkInProgree()){
            setSubMenuType('download');
            setSubMenuAnchorEl(event.currentTarget);
            const contentVersion = content.final_version || content.latest_version;
            if(content.type_id === 5){
                enqueueSnackbar({message:"Download of Virtual Space content is not available at this time.", variant:'error'});
            } else{
                getConfig(content.type_id,content.uuid,contentVersion)
                .then((data)=>{
                    if(content.type_id === 3) {
                        downloadFile(data,content.title);
                    } else {
                        setSubMenuData(data);
                        setSubMenuOpen(true);
                    }
                });
            }
        }
    }

    const handleVersions=(event) => {
        let versionsData=[];
        for(let i = 1; i <= content.latest_version;i++){
            versionsData.push({shortform:'Version '+i,version:i});
        }
        versionsData.sort((a,b) => b.version - a.version);
        if(content.final_version){
            versionsData.unshift({shortform:`Final (v${content.final_version})`,version:content.final_version});
        }
        event.stopPropagation();
        if(!checkInProgree()){
            if(versionsData.length === 0){
                enqueueSnackbar({message:"Versions for this content are not available at this time.", variant:'error'});
                autoGeneratedFeedback("Content.status is NORMAL but latest_version and final_version are both 0.");
                return false;
            }
            setSubMenuType('versions');
            setSubMenuAnchorEl(event.currentTarget);
            setSubMenuData(versionsData);
            setSubMenuOpen(true);
        }
    }

    const copyContentLinkToClipboard = (event) => {
        event.stopPropagation();
        if(!checkInProgree()){
            let contentPlayerLink = `${window.location.origin}/player?uuid=${content.uuid}`;
            if (!content.final_version) {
                contentPlayerLink = contentPlayerLink + `&v=${content.latest_version}`;
            }

            setTimeout(handleClose, 600);
            navigator.clipboard.writeText(contentPlayerLink);
            setOpenToggle(false);
            enqueueSnackbar({ message: "Content URL copied successfully!", variant: 'success' });
        }
    };

    const copyEmbedLinkToClipboard = (event) => {
        event.stopPropagation();
        if(!checkInProgree()){
            let contentPlayerLink = `${window.location.origin}/player?uuid=${content.uuid}`;
            if (!content.final_version) {
                contentPlayerLink = contentPlayerLink + `&v=${content.latest_version}`;
            }
            let embedLink=`<iframe width="560" height="315" src="${contentPlayerLink}" title="${content.title}" allowfullscreen></iframe>`;

            setTimeout(handleClose, 600);
            navigator.clipboard.writeText(embedLink);
            setOpenToggle(false);
            enqueueSnackbar({ message: "Embed URL copied successfully!", variant: 'success' });
        }
    };

    const handleRenameContent = (event) => {
        event.stopPropagation();
        setOpenToggle(false);
        setShowRenameDialogBox(true);
    };

    const handleNotes = () => {
        setOpenContent(true);
        setOpenNotes(true);
    }

    return (
    <>
    <Drawer
        className={classes.drawer}
        open={openToggle}
        anchor='bottom'
        transitionDuration={500}
        onClose={handleCloseToggle}
    >
        {!(selectedCollection && selectedCollection.serviceOfferingCollection) ?
        <Paper className={classes.mobileContainer}>
            <MenuItem onClick={handleAddToCollection}>
                <Typography
                    variant="h4"
                    color="textSecondary"
                >
                    <ImgIcons iconName="AddToCollection" className={classes.icons} />{" "}
                    Add to Collection
                </Typography>
            </MenuItem>                                                            
            <MenuItem onClick={handleRemoveContent}>
                <Typography
                    variant="h4"
                    color="textSecondary"
                >
                    <ImgIcons iconName={'Remove'} className={classes.icons} />
                    Remove From Collection
                </Typography>
            </MenuItem>
            {!uploadFailed && 
            <MenuItem disabled={!transcoderEnd} className={transcoderEnd ? classes.menuItem : classes.disabledMenuItem} onClick={copyContentLinkToClipboard}>
                <Typography
                    variant="h4"
                    color="textSecondary"
                >
                    <ImgIcons iconName="CopyLink" className={classes.icons} />{" "}
                    Copy Link
                </Typography>
            </MenuItem>
            }
            {!uploadFailed && 
            <MenuItem disabled={!transcoderEnd} className={transcoderEnd ? classes.menuItem : classes.disabledMenuItem} onClick={copyEmbedLinkToClipboard}>
                <Typography
                    variant="h4"
                    color="textSecondary"
                >
                    <ImgIcons iconName="CopyLink" className={classes.icons} />{" "}
                    Copy Embed Link
                </Typography>
            </MenuItem>
            }
            {!uploadFailed && 
            <MenuItem disabled={!transcoderEnd} className={transcoderEnd ? classes.menuItem : classes.disabledMenuItem} onClick={handleContentDownload} aria-describedby={ Boolean(subMenuAnchorEl) ? 'submenu_popover' : undefined} >
                <Typography
                    variant="h4"
                    color="textSecondary"
                    
                >
                    <ImgIcons iconName="Download" className={classes.icons} />{" "}
                    Download
                </Typography>      
            </MenuItem> 
            }
            <MenuItem onClick={handleRenameContent}>
                <Typography
                    variant="h4"
                    color="textSecondary"
                >
                    <ImgIcons iconName="Rename" className={classes.icons} />{" "}
                    Rename
                </Typography>
            </MenuItem>
            <MenuItem onClick={handleNotes}>
                <Typography
                    variant="h4"
                    color="textSecondary"
                >
                    <ImgIcons iconName="Feedback" className={classes.icons} />{" "}
                    Notes
                </Typography>
            </MenuItem>
            {!uploadFailed && 
            <MenuItem disabled={!transcoderEnd} className={transcoderEnd ? classes.menuItem : classes.disabledMenuItem} onClick={handleVersions}>
                <Typography
                    variant="h4"
                    color="textSecondary"
                >
                    <ImgIcons iconName="Version" className={classes.icons} />{" "}
                    Versions
                </Typography>
            </MenuItem>
            }
            <MenuItem onClick={handleDeleteContent}>
                <Typography
                    variant="h4"
                    className={classes.delete}
                >
                    <ImgIcons iconName={'Delete'} className={classes.icons} />
                    Delete
                </Typography>
            </MenuItem>
        </Paper>
        :
        <Paper className={classes.mobileContainer}>
            <MenuItem onClick={handleAddToCollection}>
                <Typography
                    variant="h4"
                    color="textSecondary"
                >
                    <ImgIcons iconName="AddToCollection" className={classes.icons} />{" "}
                    Add to Collection
                </Typography>
            </MenuItem> 
            {!uploadFailed && 
            <MenuItem disabled={!transcoderEnd} className={transcoderEnd ? classes.menuItem : classes.disabledMenuItem} onClick={copyContentLinkToClipboard}>
                <Typography
                    variant="h4"
                    color="textSecondary"
                >
                    <ImgIcons iconName="CopyLink" className={classes.icons} />{" "}
                    Copy Link
                </Typography>
            </MenuItem>
            }
            {!uploadFailed && 
            <MenuItem disabled={!transcoderEnd} className={transcoderEnd ? classes.menuItem : classes.disabledMenuItem} onClick={handleContentDownload} aria-describedby={ Boolean(subMenuAnchorEl) ? 'submenu_popover' : undefined} >
                <Typography
                    variant="h4"
                    color="textSecondary"
                    
                >
                    <ImgIcons iconName="Download" className={classes.icons} />{" "}
                    Download
                </Typography>      
            </MenuItem> 
            }
        </Paper>
        }
    </Drawer>
    <ContentSubMenu content={content} loadContent={loadContent} loadPlayerContent={loadPlayerContent} subMenuType={subMenuType} subMenuData={subMenuData} subMenuAnchorEl={subMenuAnchorEl} setSubMenuAnchorEl={setSubMenuAnchorEl} setOpenToggle={setOpenToggle} subMenuOpen={subMenuOpen} setSubMenuOpen={setSubMenuOpen} setCurrentVersion={setCurrentVersion}/>
    </>
    )
}
