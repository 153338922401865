import {onHideMenuIconsOnVideoSelect} from "./CollectionAction";
import { sendFeedbackAPI } from './api/userAPI';

export const ON_CHANGE_VIDEO_PARAMETER_RECEIVED = "ON_CHANGE_VIDEO_PARAMETER_RECEIVED";

export const onChangeRoomVideos = (videoParameters) => dispatch => {
    dispatch ({
        type:ON_CHANGE_VIDEO_PARAMETER_RECEIVED,
        payload:videoParameters
    });
    dispatch(onHideMenuIconsOnVideoSelect(true))
};

export const saveFeedback = (feedback) => dispatch => new Promise(async(resolve,reject)=> {
    try {
        const result = await sendFeedbackAPI(feedback);
        resolve(result);
    } catch(error) {
        reject(error);
    }
});
