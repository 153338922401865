import React from "react";
import {
    Autocomplete,
    Button,
    DialogActions,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";

import FormControlLabel from "@mui/material/FormControlLabel";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { theme } from "../../../theme";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles, withStyles } from "@mui/styles";
import { portraitBreakPoint } from "../../contextComponent/viewportContext";

const moment = require("moment");
moment.locale();

const hoursArray = [...Array(6).keys()];
const minutesArray = [...Array(12).keys()].map((i) => i * 5);
const timeArray = getTimeArray();
const tooltipMessages = {
    duration:
        "The duration is only used in session invitations to tell your guests how long you expect the session to run. Feel free to go for longer!",
    thirdParty:
        "Warning: Enabling this feature will disable the participant voice and video components of 360 Meet. It is only meant to be enabled if you intend on using another voice and/or video application like Zoom, Google Meet, or phone call during your session.",
};

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        overflow: "hidden",
    },
    root: {
        color: theme.palette.primary.main,
        borderRadius: "12px",
        padding: "12px 36px",
        "& h4": {
            color: theme.palette.text.main,
            paddingBottom: "6px",
        },
        ".MuiDialogTitle-root": {
            paddingLeft: 0,
        },
    },
    title: {
        padding: "12px 0",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.text.main,
        fontSize: "18px"
    },
    divider: {
        marginTop: 0,
        marginBottom: "20px",
    },
    button: {
        height: "30px",
        color: theme.palette.text.contrastText,
        borderRadius: 30,
        margin: "6px 0px",
        padding: "10px 30px",
        fontWeight: "bold",
        [`${portraitBreakPoint(theme)}`]: {
            padding: "0 0px",
        },
    },
    generateButton: {
        borderRadius: "33px",
        padding: "1px 8px",
        marginLeft: "15px",
        marginRight: "auto",
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "200px",
        fontFamily: "NeueHaasDisplayBold",
        [`${portraitBreakPoint(theme)}`]: {
            width: "55%",
            maxWidth: "210px",
            alignItems: "center",
        },
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.contrastText,
        },
    },
    input: {
        boxSizing: "border-box",
        padding: "2px 10px",
        marginBottom: "20px",
        border: "2px solid #DEDEDE",
        borderRadius: "10px",
        "& .MuiInputBase-input": {
            color: theme.palette.text.main,
        },
        "&::placeholder": {
            color: theme.palette.text.main,
        },
        "& .MuiInputBase-formControl": {
            marginTop: 0,
        },
        "& label": {
            paddingLeft: "10px",
        },
        "& .MuiInputLabel-formControl": {
            transform: "translate(0, 13px) scale(1)",
        },
        "& .MuiInputLabel-shrink": {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.main,
            transform: "translate(0, -9px) scale(.9)",
            padding: "0 4px",
            marginLeft: "6px",
        },
    },
    inputSpacing: {
        marginRight: "16px",
        [`${portraitBreakPoint(theme)}`]: {
            marginRight: 0,
        },
    },
    dateHolder: {
        display: "flex",
        [`${portraitBreakPoint(theme)}`]: {
            flexWrap: "wrap",
        },
    },
    timeInput: {
        '& .MuiAutocomplete-endAdornment': {
            top: 0,
        }
    },
    durationHolder: {
        display: "flex",
        alignItems: "center",
        [`${portraitBreakPoint(theme)}`]: {
            flexWrap: "wrap",
        },
    },
    buttonContainer: {
        padding: "0 0 12px",
    },
    actionButtons: {
        borderRadius: 30,
        height: 32,
        fontFamily: "NeueHaasDisplayBold",
        padding: "5px 25px",
        "&:hover": {
            borderRadius: 30,
        },
    },
    menu: {
        "& .MuiPaper-root": {
            maxHeight: "150px",
            backgroundColor: "white",
        },
    },
    helpiconDiv: {
        display: "flex",
        alignItems: "center",
        marginLeft: 3,
    },
    durationDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    subtitle: {
        marginLeft: "5px",
    },
    thirdPartyContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
    },
    thirdPartySwitch: {
        marginLeft: 0,
    },
    thirdPartyLabel: {
        fontSize: "14px",
        marginBottom: "20px",
    },
}));

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        margin: "8px",
        backgroundColor: theme.palette.background.main,
        fontSize: "15px",
        border: `.5px solid ${theme.palette.text.medium}`,
    },
    tooltipPlacementTop: {
        margin: "2px 0",
    },
}))(Tooltip);

const CustomSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
        flexDirection: "column",
        overflow: "inherit",
    },
    switchBase: {
        padding: 1,
        margin: 0,
        color: theme.palette.text.medium,
        "&$checked": {
            transform: "translateX(18px)",
            color: theme.palette.primary.main,
            "& + $track": {
                backgroundColor: theme.palette.divider,
                opacity: 1,
            },
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: "15px",
        border: `1px solid ${theme.palette.border}`,
        backgroundColor: theme.palette.divider,
        opacity: 1,
    },
    checked: {},
}))(Switch);

const MeetingForm = ({
    editable,
    handleCreateSession,
    handleChange,
    handleTimeChange,
    sessionName,
    date,
    time,
    hours,
    minutes,
    thirdPartyCheck,
    handleClose,
    loadingCreateMeeting,
    createDisable
}) => {
    const classes = useStyles();

    const onSubmit=(event)=>{
        event.preventDefault();
        handleCreateSession();
    }

    return (
        <form onSubmit={onSubmit}>
            <Typography className={classes.title}>Session Name</Typography>

            <TextField
                variant="standard"
                className={classes.input}
                fullWidth
                name="sessionName"
                value={sessionName}
                onChange={handleChange}
                autoComplete="off"
                placeholder="Session Name"
                InputProps={{
                    disableUnderline: true,
                }} />

            <Typography className={classes.title}>Time</Typography>
            <div className={classes.dateHolder}>
                <TextField
                    variant="standard"
                    className={`${classes.input} ${classes.inputSpacing}`}
                    type="date"
                    fullWidth
                    autoComplete="off"
                    name="date"
                    value={date}
                    onChange={handleChange}
                    placeholder="Next Appointment"
                    InputProps={{
                        disableUnderline: true,
                    }} />
                <Autocomplete
                    options={timeArray}
                    fullWidth
                    clearOnBlur
                    className={`${classes.input} ${classes.timeInput}`}
                    onChange={handleTimeChange}
                    value={moment(time, ["H:mm"]).format("h:mm a")}
                    renderInput={(params) => <TextField variant="standard" {...params} 
                        InputProps={{
                            ...params.InputProps, disableUnderline: true,
                        }}  label="" />}
                    renderOption={(props, option) => {
                      return (
                        <MenuItem {...props} key={option}>
                          {option}
                        </MenuItem>
                      );
                    }}
                />
            </div>
            <div className={classes.durationDiv}>
                <Typography className={classes.title}>Duration</Typography>
                <CustomTooltip
                    title={tooltipMessages.duration}
                    disableFocusListener
                    placement="bottom-start"
                    enterTouchDelay={0}
                >
                    <div className={classes.helpiconDiv}>
                        <HelpOutlineIcon style={{ fontSize: 18 }}></HelpOutlineIcon>
                    </div>
                </CustomTooltip>
            </div>
            <Typography variant={"body1"} component="div" className={classes.durationHolder}>
                <TextField
                    variant="standard"
                    className={`${classes.input} ${classes.inputSpacing}`}
                    select
                    fullWidth
                    name="hours"
                    label="Hour(s)"
                    value={hours}
                    onChange={handleChange}
                    SelectProps={{
                        MenuProps: { className: classes.menu },
                    }}
                    InputProps={{
                        disableUnderline: true,
                    }}>
                    {hoursArray.map((hour) => (
                        <MenuItem key={hour} value={hour}>
                            {hour}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    variant="standard"
                    className={classes.input}
                    select
                    fullWidth
                    autoComplete="off"
                    name="minutes"
                    label="Minutes"
                    value={minutes}
                    onChange={handleChange}
                    SelectProps={{
                        MenuProps: { className: classes.menu },
                    }}
                    InputProps={{
                        disableUnderline: true,
                    }}>
                    {minutesArray.map((minute) => (
                        <MenuItem key={minute} value={minute}>
                            {minute}
                        </MenuItem>
                    ))}
                </TextField>
            </Typography>
            <div className={classes.thirdPartyContainer}>
                <Typography className={classes.title}>Using Third Party Voice and Video?</Typography>
                <Typography variant={"subtitle1"} className={classes.subtitle}>
                    &#40;Google Meet, Zoom, Teams, etc.&#41;
                </Typography>
                <CustomTooltip
                    title={tooltipMessages.thirdParty}
                    disableFocusListener
                    placement="bottom-end"
                    enterTouchDelay={0}
                >
                    <div className={classes.helpiconDiv}>
                        <HelpOutlineIcon style={{ fontSize: 18 }}></HelpOutlineIcon>
                    </div>
                </CustomTooltip>
            </div>
            <FormControlLabel
                className={classes.thirdPartySwitch}
                control={
                    <CustomSwitch
                        checked={thirdPartyCheck}
                        onChange={handleChange}
                        name={"thirdPartySwitch"}
                    />
                }
                label={
                    <div className={classes.thirdPartyLabel}>
                        No{" "}
                        <span
                            style={{
                                marginLeft: "5px",
                                color: thirdPartyCheck ? theme.palette.primary.main : "black",
                            }}
                        >
                            Yes
                        </span>
                    </div>
                }
                labelPlacement="bottom"
            />
            <hr className={classes.divider} />
            <DialogActions className={classes.buttonContainer}>
                <Button variant="outlined" onClick={handleClose} className={classes.actionButtons}>
                    Cancel
                </Button>
                {loadingCreateMeeting ? (
                    <LoadingSpinner />
                ) : (
                    <Button
                        variant="outlined"
                        className={classes.actionButtons}
                        type="submit"
                        disabled={createDisable}
                    >
                        {editable ? "Update" : "Create"}
                    </Button>
                )}
            </DialogActions>
        </form>
    );
};

function getTimeArray() {
    const timeArray = [];

    for(let hour = 0; hour < 24; hour++) {
        timeArray.push(moment({ hour }).format('h:mm a'));
        timeArray.push(moment({ hour, minute: 15 }).format('h:mm a'));
        timeArray.push(moment({ hour, minute: 30 }).format('h:mm a'));
        timeArray.push(moment({ hour, minute: 45 }).format('h:mm a'));
    }

    return timeArray;
}

export default MeetingForm;
