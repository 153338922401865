import palette from "../palette";
const MuiDialog={
    paper: {
        border: '2px solid',
        borderColor: palette.primary.main,
        borderRadius: 11,
        margin: 20,
        background: palette.background.default,
        opacity: '1',
    },
}
export default MuiDialog;
