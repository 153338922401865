import { Box, Button, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { fetchCollections, fetchContentsFilter, updateCollections } from "../../../actions/CollectionAction";
import ContentHeader from "../ContentHeader";
import Contents from "../contents/Contents";
import FAQsComponent from "../FAQsComponent";
import CollectionFilterSortMenu from "./SortFilterComponent";
import Collections from "./Collections";
import OrganisationFilter from "./OrganisationFilter"
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../../contextComponent/viewportContext";
import config from "../../../config";
import { setRouterState } from "../../../actions/ApplicationActions";

const useStyles = makeStyles((theme) => ({
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(0.2)",
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)",
        },
    },
    activeTab: {
        "& .MuiTypography-root": {
            color: theme.palette.primary.main,
        },
    },
    notActiveTab: {
        "& .MuiTypography-root": {
            color: theme.palette.text.medium,
        },
    },
    uploadButton: {
        whiteSpace: "nowrap",
        borderRadius: "33px",
        margin:"0 10px",
        padding: '0 30px',
        height: "30px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        // width: "200px",
        fontFamily: "NeueHaasDisplayBold",
        [`${portraitBreakPoint(theme)}`]: {
            fontSize: "14px",
            alignItems: "center",
        },
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.contrastText,
        },
    },
    hideUploadButton: {
        display: "none",
    },
    contentHeader: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "12px 12px 0 12px",
    },
    infoIcon: {
        height: "22px",
        opacity: "0.8",
        transition: "0.2s ease-in-out",
        "&:hover": {
            opacity: "1",
        },
    },
    notifcationIcon: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.text.medium,
        fontSize: "large",
        padding: "2px",
        opacity: "0.65",
        borderRadius: "33px",
        transition: "0.2s ease-in-out",
        "&:hover": {
            opacity: "0.85",
        },
    },
    searchButton: {
        border: `1px solid ${theme.palette.text.medium}`,
        borderRadius: "33px",
        padding: "2px 8px",
        marginLeft: "15px",
    },
    hideSearchButton: {
        display: "none",
    },
    menuItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "200px",
        fontSize: "16px",
        color: theme.palette.text.navy,
        [`${landscapeBreakPoint(theme)}`]: {
            marginRight: "0px",
            width: 150,
        },
    },
    mobileSearchAndUpload: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    filterHolder: {
        display: "flex",
        alignItems: "center",
        margin: '30px',
        [`${landscapeBreakPoint(theme)}`]: {
            margin: '20px',
        },
        [`${portraitBreakPoint(theme)}`]: {
            margin: '15px',
        }
    },
    orgFilterHolder: {
        display: "flex",
        alignItems: "center",
        margin: '30px',
        [`${landscapeBreakPoint(theme)}`]: {
            margin: '20px',
        },
        [`${portraitBreakPoint(theme)}`]: {
            margin: '15px',
        }
    },
    tabs: {
        "& .MuiTabs-indicator": {
            height: "0px",
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: 'space-around',
        },
        "& .MuiTab-root": {
            minWidth: "200px",
            [`${landscapeBreakPoint(theme)}`]: {
                minWidth: "160px",
            },
            [`${portraitBreakPoint(theme)}`]: {
                minWidth: "120px",
            }
        },
    },
    tabsLableActive: {
        textTransform: "none",
        padding:"0",
        "& .MuiTypography-root": {
            padding: "10px 0",
            borderBottom: `4px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
        }
    },
    tabsLableInActive: {
        textTransform: "none",
        padding:"0",
        "& .MuiTypography-root": {
            color: theme.palette.primary.dark,
        }
    },
    divider: theme.typography.divider,
    searchTuneRoundedIcon: {
        color: theme.palette.text.medium,
        padding: "2px",
        border:"1px solid",
        margin:"10px",
        borderRadius: "50%",
        "&:hover": {
            color: theme.palette.text.main,
            cursor: "pointer",
        }
    },
    searchFilterHolder: {
        display: "flex",
        alignItems: 'center'
    },
    tabTitle: {
        whiteSpace: 'nowrap',
        color: theme.palette.text.main,
        fontSize: "22px",
        userSelect: 'none',
        [`${portraitBreakPoint(theme)}`]: {
            marginLeft: '5px'
        },
        [`${landscapeBreakPoint(theme)}`]: {
            marginLeft: '10px'
        }
    },
}));

const CollectionDashboard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { isMobile, isMobileLandscape } = useViewport();
    const [showFAQ, setShowFAQ] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const tabs = useMemo(() => ["", "favourite", "recent"], []);
    const [collectionContentToggle, setCollectionContentToggle] = useState(0);
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const [value, setValue] = React.useState(0);
    const collectionsSort = useSelector(state => state.collections.collections.sort);
    const collectionsPage = useSelector(state => state.collections.collections.page);
    const collectionsSearch = useSelector(state => state.collections.collections.search);
    const contentsSort = useSelector(state => state.collections.contents.sort);
    const contentsPage = useSelector(state => state.collections.contents.page);
    const contentsSearch = useSelector(state => state.collections.contents.search);
    const refetchCounter = useSelector(state => state.collections.refetchCounter);
    const selectedOrg = useSelector(state => state.collections.selectedOrganisation);
    const isViewlabsUser = useSelector(state => state.application.userDetails?.is_viewlabs_user);
    const routerState = useSelector(state => state.application.routerState);
    const isMeetingView = useSelector(state => state.session.isMeetingView);
    const [initRouterState, setInitRouterState] = useState(true);

    // const token = getTokenData();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(tabIndex) {
        return {
            id: `simple-tab-${tabIndex}`,
            "aria-controls": `simple-tabpanel-${tabIndex}`,
        };
    }

    const onClickFAQ = () => {
        setShowFAQ(!showFAQ);
    };

    const onClickTab = (index) => {
        setSelectedTab(index);
        console.log("user on tab = ", tabs[selectedTab]);
        setCollectionContentToggle(0);
        dispatch(updateCollections({ page: { size: 16, number: 0 }, search: "" }));
    };

    const goToUploader = () => {
        history.push("/uploadContent");
    };

    React.useEffect(() => {
        if (selectedTab === 0 || collectionContentToggle === 0) {
            dispatch(fetchCollections(collectionsPage.size, collectionsPage.number, collectionsSort, collectionsSearch, tabs[selectedTab], selectedOrg?.id));
        } else {
            const ignoreUser = !!selectedOrg;
            dispatch(fetchContentsFilter(contentsPage.size, contentsPage.number, contentsSort, contentsSearch, tabs[selectedTab], ignoreUser));
        }
    }, [dispatch, tabs, collectionsPage, collectionsSort, collectionsSearch, contentsPage, contentsSort, contentsSearch, selectedTab, collectionContentToggle, refetchCounter, selectedOrg]);

    // Checks if content is displayed in an iframe. Content tab in meeting view uses an iframe.
    React.useEffect(() => {
        if(initRouterState){
            setInitRouterState(false);
            dispatch(setRouterState({
                ...routerState,
                page: history.location.pathname,
                uploaderBack: history.location.pathname,
            }));
        }
    }, [dispatch, history.location.pathname, initRouterState, routerState]);

    const handleMenuItemClick = (event, index) => {
        event.stopPropagation();
        setCollectionContentToggle(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    const setCollectionSort = (sort) => {
        dispatch(updateCollections({ sort }))
    }

    const setCollectionSearch = (search) => {
        dispatch(updateCollections({ search }))
    }

    if (showFAQ) {
        return <FAQsComponent onClickBack={onClickFAQ} />;
    }

    return (
        <Box height="100%" id="collectionDashboard" display={"flex"} flexDirection={"column"}>
            <Box
                className={classes.divider}
                display="flex"
                justifyContent="space-between"
                flexWrap={isMobile || isMobileLandscape ? "wrap" : null}
                flexDirection={isMobile || isMobileLandscape ? "column-reverse" : "row"}
            >
                <Box
                display="flex"
                alignItems="flex-end"
                flexWrap=""
                flexDirection="row"
                >
                    <Tabs
                    className={classes.tabs}
                    style={{ width: isMobile ? "100%" : "" }}
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs"
                    >
                        <Tab
                            className={selectedTab === 0 ? classes.tabsLableActive : classes.tabsLableInActive}
                            label={<Typography variant="h4">Collections</Typography>}
                            {...a11yProps(0)}
                            onClick={() => onClickTab(0)}
                        />
                        <Tab
                            className={selectedTab === 1 ? classes.tabsLableActive : classes.tabsLableInActive}
                            label={<Typography variant="h4">Favorites</Typography>}
                            {...a11yProps(1)}
                            onClick={() => onClickTab(1)}
                        />
                        <Tab
                            className={selectedTab === 2 ? classes.tabsLableActive : classes.tabsLableInActive}
                            label={<Typography variant="h4">Recent</Typography>}
                            {...a11yProps(2)}
                            onClick={() => onClickTab(2)}
                        />
                    </Tabs>
                </Box>
                
                {!isMeetingView && 
                    <ContentHeader isOpen={isOpen} setIsOpen={setIsOpen} iconMode>
                        {(isMobile || isMobileLandscape) && 
                            <Typography className={classes.tabTitle} variant="h4">
                                Content Hub
                            </Typography>
                        }
                    </ContentHeader>
                }
            </Box>
            <div className={selectedTab === 0 ? classes.orgFilterHolder : classes.filterHolder}>
                { !isMobile && !isMobileLandscape &&
                    (config.ENABLE_UPLOADER || (config.ENABLE_UPLOADER_FOR_VL && isViewlabsUser)) && 
                        <Button
                            variant="text"
                            className={classes.uploadButton}
                            onClick={goToUploader}
                        >
                            Upload Content
                        </Button>
                }
                <div className={classes.searchFilterHolder}>
                    <CollectionFilterSortMenu setSort={setCollectionSort} setSearch={setCollectionSearch} />
                </div>
                <Button
                    className={selectedTab === 0 ? classes.hideSearchButton : classes.searchButton}
                    ref={anchorRef}
                    color="primary"
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    {collectionContentToggle === 0 && 
                        <Typography variant="caption" className={classes.menuItem}>
                            Collections <ExpandMoreIcon />
                        </Typography>
                    }
                    {collectionContentToggle === 1 && 
                        <Typography variant="caption" className={classes.menuItem}>
                            Contents <ExpandMoreIcon />
                        </Typography>
                    }
                </Button>
                <div id='org-filter-holder' style={{marginLeft: (!isMobile && !isMobileLandscape ?'auto':0)}}>
                    {selectedTab === 0 && isViewlabsUser && <div>
                        {
                            !isMobile && !isMobileLandscape ?
                                <OrganisationFilter id='organisation-filter' />
                                : 
                                (isMobile || isMobileLandscape) && <OrganisationFilter id='OrganisationFilter' />
                        }
                    </div>}
                </div>
            </div>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                placement="bottom-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper className={classes.paper}>
                            <ClickAwayListener
                                touchEvent="onTouchStart"
                                onClickAway={handleClose}
                                disableReactTree
                            >
                                <Paper>
                                    <MenuList id="split-button-menu">
                                        <MenuItem onClick={(e) => handleMenuItemClick(e, 0)}>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                className={classes.menuItem}
                                            >
                                                Collections{" "}
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem onClick={(e) => handleMenuItemClick(e, 1)}>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                className={classes.menuItem}
                                            >
                                                Contents{" "}
                                            </Typography>
                                        </MenuItem>
                                    </MenuList>
                                </Paper>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            {collectionContentToggle === 0 || selectedTab === 0 ? (
                <Collections selectedTab={tabs[selectedTab]} />
            ) : (
                <Contents selectedTab={tabs[selectedTab]} />
            )}
        </Box>
    );
};

export default CollectionDashboard;
