import { colors } from '@mui/material';
import palette from '../../theme/palette';
const MuiTableHead={
  root: {
    backgroundColor: colors.grey[50],
    fontWeight:600,
    color: palette.text.secondary
  }
}
export default MuiTableHead;
