import { TextField } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import palette from "../theme/palette";

const CssTextField = withStyles({
    root: {
        color: palette.text.main,
        backgroundColor: palette.background.main,
        WebkitTextFillColor: palette.text.main,
        '& .MuiInputBase-input': {
            color: palette.text.main,   
        }
    }
})(TextField);

export default CssTextField;
