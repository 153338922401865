import axios from '../../utils/axios';


export const loginAPI = (email, password) => {
    return axios.get("/api/user/login", {
        params: {
            email,
            password
        },
    });
};

export const logoutAPI = () => {
    return axios.get("/api/user/logout");
};

export const requestDemoAPI = (email, name, phone) => {
    return axios.post("/api/user/demo-request", null, {
        params: {
            email,
            name,
            phone
        }
    });
};

export const resetPasswordAPI = (email) => {
    return axios.get("/api/v2/user/password/reset", {
        params: {
            email
        }
    });
}

export const setNewPasswordAPI = (key, password) => {
    return axios.post("/api/user/password/reset", null, {
        params: {
            key,
            password
        },
    });
}

export const firstTimeSetupAPI = (key, first, last, password) => {
    return axios.patch("/api/user/firstTimeSetup", null, {
        params: {
            key,
            first,
            last,
            password
        }
    });
}

export const loginAuthAPI = (email) => {
    return axios.get("/api/user/login-auth");
}

export const sendFeedbackAPI = (feedback) => {
    return axios.post(`/api/user/feedback`, feedback);
};

export const emailOpt = (email, choice) => {
    return axios.patch("/api/user/marketing/opt-in", null, {
        params: {
            email,
            choice,
        },
    });
};

export const confirmChangeEmail = (key) =>{
    return axios.patch("/api/user/changeEmail/"+key);
}

export const revertChangeEmail = (key) =>{
    return axios.patch("/api/user/revertChangeEmail/"+key);
}

export const updateUserName = (userName) => {
    return axios.patch("/api/user/updateName", userName);
}

export const changePasswordAPI = (data) => {
    return axios.patch("/api/user/changePassword", data);
}

export const updateEmail = (email) => {
    return axios.patch("/api/user/changeEmailRequest/" + email);
}

export const getTimeZone = ()=>{
    return axios.get('/api/user/timezone');
}

export const changeTimeZone = (timezone)=>{
    return axios.post("/api/user/timezone", timezone)
} 
