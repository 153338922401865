import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import applicationReducer from './application';
import collectionReducer from './collectionReducer';
import meetingReducer from './meetingReducer';
import uploadReducer from './uploadReducer';
import orgReducer from './orgReducer';
import analyticsReducer from './analyticsReducer';


const rootReducer = combineReducers({
    session: sessionReducer,
    application: applicationReducer,
    collections: collectionReducer,
    meeting: meetingReducer,
    uploader: uploadReducer,
    organization: orgReducer,
    analytics: analyticsReducer
})

export default rootReducer;