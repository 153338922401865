import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 20,
    borderRadius: 3,
    "& .MuiSvgIcon-root": {
      fill: theme.palette.primary.main,
    },
  }

}));

export default function ServiceCheckbox ({ label, onClick }) {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          className={classes.root}
          onChange={onClick}
          value={label}
        />
      }
      label={label} />
  )
}
