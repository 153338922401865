import React from "react";
import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ImgIcons from "../../../components/ImgIcons";
import { makeStyles } from "@mui/styles";
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import { portraitBreakPoint } from "../../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    icons: {
        marginRight: '6px',
    },
    mobileContainer: {
        width: '100%',
        height: '100%',
        overflowY: "auto",
        textAlign: 'center',
        background: theme.palette.background.default,
        '& li': {
            cursor: 'pointer',
            borderTop: '1px solid grey',
            justifyCollection: 'center',
        },
        '& h4': {
            display: 'flex',
            fontFamily: 'NeueHaasDisplayMedium',
            alignItems: 'center',
            color: theme.palette.primary.dark,
            padding: '20px 0',
        }
    },
    drawer: {
        '& .MuiDrawer-paper': {
            borderRadius: '20px 20px 0 0',
            maxHeight: '66%',
            overflow: 'hidden',
        }
    },
    divider: {
        fontSize: '24px',
        borderColor: `${theme.palette.border}`,
        margin: '8px 20px',
        cursor: 'default',
        [`${portraitBreakPoint(theme)}`]: {
            display: 'none',
        }
    },
    delete:{
        color: `${theme.palette.text.darkRed} !important`
    }
}));

export default function CollectionDrawer({ openToggle, setOpenToggle, setShowRenameDialogBox}) {
    const classes = useStyles();

    const handleCloseToggle = (event) => {
        event.stopPropagation();
        setOpenToggle(false);
    };

    const handleRenameCollection = (event) => {
        event.stopPropagation();
        setOpenToggle(false);
        setShowRenameDialogBox(true);
    };

    return (
    <Drawer
        className={classes.drawer}
        open={openToggle}
        anchor='bottom'
        transitionDuration={500}
        onClose={handleCloseToggle}
    >
        <Paper className={classes.mobileContainer}>
            <MenuItem onClick={handleRenameCollection}>
                <Typography
                    variant="h4"
                    color="textSecondary"
                >
                    <ImgIcons iconName="Rename" className={classes.icons} />{" "}
                    Rename
                </Typography>
            </MenuItem>
        </Paper>
    </Drawer>
    )
}
