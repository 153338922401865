import * as actionTypes from '../actions';

const initialState = {
    changedVideoParameters: null,
};

const meetingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ON_CHANGE_VIDEO_PARAMETER_RECEIVED:{
            	console.log("video has changed");
	    	console.log("state.changedVideoParameters: " + state.changedVideoParameters);
		console.log("action.payload: " + action.payload);	
	    if(state.changedVideoParameters && action.payload) {
		const clonedVideoParam = JSON.parse(JSON.stringify(state.changedVideoParameters));
		if (clonedVideoParam.videoType === action.payload.videoType && clonedVideoParam.videoPath === action.payload.videoPath && clonedVideoParam.id === action.payload.id) {
		    return state;
		}
	    }
	    return {
		...state,
		changedVideoParameters: action.payload
		}
        }
        default: {
            return state;
        }
    }
};

export default meetingReducer;
