import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, IconButton, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Close from "@mui/icons-material/Close";
import { useSnackbar } from "../../../hooks/useCustomSnackbar";
import { portraitBreakPoint } from "../../../views/contextComponent/viewportContext";
import { addContentNote } from "../../../actions";
import { getContentNotesAPI } from "../../../actions/api/CollectionAPI";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentPreviewNote from "./ContentPreviewNote";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '25%',
        minWidth: '350px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderLeft: `1px solid ${theme.palette.border}`,
        backgroundColor: theme.palette.background.default,
        overflow: 'hidden',
        '& > *': {
            padding: '16px',
        },
        [`${portraitBreakPoint(theme)}`]: {
            width: '100%',
            minWidth: '100%',
            position: 'absolute',
            top: 0,
            right: 0,
        },
    },
    header: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.border}`,
        '& h3': {
            color: theme.palette.text.main,
            fontSize: '24px',
            lineHeight: 1,
        }
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    notesContainer: {
        flex: 1,
        overflow: 'auto',
    },
    newNote: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        boxSizing: 'border-box',
        '& .MuiInputBase-root': {
            color: theme.palette.text.main,
            background: theme.palette.background.main,
            height: 'auto',
            padding: '10px 14px',
            '& ::placeholder': {
                fontStyle: 'italic',
                color: theme.palette.text.medium,
            }
        },
        
    },
    actionButtons: {
        padding: '0 16px 22px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    submit: {
        padding: '0 30px',
    },
}));

const ContentPreviewNotes = ({ open, setOpen, contentId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [input, setInput] = useState("");
    const [notes, setNotes] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [refreshData, setRefreshData] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const pageSize = 8;

    useEffect(() => {
        getNotes(pageSize * (pageNumber + 1), 0);
    }, [refreshData])

    useEffect(() => {
        getNotes(pageSize, pageNumber);
    }, [pageNumber])

    const getNotes = (size, number) => {
        getContentNotesAPI(contentId, size, number)
            .then(res => {
                if(number > 0) {
                    setNotes(prevNotes => {
                        return { ...res.data, items: [...prevNotes.items, ...res.data.items]}
                    })
                } else {    
                    setNotes(res.data);
                }
            })
            .catch(error => {
                enqueueSnackbar({message: error.response.data.message, variant: "error"});
            })
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleInput = (e) => {
        setInput(e.target.value);
    }

    const handleSubmit = () => {
        if(input.length > 0) {
            setDisableSubmit(true);
            dispatch(addContentNote(contentId, input))
                .then(() => {
                    handleRefreshData();
                    setInput("");
                    enqueueSnackbar({message: "Note successfully added!", variant: "success"});
                    setDisableSubmit(false);
                })
                .catch(error => {
                    enqueueSnackbar({message: error.response.data.message, variant: "error"});
                    setDisableSubmit(false);
                })
        } else {
            enqueueSnackbar({message: "Note cannot be empty.", variant: "error"});
        }
    }

    const handleRefreshData = () => {
        setRefreshData(prev => !prev);
    }

    const loadMore = () => {
        setPageNumber(pageNumber + 1);
    };

    return (
        <div className={classes.container} style={!open ? {width: 0, minWidth: 0} : null}>
            <header className={classes.header}>
                <Typography variant="h3">Notes</Typography>
                <IconButton className={classes.closeButton} onClick={handleClose}>
                    <Close />
                </IconButton>
            </header>
            {notes.items?.length > 0 ? 
                <div id="notesScrollDiv" className={classes.notesContainer}>
                    <InfiniteScroll
                        dataLength={notes.items?.length}
                        next={loadMore}
                        hasMore={notes.count !== notes.items?.length}
                        loader={<Typography align="center">Loading...</Typography>}
                        scrollableTarget={"notesScrollDiv"}
                        refreshFunction={() => { }}
                        pullDownToRefresh={false}
                        pullDownToRefreshThreshold={50}
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>&#8595; Pull down to load data</h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>&#8593; Release to load data</h3>
                        }
                    >
                        {notes.items?.map((note) => (
                            <ContentPreviewNote key={note.id} note={note} contentId={contentId} handleRefreshData={handleRefreshData} handleNotesClose={handleClose} />
                        ))}
                    </InfiniteScroll>
                </div>
            :
                <Typography variant="h4" className={classes.newNote}>Add a new note below!</Typography>
            }
            <TextField className={classes.input} fullWidth placeholder="Write a comment..." value={input} onChange={handleInput} multiline rows={3} />
            <div className={classes.actionButtons}>
                <Button variant="outlined" className={classes.submit} onClick={handleSubmit} disabled={disableSubmit}>
                    Submit
                </Button>
            </div>
        </div>
    )
}

export default ContentPreviewNotes;
