import React, { useEffect, useState } from "react";
import {
    Button,
    DialogActions,
    DialogTitle,
    Popover,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InviteLogic from "../invite/InviteLogic";
import queryString from "query-string";
import { inviteUsersToSession } from "../../../actions";
import DialogMigrate from "../../../components/DialogMigrate";
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import { portraitBreakPoint } from "../../contextComponent/viewportContext";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        borderRadius: "12px",
        padding: "12px 36px",
        "& h4": {
            color: theme.palette.text.main,
            paddingBottom: "6px",
            wordWrap: 'break-word',
        },
        ".MuiDialogTitle-root": {
            paddingLeft: 0,
        },
        [`${portraitBreakPoint(theme)}`]: {
            border:'none',
            padding:'12px 18px'
        }
    },
    paper: {
        "& .MuiDialog-paper": {
            border: "1px solid",
            borderColor: theme.palette.border,
        },
        "& .MuiBackdrop-root": {
            background: "transparent"
        }
    },
    title: {
        padding: "12px 0",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.primary.main,
        fontSize: "24px"
    },
    divider: {
        marginTop: 0,
        marginBottom: "20px",
    },
    button: {
        height: "30px",
        color: theme.palette.text.contrastText,
        borderRadius: 30,
        margin: "6px 0px",
        padding: "10px 30px",
        fontWeight: "bold",
        [`${portraitBreakPoint(theme)}`]: {
            padding: "0 0px",
        },
    },
    input: {
        boxSizing: "border-box",
        padding: "2px 10px",
        marginBottom: "20px",
        border: "2px solid #DEDEDE",
        borderRadius: "10px",
        "& .MuiInputBase-input": {
            color: theme.palette.text.main,
        },
        "&::placeholder": {
            color: theme.palette.text.main,
        },
    },
    meetingInfoHolder: {
        display: "flex",
        marginBottom: "20px",
    },
    meetingInfo: {
        paddingRight: "10px",
        color: theme.palette.primary.dark
    },
    buttonContainer: {
        padding: "0 0 12px",
    },
    copyContainer: {
        display: "flex",
        padding: "0 0 12px",
    },
    actionButtons: {
        borderRadius: 30,
        height: 32,
        fontFamily: "NeueHaasDisplayMedium",
        "&:hover": {
            borderRadius: 30,
        },
        [`${portraitBreakPoint(theme)}`]:{
            fontSize:'14px',
            padding:'4px 10px',
        }
    },
    typography: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        fontWeight: "bold",
    },
    header:{
        color: `${theme.palette.primary.dark} !important`
    }
}));

const MeetingDetailsModal = ({
    sessionName,
    startDateTime,
    duration,
    openSuccess,
    setOpenSuccess,
    handleDeleteState,
    setOpenMessage,
    setAlertText,
    setSeverity,
    setSelectedTab,
    setEnableEditMenuPopup,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [displayDate, setDisplayDate] = useState(null);
    const [displayTime, setDisplayTime] = useState(null);
    const [displayEndTime, setDisplayEndTime] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const sessionLink = useSelector((state) => state.session.sessionLink);

    useEffect(() => {
        if (startDateTime) {
            const newDate = moment.unix(startDateTime);
            setDisplayDate(newDate.format("MMMM Do YYYY"));
            setDisplayTime(newDate.format("hh:mm a"));
            const endTime = newDate.add(duration, "seconds");
            setDisplayEndTime(endTime.format("hh:mm a"));
        }
    }, [duration, startDateTime]);

    const handleClose = () => {
        setSelectedTab(0);
        setOpenSuccess(false);
        handleDeleteState();
        setEnableEditMenuPopup && setEnableEditMenuPopup(false);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };
    const onSessionLinkClick = () => {
        window.open(sessionLink);
    };

    const inviteUsers = (invitees) => {
        const paramString = sessionLink.split("?")[1];
        const params = queryString.parse(paramString);
        const id = params.id;
        dispatch(inviteUsersToSession(id, invitees));
    };

    const copyToClipboard = (event) => {
        setAnchorEl(event.currentTarget);
        setTimeout(handleClosePopover, 1000);
        navigator.clipboard.writeText(sessionLink);
        enqueueSnackbar({ message: "Session URL copied successfully!", variant: 'success' });
    };

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? "simple-popover" : undefined;

    return (
        <React.Fragment>
            <DialogMigrate className={classes.paper} open={openSuccess} onClose={handleClose} fullWidth>
                <div className={classes.root}>
                    <DialogTitle id="simple-dialog-title" className={classes.title}>
                        Session
                    </DialogTitle>
                    <hr className={classes.divider} />
                    <Typography variant={"h4"} className={classes.header}>{sessionName}</Typography>
                    <div className={classes.meetingInfoHolder}>
                        <Typography className={classes.meetingInfo} variant={"body1"}>
                            {displayDate}
                        </Typography>
                        <Typography className={classes.meetingInfo} variant={"body1"}>
                            |
                        </Typography>
                        <Typography className={classes.meetingInfo} variant={"body1"}>
                            {displayTime + " - " + displayEndTime}
                        </Typography>
                    </div>

                    <Typography variant={"h4"} className={classes.header}>Invite Participants</Typography>
                    <InviteLogic
                        inviteUsers={inviteUsers}
                        isMeetingDetails={true}
                        closeMeetingDetails={handleClose}
                        setOpenMessage={setOpenMessage}
                        setAlertText={setAlertText}
                        setSeverity={setSeverity}
                    />

                    <hr className={classes.divider} />

                    <DialogActions className={classes.copyContainer}>
                        <CopyToClipboard text={sessionLink}>
                            <Button
                                variant="outlined"
                                className={classes.actionButtons}
                                onClick={copyToClipboard}
                            >
                                Copy Session Link
                            </Button>
                        </CopyToClipboard>
                        <Button
                                variant="outlined"
                                className={classes.actionButtons}
                               onClick={onSessionLinkClick}
                            >
                                Start session
                            </Button>
                    </DialogActions>
                </div>
            </DialogMigrate>

            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Typography className={classes.typography}>Copied!</Typography>
            </Popover>
        </React.Fragment>
    );
};

export default MeetingDetailsModal;
