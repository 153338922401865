import palette from '../palette';
import typography from '../typography';
const MuiTableCell={
  root: {
    ...typography.body1,
    backgroundColor: 'inherit',
    padding: "25px",
    borderBottom: 0,
    fontFamily: 'NeueHaasDisplayMedium',
    minWidth: '150px'

  },
  head: {
    backgroundColor: palette.background.main,
    color: palette.text.secondary,
    fontFamily: 'NeueHaasDisplayMedium'
  },
  secondary: {
    color: 'red'
  }
}
export default MuiTableCell;
