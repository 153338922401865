import React from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import ImgIcons from "../../../components/ImgIcons";
import { Typography } from "@mui/material";
import CustomTooltip from "../../../components/CustomTooltip";

const useStyles = makeStyles((theme) => ({
    container: {
        objectFit: "cover",
        border: `1px solid ${theme.palette.border}`,
        width: "100%",
        padding:1,
        maxWidth: "calc(100vw - 38px)",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        borderRadius: 12,
        background: "#ffc1c1",
    },
    title: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontWeight: "bold",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.primary.dark,
    },
    error: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontSize:"18px",
        fontWeight: "bold",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.error.main,
    },
    topRightIcons: {
        color: theme.palette.primary.contrastText,
        position: "absolute",
        display: "flex",
        alignContent: "space-between",
        margin: "5px",
        flexDirection: "row",
        top: 3,
        right: 3,
    },
    errorHelp:{
        verticalAlign: 'middle',
        fontSize: '1.0rem',
        marginLeft: '6px',
        color: theme.palette.error.main, 
    },
    text: {
        fontSize: "18px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        fontFamily: "NeueHaasDisplayMedium",
        alignSelf: "center",
        maxWidth: '90%',
        display: 'inline-flex'
    },
}));

const CustomWidthTooltip = withStyles((theme) => ({
    tooltip: {
        margin: "8px",
        backgroundColor: theme.palette.background.main,
        fontSize: "12px",
        boxShadow: `0px 0px 4px 0px ${theme.palette.text.medium}`,
    },
    tooltipPlacementTop: {
        margin: "2px 0",
    },
}))(Tooltip);



export default function UploadContentFailed({ uploadFailed, imageHeight }) {
    const classes = useStyles();
    const tipText =
        "This item will be automatically removed within 24 hours. Please verify that the file being uploaded is not corrupt before trying the upload again.";
    const [ isEllipsis, setIsEllipsis ] = React.useState(true);
    const handleMouseEnter=(event)=>{
        setIsEllipsis(event.target.scrollWidth > event.target.offsetWidth);
    }
    return (
        <div className={classes.container} style={{height:imageHeight,display: 'flex', justifyContent: 'center' }}>
            <div className={classes.text}>
                <CustomTooltip title="Upload Processing Failed" isEllipsis={isEllipsis}>
                    <Typography
                        className={classes.error}
                        onMouseEnter={handleMouseEnter}
                    >
                        Upload Processing Failed
                    </Typography>
                </CustomTooltip>
                <CustomWidthTooltip title={tipText}>
                        <div>
                            <ImgIcons iconName="ErrorHelp" className={classes.errorHelp} />
                        </div>
                </CustomWidthTooltip>
            </div>

            
            <div className={classes.topRightIcons}>
                <ImgIcons iconName="Disable" />
            </div>
        </div>
    );
}
