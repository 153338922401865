import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignContentToCollection } from "../../../actions/CollectionAction";
import DialogMigrate from "../../../components/DialogMigrate";
import CollectionSelector from "./CollectionSelector";
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import { portraitBreakPoint, landscapeBreakPoint } from "../../contextComponent/viewportContext";

const StyledButton = styled(Button)(({ theme }) => ({
	background: theme.palette.text.contrastText,
	color: theme.palette.primary.main,
	borderColor: theme.palette.primary.main,
	padding: '4px 35px',
	fontWeight: "bold",
	"&:hover": {
		background: theme.palette.primary.main,
		color: theme.palette.text.contrastText
	}
}));

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		color: theme.palette.text.secondary,
		margin: "5px",
		cursor: "pointer",
		flexDirection: "column",
	},
	contentImageHolder: {
		display: "flex",
		cursor: "pointer",
		flexDirection: "column",
		margin: "5px",
		position: "relative",
        [`${landscapeBreakPoint(theme)}`]: {
            minWidth: "200px",
        },
        [`${portraitBreakPoint(theme)}`]: {
            minWidth: "200px",
        }
	},
	image: {
		objectFit: "cover",
		maxHeight: "100%",
		borderRadius: 12,
		border: `1px solid ${theme.palette.border}`,
		background: theme.palette.background.default,
		width: "100%",
		padding: 1,
		display: "flex",
		justifyContent: "center",
		alignContent: "center",
		alignItems: 'center',
		flexDirection: 'column'
	},
}));

export const AssignContentDialog = ({ addToCollectionVisible, closeCollectionPopUp, content }) => {

	const classes = useStyles();
	const dispatch = useDispatch();
	const selectedCollection = useSelector(state => state.collections.selectedCollection);
	const { enqueueSnackbar } = useSnackbar();

	const onSubmit = (e) => {
		e.preventDefault();
		if (selectedCollection && content) {
			dispatch(assignContentToCollection(selectedCollection.id, content.uuid)).then(() => {
				closeCollectionPopUp();
				enqueueSnackbar({ message: "Content assigned successfully!", variant: 'success' });
			}).catch(() => {
				enqueueSnackbar({ message: "Error while assigning content", variant: 'error' });
			})
		} else {
			enqueueSnackbar({ message: "Please select colletion", variant: 'error' });
		}
	}

	return (
		<DialogMigrate open={addToCollectionVisible} onClose={closeCollectionPopUp} fullWidth>
			<div>
				<DialogTitle variant={"h4"} color="primary" id="add-collection-dialog" style={{ padding: '16px 24px 2px 30px' }}>
					Add To Collection Card
				</DialogTitle>
				<hr className={classes.dialogDivider} />
				<DialogContent id="create-collection-dialog-content" className={classes.dialogContent}>
					<Typography variant={"h4"} color="textSecondary">Collection Name</Typography>
					<form id="create-collection-from" onSubmit={onSubmit} style={{ width: '100%' }}>
						<CollectionSelector />
					</form>
				</DialogContent>
				<DialogActions className={classes.buttonContainer}>
					<StyledButton variant="outlined" onClick={closeCollectionPopUp} className={classes.dialogButtons}>
						Cancel
					</StyledButton>
					<StyledButton
						variant="outlined"
						className={classes.dialogButtons}
						type={'submit'}
						form={"create-collection-from"}
					>
						Add
					</StyledButton>
				</DialogActions>
			</div>
		</DialogMigrate>
	)
};


export default AssignContentDialog;
