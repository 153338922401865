import axios from 'axios';

let src_url;
// Gets the directory of the current file
function getFilePath(url) {
    let filepath = url.substring(0, url.lastIndexOf("/") + 1);
    return filepath;
  }
// Gets the file name of the current file
function getFileName(url) {
    let filename = url.substring(url.lastIndexOf("/")+1, url.length);
    return filename;
  }
// Gets the suffix of the current file
function getSuffix(url) {
    let filename = url.substring(url.lastIndexOf("/")+1, url.length);
    let suffix = filename.substring(filename.lastIndexOf("."), filename.length);
    return suffix;
  }
// Convert paths to URLs according to four rules
function getFileUrl(jsonUrl, media_url, language) {
    let str;
    switch (typeof jsonUrl) {
        case "object":
            str = jsonUrl.config;
            break;
        case "string":
            str = jsonUrl;
            break;
        default: {
            console.log("Json data structure error.");
            return "";
        }
    }

    if (str && typeof str === "string" && str.length > 0 && !str.match(/^[ ]*$/)) {
        if (str.indexOf("%$language%") > 0) {
            str = str.replace("%$language%", language);
        }
        if (str.indexOf("http") === 0 || str.indexOf("//") === 0) {
            return str;
        } else if (str.indexOf("%$media_url%") === 0) {
            return str.replace("%$media_url%", media_url);
        } else if (str.indexOf("./") === 0) {
            return src_url + str.substring(2, str.length);
        } else if (str.indexOf("/") === 0) {
            return `//${window.location.host}${str}`;
        } else {
            return src_url + str;
        }
    } else {
        // console.log('Empty url!');
        return "";
    }
}

export const getConfig = async (typeId, uuid, contentVersion) => {
    const configUrl = contentVersion
        ? `/assets/content/${uuid}/config-v${contentVersion}.json`
        : `/assets/content/${uuid}/config.json`;
    src_url = getFilePath(configUrl);
    const response = await fetch(configUrl);
    if (!response.ok) return;
    const config = await response.json();
    let data;
    const videoUrl =(location)=> {
        const media_url=config.media_url || config.settings.media_url;
        const language=config.settings.language ? config.settings.language[0] : 'english';
        return getFileUrl(location, media_url || '', language );
    };
    if (config) {
        switch (typeId) {
            case 1: // 360 Video 
            case 2: 
                // Standard Video
                const scenes=config.scenes || config.scene;
                let resolutions = scenes[0].resolutions || scenes[0].resolution;
                resolutions.sort((a, b) => b.bitrate - a.bitrate);
                const res_4k=resolutions.filter(r=>{return r.shortform === "4K"});
                if(res_4k.length >1){
                    const res_rest=resolutions.filter(r=>{return r.shortform !== "4K"});
                    resolutions=[res_4k[0],...res_rest];
                }
                resolutions = resolutions.filter((n) => n.shortform !== '54p');
                data = resolutions.map((n) => ({ shortform: n.shortform, location: videoUrl(n.location), title: config.info.title }));
                break;
            case 3: 
                // PDFs
                data = `/assets/content/${uuid}/${config.filename}`;
                break;
            case 4: 
                // Image Galleries
                const jsonUrl = `/assets/content/${uuid}/${config.filename}`;
                const json = await fetch(jsonUrl);
                const { images } = await json.json();
                data=images.map( (n,i)=> ({ shortform: `Image ${i}`, location:getFileUrl(n.src) }) );
                break;
            default:
                data = config;
                break;
        }
    }
    return data;
};

export const downloadFile = (url,title,type) => {
    const link = document.createElement('a');
    if(type === 'blob'){
        axios.get(url, {
            responseType: 'blob',
          })
          .then((res) => {
            console.log('downloaded');
            link.href = URL.createObjectURL(res.data);
            link.download = getFileName(url);
            document.body.appendChild(link);       
        })
    }else{
        link.href = url;
        link.download = title?(title+getSuffix(url)):getFileName(url);
    }
    link.click();
    link.remove();
  };
