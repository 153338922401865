import * as actionTypes from '../actions';

const initialState = {
  loginLoading: true,
  loggedinData: null,
  openNavBarMobile: false,
  openRightMenu: false,
  userData: null,
  isUserLoggedIn: false,
  showResetMsg: false,
  sessionLink: "",
  infoEnabled: false,
  sessionId: '',
  currentSessionUserName: '',
  sessionHistoryData: {
    "count": 0,
    "items": []
  },
  upcomingSessionData: {
    "count": 0,
    "items": []
  },
  deviceInfo: null,
  selectedContents: [],
  resetPasswordRequest: {
    status: '',
    email: '',
    message: '',
  },
  isMeetingView: false
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ON_LOGIN_LOADING: {
      return {
        ...state,
        loginLoading: false,
      }
    }
    case actionTypes.SESSION_LOGIN: {
      return {
        ...state,
        loggedinData: action.payload,
        isUserLoggedIn: true
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...initialState
      };
    }
    case actionTypes.ON_OPEN_NAVBAR_MOBILE: {
      return {
        ...state,
        openNavBarMobile: action.payload
      }
    }
    case actionTypes.ON_SESSION_LINK_ID: {
      return {
        ...state,
        sessionId: action.payload
      }
    }
    case actionTypes.ON_OPEN_RIGHT_MENU: {
      return {
        ...state,
        openRightMenu: action.payload
      }
    }
    case actionTypes.ON_USER_IDENTITY_RECEIVED: {
      return {
        ...state,
        userData: action.payload
      }
    }
    case actionTypes.ON_PASSWORD_CHANGED: {
      return {
        ...state,
        showResetMsg: action.payload
      }
    }
    case actionTypes.ON_UPDATE_SESSION_LINK: {
      return {
        ...state,
        sessionLink: action.payload
      }
    }
    case actionTypes.ON_SESSION_HISTORY_RECEIVED: {
      return {
        ...state,
        sessionHistoryData: action.payload
      }
    }
    case actionTypes.ON_UPCOMING_SESSION_RECEIVED: {
      return {
        ...state,
        upcomingSessionData: action.payload
      }
    }
    case actionTypes.ON_STORE_USERNAME: {
      return {
        ...state,
        currentSessionUserName: action.payload
      }
    }
    case actionTypes.ON_SET_DEVICE_INFO: {
      return {
        ...state,
        deviceInfo: action.payload
      }
    }
    case actionTypes.ON_CONTENT_SELECT: {
      return {
        ...state,
        selectedContents: action.payload
      }
    }
    case actionTypes.CONFERENCE_DATA_LOADING: {
      return {
        ...state,
        conference: { loading: true }
      }
    }
    case actionTypes.CONFERENCE_DATA_LOADED: {
      return {
        ...state,
        conference: {
          loading: false,
          url: action.payload.url,
          loginToken: action.payload.loginToken,
        }
      }
    }
    case actionTypes.CONFERENCE_DATA_ERROR: {
      return {
        ...state,
        conference: {
          loading: false,
          error: {
            code: action.error.code,
            message: action.error.message
          }
        }
      }
    }
    case actionTypes.ON_RESET_REQUEST: {
      return {
        ...state,
        resetPasswordRequest: {
          status: action.payload.status,
          email: action.payload.email,
          message: action.payload.message,
        }
      }
    }
    case actionTypes.ON_MEETING_VIEW: {
      return {
        ...state,
        isMeetingView: action.payload,
      }
    }
    case actionTypes.ON_DELETE_SESSION: {
      return {
        ...state,
        sessionHistoryData: {
          "count": state.sessionHistoryData.count - 1,
          "items": [...state.sessionHistoryData.items.filter(x => x.id !== action.payload)]
        }
      }
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
