import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from "@mui/styles";
import React from "react";
import {  useSelector } from "react-redux";
import "./content.css";
import AddIcon from '../../../assets/images/addIcon.svg';
import { useHistory } from "react-router";
import { useViewport, portraitBreakPoint, landscapeBreakPoint} from "../../contextComponent/viewportContext";



const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        color: theme.palette.text.secondary,
        margin: "5px",
        cursor: "pointer",
        flexDirection: "column",
    },
    contentImageHolder: {
        display: "flex",
        cursor: "pointer",
        flexDirection: "column",
        margin: "5px",
        position: "relative",
        [`${landscapeBreakPoint(theme)}`]: {
            minWidth: "200px",
        },
        [`${portraitBreakPoint(theme)}`]: {
            minWidth: "200px",
        }
    },
    image: {
        objectFit: "cover",
        maxHeight: "100%",
        border: `1px solid ${theme.palette.border}`,
        background: theme.palette.background.default,
        width: "100%",
        padding:1,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        borderRadius: 12,
        alignItems: 'center',
        flexDirection: 'column',
        fontWeight: 'bold',
    },
}));

export const AddNewContent = () => {
    const classes = useStyles();
    const history = useHistory();
    const { isMobile } = useViewport();
    const meetingView = useSelector((state) => state.session.isMeetingView);
    const [imageHeight, setImageHeight] = React.useState(230);



    // Sets the thumbnail image height based on if user views from meeting or main dashboard
    React.useEffect(() => {
        if (meetingView) setImageHeight(100);
        if (!meetingView) setImageHeight(230);
    }, [meetingView]);


    const onContentClick = () => {
        history.push("/uploadContent");
    };






    return (
        <Grid
            item
            id={"new-content"}
            onClick={onContentClick}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            style={{ padding: !meetingView ? isMobile && "16px 16px 0px 16px" : "2px" }}
        >
            <div className={classes.root}>
                <div className={classes.contentImageHolder}>
                    <div
                        className={classes.image}
                        style={{
                            height: imageHeight,
                            maxWidth: "calc(100vw - 38px)"
                        }}
                    >
                        <img src={AddIcon} height={30} width={30} alt="Add"/>
                        Add New Content
                    </div>
                </div>
            </div>
        </Grid>
    );
};


export default AddNewContent;
