import {
    Button, DialogActions, DialogContent, DialogTitle, Divider, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { deleteContent } from "../../../actions";
import DialogMigrate from "../../../components/DialogMigrate";
import { portraitBreakPoint } from '../../../views/contextComponent/viewportContext'


const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            border: "1px solid",
            borderColor: theme.palette.border,
            width: 'calc(100% - 20px)'
        },
        "& .MuiBackdrop-root": {
            background: "transparent"
        }
    },
    dialogRoot: {
        borderColor: theme.palette.text.light,
        opacity: 0.9,
    },
    dialogButtons: {
        borderRadius: 30,
        height: 32,
        fontFamily: "NeueHaasDisplayBold",
        padding: "0px 30px",
        "&:hover": {
            borderRadius: 30,
        },
    },
    deleteButton: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        "&:hover": {
            borderColor: theme.palette.error.main,
            backgroundColor: theme.palette.error.main,
        },
    },
    buttonContainer: {
        height: 50,
        justifyContent: 'space-evenly'
    },
    divider: {
        borderColor: theme.palette.text.light,
    },
    contentText: {
        fontWeight: '900',
        color: theme.palette.text.navy,
        [`${portraitBreakPoint(theme)}`]: {
            fontSize: '12px',
            lineHeight: '18px',
        },
    },
    titleText: {
        fontFamily: 'NeueHaasDisplayBold',
        color: theme.palette.text.navy,
        [`${portraitBreakPoint(theme)}`]: {
            paddingTop: '12px',
            paddingBottom: '8px'
        },
    }
}));

const ContentDeleteDialog = ({ content, showDeleteDialogBox, closePopUp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onDelete = async () => {
        await dispatch(deleteContent(content.uuid));
        closePopUp();
    }



    return (
        <DialogMigrate open={showDeleteDialogBox} onClose={closePopUp} fullWidth className={classes.dialogContainer} classes={{
            paper: classes.dialogRoot,
        }}>
            <div>
                <DialogTitle variant={"h4"} color="textSecondary" align="center" className={classes.titleText} id="delete-content-title-dialog">
                    Delete Item
                </DialogTitle>
                <Divider className={classes.divider} />
                <DialogContent>
                    <Typography variant={"h4"} color="textSecondary" align="center" className={classes.contentText}>
                        This content is included in one or more other collections. <br />
                        Deleting it will remove it from those collections as well. <br />
                        Do you want to continue?
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button variant="outlined" onClick={closePopUp} className={classes.dialogButtons}>
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            className={`${classes.dialogButtons} ${classes.deleteButton}`}
                            onClick={onDelete}
                        >
                            Delete
                        </Button>
                    </div>
                </DialogActions>
            </div>
        </DialogMigrate>
    )

}

export default ContentDeleteDialog;
