import React from 'react';
import ImgIcons from "../../../components/ImgIcons";
import { TYPEID } from "../../../constant";

export default function ContentTypeIcon ({ contentType }) {

  return (
    <>
      {contentType === TYPEID.video360 && <ImgIcons iconName="PanoVideo" fontSize="large" />}
      {contentType === TYPEID.videoStandard && <ImgIcons iconName="StandardVideo" fontSize="large" />}
      {contentType === TYPEID.pdf && <ImgIcons iconName="PDF" fontSize="large" />}
      {contentType === TYPEID.imageGallery && <ImgIcons iconName="Gallery" fontSize="large" />}
      {contentType === TYPEID.pointCloud && <ImgIcons iconName="PointCloud" fontSize="large" />}
      {contentType === TYPEID.virtualSpace && <ImgIcons iconName="VirtualSpace" fontSize="large" />}
    </>

  )
}
