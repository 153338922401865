import * as actionTypes from '../actions';

export const initialState = {
	orgMembers: {
		count: 0,
		items: []
	},
	orgOwner: {
		first_name: null,
		last_name: null,
		email: null
	}
}

export default function orgReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.FETCH_ORG_MEMBERRS: {
			return {
				...state,
				orgMembers: action.payload
			}
		}
		case actionTypes.FETCH_ORG_OWNER: {
			return {
				...state,
				orgOwner: action.payload
			}
		}
		default: {
			return state
		}
	}
}
