export const LOGGED_IN_DATA  = 'LOGGED_IN_DATA'
export const AUTH_DATA  = 'AUTH_DATA'
export const FILTER_TYPE_META_DATA = 'FilterType'
export const STATE_TYPE_META_DATA = 'State'
export const CITY_TYPE_META_DATA = 'City'
export const ITEM = 'Item'
export const DEALER = 'BusinessEntity'
export const Lead = "Lead"
export const B2CSaleOrder = 'B2CSaleOrder'
export const CCAvenuePaymentAgent = "CCAvenuePaymentAgent"
export const CancellationReason = 'CancellationReason'
export const TestRideCancellationReason = "TestRideCancellationReason"
export const Bookings = "Bookings"
export const GroupEventAttachments = "GroupEventAttachments"
export const ContactSchema = "Contact"
export const BookingFeedback = "BookingFeedback"
export const TestRideFeedback = "Feedback"
export const Complaint = "Complaint"
export const POPUP_DURATION = 5000  //Snackbar popup duration
export const RENAME_CONTENT_MAX_CHARS = 64;
export const SESSION_TITLE_MAX_CHARS = 64;
export const ORG_NAME_MAX_CHARS = 64;
export const USER_NAME_MAX_CHARS = 64;
export const PASSWORD_MAX_CHARS = 64;
export const REDIRECT_WHITELIST = [/^\/.*/];
export const TYPEID={
    video360: 1,
    videoStandard: 2,
    pdf: 3,
    imageGallery: 4,
    virtualSpace: 5,
    pointCloud: 6
}
export const COLLECTIONS_MENU_BLACKLIST={
    org: [233,530],
    com: [216,360]
}
