import * as actionTypes from '../actions';

const initialState = {
    collections: {
        items: [],
        count: 0,
        sort: 'a',
        page: { size: 16, number: 0 },
        search: '',
        refreshToggle: false,
    },
    contents: {
        items: [],
        count: 0,
        sort: 'a',
        page: { size: 16, number: 0 },
        search: '',
        refreshToggle: false,
    },
    selectionCollectionName: '',
    hiddenMenuIcons: false,
    selectedCollection: null,
    refetchCounter: 0,
    organisation: {
        items: [],
        count: 0
    },
    selectedOrganisation: null,
    currentOrganisation: {},
};

const collectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ON_UPDATE_COLLECTIONS: {
            return {
                ...state,
                collections: {
                    ...state.collections,
                    ...action.payload
                },
            };
        }
        case actionTypes.ON_UPDATE_CONTENTS: {
            return {
                ...state,
                contents: {
                    ...state.contents,
                    ...action.payload
                },
            }
        }
        case actionTypes.ON_COLLECTION_NAME_SET: {
            return {
                ...state,
                selectionCollectionName: action.payload
            }
        }
        case actionTypes.ON_HIDE_MENU_ICONS_ON_VIDEO_SELECT: {
            return {
                ...state,
                hiddenMenuIcons: action.payload
            }
        }
        case actionTypes.SELECTED_COLLECTION: {
            return {
                ...state,
                selectedCollection: action.payload
            }
        }
        case actionTypes.DISLIKE_COLLECTION: {
            return {
                ...state,
                collections: {
                    ...state.collections,
                    items: [...state.collections.items.filter(collection => collection.id !== action.payload)],
                    count: state.collections.count - 1
                },
            }
        }
        case actionTypes.REMOVE_CONTENT: {
            return {
                ...state,
                contents: {
                    ...state.contents,
                    items: [...state.contents.items.filter(content => content.uuid !== action.payload).filter(content => content.id !== action.payload)],
                    count: state.contents.count - 1
                },
            }
        }
        case actionTypes.RENAME_CONTENT: {
            const content = state.contents.items.find(content => content.id === action.id);
            if (content) {
                const renamedContent = {
                    ...content,
                    title: action.newTitle,
                };

                const newItems = state.contents.items.map((item) => {
                    if (item.id === action.id) {
                        return renamedContent;
                    }

                    return item;
                })
                return {
                    ...state,
                    contents: {
                        ...state.contents,
                        items: [...newItems]
                    },
                }
            }
        }
        break;
        case actionTypes.RENAME_COLLECTION: {
            const collection = state.collections.items.find(collection => collection.id === action.id);
            if (collection) {
                const renamedCollection = {
                    ...collection,
                    title: action.newTitle,
                };

                const newItems = state.collections.items.map((item) => {
                    if (item.id === action.id) {
                        return renamedCollection;
                    }

                    return item;
                })
                return {
                    ...state,
                    collections: {
                        ...state.collections,
                        items: [...newItems]
                    },
                }
            }
        }
        break;
        case actionTypes.RE_FETCH_COLLECTIONS: {
            return {
                ...state,
                refetchCounter: state.refetchCounter + 1,
            };
        }
        case actionTypes.FETCH_ORGANISATIONS: {
            return {
                ...state,
                organisation: action.payload,
            };
        }
        case actionTypes.SELECTED_ORG: {
            return {
                ...state,
                selectedOrganisation: action.payload
            }
        }
        case actionTypes.CURRENT_ORG: {
            return {
                ...state,
                currentOrganisation: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default collectionReducer;
