import { Box, Button, Typography, IconButton } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useHistory } from "react-router";
import ContentHeader from "../Dashboard/ContentHeader";
import { v4 as uuidv4 } from 'uuid';
import ImgIcons from "../../components/ImgIcons";
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        padding: "0px",
    },
    iconButton: {
        backgroundColor: theme.palette.primary.main,
        padding: "1px",
        color: theme.palette.primary.contrastText,
        margin: "24px 5px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    backButtonText: {
        display: "flex",
        alignItems: "center",
        marginLeft: 5,
        fontSize: 22,
        cursor: "pointer",
    },
    infoSection: {
        overflow: "hidden auto",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        width: "calc(100% - 60px);",
        height: "calc(100% - 72px);",
        padding: "15px 30px",
        "& .MuiAccordion-root.Mui-expanded": {
            margin: "0",
        },
        "&.MuiAccordion-root.Mui-expanded:last-child": {
            marginBottom: "10px",
        },
        [`${landscapeBreakPoint(theme)}`]: {
            padding: "15px 30px",
        },
        [`${portraitBreakPoint(theme)}`]: {
            width: "100%",
            padding: "0px",
        }
    },
    headingFAQ: {
        "& .Mui-expanded": {
            "& h1": {
                color: theme.palette.primary.main,
            },
        },
        boxShadow: "none",
        borderBottom: `1px solid ${theme.palette.divider}`,
        maxWidth: '560px',
        [`${landscapeBreakPoint(theme)}`]: {
            maxWidth: 'none'
        },
        [`${portraitBreakPoint(theme)}`]: {
            maxWidth: 'none'
        }
    },
    heading: {
        fontFamily: 'NeueHaasDisplayMedium',
        [`${landscapeBreakPoint(theme)}`]: {
            margin: "3px 0px",
        },
        margin: "0",
        "& .MuiTypography-root": {
            color: theme.palette.text.navy,
        },
        "& .Mui-expanded .MuiTypography-root": {
            color: theme.palette.text.primary,
        },
        "& .MuiAccordionSummary-expandIconWrapper": {
            padding: "12px 0",
            margin: "5px 0",
        }
    },
    listRoot: {
        flexDirection: "column",
        paddingTop: "0px",
        paddingBottom: "0px",
        "& .MuiListItem-gutters":{
            padding: "0 16px"
        }
    },
    bulletIcon: {
        height: 10,
        color: theme.palette.text.primary,
        width: 10,
    },
    bulletText: {
        color: theme.palette.text.primary,
        fontFamily: 'NeueHaasDisplayRoman',
        lineHeight: '1.5em'
    },
    detail: {
        padding: "0 0 30px 0",
        display: "flex",
        flexDirection: "column",
    },
    listView: {
        fontSize: 16,
        color: theme.palette.text.secondary,
        fontFamily: "NeueHaasDisplayLight",
    },
    dotDiv: {
        height: "12px",
        width: "16px",
        borderRadius: "50%",
        margin: "7px 10px",
        display: "inline-block",
    },
    dotDiv1: {
        height: "12px",
        width: "19px",
        borderRadius: "50%",
        margin: "7px 10px",
        display: "inline-block",
    },
    skyscraperImageContainer: {
        maxWidth: "300px",
    },
    tabs: {
        "& .MuiTabs-indicator": {
            height: "0px",
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: 'space-around',
        },
        "& .MuiTab-root": {
            minWidth: "220px",
            [`${landscapeBreakPoint(theme)}`]: {
                minWidth: "180px",
            },
            [`${portraitBreakPoint(theme)}`]: {
                minWidth: "120px",
            }
        },
    },
    tabsLableActive: {
        textTransform: "none",
        padding:"0",
        "& .MuiTypography-root": {
            padding: "10px 0",
            borderBottom: `4px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
        }
    },
    tabsLableInActive: {
        textTransform: "none",
        padding:"0",
        "& .MuiTypography-root": {
            color: theme.palette.primary.dark,
        },
    },
    divider: theme.typography.divider,
    container: {
        display:'flex',
        flexDirection:'column',
        width: "100%",
        height:"100%",
        padding: "0px",
        [`${landscapeBreakPoint(theme)}`]:{
            paddingBottom: '30%',
            paddingTop: '30%'
        },
    },
    feedbackButton: {
        whiteSpace: "nowrap",
        borderRadius: "33px",
        margin:"30px 50px 90px",
        padding: '0 24px',
        height: "40px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontFamily: "NeueHaasDisplayBold",
        [`${portraitBreakPoint(theme)}`]: {
            fontSize: "14px",
            alignItems: "center",
        },
        border: "1px solid",
        borderColor: theme.palette.border,
        color: theme.palette.text.navy,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.contrastText,
            "& path": {
                fill: `${theme.palette.text.contrastText} !important`,
                stroke: `${theme.palette.text.contrastText} !important`,
            }
        },
    },
    icons: {
        marginRight: "10px",
        fontSize: "1.2rem",
        "& path": {
            strokeWidth: "2 !important"
        }
    }
}));

let config = [
    {
        panelTitle: "What's in the Content Hub tab?",
        panelSummary: {
            1: "Your Content Hub is a centralized location for access to all the content produced for you by View Labs or that you've uploaded to the View Labs platform yourself. Within the Content Hub you may browse through your content, copy standalone links to experiences produced by View Labs to share on social media or via email, and more.",
        },
    },
    {
        panelTitle: "What's in the 360 Meet tab?",
        panelSummary: {
            1: "Within the 360 Meet tab in The Lab you may create new meeting URLs, invite participants, copy meeting URLs to your clipboard to share with participants, and access links to sessions you’ve created in the past.",
        },
    },
    {
        panelTitle: "What are my Collections?",
        panelSummary: {
            1: "Your Collections are catalogues of all your content assets grouped together by project.",
        },
    },
    {
        panelTitle: "What are the contents in my Collections?",
        panelSummary: {
            1: "Contents in a collection can be a 360 degree experience, a standard video, a PDF, or an image gallery.",
        },
    },
    {
        panelTitle: "How do I preview my content?",
        panelSummary: {
            1: "You can preview your content by opening a collection and clicking the center of the content image you wish to preview. Once selected, a window will appear and the content will be displayed.",
        },
    },
    {
        panelTitle: "What's shown in the Favorites section?",
        panelSummary: {
            1: "Any Collection or Content that you favorite will be made available in the Favorites section for you to easily access.",
        },
    },
    {
        panelTitle: "What's shown in the Recent section?",
        panelSummary: {
            1: "The Collections that you most recently viewed will be available for you in the Recent section.",
        },
    },
    {
        panelTitle: "Do sessions expire after creation?",
        panelSummary: {
            1: "Once created, sessions do not expire and you can use them at any time to organize your meetings.",
            2: "You can access past session information in the Session History section of the 360 Meet tab located in The Lab.",
        },
    },
];

const meetQuestions = [
    {
        panelTitle: "Your Account",
        panelSummary: {
            1: "Never share your login credentials and account with anyone. You risk losing access to your account if they change your password.",
            2: "Only access a session you intend on hosting with one device that's logged into your account. Accessing the same session from multiple devices logged into your account will have unwanted consequences.",
        },
    },
    {
        panelTitle: "Generating and Sharing URLs",
        panelSummary: {
            1: 'Tap the "Generate" button from your 360 Meet dashboard to create a new 360 Meet session URL.',
            2: "Each session is unique and may only be joined by guests with which you've shared the link.",
        },
    },
    {
        panelTitle: "Ready to Host",
        panelSummary: {
            1: "You must be logged into your 360 Meet to access your content menu from within a your own sessions. If you navigate  to your session without being logged in, you may log in from within the session using the provided prompts. Additionally, you may also navigate back to https://meet.viewlabs.com, login, and then refresh your session window.",
            2: "Ask your guests to join by opening the session URL you sent them on their web browser.",
        },
    },
    {
        panelTitle: "Session Guide",
        panelSummary: {
            1: "You must be logged into your 360 Meet to access your content menu from within a your own sessions. If you navigate  to your session without being logged in, you may loOnce the session web page loads, select 'Join Meeting' from the Chat control panel and enable microphone and video access to start the web conferencing component of 360 Meet.",
            2: "Viewers will not see your content until you've selected a collection's items from your content menu. Only you can see your collections and content menu.",
            3: "Once you've selected your desired content, your viewers' devices will load that same content. For any video content, change the playback time using the time bar. For 360 video, click and drag the image for everyone in your session to see what you see.",
        },
    },
];

// const analyticsQuestions = [
//     {
//         panelTitle: 'How do I view video/s analytics?',
//         panelSummary: {
//             1: 'answer is ..',
//         },
//     },
// ];

const FAQsComponent = ({ width, loginUser }) => {
    const classes = useStyles();
    const history = useHistory();
    const [value, setValue] = React.useState(0);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [helpQuestion, setHelpQuestions] = useState(config);
    const { isMobile, isMobileLandscape } = useViewport();

    const onBackIconClick = () => {
        history.goBack();
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(tabIndex) {
        return {
            id: `simple-tab-${tabIndex}`,
            "aria-controls": `simple-tabpanel-${tabIndex}`,
        };
    }

    const onClickTab = (index) => {
        setSelectedTab(index);
        setHelpQuestions(index === 1 ? meetQuestions : config);
    };

    const onFeedback = () => {
        window.open('/dashboard/web/feedback');
    }

    return (
        <Box height="100%" id="settings" display={"flex"} flexDirection={"column"}>
            <Box
                className={classes.divider}
                display="flex"
                justifyContent="space-between"
                flexWrap={isMobile || isMobileLandscape ? "wrap" : null}
                flexDirection={isMobile || isMobileLandscape ? "column-reverse" : "row"}
            >

                <Box
                display="flex"
                alignItems="flex-end"
                flexWrap=""
                flexDirection="row"
                >
                    <Tabs
                        className={classes.tabs}
                        style={{ width: isMobile ? "100%" : "" }}
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs"
                    >
                        <Tab
                            className={
                                selectedTab === 0 ? classes.tabsLableActive : classes.tabsLableInActive
                            }
                            label={<Typography variant="h4">Content Hub</Typography>}
                            {...a11yProps(0)}
                            onClick={() => onClickTab(0)}
                        />
                        <Tab
                            className={
                                selectedTab === 1 ? classes.tabsLableActive : classes.tabsLableInActive
                            }
                            label={<Typography variant="h4">360 Meet</Typography>}
                            {...a11yProps(1)}
                            onClick={() => onClickTab(1)}
                        />
                        {/* <Tab
                            className={selectedTab === 2 ? classes.tabsLableActive : classes.tabsLableInActive}
                            label={<Typography variant="h4">View Analytics</Typography>}
                            {...a11yProps(2)}
                            onClick={() => onClickTab(2)}
                        /> */}
                    </Tabs>
                </Box>   

                <ContentHeader>
                    {(isMobile || isMobileLandscape) && 
                        <>
                            <IconButton onClick={onBackIconClick} size="large">
                                <ChevronLeftIcon />
                            </IconButton>
                            <Typography>
                                Help
                            </Typography>
                        </>
                    }
                </ContentHeader>
            </Box>

            <Box className={classes.infoSection}>
                {helpQuestion.map((item, idx) => (
                    <Accordion className={classes.headingFAQ} key={`'${selectedTab}'` + idx}>
                        <AccordionSummary className={classes.heading} expandIcon={<ImgIcons iconName="DropDownArrow" />}>
                            <Typography variant={"h4"}>
                                {item.panelTitle}
                            </Typography>
                        </AccordionSummary>
                        {/* START BULLET POINTS */}
                        {Object.values(item.panelSummary).map((bulletPoint) => (
                            <AccordionDetails className={classes.detail} key={uuidv4()}>
                                <List className={classes.listRoot}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemText>
                                            <Typography
                                                className={classes.bulletText}
                                                variant={"subtitle2"}
                                            >
                                                {bulletPoint}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        ))}
                    </Accordion>
                ))}
            </Box>
            { !isMobile && !isMobileLandscape &&
            <Box>
                <Button
                    variant="text"
                    className={classes.feedbackButton}
                    onClick={onFeedback}
                >
                    <ImgIcons iconName="Feedback" className={classes.icons}/>
                    Give Feedback
                </Button>
            </Box>
            }
        </Box>
    );
};

export default FAQsComponent;
