import axios from "../../utils/axios";

export const generateSession = (session_name, start_datetime, duration_in_seconds, disable_participants_menu) => {
    return axios.post("/api/user/session", {
        session_name,
        start_datetime,
        duration_in_seconds,
        disable_participants_menu
    });
};

export const inviteUsers = (id, invitees) => {
    return axios.post("/api/v2/user/session/invitation", null, {
        params: {
            id,
            invitees,
        },
    });
};

export const sessionHistoryAPI = (pageSize, pageNumber, search, sortBy, sortType) => {
    return axios.get("/api/user/sessions", {
        params: {
            pageSize,
            pageNumber,
            upcoming: 0,
            search,
            sortBy,
            sortType,
        },
    });
};

export const upcomingSessionAPI = (pageSize, pageNumber, search, sortBy, sortType) => {
    return axios.get("/api/user/sessions", {
        params: {
            pageSize,
            pageNumber,
            upcoming: 1,
            search,
            sortBy,
            sortType,
        },
    });
};

export const sessionNoteAPI = (sessionID, note) => {
    return axios.post(`/api/user/session/${sessionID}/note`, note);
};

export const sessionDeleteAPI = (sessionID) => {
    return axios.delete(`/api/user/session/${sessionID}`);
};

export const sessionUpdateAPI = (sessionID, session_name, start_datetime, duration_in_seconds, disable_participants_menu) => {
    return axios.put(`/api/user/session/${sessionID}`, {
        session_name,
        start_datetime,
        duration_in_seconds,
        disable_participants_menu
    });
}
