import { Box, Button, Divider, Typography, Autocomplete, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles, styled } from "@mui/styles";
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrgOwner, setOrgOwner } from '../../../actions/OganizationActions';
import { portraitBreakPoint, landscapeBreakPoint} from "../../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: `${theme.spacing(1)} 0px`,
        marginBottom: 0,
        maxWidth: "450px",
        width: '95%',
    },
    form: {
        display: 'flex',
        alignItems: 'flex-end',
        ".MuiInputLabel-formControl": {
            transition: 'none'
        },
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        [`${landscapeBreakPoint(theme)}`]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
    },
    formLabel: {
        transform: 'none',
        position: 'relative',
        padding: "15px 0",
        fontSize: "18px",
        "& .MuiTypography-h4":{
            [`${portraitBreakPoint(theme)}`]: {
                fontSize: "16px",
            },
            [`${landscapeBreakPoint(theme)}`]: {
                fontSize: "16px",
            }
        }
    },
    divider: {
        display: 'flex',
        alignSelf: "center",
        borderColor: "black",
        height: '15px',
        marginRight: "5px",
        marginLeft: "5px",

    },
    editButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        }
    },
    upcomingTitle: {
        color: theme.palette.text.blue,
        '&:hover': {
            cursor: 'pointer',
        }
    },
    ownerHolder: {
        display: 'flex',
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "15px",
    },
    ownerId: {
        display: 'flex',
        gap: "15px",
        width: "100%",
        padding: "2px 15px 2px 0px",
    },
    editOwner: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        gap: "10px",
        "& .MuiTypography-body1":{
            fontFamily: "NeueHaasDisplayRoman",
            fontSize: "16px"
        },
        [`${portraitBreakPoint(theme)}`]: {
            "& .MuiTypography-body1":{
                fontFamily: "NeueHaasDisplayRoman",
                fontSize: "14px"
            }
        },
        [`${landscapeBreakPoint(theme)}`]: {
            "& .MuiTypography-body1":{
                fontFamily: "NeueHaasDisplayRoman",
                fontSize: "14px"
            }
        }
    },
    ownerLabel: {
        display: 'flex',
        justifyContent: "space-between",
    },
    ownerSelector: {
        border: '1px solid',
        borderRadius: 25,
        width: "100%",
        "& .MuiAutocomplete-inputRoot":{
            height: "36px",
            padding: "0 5px"
        },
        '& .MuiAutocomplete-input': {
            padding: "0px 15px !important",
            fontSize: "16px",
            fontFamily: "NeueHaasDisplayRoman",
            color: "black",
        },
        "& .MuiAutocomplete-endAdornment": {
            top: 0
        },
        "& fieldset": {
            border: "0"
        }
    }
}))

const SaveButton = styled(Button)(({ theme }) => ({
    background: theme.palette.text.contrastText,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    padding: '3px 35px',
    lineHeight: "1.4",
    "&:hover": {
        background: theme.palette.primary.main,
        color: theme.palette.text.contrastText
    }
}));


const OwnerForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const members = useSelector(state => state.organization.orgMembers);
    const orgOwner = useSelector(state => state.organization.orgOwner);
    const isViewlabsUser = useSelector(state => state.application.userDetails?.is_viewlabs_user);
    const [owner, setOwner] = useState({});   
    const [options, setOptions] = useState([]);

    const handleOnClickEdit = () => {
        setEdit(true);
    }

    const onOwnerChange = (event,value) => {
        setOwner({...value});
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (owner.email) {
            const currentOwner=options.find(option => option.email === owner.email );
            dispatch(updateOrgOwner({ new_owner_user_id: currentOwner.id }))
            .then( res => {
                setEdit(false);
                dispatch(setOrgOwner({...currentOwner}));
                enqueueSnackbar({ message: 'You will receive a confirmation email shortly!', variant: 'success' });
            })
            .catch(err=>{
                enqueueSnackbar({ message: err.response.data.message, variant: 'error' });
            })
        }else{
            enqueueSnackbar({ message: 'The owner cannot be empty.', variant: 'error' });
        }
    }
    
    const getLabel=(user) => user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : (user.email || '');

    useEffect(() => {
        if(members.items){
            const membersList=members.items.map((item)=>({
                label: getLabel(item),
                first_name: item.first_name,
                last_name: item.last_name,
                email: item.email,
                id: item.id
            }));
            setOptions(membersList);
        }else{
            setOptions([]);
        }     
    },[members.items])

    useEffect(() => {
        setOwner({...orgOwner});
    },[orgOwner])

    return (
        <Box id="owner-change" paddingBottom={1}>
            <form onSubmit={onSubmit} className={classes.form}>
                <FormControl variant="standard" className={classes.margin}>
                    <InputLabel htmlFor="owner-id" className={classes.formLabel}>
                        <Typography variant='h4' color='textSecondary'>Current Owner</Typography>
                    </InputLabel>
                    {edit ?
                        <div className={classes.ownerHolder}>
                            <Autocomplete
                                disablePortal
                                id="owner-name"
                                className={classes.ownerSelector}
                                options={options}
                                value={{
                                    ...owner,
                                    label: getLabel(owner)
                                }}
                                onChange={onOwnerChange}
                                isOptionEqualToValue={ (option,value) => option.email === value.email }
                                renderInput={(params) => <TextField {...params}/>}
                                />

                                <SaveButton type='submit' variant="outlined" >Confirm</SaveButton>
                        </div>
                        :
                        <div className={classes.editOwner}>
                            <Typography color='textSecondary'>{getLabel(orgOwner)}</Typography>
                            {!isViewlabsUser &&
                            <>
                            <Divider orientation="vertical" flexItem className={classes.divider} />
                            <Typography className={classes.upcomingTitle} fontWeight={'bold'} onClick={handleOnClickEdit} >Edit</Typography>
                            </>
                            }
                        </div>
                    }
                </FormControl>
            </form>
        </Box>
    );
}

export default OwnerForm;
