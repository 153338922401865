import palette from '../palette';
const MuiButton={
  root: {
    color: palette.text.main,
    fontFamily: "NeueHaasDisplayMedium",
    "&.Mui-disabled": {
      backgroundColor: palette.secondary.light,
      color: palette.text.main,
    },
    borderRadius: 25,
    fontSize: 16,
  },
  outlined: {
    color: palette.text.primary,
    boxShadow: 'none',
    "@media (hover: hover)":{
      '&:hover': {
        border: `2px solid ${palette.primary.main}`,
        boxShadow: 'none',
        backgroundColor: palette.primary.main,
        color: palette.text.contrastText
      }
    },
    opacity: 1,
    textTransform: "none",
    backgroundColor: palette.primary.contrastText,
    border: `2px solid ${palette.primary.main}`,
  },
  outlinedSecondary: {
    border: '1px solid rgba(0, 93, 171, 0.5)'
  },
  contained: {
    boxShadow: 'none',
    "@media (hover: hover)":{
      '&:hover': {
        color: palette.text.contrastText,
        backgroundColor: palette.primary.main,
        boxShadow: 'none',
      }
    },
    color: palette.text.main,
    fontSize: 18,
    fontFamily: "NeueHaasDisplayLight",
    opacity: 1,
    textTransform: "none",
    backgroundColor: palette.primary.main,
  },
  containedSecondary: {
    boxShadow: 'none',
    "@media (hover: hover)":{
      '&:hover': {
        backgroundColor: palette.primary.light,
        boxShadow: 'none',
      }
    },
    color: palette.text.main,
    fontSize: 18,
    fontWeight: 900,
    fontFamily: "NeueHaasDisplayLight",
    opacity: 1,
    textTransform: "none",
    backgroundColor: palette.primary.light,
  },
  text: {
    color: palette.text.medium,
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: "none",
  }
}
export default MuiButton;
