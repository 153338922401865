import React, { useEffect, useState } from "react";
import {
    Button,
    DialogTitle,
} from "@mui/material";
import { SESSION_TITLE_MAX_CHARS } from "../../../constant";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { createSession, updateSession } from "../../../actions";
import MeetingDetailsModal from "./MeetingDetailsModal";
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import DialogMigrate from "../../../components/DialogMigrate";
import MeetingForm from "./MeetingForm"
import { portraitBreakPoint, landscapeBreakPoint } from "../../contextComponent/viewportContext";
import Bolt from '@mui/icons-material/Bolt';
import CustomTooltip from "../../../components/CustomTooltip";
import LoadingSpinner from "../../../components/LoadingSpinner";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        overflow:'hidden',
        "& .MuiDialog-paper": {
            border: "1px solid",
            borderColor: theme.palette.border,
        },
        "& .MuiBackdrop-root": {
            background: "transparent"
        }
    },
    root: {
        color: theme.palette.primary.main,
        borderRadius: "12px",
        padding: "12px 36px",
        "& h4": {
            color: theme.palette.text.main,
            paddingBottom: "6px",
        },
        ".MuiDialogTitle-root": {
            paddingLeft: 0,
        },
    },
    title: {
        padding: "12px 0",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.primary.main,
        fontSize: "24px"
    },
    divider: {
        marginTop: 0,
        marginBottom: "20px",
    },
    button: {
        height: "30px",
        color: theme.palette.text.contrastText,
        borderRadius: 30,
        margin: "6px 0px",
        padding: "10px 30px",
        fontWeight: "bold",
        [`${portraitBreakPoint(theme)}`]: {
            padding: "0px",
        },
        [`${landscapeBreakPoint(theme)}`]: {
            padding: "0px",
        },
    },
    generateButton: {
        whiteSpace: "nowrap",
        borderRadius: "33px",
        margin:"0 10px",
        padding: '0 30px',
        height: "30px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        // width: "200px",
        fontFamily: "NeueHaasDisplayBold",
        [`${portraitBreakPoint(theme)}`]: {
            fontSize: "14px",
            alignItems: "center",
        },
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.contrastText,
        },
    },
    instantGenerateButton: {
        whiteSpace: "nowrap",
        borderRadius: "30px",
        marginLeft:"10px",
        padding: "0",
        minWidth: "30px",
        height: "30px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.contrastText,
        },
    },
    input: {
        boxSizing: "border-box",
        padding: "2px 10px",
        marginBottom: "20px",
        border: "2px solid #DEDEDE",
        borderRadius: "10px",
        "& .MuiInputBase-input": {
            color: theme.palette.text.main,
        },
        "&::placeholder": {
            color: theme.palette.text.main,
        },
        "& .MuiInput-formControl": {
            marginTop: 0,
        },
        "& label": {
            paddingLeft: "10px",
        },
        "& .MuiInputLabel-formControl": {
            transform: "translate(0, 13px) scale(1)",
        },
        "& .MuiInputLabel-shrink": {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.main,
            transform: "translate(0, -9px) scale(.9)",
            padding: "0 4px",
            marginLeft: "6px",
        },
    },
    inputSpacing: {
        marginRight: "16px",
        [`${portraitBreakPoint(theme)}`]: {
            marginRight: 0,
        },
    },
    dateHolder: {
        display: "flex",
        [`${portraitBreakPoint(theme)}`]: {
            flexWrap: "wrap",
        },
    },
    durationHolder: {
        display: "flex",
        alignItems: "center",
        [`${portraitBreakPoint(theme)}`]: {
            flexWrap: "wrap",
        },
    },
    buttonContainer: {
        padding: "0 0 12px",
    },
    actionButtons: {
        borderRadius: 30,
        height: 32,
        fontFamily: "NeueHaasDisplayBold",
        padding: "0px 10px",
        "&:hover": {
            borderRadius: 30,
        },      
    },
    menu: {
        "& .MuiPaper-root": {
            maxHeight: "150px",
            backgroundColor: "white",
        },
    },
    helpiconDiv: {
        display: "flex",
        alignItems: "center",
        paddingBottom: 7,
        marginLeft: 3,
    },
    durationDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    subtitle: {
        paddingBottom: "6px",
        marginLeft: "5px",
    },
    thirdPartyContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
    },
    thirdPartySwitch: {
        marginLeft: 0,
    },
    thirdPartyLabel: {
        fontSize: "14px",
        marginBottom: "20px",
    }
}));

const CreateMeetingModal = ({ requestRefetchData, setOpenMessage, setAlertText, setSeverity, editable, setEnableEditMenuPopup, selectedItem, refreshCurrentData, setSelectedTab}) => {

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(editable ? true : false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [sessionName, setSessionName] = useState("");
    const [openTime, setOpenTime] = useState(null);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [startDateTime, setStartDateTime] = useState(null);
    const [hours, setHours] = useState(1);
    const [minutes, setMinutes] = useState(0);
    const [durationInSeconds, setDurationInSeconds] = useState(null);
    const [thirdPartyCheck, setThirdPartyCheck] = useState(false);
    const [createDisable, setCreateDisable] = useState(false);
    const [loadingCreateMeeting, setLoadingCreateMeeting] = useState(false);

    useEffect(() => {
        if(!open || openSuccess) {
            return;
        }

        if (editable && selectedItem) {
            handleDeleteState();
            setSessionName(selectedItem.name)
            setDate(moment.unix(selectedItem.start_datetime).format('YYYY-MM-DD'))
            setTime(moment.unix(selectedItem.start_datetime).format('HH:mm'))
            setOpenTime(moment().unix() - 60);
            setCreateDisable(false);
            let secToTime = convertToTime(selectedItem.duration_in_sec)
            setHours(secToTime.hours)
            setMinutes(secToTime.min)
            setThirdPartyCheck(selectedItem.disable_participants_menu)
        } else {
            handleDeleteState();
            setDate(moment().format("YYYY-MM-DD"));
            setTime(defaultTime(moment().minute(), moment().hour()));
            setOpenTime(moment().unix() - 60);
            setCreateDisable(false);
        }
    }, [open, openSuccess, selectedItem, editable]);

    useEffect(() => {
        if (date && time) {
            const newDate = moment(date + " " + time).unix();
            setStartDateTime(newDate);
        }
    }, [date, time]);

    const convertToTime = (sec) => {
        let hours = Math.floor(sec / 3600)
        let remaining = sec - (hours * 3600)
        let min = Math.floor(remaining / 60)
        return { hours: hours, min: min }
    }

    const defaultTime = (min, hour) => {
        let strMin = "";
        if (min < 15) {
            strMin = "00";
            hour++;
        } else if (min < 45) {
            strMin = "30";
            hour++;
        } else {
            strMin = "00";
            hour += 2;
        }
        
        if(hour >= 24) {
            setDate(moment().add(1, 'days').format("YYYY-MM-DD"));
        }
        hour %= 24;

        let strHour = hour.toString();
        if (hour < 10) {
            strHour = '0' + strHour;
        }
        return strHour + ":" + strMin;
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (reason) => {
        setOpen(false);
        setLoadingCreateMeeting(false);
        if(editable && reason !== "update") {
            setEnableEditMenuPopup(false);
        }
    };

    const handleOpenSuccess = () => {
        setOpenSuccess(true);
    };

    const handleDeleteState = () => {
        setSessionName("");
        setHours(1);
        setMinutes(0);
        setThirdPartyCheck(false);
    };

    const handleChange = (event) => {
        setAlertText("");
        switch (event.target.name) {
            case "sessionName":
                if (event.target.value.length > SESSION_TITLE_MAX_CHARS) {
                    enqueueSnackbar({message:`Session name cannot be more than ${SESSION_TITLE_MAX_CHARS} characters.`, variant:'error'});
                } else {
                    setSessionName(event.target.value);
                }
                break;
            case "date":
                setDate(event.target.value);
                break;
            case "time":
                setTime(event.target.value);
                break;
            case "hours":
                setHours(event.target.value);
                break;
            case "minutes":
                setMinutes(event.target.value);
                break;
            case "thirdPartySwitch":
                setThirdPartyCheck(event.target.checked);
                break;
            default:
                break;
        }
    };

    const handleTimeChange = (event, value) => {
        if(value) {
            const newTime = moment(value, ["hh:mm a"]).format("HH:mm");
            setTime(newTime);
        }
    }

    const handleCreateSession = () => {
        if (!sessionName || !date || !time || hours === null || minutes === null) {
            enqueueSnackbar({message:"Please input all fields.", variant:'error'});
        } else if (startDateTime < openTime) {
            enqueueSnackbar({message:"Meeting time cannot be earlier than current time.", variant:'error'});
        } else if (hours === 0 && minutes === 0) {
            enqueueSnackbar({message:"Please input a valid duration.", variant:'error'});
        }else if (editable) {
            setLoadingCreateMeeting(true);
            //PATCH request to api
            let duration_in_seconds = hours * 3600 + minutes * 60;
            setDurationInSeconds(duration_in_seconds);
            setCreateDisable(true);
            dispatch(updateSession(selectedItem.id, sessionName, startDateTime, duration_in_seconds, thirdPartyCheck))
                .then(() => {
                    handleClose("update");
                    refreshCurrentData();
                    requestRefetchData();
                    handleOpenSuccess();
                    setLoadingCreateMeeting(false);
                })
                .catch((error) => {
                    console.log(error, "HERE");
                    setCreateDisable(false);
                });
        }else {
            setLoadingCreateMeeting(true);
            let duration_in_seconds = hours * 3600 + minutes * 60;
            setDurationInSeconds(duration_in_seconds);
            setCreateDisable(true);
            dispatch(createSession(sessionName, startDateTime, duration_in_seconds, thirdPartyCheck))
                .then(() => {
                    handleClose();
                    requestRefetchData();
                    handleOpenSuccess();
                    setLoadingCreateMeeting(false);
                })
                .catch((error) => {
                    setCreateDisable(false);
                    enqueueSnackbar({message:`Error while creating meeting, please try again. `, variant:'error'});
                });
        }
    };

    const handleInstantGenerate= () => {
        setLoadingCreateMeeting(true);
        let duration_in_seconds = 30 * 60;
        dispatch(createSession("Instant Session", moment().unix(), duration_in_seconds, thirdPartyCheck))
        .then(() => {
            requestRefetchData();
            setLoadingCreateMeeting(false);
        })
        .catch((error) => {
            setCreateDisable(false);
            enqueueSnackbar({message:`Error while creating meeting, please try again. `, variant:'error'});
        });
    }

    return (
        <React.Fragment>
            {!editable &&
            <>
                <CustomTooltip title="Create an instant session">
                    <Button className={classes.instantGenerateButton} onClick={handleInstantGenerate}>
                        <Bolt />
                    </Button>
                </CustomTooltip>

                <Button className={classes.generateButton} onClick={handleOpen}>
                    Create Session
                </Button>
            </>
            }

            { loadingCreateMeeting && <LoadingSpinner /> }
                
            <DialogMigrate className={classes.dialogContainer} open={open} onClose={handleClose} fullWidth>
                <div className={classes.root}>
                    <DialogTitle id="simple-dialog-title" className={classes.title}>
                        {editable ? 'Update' : 'Create a'} Session
                    </DialogTitle>
                    <hr className={classes.divider} />
                    <MeetingForm editable={editable} handleCreateSession={handleCreateSession} handleChange={handleChange} handleTimeChange={handleTimeChange} sessionName={sessionName} date={date} time={time} hours={hours} minutes={minutes} thirdPartyCheck={thirdPartyCheck} handleClose={handleClose} loadingCreateMeeting={loadingCreateMeeting} createDisable={createDisable} />
                </div>
            </DialogMigrate >

            <MeetingDetailsModal
                sessionName={sessionName}
                startDateTime={startDateTime}
                duration={durationInSeconds}
                openSuccess={openSuccess}
                setOpenSuccess={setOpenSuccess}
                handleDeleteState={handleDeleteState}
                setOpenMessage={setOpenMessage}
                setAlertText={setAlertText}
                setSeverity={setSeverity}
                setSelectedTab={setSelectedTab}
                setEnableEditMenuPopup={setEnableEditMenuPopup}
            />
        </React.Fragment>
    );
};

export default CreateMeetingModal;
