import { Divider, Typography, Button } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { useViewport, portraitBreakPoint } from "../../contextComponent/viewportContext";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import ImgIcons from "../../../components/ImgIcons";
import FilterComponent from './FilterComponent';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    searchFilterHolder: {
        display: "flex",
        padding: "2px",
    },
    searchButton: {
        border: `1px solid ${theme.palette.border}`,
        borderRadius: "33px",
        backgroundColor: theme.palette.background.default,
        margin: "0 5px",
        width: '100%',
        padding: '0 5px',
        alignItems: "center",
        [`${portraitBreakPoint(theme)}`]: {
            width: '100%',
        },
    },
    hideSearchButton: {
        border: `1px solid ${theme.palette.text.medium}`,
        borderRadius: "33px",
        padding: "0px 8px",
        margin: "0 5px",
        width: 260,
        height: 30,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [`${portraitBreakPoint(theme)}`]: {
            width: 80,
            height:24,
            opacity:0
        },
    },
    menuItem: {
        display: "flex",
        alignItems: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.main,
        width: "200px",
    },
    divider: {
        width: "1px",
        borderColor: theme.palette.secondary.light,
        margin: "3px 5px"
    },
    searchButtonContents: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.palette.text.medium,
    },
    searchInputText: {
        WebkitTextFillColor: theme.palette.text.navy,
        marginLeft: "10px",
        height: "28px",
        '& .MuiInputBase-input':{
            padding: "0 5px"
        }
    },
    searchTuneRoundedIcon: {
        color: theme.palette.text.navy,
        "&:hover": {
            color: theme.palette.text.main,
            cursor: "pointer",
        }
    },
    bookNowButton: {
        height: '25px',
        color: theme.palette.primary.contrastText,
        fontSize: '15px',
        fontWeight: 'bold',
        marginRight: '10px',
        whiteSpace: 'nowrap',
        "&:hover": {
            backgroundColor: theme.palette.primary.main
        }
    }
}));

export default function SortFilterComponent({ setSort, search, setSearch }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const sortArray = ["a", "z", "newest", "oldest"];
    const [searchDebounce, setSearchDebounce] = React.useState(search || '');
    const { isMobile } = useViewport();

    const handleMenuItemClick = (event, index) => {
        event.stopPropagation();
        setSort(sortArray[index]);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    const clearText = () => {
        setSearchDebounce("");
        debounceHandler('');
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    const debounceHandler = React.useMemo( () =>_.debounce((value) => {
        setSearch(value)
    }, 500), [setSearch])

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setOpen(open);
    };

    const onSearchHandler = (e) => {
        setSearchDebounce(e.target.value);
        debounceHandler(e.target.value);
    }

    return (
        <div className={classes.searchFilterHolder}>
            <Button
                className={ classes.searchButton }
                ref={anchorRef}
                id="search-button"
                color="primary"
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
            >
                <React.Fragment>
                    <div className={classes.searchButtonContents}>
                        <ImgIcons iconName="Search"/>
                        <InputTextSearch onSearchHandler={onSearchHandler} value={searchDebounce} />
                        {searchDebounce &&
                            <ClearRoundedIcon className={classes.searchTuneRoundedIcon} onClick={clearText} />
                        }
                    </div>
                    
                    <div className={classes.searchButtonContents}>
                        <Divider orientation="vertical" flexItem className={classes.divider} />
                        <ImgIcons iconName="Filter" className={classes.searchTuneRoundedIcon} selected={open} onClick={isMobile?toggleDrawer(!open):handleToggle} />
                    </div>
                </React.Fragment>
            </Button>
            
            {isMobile?
                <React.Fragment key="bottom">
                    <FilterComponent handleMenuItemClick={handleMenuItemClick} open={open} onClose={toggleDrawer(false)} />
                </React.Fragment>
                : 
                <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                placement="bottom-end"
                >
                { ({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper className={classes.paper}>
                            <ClickAwayListener
                                touchEvent="onTouchStart"
                                onClickAway={handleClose}
                                disableReactTree
                            >
                                <Paper>
                                    <MenuList id="split-button-menu">
                                        <MenuItem onClick={(e) => handleMenuItemClick(e, 0)}>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                className={classes.menuItem}
                                            >
                                                Sort A <ArrowRightAltIcon /> Z
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem onClick={(e) => handleMenuItemClick(e, 1)}>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                className={classes.menuItem}
                                            >
                                                Sort Z <ArrowRightAltIcon /> A
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem onClick={(e) => handleMenuItemClick(e, 2)}>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                className={classes.menuItem}
                                            >
                                                Sort Date <ArrowDropUpIcon />
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem onClick={(e) => handleMenuItemClick(e, 3)}>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                className={classes.menuItem}
                                            >
                                                Sort Date <ArrowDropDownIcon />
                                            </Typography>
                                        </MenuItem>
                                    </MenuList>
                                </Paper>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
                </Popper>
            }

        </div>
    );
}

const InputTextSearch = ({onSearchHandler, value}) => {
    const classes = useStyles();
    const { isMobile } = useViewport();
    return <InputBase
        id="search"
        className={classes.searchInputText}
        placeholder={ value && value !== '' ? value : (isMobile ? "Search..." : "Search") }
        autoFocus={false}
        onChange={onSearchHandler}
        value={value}
        autoComplete={"off"}
    />
}
