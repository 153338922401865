import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import { Typography, Box } from "@mui/material";

export default function ProgressBar(props) {

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} style={{ height: 10 }} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
