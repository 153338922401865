import { Box, Divider, List, Typography } from '@mui/material';
import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles } from "@mui/styles";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrgMembers } from '../../../actions';
import Member from './Member';
import AddTeamMember from './AddTeamMember';
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    title: {
        // paddingBottom: theme.spacing(1)
    },
    titleTeamMember: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
        // paddingBottom: theme.spacing(1)
    },
    divider: {
        borderColor: theme.palette.text.light,
    },
    scrollDiv: {
        overflowY: "auto", 
        overflowX: "hidden", 
        height: "100%", 
        maxHeight: '350px',
        [`${portraitBreakPoint(theme)}`]: {
            maxHeight: '250px',
        },
        [`${landscapeBreakPoint(theme)}`]: {
            maxHeight: '250px',
        },
    }
}))


const TeamMembers = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isMobile } = useViewport();
    const currentOrg = useSelector(state => state.collections.currentOrganisation);
    const members = useSelector(state => state.organization.orgMembers);
    const [page, setPage] = React.useState({
        pageSize: 10,
        pageNumber: 0
    });

    const loadMore = () => {
        setPage({
            ...page,
            pageSize: page.pageSize + 10
        });
    }

    React.useEffect(() => {
        if (currentOrg) {
            dispatch(fetchOrgMembers(currentOrg?.id, page))
        }
    }, [dispatch, page, currentOrg])



    return (
        <Box id="org-team-member" pt={isMobile ? 1 : 2} pb={isMobile ? 1 : 2} width={isMobile ? "100%" : "60%"}>
            <div className={classes.titleTeamMember}>
                <Typography variant='h4' className={classes.title}>Team Members</Typography>
                <AddTeamMember page={page} setPage={setPage}/>
            </div>
            <Divider varient="middle" className={classes.divider} />

            <div id="scrollDiv" className={classes.scrollDiv}>
                { members && members.items &&
                <InfiniteScroll
                    dataLength={members.items.length}
                    next={loadMore}
                    hasMore={members.items.length !== members.count}
                    loader={<Typography align="center">Loading...</Typography>}
                    scrollableTarget="scrollDiv"
                >
                    <List dense>
                        {
                            members.items.map(member => <Member member={member} key={member.id}/>)
                        }
                    </List>
                </InfiniteScroll>
                }
            </div>
            <Divider varient="middle" className={classes.divider} />

        </Box>
    )
}

export default TeamMembers;
