import { Box } from '@mui/material';
import React from 'react'
import SessionHistoryComponent from './SessionHistory/SessionHistoryComponent'

const Meet = () => {
    const [refreshData, setRefreshData] = React.useState(false);

    const requestRefetchData = () => {
        setRefreshData(!refreshData);
    }

    return (
        <React.Fragment>
            <Box id="360-meet" height="100%" display='flex' flexDirection={'column'} flex={1}>
                <SessionHistoryComponent refetchData={refreshData} requestRefetchData={requestRefetchData} />
            </Box>
        </React.Fragment>
    )
}

export default Meet;
