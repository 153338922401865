import React from 'react';
import { GalleryViewer, PDFViewer } from "synced-media";
import { get } from '../../actions/common';
import queryString from 'query-string';

function ContentViewer() {
    const [options, setOptions] = React.useState(null);
    const query = queryString.parse(window.location.search);
    const uuid = query.uuid;
    const version = query.v;

    React.useEffect(() => {
        const getConfig = async () => {
            let options = {
                uuid: uuid,
                pageNum: 1,
                isSessionView: false
            };
    
            let config = "config.json";
            if(version) {
                config = `config-v${version}.json`;
                options.contentVersion = version;
            }
    
            get(`/assets/content/${uuid}/${config}`)
                .then(res => {
                    if(res.type === "pdf") {
                        options.type = "pdf";
                    } else if (res.type === "image-gallery") {
                        options.type = "gallery";
                    }
                    setOptions(options);
                })
                .catch(error => {
                    console.error(error);
                })
        }

        if(uuid) {
            getConfig();
        }
    }, [uuid, version])



    return (
        <div>
            {options ?
                options.type === "pdf" ?
                    <PDFViewer sync={options} />
                :
                    <GalleryViewer sync={options} />
            :
                null
            }
        </div>
    )
}

export default ContentViewer;
