import React,{ useState, useEffect} from 'react'
import DialogMigrate from "../../../components/DialogMigrate";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import InviteLogic from '../../Dashboard/invite/InviteLogic';
import { DialogTitle, IconButton } from "@mui/material";
import { useDispatch, useSelector} from 'react-redux';
import { makeStyles } from "@mui/styles";
import { addUsersInOrg, ON_LOADING_START, ON_LOADING_FINISHED } from '../../../actions';
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import CreateOwnerDialog from "./CreateOwnerDialog"

const useStyles = makeStyles((theme) => ({
    iconButton: {
        margin: 0
    }
}))

const AddTeamMember = ({ page, setPage}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [members, setMembers] = useState(null);
    const [addEmails, setAddEmails] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [createOwner, setCreatOwner] = useState(false);
    const orgMembers = useSelector(state => state.organization.orgMembers);

    useEffect(() => {
        if(orgMembers && orgMembers.count > 0){
            if(members && members.count <= orgMembers.count && addEmails){
                let failEmails=[];
                addEmails.forEach(email=>{
                    const matchEmail=orgMembers.items.find(item => item.email === email);
                    if(!matchEmail) {
                        failEmails.push(email);
                    }
                });
                
                if(failEmails.length === 0){
                    enqueueSnackbar({ message: "All users added successfully!", variant: 'success' });
                }else{
                    enqueueSnackbar({ message: `This email cannot be used to invite a new organization member. Please check that a user doesn't already exist with the email address.(${failEmails.join(',')})`, variant: 'error' });
                }
            }
            setMembers({ ...orgMembers });
        }
    },[orgMembers]);

    const inviteUsers = async (users) => {
        dispatch({ type: ON_LOADING_START });
        try {
            const emails=users.split(",");
            setAddEmails([...emails]);
            await dispatch(addUsersInOrg({
                users: emails.map( email => ({ 
                    email, 
                    owner: createOwner
                 }) )
            }));
            setPage({
                pageNumber: 0,
                pageSize: orgMembers.count + emails.length
            });
        } catch (error) {
            enqueueSnackbar({ message: "Users added failure!", variant: 'error' });
        }
        dispatch({ type: ON_LOADING_FINISHED });
    }

    const handleOpen = () => {
        if(members.count > 0){
           setOpen(true);
           setCreatOwner(false);
        }else{
           setShowDialog(true);
        }
    }

    const handleClose=() => {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <IconButton className={classes.iconButton} onClick={handleOpen} size="large">
                <PersonAddIcon color='primary' />
            </IconButton>

            <CreateOwnerDialog showDialog={showDialog} setShowDialog={setShowDialog} setOpen={setOpen} setCreatOwner={setCreatOwner} />
            
            <DialogMigrate
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
                fullWidth>
                <DialogTitle id="simple-dialog-title" variant="h4" color='textSecondary'>
                    Add Team Member
                </DialogTitle>
                <InviteLogic
                    inviteUsers={inviteUsers}
                    enablePersonInvitePopup
                    setEnablePersonInvitePopup={() => { }}
                    placeHolder="Enter emails here..."
                    actionButtonLabel="Add"
                    closeButtonLabel="Close"
                    onCloseHandler={handleClose}
                />

            </DialogMigrate>
        </React.Fragment>
    );
}

export default AddTeamMember;
