import {
    Button, DialogActions, DialogContent, DialogTitle, Divider, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DialogMigrate from "../../../components/DialogMigrate";


const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            border: "1px solid",
            borderColor: theme.palette.border,
        },
        "& .MuiBackdrop-root": {
            background: "transparent"
        }
    },
    dialogRoot: {
        borderColor: theme.palette.text.light,
    },
    dialogButtons: {
        borderRadius: 30,
        height: 32,
        fontFamily: "NeueHaasDisplayBold",
        padding: "0px 35px",
        "&:hover": {
            borderRadius: 30,
        },
    },
    deleteButton: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        "&:hover": {
            borderColor: theme.palette.error.main,
            backgroundColor: theme.palette.error.main,
        },
    },
    buttonContainer: {
        height: 50,
        justifyContent: 'space-evenly'
    },
    divider: {
        borderColor: theme.palette.text.light,
    },
    contentText: {
        fontWeight: '900',
        fontFamily: 'NeueHaasDisplayRoman',
        wordSpacing: '2px'
    },
    titleText: {
        fontFamily: 'NeueHaasDisplayBold',
    }
}));

const CreateOwnerDialog = ({ showDialog, setShowDialog, setOpen, setCreatOwner }) => {
    const classes = useStyles();

    const onConfirm = () => {
        setShowDialog(false);
        setCreatOwner(true);
        setOpen(true);
    }

    const onCancel = ()=> {
        setCreatOwner(false);
        setShowDialog(false);
    }

    return (
        <DialogMigrate open={showDialog} onClose={onCancel} fullWidth className={classes.dialogContainer} classes={{
            paper: classes.dialogRoot,
        }}>
            <div>
                <DialogTitle variant={"h4"} color="textSecondary" align="center" className={classes.titleText} id="delete-content-title-dialog">
                    Invite Owner
                </DialogTitle>
                <Divider className={classes.divider} />
                <DialogContent>
                    <Typography variant={"h4"} color="textSecondary" align="center" className={classes.contentText}>
                        The organization has no members. This first member will be the org owner and they will have special privileges. <br />
                        Do you want to invite the member as owner?
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button variant="outlined" onClick={onCancel} className={classes.dialogButtons}>
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            className={`${classes.dialogButtons} ${classes.deleteButton}`}
                            onClick={onConfirm}
                        >
                            Confirm
                        </Button>
                    </div>
                </DialogActions>
            </div>
        </DialogMigrate>
    )

}

export default CreateOwnerDialog;
