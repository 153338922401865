import React, { useState, useEffect } from 'react'
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useHistory } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import { useDimensions } from '../../hooks/useDimensions';
import { portraitBreakPoint } from "../../views/contextComponent/viewportContext";


const useStyles = makeStyles((theme, width) => ({

    logOutContainer: {
        textAlign: 'center',
        padding: '18px 50px',
        marginRight: '16px',
        borderRadius: '14px',
        boxShadow: '1px 1px 3px grey',
    },
    mobileContainer: {
        paddingTop: '20%',
        height: '93vh',
        width: '100%',
        textAlign: 'center',
    },
    logOutOptions: {
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h4': {
            cursor: 'pointer',
            opacity: '0.4',
            transition: '0.3s ease-out',
            [`${portraitBreakPoint(theme)}`]: {
                opacity: '1',
                margin: '6px',
                padding: '4px 36px',
                borderRadius: '33px',
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
            },
            '&:hover': {
                opacity: '1'
            },
        },
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: 'column-reverse',
            marginTop: '30px',
        },
    },
    divider: {
        fontSize: '24px',
        marginRight: '22px',
        marginLeft: '22px',
        cursor: 'default',
        [`${portraitBreakPoint(theme)}`]: {
            display: 'none',
        }
    }
}));

const LogoutPopUp = ({ open, anchor, onClickClose }) => {
    const history = useHistory();
    const classes = useStyles();
    const { width } = useDimensions();
    const [mobileLogOut, setMobileLogOut] = useState('');

    useEffect(() => {
        if (width <= 600) {
            setMobileLogOut(true)
        } else if (width > 600) {
            setMobileLogOut(false)
        }
    }, [width])

    const onLogout = async () => {
        const getValue = deleteAllCookies();
        if (getValue) {
            history.go('/login')
        }
    };

    const deleteAllCookies = () => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT";
        }
        return true
    };



    return (
        <>
            {open && (
                <div className={classes.root}>
                    <ClickAwayListener onClickAway={onClickClose}>
                        {!mobileLogOut ? (
                            <Popper
                                open={open}
                                anchorEl={anchor}
                                placement={'bottom-end'}
                                transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper className={classes.logOutContainer}>
                                            <Typography variant="body1" >
                                                Are you sure you would<br />
                                                like to log out?
                                            </Typography>
                                            <div className={classes.logOutOptions}>
                                                <Typography variant="h4" onClick={onClickClose}>Cancel</Typography>
                                                <div className={classes.divider}>|</div>
                                                <Typography variant="h4" onClick={onLogout}>Log out</Typography>
                                            </div>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        ) : (
                                <Paper className={classes.mobileContainer}>
                                    <Typography variant="body1" >
                                        Are you sure you would<br />
                                        like to log out?
                                    </Typography>
                                    <div className={classes.logOutOptions}>
                                        <Typography variant="h4" onClick={onClickClose}>Cancel</Typography>
                                        <div className={classes.divider}>|</div>
                                        <Typography variant="h4" onClick={onLogout}>Log out</Typography>
                                    </div>
                                </Paper>
                            )}
                    </ClickAwayListener>
                </div>
            )}
        </>
    )
}


export default LogoutPopUp
