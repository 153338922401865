import React from "react";
import { useSnackbar } from "notistack";

const useCustomSnackbar = () => {
    const { enqueueSnackbar: originalEnqueueSnackbar, closeSnackbar: originalCloseSnackbar } = useSnackbar();
    const [messageList, setMessageList] = React.useState([]);

    const removeFromList = (message) => {
        setMessageList((prevList) => [...prevList.filter(item => message !== item)]);
    }

    const enqueueSnackbar = ({ message, variant }) => {
        if(!messageList.includes(message)) {
            originalEnqueueSnackbar({message: message, variant:variant}, {
                onExited: () => {
                    removeFromList(message);
                }
            })
            setMessageList((prevList) => [...prevList, message]);
        }
    }

    const closeSnackbar = () => {
        originalCloseSnackbar();
    } 

    return { enqueueSnackbar, closeSnackbar };
}

export { useCustomSnackbar as useSnackbar };
