import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';
const grey = '#6b6565';
const red = '#FF0000';
const navy = '#04163E';
const cyan = '#00FFFF'
const darkPurple = '#2b07a3';
const mainColor = "#5100FF";
const darkRed = '#B70000';
const palette={
  type: 'light',
  primary: {
    contrastText: white,
    dark: navy,
    main: mainColor,
    light: cyan
  },
  secondary: {
    contrastText: white,
    dark: black,
    main: mainColor,
    light: '#B2B2B2'
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: red,
    light: colors.red[400]
  },
  success: {
    main: colors.green[600]
  },
  text: {
    primary: mainColor,
    secondary: black,
    main: black,
    link: mainColor,
    contrastText: white,
    medium: grey,
    blue: colors.blue[600],
    light: '#d3d3d3',
    navy: navy,
    darkRed: darkRed
  },
  button: {
    hover: darkPurple
  },
  link: mainColor,
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white,
    main: white,
    contrast: '#011346d1',
    gray: '#dddddd',
    dark: darkPurple,
    translucent: 'rgba(240,240,240,0.9)'
  },
  divider: colors.grey[300],
  border: colors.grey[500],
  common: white,
  action: {
    disabled: 'rgba(0, 0, 0, 0.26)',
  }
}
export default palette;
