import axios from '../../utils/axios';
import { get, patch, post, remove } from '../common';


export const getCollections = (pageSize, pageNumber, sortType, search, filterType, orgId) => {
    return axios.get("/api/user/collections", {
        params: {
            pageSize,
            pageNumber,
            sortType,
            search,
            filterType,
            orgId
        }
    });
}

export const getContents = (pageSize, pageNumber, sortType, id, search, filterType, ignore_user, org_id) => {
    return axios.get(`/api/user/collections/${id}/content`, {
        params: {
            pageSize,
            pageNumber,
            sortType,
            search,
            filterType,
            ignore_user,
            org_id
        }
    });
}

export const getContentsFilter = (pageSize, pageNumber, sortType, search, filterType, ignore_user, org_id) => {
    return axios.get(`/api/user/content`, {
        params: {
            pageSize,
            pageNumber,
            sortType,
            search,
            filterType,
            ignore_user,
            org_id
        }
    });
}

export const deleleContentAPI = (id) => {
    return remove("/api/user/content/" + id);
}

export const removeContentAPI = (collection_id, content_id) => {
    return patch(`/api/user/collections/${collection_id}/content/${content_id}/remove`);
}

export const renameContentTitle = (id, title) => {
    return axios.post("/api/user/content/" + id + "/title", {
        title
    });
}

export const renameCollectionTitle = (id, title) => {
    return axios.post("/api/user/collections/" + id + "/title", {
        title
    });
}

export const getCollectionDetail = (id) => {
    return axios.get("/api/user/collections/" + id ?? '');
}

export const viewCollection = (id) => {
    return axios.patch("/api/collection/" + id + "/viewed");
}

export const viewContent = (id) => {
    return axios.patch("/api/content/" + id + "/viewed");
}

export const patchFavoriteCollection = (id) => {
    return axios.patch("/api/user/collection/" + id + "/markFavourite");
}

export const patchFavoriteContent = (id) => {
    return axios.patch("/api/user/content/" + id + "/markFavourite");
}

export const addNewCollectionAPI = (name,org_id) => {
    return axios.post("/api/user/collection", {
        title: name,
        org_id
    });
}

export const getOrganiations = (pageNumber, pageSize, sortType, search) => {
    return axios.get("/api/orgs", {
        params: {
            pageNumber,
            pageSize, 
            sortType, 
            sortBy: 'title',
            search
        }
    })
}

export const putContentToCollection = (collectionId, contentId) => {
    return axios.post("/api/user/collections/" + collectionId + "/existing_content", {
        content_uuid: contentId
    });
}

export const getContentDetail = (contentId) => {
    const details=axios.get("/api/content/"+contentId+"/details");
    return details;
}

export const getContentNotesAPI = (content_id, pageSize, pageNumber) => {
    return axios.get(`/api/user/content/${content_id}/notes`, {
        params: {
            pageNumber,
            pageSize,
        }
    })
}

export const postContentNoteAPI = (content_id, note) => {
    return post(`/api/user/content/${content_id}/note`, {
        message: note,
    })
}

export const patchContentNoteAPI = (content_id, note_id, note) => {
    return patch(`/api/user/content/${content_id}/note/${note_id}`, {
        message: note,
    })
}

export const deleteContentNoteAPI = (content_id, note_id) => {
    return remove(`/api/user/content/${content_id}/note/${note_id}`)
}
