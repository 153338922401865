import {
    Button,
    DialogActions,
    DialogTitle,
    TextField
} from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { renameContent } from "../../../actions/CollectionAction";
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import { RENAME_CONTENT_MAX_CHARS } from "../../../constant";
import DialogMigrate from "../../../components/DialogMigrate";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        color: theme.palette.text.secondary,
        margin: "5px",
        cursor: "pointer",
        flexDirection: "column",
    },
    dialogButtons: {
        borderRadius: 30,
        height: 32,
        fontFamily: "NeueHaasDisplayBold",
        padding: "0px 25px",
        "&:hover": {
            borderRadius: 30,
        },
    },
    renameDialog: {
        border: '2px solid gray',
        margin: "10px 26px",
        borderRadius: 30,
        width: 'calc(100% - 60px)',
        height: 30,
        "& .MuiInput-input":{
            padding: "6px 10px"
        }
    },
    buttonContainer: {
        height: 50,
    },
}));

export const ContentRenameDialog = ({ content, showRenameDialogBox, closeRenamePopUp }) => {

    const [newContentTitle, setNewContentTitle] = useState("");
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const onSaveRename = async () => {
        await dispatch(renameContent(content.id, newContentTitle));
        closeRenamePopUp();
    }

    useEffect(() => {
        setNewContentTitle(content.title);
    }, [showRenameDialogBox]);

    const handleChange = (event) => {
        if (event.target.value.length > RENAME_CONTENT_MAX_CHARS) {
            enqueueSnackbar({message:`Content name cannot be more than ${RENAME_CONTENT_MAX_CHARS} characters.`, variant:'error'});
        } else {
            setNewContentTitle(event.target.value);
        }
    }

    return (
        <DialogMigrate open={showRenameDialogBox} onClose={closeRenamePopUp} fullWidth>
            <div>
                <DialogTitle variant={"h4"} color="textSecondary" id="rename-content-title-dialog">
                    <EditOutlinedIcon /> Rename Item
                </DialogTitle>
                <hr className={classes.divider} />
                <TextField
                    variant="standard"
                    className={classes.renameDialog}
                    fullWidth
                    value={newContentTitle}
                    onChange={handleChange}
                    autoComplete="off"
                    InputProps={{
                        disableUnderline: true,
                        max: 3,
                    }} />
                <DialogActions className={classes.buttonContainer}>
                    <Button variant="outlined" onClick={closeRenamePopUp} className={classes.dialogButtons}>
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        className={classes.dialogButtons}
                        onClick={onSaveRename}
                    >
                        Save
                    </Button>
                </DialogActions>
            </div>
        </DialogMigrate>
    );

}

export default ContentRenameDialog;
