import { verifyQrCode, registerDemoUserAPI } from '../actions/api/ApplicationAPI';
import { login } from '../actions';
import { ON_LOADING_START, ON_LOADING_FINISHED } from './sessionActions';


export const VALID_QR_CODE = "VALID_QR_CODE";
export const SHOW_WELCOME_BANNER = "SHOW_WELCOME_BANNER";
export const ROUTER_STATE = "ROUTER_STATE";

export const setRouterState = (routerState) => {
    return {
        type: ROUTER_STATE,
        routerState: routerState
    }
};

export const validQrCode = (code) => {
    return {
        type: VALID_QR_CODE,
        payload: code
    }
};

export const showWelcomeBanner = () => {
    return {
        type: SHOW_WELCOME_BANNER
    }
};


export function validateQrCodeLink(id) {
    return (dispatch) =>
        new Promise(async (resolve, reject) => {
            verifyQrCode(id)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(validQrCode(id));
                        resolve(response?.data);
                    } else {
                        // resolve(response);
                        reject({ msg: "Invalid code" });
                    }
                })
                .catch((error) => {
                    // resolve();
                    reject(error);
                });
        }
    );
}

export function registerDemoUser(data) {
    return (dispatch) =>
        new Promise(async (resolve, reject) => {
            dispatch({ type: ON_LOADING_START });
            registerDemoUserAPI(data)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(login());
                        dispatch(showWelcomeBanner());
                        resolve(response);
                    } else {
                        reject({ msg: "Invalid user" });
                    }
                })
                .catch((error) => {
                    reject(error);
                }).finally(() => {
                    dispatch({ type: ON_LOADING_FINISHED });
                })
        }
    );
}
