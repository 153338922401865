import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import LogoutPopUp from "../Dashboard/LogoutPopUp";
import ImgIcons from "../../components/ImgIcons";
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../../views/contextComponent/viewportContext";
import { useSelector } from "react-redux";

const allTabs = ["/", "/360-meet", "/view-analytics", "/settings", "/help", "/organizations"];

const useStyles = makeStyles((theme, width) => ({
    root: {
        display: "flex",
        width: "100%",
        height: "100%",
        position: "fixed",
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: "column",
        },
    },
    appBar: {
        background: "transparent",
        color: theme.palette.text.main,
    },
    divider: {
        width: "80%",
        color: theme.palette.divider,
        [`${portraitBreakPoint(theme)}`]: {
            display: "none",
        },
    },
    logoutButton: {
        background: theme.palette.background.default,
        minWidth: "155px",
        marginRight: 8,
        height: "100%",
        fontFamily: "NeueHaasDisplayBold",
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        justifyContent: "flex-start",
        padding: "10px 26px",
    },
    uploadButton: {
        "& svg": {
            width: "36px !important",
            height: "36px !important",
        },
    },
    box:{
        [`${portraitBreakPoint(theme)}`]: {
            height:'calc(100% - 72px)'
        }
    }
}));

const StyledTabs = withStyles((theme) => ({
    root: {
        background: theme.palette.background.default,
        width: "200px",
        padding: "20px 0",
        [`${portraitBreakPoint(theme)}`]: {
            width: "100%",
            padding: "0",
            bottom: 0,
            zIndex: 2,
        },
        [`${landscapeBreakPoint(theme)}`]: {
            padding: 0,
            width: "80px",
            height: "100%",
            "& .MuiTabs-flexContainer": {
                height: "100%",
            },
            "& .MuiTabs-fixed": {
                height: "100%",
            },
        },
    },
    flexContainer: {
        display: "flex",
        justifyContent: "center",
    },
    indicator: {
        display: "none",
    },
}))(({value,...rest}) => <Tabs value={allTabs.includes(value)?value:'/'} {...rest} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: "none",
        color: theme.palette.primary.dark,
        fontFamily: "NeueHaasDisplayMedium",
        fontSize: theme.typography.pxToRem(15),
        padding: "10px 26px",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        "&:focus": {
            opacity: 1,
        },
        "&:hover": {
            background: theme.palette.background.main,
        },
        "& svg": {
            width: "24px",
            height: "26px",
            marginRight: "8px",
            marginBottom: 0,
        },
        "& > *:first-child": {
            marginBottom: 0,
        },
    },
    labelIcon: {
        minHeight: 0,
    },
    selected: {
        color: theme.palette.text.primary,
        background: theme.palette.background.main,
    },
}))(({iconPosition,...rest}) => <Tab disableRipple {...rest} />);

const StyledMobileTab = withStyles((theme) => ({
    root: {
        flex: "1 1 0",
        textTransform: "none",
        color: theme.palette.primary.dark,
        fontFamily: "NeueHaasDisplayMedium",
        padding: "5px 0",
        minWidth: 0,
        fontSize: "1rem",
        "& svg": {
            width: "26px",
            height: "26px",
        },
        "& > *:first-child": {
            marginBottom: 0,
        },
        [`${portraitBreakPoint(theme)}`]: {
            fontSize: "0.8rem",
            "& svg": {
                width: "40px",
                height: "40px",
            },
        },
        uploadButton: {
            "& svg": {
                width: "30px",
                height: "30px",
            },
        },
        [`${landscapeBreakPoint(theme)}`]: {
            display: "inline-flex",
            fontSize: "0.65rem",
            "& svg": {
                width: "32px",
                height: "32px",
            },
        },
    },
    selected: {
        color: `${theme.palette.text.primary} !important`,
        borderBottom: "none",
    },
}))((props) => <Tab disableRipple {...props} />);

const Hr=() =><hr className={useStyles().divider}></hr>;

export const MainDashboard = ({ children }) => {
    const { isMobile, isMobileLandscape } = useViewport();
    const classes = useStyles();

    const history = useHistory();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const hidden = true;
    const routerState = useSelector(state => state.application.routerState);

    const isViewlabsUser = useSelector(state => state.application.userDetails?.is_viewlabs_user);
    const isMeetingView = useSelector(state => state.session.isMeetingView);

    React.useEffect(() => {
        if(!isViewlabsUser && history.location.pathname === './organizations') {
            history.push("/");
        }else{
            setValue(history.location.pathname);
        }
    }, [history, isViewlabsUser]);

    const onClickClose = () => {
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const checkSelected = (index)=>{
        if(index !== 0){
            return value === allTabs[index];
        }else{
            return value === allTabs[0] || !allTabs.includes(value);
        }
    }

    const WebTabs = () => {
        return (
            <StyledTabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                aria-label="full width tabs example"
                orientation="vertical"
            >
                <StyledTab
                    label={"Conferencing"}
                    icon={<ImgIcons iconName="Meet" selected={checkSelected(1)} />}
                    iconPosition="start"
                    value={allTabs[1]}
                    component={Link}
                    to={allTabs[1]}
                    {...a11yProps(1)}
                />
                <StyledTab
                    label={"Content Hub"}
                    icon={<ImgIcons iconName="Content" selected={checkSelected(0)}/>}
                    iconPosition="start"
                    value={allTabs[0]}
                    component={Link}
                    to={routerState.page ?? allTabs[0]}
                    {...a11yProps(0)}
                />
                {!hidden && (
                    <StyledTab
                        label={"Analytics"}
                        icon={<ImgIcons iconName="Analytics" selected={checkSelected(2)} />}
                        iconPosition="start"
                        value={allTabs[2]}
                        component={Link}
                        to={allTabs[2]}
                        {...a11yProps(2)}
                    />
                )}
                <Hr />
                <StyledTab
                    label={"Settings"}
                    icon={<ImgIcons iconName="Settings" selected={checkSelected(3)} />}
                    iconPosition="start"
                    value={allTabs[3]}
                    component={Link}
                    to={allTabs[3]}
                    {...a11yProps(3)}
                />
                <StyledTab
                    label={"Help"}
                    icon={<ImgIcons iconName="Help" selected={checkSelected(4)} />}
                    iconPosition="start"
                    value={allTabs[4]}
                    component={Link}
                    to={allTabs[4]}
                    {...a11yProps(4)}
                />
                { isViewlabsUser && <Hr />}
                { isViewlabsUser &&
                    <StyledTab
                        label={"Organizations"}
                        icon={<ImgIcons iconName="Settings" selected={checkSelected(5)} />}
                        iconPosition="start"
                        value={allTabs[5]}
                        component={Link}
                        to={allTabs[5]}
                        {...a11yProps(5)}
                    />
                }
            </StyledTabs>
        );
    };

    const MobileTabs = () => {
        return (
            <StyledTabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                aria-label="full width tabs example"
                orientation={isMobileLandscape ? "vertical" : "horizontal"}
            >
                <StyledMobileTab
                    label={"Conferencing"}
                    icon={<ImgIcons iconName="Meet" selected={checkSelected(1)} />}
                    value={allTabs[1]}
                    component={Link}
                    to={allTabs[1]}
                />
                <StyledMobileTab
                    label={"Content Hub"}
                    icon={<ImgIcons iconName="Content" selected={checkSelected(0)} />}
                    value={allTabs[0]}
                    component={Link}
                    to={allTabs[0]}
                />
                {!hidden && (
                    <StyledMobileTab
                        className={classes.uploadButton}
                        icon={<AddCircleOutlineIcon />}
                        value={allTabs[5]}
                        component={Link}
                        to={allTabs[5]}
                    />
                )}
                {!hidden && (
                    <StyledMobileTab
                        label={"Analytics"}
                        icon={<ImgIcons iconName="Analytics" selected={checkSelected(2)} />}
                        value={allTabs[2]}
                        component={Link}
                        to={allTabs[2]}
                    />
                )} 
                <StyledMobileTab
                    label={"Settings"}
                    icon={<ImgIcons iconName="Settings" selected={checkSelected(3)} />}
                    value={allTabs[3]}
                    component={Link}
                    to={allTabs[3]}
                />
                { isViewlabsUser &&
                    <StyledMobileTab
                        label={"Organizations"}
                        icon={<ImgIcons iconName="Settings" selected={checkSelected(5)} />}
                        value={allTabs[5]}
                        component={Link}
                        to={allTabs[5]}
                    />
                }
            </StyledTabs>
        );
    };

    return (
        <div className={classes.root}>
            {!isMeetingView && !isMobile && !isMobileLandscape && <WebTabs />}

            <LogoutPopUp open={open} onClickClose={onClickClose} />

            <Box id="main-dashboard" flexGrow="1" padding="0" height={isMobile ? 'calc(100% - 200px)' : '100%'} width={ isMobile ? '100%' : isMobileLandscape ? 'calc(100% - 80px)' : 'calc(100% - 200px)' }>
                {children}
            </Box>
            {!isMeetingView && (isMobile || isMobileLandscape) && <MobileTabs />}
        </div>
    );
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

export default MainDashboard;
