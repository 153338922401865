import {
  getContentTypesAPI, getSignedUrlAPI, sendUploadCompleteAPI
} from '../actions/api/UploaderAPI';

export const ADD_UPLOAD = 'ADD_UPLOAD';
export const CANCEL_UPLOAD = 'CANCEL_UPLOAD';

export const addUpload = (progressBarData, uid) => {
  return {
    type: ADD_UPLOAD,
    payload: progressBarData,
    uid
  }
};

export const removeProgressBar = (bar) => {
  return {
    type: CANCEL_UPLOAD,
    id: bar.id
  }
};

// Gets content types
export const getContentTypesFromDB = () => dispatch => new Promise(async (resolve, reject) => {
  try {
    const result = await getContentTypesAPI();
    resolve(result);
  } catch (error) {
    reject(error);
  }
});

// Part of step 5. in the uploader api calls.
export const uploadComplete = (collectionID, contentType, uploadID) => dispatch => new Promise(async (resolve, reject) => {
  try {
    const result = await sendUploadCompleteAPI(collectionID, contentType, uploadID);
    resolve(result);
  } catch (error) {
    reject(error);
  }
});


// Part of step 1. in the uploader api calls 
export const signedUrl = (collectionID, data) => dispatch => new Promise(async (resolve, reject) => {
  try {
    const result = await getSignedUrlAPI(collectionID, data);
    resolve(result);
  } catch (error) {
    reject(error);
  }
});
