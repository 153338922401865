import React from "react";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GalleryViewer, PDFViewer } from "synced-media";
import VideoPreview from "./VideoPreview";
import DialogMigrate from "../../../components/DialogMigrate";
import ContentPreviewNotes from './ContentPreviewNotes';
import ImgIcons from "../../../components/ImgIcons";
import { portraitBreakPoint } from "../../contextComponent/viewportContext";
import { useLocation } from 'react-router-dom';
import { set } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        color: theme.palette.text.secondary,
        margin: "5px",
        cursor: "pointer",
        flexDirection: "column",
        WebkitTapHighlightColor: 'transparent',
    },
    imageSection: {
        flexBasis: "85%",
        border: "1px solid",
        borderRadius: "5px",
        borderColor: theme.palette.text.secondary,
    },
    title: {
        width: 'calc(100% - 30px)',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontWeight: "bold",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.primary.dark,
    },
    error: {
        width: 'calc(100% - 30px)',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontWeight: "bold",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.error.main,
    },
    meetingTitle: {
        width: 'calc(100% - 30px)',
        fontSize: "14px",
        marginLeft: "12px",
        "&.MuiTypography-h4": {
            lineHeight: "16px",
        },
    },
    selectedTitle: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontWeight: "bold",
    },
    contentPreview: {
        height: "100%",
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    previewText: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        fontFamily: "NeueHaasDisplayMedium",
    },
    previewTitle: {
        fontSize: "18px",
        color: theme.palette.primary.dark,
        fontFamily: "NeueHaasDisplayBold",
    },
    content: {
        position: "relative",
        height: 'calc(100% - 160px)',
        overflow: 'hidden',
    },
    dialogRoot: {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.text.secondary,
        borderRadius: "11px",
        width: '80%',
        height: '90%',
        maxWidth: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        [`${portraitBreakPoint(theme)}`]: {
            width: "96%"
        },
    },
    actionButtons: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.contrastText,
        borderRadius: 30,
        padding: "10px 55px",
        height: 32,
        fontFamily: "NeueHaasDisplayBold",
        "&:hover": {
            backgroundColor: theme.palette.button.hover,
        },
    },
    activeBorder: {
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        boxShadow: `1px 1px 2px ${theme.palette.text.medium}`,
    },
    contentImageHolder: {
        display: "flex",
        cursor: "pointer",
        flexDirection: "column",
        margin: "5px",
        position: "relative",
    },
    favoriteIcon: {
        color: theme.palette.primary.main,
        fontSize: "30px",
        position: "absolute",
        bottom: "12px",
        right: "12px",
    },
    loader: {
        position: "absolute",
        top: "calc(50% - 20px)",
        left: "calc(50% - 20px)",
    },
    menuItemIcon: {
        height: "20px",
        position: "absolute",
    },
    menuItem: {
        
    },
    disabledMenuItem: {
        backgroundColor:  theme.palette.secondary.light,
        "& .MuiTypography-root":{
            color: theme.palette.text.medium
        }
    },
    noteButton: {
        cursor: 'pointer',
        marginRight: '10px',
    }
}));

function ContentPreview({content, collectionName, open, videoUUID, videoVersion, pdfOptions, galleryOptions, currentVersion, handleClose}) {
    const [openNotes, setOpenNotes] = React.useState(false);
    const classes = useStyles();
    const query = new URLSearchParams(useLocation().search);
    const open_notes = query.get('open_notes');

    React.useEffect(() => {
        if(open_notes){
            console.log('>>>',open_notes)
            setOpenNotes(open_notes === 'true');
        }
    },[open_notes])

    const handleNotesClick = () => {
        setOpenNotes(prev => !prev);
    }

    if(!content){
        return <></>
    }

    return (
        <DialogMigrate
            onClose={handleClose}
            aria-labelledby={"simple-dialog-title-" + content.thumbnail_url}
            open={open}
            keepMounted={false}
            fullWidth
            maxWidth="md"
            classes={{
                paper: classes.dialogRoot,
            }}
        >
            <div className={classes.contentPreview}>
                <DialogTitle id={"simple-dialog-title-" + content.thumbnail_url}>
                    <Typography className={classes.previewText}>
                        Preview: <br />
                        <span className={classes.previewTitle}>
                            {`${collectionName} - ${content.title} - `}
                            {currentVersion === content.final_version
                                ? "Final Version"
                                : `Version ${currentVersion}`}
                        </span>
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    {videoUUID && (
                        <VideoPreview videoUUID={videoUUID} videoVersion={videoVersion} open={open} />
                    )}
                    {pdfOptions && <PDFViewer sync={pdfOptions} />}
                    {galleryOptions && <GalleryViewer sync={galleryOptions} />}
                </DialogContent>
                <DialogActions style={{ padding: "4px 24px 14px" }}>
                    <ImgIcons
                        iconName="Feedback"
                        title="Notes"
                        className={classes.noteButton}
                        onClick={handleNotesClick}
                    />
                    <Button variant="text" onClick={handleClose} className={classes.actionButtons}>
                        Close
                    </Button>
                </DialogActions>
            </div>
            <ContentPreviewNotes open={openNotes} setOpen={setOpenNotes} contentId={content.id} />
        </DialogMigrate>
    );
}

export default ContentPreview;
