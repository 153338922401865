import {
    Button,
    Dialog,
    DialogActions, DialogContent, DialogTitle, Typography
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { makeStyles, styled } from "@mui/styles";
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { withRouter } from 'react-router-dom';
import { onUpdatePassword } from '../../../actions/sessionActions';
import CssTextField from '../../../components/TextField';
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import { portraitBreakPoint, landscapeBreakPoint } from "../../contextComponent/viewportContext";
import { PASSWORD_MAX_CHARS } from "../../../constant";

const ChangePasswordButton = styled(Button)(({ theme }) => ({
    background: theme.palette.text.contrastText,
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    padding: '2px 35px',
    lineHeight: "1.4",
    margin: '25px 0 30px 0',
    width: "300px",
    "@media (hover: hover)":{
        "&:hover": {
            background: theme.palette.primary.main,
            color: theme.palette.text.contrastText
        },
    },
    [`${landscapeBreakPoint(theme)}`]: {
        width: "260px",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth: '350px',
        '& h2': {
            color: theme.palette.text.contrastText,
            marginBottom: '50px',
        },
        [`${portraitBreakPoint(theme)}`]: {
            marginTop: '20px',
        },
        [`${landscapeBreakPoint(theme)}`]: {
            '& h2': {
                marginBottom: '30px',
            }
        }
    },
    emphasis: {
        color: theme.palette.primary.light
    },
    passForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '90%',
        margin: 'auto'
    },
    passInput: {
        padding: '5px 15px',
        borderRadius: 10,
        border: '1px solid'
    },
    errorMessage: {
        color: theme.palette.error.main,
        padding: '5px',
        fontSize: '17px',
    },
    errorBorder: {
        border: '3px solid',
        borderColor: theme.palette.error.main,
    },

}));


const PasswordChange = ({ location }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [show, setShow] = React.useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [oldPasswordError, setOldPasswordError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [rePasswordError, setRePasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        setOldPassword('')
        setOldPasswordError('')
        setPassword('')
        setPasswordError('')
        setRePassword('')
        setRePasswordError('')
        setShowPassword(false)
        setShowRePassword(false)
        setShowOldPassword(false)
        setErrorMessage('')
    }, [show]);

    const onClickHandler = () => {
        setShow(!show);
    }

    const onPasswordChange = (e) => {
        const maxChars = PASSWORD_MAX_CHARS;
        if (e.target.value.length > maxChars) {
            enqueueSnackbar({message:`Password cannot be more than ${maxChars} characters.`, variant:'error'});
        } else {
            setPassword(e.target.value.trim());
            setPasswordError('');
            setErrorMessage('');
        }
    };

    const onRePasswordChange = (e) => {
        const maxChars = PASSWORD_MAX_CHARS;
        if (e.target.value.length > maxChars) {
            enqueueSnackbar({message:`Password cannot be more than ${maxChars} characters.`, variant:'error'});
        } else {
            setRePassword(e.target.value.trim());
            setRePasswordError('');
            setErrorMessage('');
        }
    };

    const onOldPasswordChange = (e) => {
        const maxChars = PASSWORD_MAX_CHARS;
        if (e.target.value.length > maxChars) {
            enqueueSnackbar({message:`Password cannot be more than ${maxChars} characters.`, variant:'error'});
        } else {
            setOldPassword(e.target.value.trim());
            setOldPasswordError('');
            setErrorMessage('');
        }
    };


    const validation = () => {
        setPasswordError('');
        setRePasswordError('');
        setOldPasswordError('');
        let errorFlag = false;
        if (password) {
            if (password.length < 8) {
                errorFlag = true;
                setPasswordError("Password must be at least 8 characters long with 1 Capital letter, 1 Number & 1 Special Character");
            } else if (password.length > 256) {
                errorFlag = true;
                setPasswordError("Password length exceeds maximum length of 256");
            } else {
                const reg = new RegExp("^(?=.*\\d)(?=.*[A-Z])(?=.*[!@#$%^&*(),.?\":;{}|<>~_+-=|]).{8,256}$");
                const isOk = reg.test(password);
                if (isOk) {
                    setPasswordError("");
                } else {
                    errorFlag = true;
                    setPasswordError("Password must contain at least 8 characters, including 1 Capital letter, 1 Number & 1 Special Character");
                }
            }
        } else {
            errorFlag = true;
            setPasswordError("New Password is required");
        }
        if (!rePassword) {
            errorFlag = true;
            setRePasswordError("Re-enter New Password is required");
        }
        if (!oldPassword) {
            errorFlag = true;
            setOldPasswordError("Current Password is required");
        }
        if (password !== rePassword) {
            errorFlag = true;
            setRePasswordError("Password doesn't match");
        }
        if (!errorFlag) {
            if (password === rePassword) {
                return true;
            }
        }
        return false;
    }



    const onSubmit = async (event) => {
        event.preventDefault();
        if (validation()) {
            await dispatch(onUpdatePassword({
                old_password: oldPassword,
                new_password: password
            })).then(res => {
                enqueueSnackbar({message:"Password updated successfully!", variant:'success'});
                setShow(false);
            }).catch(({response, request}) => {
                if(response){
                    enqueueSnackbar({message:response.data.message, variant:'error'});
                }
            })
        }
    };

    return (
        <React.Fragment>
            <ChangePasswordButton onClick={onClickHandler}>
                Change Password
            </ChangePasswordButton>
            <Dialog open={show} onClose={onClickHandler} fullWidth maxWidth={'sm'} keepMounted={false}>
                <DialogTitle variant={"h4"} id="rename-content-title-dialog">
                    Change Password
                </DialogTitle>
                <DialogContent>
                    <form id="password-change-form" onSubmit={onSubmit} noValidate autoComplete="off" className={classes.passForm}>
                        <CssTextField variant="standard" fullWidth placeholder={'Enter Current Password'} type={showOldPassword ? "text" : "password"} value={oldPassword} onChange={onOldPasswordChange}
                            id="current-password" autoComplete="off"
                            className={oldPasswordError ? `${classes.passInput} ${classes.errorBorder}` : classes.passInput}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowOldPassword(!showOldPassword)}
                                        onMouseDown={() => setShowOldPassword(!showOldPassword)}
                                        edge="end"
                                        size="large">
                                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}

                        />
                        <Typography variant="body1" className={classes.errorMessage}>
                            {oldPasswordError}
                        </Typography>
                        <CssTextField variant="standard" fullWidth placeholder={'Enter New Password'} type={showPassword ? "text" : "password"} value={password} onChange={onPasswordChange}
                            id="password" autoComplete="off"
                            className={passwordError ? `${classes.passInput} ${classes.errorBorder}` : classes.passInput}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        size="large">
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}

                        />
                        <Typography variant="body1" className={classes.errorMessage}>
                            {passwordError}
                        </Typography>
                        <CssTextField variant="standard" fullWidth placeholder={'Re-enter New Password'} type={showRePassword ? "text" : "password"} value={rePassword} onChange={onRePasswordChange}
                            id="re-password" autoComplete="off"
                            className={rePasswordError ? `${classes.passInput} ${classes.errorBorder}` : classes.passInput}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowRePassword(!showRePassword)}
                                        onMouseDown={() => setShowRePassword(!showRePassword)}
                                        edge="end"
                                        size="large">
                                        {showRePassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                        <Typography variant="body1" className={classes.errorMessage}>
                            {rePasswordError}
                        </Typography>
                        <Typography variant="body1" className={classes.errorMessage}>
                            {errorMessage}
                        </Typography>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClickHandler}>
                        Cancel
                    </Button>
                    <Button variant="outlined" type="submit" form="password-change-form">
                        Change Password
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default withRouter(PasswordChange);
