import React,{ useEffect, useState, createContext, useContext} from "react";
import { useTheme } from '@mui/styles';

const viewportContext = createContext({});

const ViewportProvider = ({ children }) => {
  const theme = useTheme();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileLandscape, setIsMobileLandscape] = useState(false);



  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      setIsMobile( window.matchMedia(`(max-width: ${theme.breakpoints.values['md']}px) and (orientation: portrait), (max-width: ${theme.breakpoints.values['sm']}px)`).matches );
      setIsMobileLandscape( window.matchMedia(`(max-width: ${theme.breakpoints.values['md']}px) and (orientation: landscape) and (max-height: 600px) and (min-width: ${theme.breakpoints.values['sm']}px)`).matches );
    }
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [theme.breakpoints.values]);

  return (
    <viewportContext.Provider value={{ width, height, isMobile, isMobileLandscape }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height, isMobile, isMobileLandscape } = useContext(viewportContext);
  return { width, height, isMobile, isMobileLandscape };
}

const portraitBreakPoint = (theme) => {
    return `${theme.breakpoints.down('md')} and (orientation: portrait), (max-width: ${theme.breakpoints.values['sm']}px)`;
}

const landscapeBreakPoint = (theme) => {
  return `${theme.breakpoints.down('md')} and (orientation: landscape) and (max-height: 600px) and (min-width: ${theme.breakpoints.values['sm']}px)`;
}

export {useViewport, ViewportProvider, portraitBreakPoint, landscapeBreakPoint}
