import { Button, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles, styled } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewCollection } from "../../../actions/CollectionAction";
import AddIcon from '../../../assets/images/addIcon.svg';
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import DialogMigrate from "../../../components/DialogMigrate";
import { useViewport } from "../../contextComponent/viewportContext";
import { getTokenData } from '../../../actions/util';
import { RENAME_CONTENT_MAX_CHARS } from "../../../constant";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        cursor: "pointer",
        flexDirection: "column",
        margin: "12px",
    },
    imageBorder: {
        border: `1px solid ${theme.palette.border}`,
        background: theme.palette.background.default,
        verticalAlign: 'middle',
        padding: 1,
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignContent: "center",
        borderRadius: 12,
        placeContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        "&:hover": {
            color: theme.palette.text.primary
        }
    },
    dialogButtons: {
        borderRadius: 30,
        height: 32,
        fontFamily: "NeueHaasDisplayBold",
        "&:hover": {
            borderRadius: 30,
        },
    },
    renameDialog: {
        border: '2px solid gray',
        borderRadius: 30,
        height: 25,
        width: '97%',
        padding: '1px 0px 7px 10px'
    },
    buttonContainer: {
        height: 50,
        paddingRight: '5%',

    },
    dialogDivider: {
        display: 'flex',
        width: '90%',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'unset',
        gap: '10px',
        padding: '8px 30px'
    },
    subtitle: {
        fontFamily: "NeueHaasDisplayRoman",
        marginBottom: "15px",
        fontSize: "18px"
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    background: theme.palette.text.contrastText,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    padding: '4px 35px',
    fontWeight: "bold",
    "&:hover": {
        background: theme.palette.primary.main,
        color: theme.palette.text.contrastText
    }
}));

export const NewCollection = ({ width, isSessionView }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isMobile } = useViewport();
    const [imageHeight, setImageHeight] = React.useState(230);
    const meetingView = useSelector((state) => state.session.isMeetingView);
    const [show, setShow] = React.useState(false);
    const [newCollectionName, setNewCollectionName] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();
    const selectedOrg = useSelector(state => state.collections.selectedOrganisation);
    const userTokenData = getTokenData();

    const toggleShow = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShow(!show);
        setNewCollectionName("");
    }

    const handleCreateCollection = async (e) => {
        e.preventDefault();

        if(newCollectionName !== ''){
            await dispatch(addNewCollection(newCollectionName));
            enqueueSnackbar({message:"New collection created successfully!", variant:'success'});
            setShow(!show);
        }else{
            enqueueSnackbar({message:'Collection name cannot be empty.', variant:'error'});
        }
    }

    const handleChange = (event) => {

        const maxChars = RENAME_CONTENT_MAX_CHARS;
        if (event.target.value.length > maxChars) {
            enqueueSnackbar({message:`Collection name cannot be more than ${maxChars} characters.`, variant:'error'});
        } else {
        setNewCollectionName(event.target.value);
        }
    }

    React.useEffect(() => {
        if (meetingView) setImageHeight(100);
        if (!meetingView) setImageHeight(230);
    }, [meetingView]);

    return (
        <React.Fragment>
            <Grid
                item
                key={'new-collection'}
                xs={12}
                sm={isSessionView && width === "sm" ? 6 : isSessionView ? 12 : 6}
                md={isSessionView ? 12 : 4}
                lg={isSessionView ? 12 : 3}
                style={{ padding: !meetingView ? isMobile && "16px 16px 0px 16px" : "2px" }}
            >
                <div className={classes.root} onClick={toggleShow}>
                    <div
                        className={classes.imageBorder}
                        style={{
                            height: imageHeight,
                            maxWidth: width === "xs" && "calc(100vw - 38px)",
                        }}
                    >
                        <img src={AddIcon} height={30} width={30} style={{ color: 'red' }} alt="add"/>
                        Add New Collection
                    </div>
                </div>
            </Grid>
            <DialogMigrate open={show} onClose={toggleShow} fullWidth>
                <div>
                    <DialogTitle variant={"h4"} color="primary" id="create-collection-dialog" style={{ padding: '24px 24px 2px 30px',fontSize: '24px' }}>
                        Add New Collection Card
                    </DialogTitle>
                    <hr className={classes.dialogDivider} />
                    <DialogContent id="create-collection-dialog-content" className={classes.dialogContent}>
                        {selectedOrg && userTokenData.org_id !== selectedOrg.id &&
                        <>
                            <Typography variant={"h4"} color="textSecondary">Organization</Typography>
                            <Typography variant={"subtitle2"} className={classes.subtitle} color="textSecondary">{selectedOrg.title}</Typography>
                        </>
                        }
                        <Typography variant={"h4"} color="textSecondary">Collection Name</Typography>
                        <form id="create-collection-from" onSubmit={handleCreateCollection} style={{ width: '100%' }}>
                            <TextField
                                variant="standard"
                                className={classes.renameDialog}
                                fullWidth
                                value={newCollectionName}
                                onChange={handleChange}
                                autoComplete="off"
                                InputProps={{
                                    disableUnderline: true,
                                    max: 3,
                                }} />
                        </form>
                    </DialogContent>
                    <DialogActions className={classes.buttonContainer}>
                        <StyledButton variant="outlined" onClick={toggleShow} className={classes.dialogButtons}>
                            Cancel
                        </StyledButton>
                        <StyledButton
                            variant="outlined"
                            className={classes.dialogButtons}
                            type={'submit'}
                            form={"create-collection-from"}
                        >
                            Create
                        </StyledButton>
                    </DialogActions>
                </div>
            </DialogMigrate>
        </React.Fragment>
    );
};

export default NewCollection;
