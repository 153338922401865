import { Box, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useViewport, portraitBreakPoint, landscapeBreakPoint } from "../../../contextComponent/viewportContext";
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from "@mui/styles";
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSessionHistory, getUpcomingSession } from "../../../../actions";
import HistoryFilterSortMenu from "../../collections/SortFilterComponent";
import CreateMeetingModal from "../CreateMeetingModal";
import SessionList from "./SessionListComponent";
import SessionGenerator from '../SessionGenerator';
import ContentHeader from "../../../Dashboard/ContentHeader";

const useStyles = makeStyles((theme) => ({
    tabs: {
        "& .MuiTabs-indicator": {
            height: "0px",
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: 'space-around',
        },
        "& .MuiTab-root": {
            minWidth: "280px",
            [`${landscapeBreakPoint(theme)}`]: {
                minWidth: "240px",
            },
            [`${portraitBreakPoint(theme)}`]: {
                minWidth: "120px",
            }
        },
    },
    tabsLableActive: {
        textTransform: "none",
        padding:"0",
        "& .MuiTypography-root": {
            padding: "10px 0",
            borderBottom: `4px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
        }
    },
    tabsLableInActive: {
        textTransform: "none",
        padding:"0",
        "& .MuiTypography-root": {
            color: theme.palette.primary.dark,
        }
    },
    divider: theme.typography.divider,
    searchFilterHolder: {
        margin: '30px',
        display: "flex",
        alignItems: 'center',
        [`${landscapeBreakPoint(theme)}`]: {
            margin: '20px',
        },
        [`${portraitBreakPoint(theme)}`]: {
            margin: '15px',
        }
    }
}));


const Alert = (props) => {
    return <MuiAlert style={{ boxShadow: "none" }} elevation={6} variant="filled" {...props} />;
};

const SessionHistoryComponent = ({ refetchData, requestRefetchData }) => {
    const classes = useStyles();
    const { isMobile, isMobileLandscape } = useViewport();
    const dispatch = useDispatch();
    const sessionHistoryData = useSelector(state => state.session.sessionHistoryData);
    const upcomingSessionData = useSelector(state => state.session.upcomingSessionData);
    const [openMessage, setOpenMessage] = useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [alertText, setAlertText] = React.useState("");
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [value, setValue] = React.useState(0);
    const [enableEditMenuPopup, setEnableEditMenuPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [refreshData, setRefreshData] = React.useState(false);
    const [sort, setSort] = React.useState("oldest");
    const [sortUpcoming, setSortUpcoming] = React.useState("newest");
    const [search, setSearch] = React.useState("");

    const refreshCurrentData = () => {
        setRefreshData(!refreshData);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        refreshCurrentData();
    };

    const fetchSessionHistory = useCallback((pgSize, pgNum, searchText) => {
        const sortBy = (sort === 'a' || sort === 'z') ? 'name' : 'date';
        const sortType = (sort === 'a' || sort === 'newest') ? 'asc' : 'desc'
        dispatch(getSessionHistory(pgSize, pgNum, searchText, sortBy, sortType));
    },[dispatch, sort]);

    const fetchUpcomingSession = useCallback((pgSize, pgNum, searchText) => {
        const sortBy = (sortUpcoming === 'a' || sortUpcoming === 'z') ? 'name' : 'date';
        const sortType = (sortUpcoming === 'a' || sortUpcoming === 'newest') ? 'asc' : 'desc'
        dispatch(getUpcomingSession(pgSize, pgNum, searchText, sortBy, sortType));
    },[dispatch, sortUpcoming]);

    React.useEffect(() => {
        fetchSessionHistory(20, 0);
        fetchUpcomingSession(20, 0);
    }, [fetchSessionHistory, fetchUpcomingSession])

    const handleSnackClose = () => {
        setSeverity("success");
        setOpenMessage(false);
        setAlertText("");
    };

    const onClickTab = (index) => {
        setSelectedTab(index);
    };

    function a11yProps(tabIndex) {
        return {
            id: `simple-tab-${tabIndex}`,
            "aria-controls": `simple-tabpanel-${tabIndex}`,
        };
    }

    return (
        <>
            <SessionGenerator requestRefetchData={requestRefetchData} setSelectedTab={setSelectedTab} setSort={selectedTab === 0 ? setSortUpcoming : setSort} setSearch={setSearch} />
            <Snackbar
                open={openMessage}
                autoHideDuration={3000}
                onClose={handleSnackClose}
                style={{ marginTop: isMobile && 15 }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Alert onClose={handleSnackClose} severity={severity}>
                    {alertText}
                </Alert>
            </Snackbar>
            <Box id="collectionDashboard" display={"flex"} flexDirection={"column"} height={isMobile && !isMobileLandscape ? 'calc(100% - 68px)' : 'calc(100% - 56px)'}>
                <Box
                    className={classes.divider}
                    display="flex"
                    justifyContent="space-between"
                    flexWrap={isMobile || isMobileLandscape ? "wrap" : null}
                    flexDirection={isMobile || isMobileLandscape ? "column-reverse" : "row"}
                >
                    <Box
                    display="flex"
                    alignItems="flex-end"
                    flexWrap=""
                    flexDirection="row"
                    >
                        <Tabs
                            className={classes.tabs}
                            style={{ width: isMobile ? "100%" : "" }}
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs"
                        >
                            <Tab
                                className={selectedTab === 0 ? classes.tabsLableActive : classes.tabsLableInActive}
                                label={<Typography variant="h4">Upcoming sessions</Typography>}
                                {...a11yProps(0)}
                                onClick={() => onClickTab(0)}
                            />
                            <Tab
                                className={selectedTab === 1 ? classes.tabsLableActive : classes.tabsLableInActive}
                                label={<Typography variant="h4">Session history</Typography>}
                                {...a11yProps(1)}
                                onClick={() => onClickTab(1)}
                            />
                        </Tabs>
                    </Box>

                    <ContentHeader setSort={setSort} setSearch={setSearch} >
                        {(isMobile || isMobileLandscape) && 
                            <Typography>
                                360 Meet
                            </Typography>
                        }
                    </ContentHeader>
                </Box>
                
                <div className={classes.searchFilterHolder}>
                   <CreateMeetingModal
                            requestRefetchData={requestRefetchData}
                            setOpenMessage={setOpenMessage}
                            setSeverity={setSeverity}
                            setAlertText={setAlertText}
                            editable={false}
                            setSelectedTab={setSelectedTab}
                    />
                    <HistoryFilterSortMenu setSort={selectedTab === 0 ? setSortUpcoming : setSort} setSearch={setSearch} /> 
                </div>
               
                {selectedTab === 0 ?
                    <SessionList
                        sessionHistoryData={upcomingSessionData}
                        fetchData={fetchUpcomingSession}
                        refetchData={refetchData}
                        isUpcoming={true}
                        setEnableEditMenuPopup={setEnableEditMenuPopup}
                        setSelectedItem={setSelectedItem}
                        refreshData={refreshData}
                        refreshCurrentData={refreshCurrentData}
                        search={search}
                        sort={sortUpcoming}
                    />
                    :
                    <SessionList
                        sessionHistoryData={sessionHistoryData}
                        fetchData={fetchSessionHistory}
                        refetchData={refetchData}
                        setEnableEditMenuPopup={setEnableEditMenuPopup}
                        setSelectedItem={setSelectedItem}
                        refreshData={refreshData}
                        refreshCurrentData={refreshCurrentData}
                        search={search}
                        sort={sort}
                    />
                }
            </Box>
            {enableEditMenuPopup && (
                <CreateMeetingModal
                    setOpenMessage={setOpenMessage}
                    setSeverity={setSeverity}
                    setAlertText={setAlertText}
                    editable={true}
                    setEnableEditMenuPopup={setEnableEditMenuPopup}
                    selectedItem={selectedItem}
                    requestRefetchData={requestRefetchData}
                    refreshCurrentData={refreshCurrentData}
                    setSelectedTab={setSelectedTab}
                />
            )}

        </>
    );
};

export default SessionHistoryComponent;
