import React from "react";
import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ImgIcons from "../../../components/ImgIcons";
import { makeStyles } from "@mui/styles";
import Popover from "@mui/material/Popover";

const useStyles = makeStyles((theme) => ({
    icons: {
        marginRight: "6px",
        verticalAlign: 'top',
        fontSize: "1rem",
    },
    popper: {
        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.dark
          },
          "& .MuiPopover-paper":{
            maxHeight:"260px",
            padding:"5px 0"
          },
    },
    delete:{
        color: `${theme.palette.text.darkRed} !important`
    }
}));

export default function CollectionMenu({collection, anchorRef, openToggle, setOpenToggle, setShowRenameDialogBox}) {
    const classes = useStyles();
 
    const handleCloseToggle = (event) => {
        event.stopPropagation();
        setOpenToggle(false);
    };

    const handleRenameCollection = (event) => {
        event.stopPropagation();
        setOpenToggle(false);
        setShowRenameDialogBox(true);
    };

    return (
        <Popover
            key={collection?.id + "_popper_id"}
            open={openToggle}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            className={classes.popper}
            onClose={handleCloseToggle}
        >
            <MenuItem onClick={handleRenameCollection}>
                <Typography
                    variant="h5"
                    color="textSecondary"
                >
                    <ImgIcons iconName="Rename" className={classes.icons} />{" "}
                    Rename
                </Typography>
            </MenuItem>
        </Popover>
    );
}
