import { patch, get, post } from '../common';


export const fetchOrgDetailsAPI = (id) => {
    return get("/api/org/" + id + "/details");
}


export const updateOrgTitleAPI = (id, data) => {
    return patch("/api/org/" + id + "/title", data);
}

export const getOrgMemberAPI = (id, data) => {
    return get("/api/org/" + id + `/members?pageSize=${data.pageSize}&pageNumber=${data.pageNumber}`);
}


export const getPaymentLinkAPI = (id) => {
    return get("/api/org/" + id + "/stripe/portal");
}

export const addUsersAPI = (id, data) => {
    return post("/api/org/" + id + "/users", data);
}

export const updateOrgOwnerAPI = (id, data) => {
    return patch("/api/org/" + id + "/change_owner", data);
}

export const addNewOrganizationAPI = (data) => {
    return post("/api/org/", data);
}

export const confirmChangeOwner = (key) =>{
    return patch("/api/org/change_owner/"+key);
}

export const revertChangeOwner = (key) =>{
    return patch("/api/org/revert_change_owner/"+key);
}
