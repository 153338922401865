import { Box, Divider } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Drawer from '@mui/material/Drawer';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { logout } from '../../actions';
import { logoutAPI } from "../../actions/api/userAPI";
import { deleteTokenCookie } from "../../actions/util";
import ImgIcons from '../../components/ImgIcons';
import { useViewport } from "../../views/contextComponent/viewportContext";
import { portraitBreakPoint } from "../../views/contextComponent/viewportContext";

const useStyles = makeStyles((theme, width) => ({
    profileContainer: {
        padding: '12px 0',
        borderRadius: '10px',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        width: '250px',
        right:"10px",
        background: theme.palette.background.default,
        '& h4': {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.dark,
            padding: '6px 50px 6px 18px',
            cursor: 'pointer',
            fontFamily: 'NeueHaasDisplayMedium',
            fontSize: '18px',
            '&:hover': {
                background: theme.palette.background.main,
            },
        },
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: 'column-reverse',
            marginTop: '30px',
        },
    },
    icons: {
        marginRight: '6px',
    },
    mobileContainer: {
        width: '100%',
        textAlign: 'center',
        background: theme.palette.background.default,
        '& h4': {
            alignItems: 'center',
            color: theme.palette.primary.dark,
            padding: '20px 0',
            cursor: 'pointer',
            borderTop: '1px solid grey',
            display: 'flex',
            justifyContent: 'center',
            fontFamily: 'NeueHaasDisplayMedium',
        },
        '& h5': {
            fontFamily: 'NeueHaasDisplayMedium',
        }
    },
    drawer: {
        '& .MuiDrawer-paper': {
            borderRadius: '20px 20px 0 0',
        }
    },
    divider: {
        fontSize: '24px',
        borderColor: `${theme.palette.border}`,
        margin: '8px 20px',
        cursor: 'default',
        [`${portraitBreakPoint(theme)}`]: {
            display: 'none',
        }
    },
    userName: {
        color: `${theme.palette.text.primary}`,
        fontSize:"18px",
        fontWeight: 'normal',
        paddingLeft: '20px',
        fontFamily: 'NeueHaasDisplayMedium',
    },
}));

const ProfilePopUp = ({ open, anchor, onClickClose }) => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isMobile } = useViewport();
    const { first_name, last_name } = useSelector(state => state.application?.userDetails ?? {});

    const onSettings = () => {
        history.push('/settings');
        if (isMobile) {
            onClickClose();
        }
    }

    const onFeedback = () => {
        if (isMobile) {
            onClickClose();
        }
        window.open('/dashboard/web/feedback');
    }

    const goToLogin = () => {
        history.push('/login');
    }

    const onLogout = async (e) => {
        e.preventDefault();
        dispatch(() => logoutAPI())
            .then((res) => {
                try {
                    dispatch(logout());
                    const getValue = deleteTokenCookie();
                    if(getValue) { goToLogin() };
                } catch (err) {
                    console.log(err);
                }
            })
            .catch((err) => {
                console.log(err)
                goToLogin();
            })
    };

    return (
        <>
            {open && !isMobile && (
                <ClickAwayListener onClickAway={onClickClose}>
                    <Popper
                        open={open}
                        anchorEl={anchor}
                        placement={'left'}
                        transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper className={classes.profileContainer}>
                                    <Box style={{ padding: '0 10px'}}>
                                    <Typography variant="h5" className={classes.userName}>
                                        {first_name} {last_name}
                                    </Typography>
                                    <Divider className={classes.divider}/>
                                    <Typography variant="h4" onClick={onSettings}>
                                        <ImgIcons iconName="BoldSettings" className={classes.icons}/> Settings
                                    </Typography>
                                    <Typography variant="h4" onClick={onFeedback}>
                                        <ImgIcons iconName="Feedback" className={classes.icons}/> Give Feedback
                                    </Typography>
                                    <Typography variant="h4" onClick={onLogout}>
                                        <ImgIcons iconName="Logout" className={classes.icons}/> Log out
                                    </Typography>
                                    </Box>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </ClickAwayListener>
            )}
            {open && isMobile && (
                <Drawer
                    className={classes.drawer}
                    open={open}
                    anchor='bottom'
                    transitionDuration={500}
                    onClose={onClickClose}
                >
                    <Paper className={classes.mobileContainer}>
                        <Typography variant="h4" onClick={onSettings}>
                            <ImgIcons iconName="BoldSettings" className={classes.icons}/> Settings
                        </Typography>
                        <Typography variant="h4" onClick={onFeedback}>
                            <ImgIcons iconName="Feedback" className={classes.icons} /> Give Feedback
                        </Typography>
                        <Typography variant="h4" onClick={onLogout}>
                            <ImgIcons iconName="Logout" className={classes.icons}/> Log out
                        </Typography>
                    </Paper>
                </Drawer>
            )}
        </>
    )
}


export default ProfilePopUp
