import React from 'react'
import ProgressBar from "./ProgressBar";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from "@mui/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from 'react-redux';
import { removeProgressBar } from "../../../actions";
import { portraitBreakPoint, landscapeBreakPoint } from "../../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    uploadHeading: {
        color: theme.palette.secondary.dark,
        marginBottom: "20px",
    },
    progressHeading: {
        color: theme.palette.secondary.dark,
        marginBottom: "10px",
        marginTop: "10px",
    },
    noUploadsText: {
        margin: "20px 0px 0px 14px",
        [`${portraitBreakPoint(theme)}`]: {
            marginTop: "4px",
        },
    },
    progressSection: {
        marginTop: "20px",
        width: "100%",
        // display: "flex",
        // flexDirection: "column",
        height: "auto",
        maxHeight: "80%",
        // flexGrow: 1,
        overflow: "auto",
        [`${landscapeBreakPoint(theme)}`]: {
            maxHeight: "85%",
        },
    },
    progressBarAndUploadInfo: {
        display: 'flex',
    },
    uploadInfo: {
        display: "flex",
        flexDirection: "row",
        paddingLeft: "1px",
        marginBottom: "0px",
        "& li": {
            listStyle: "none",
            margin: "0px 2px 0px 2px",
        },
        [`${landscapeBreakPoint(theme)}`]: {
            flexDirection: "column",
        },
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: "row",
            fontSize: "14px",
        },
        [`${landscapeBreakPoint(theme)}`]: {
            flexDirection: "row",
            fontSize: "12px",
        },
    },
    uploadInfoDivider: {
        [`${landscapeBreakPoint(theme)}`]: {
            display: "initial",
        },
        [`${portraitBreakPoint(theme)}`]: {
            display: "initial",
        },
    },
    uploadBarAndMsg: {
        width: '100%',
        display: "flex",
        flexDirection: "row",
        marginBottom: "16px",
        gap: "10px",
        justifyContent: 'flex-start'
    },
    uploadMsgs: {
        width: "220px",
        minWidth: "220px",
        [`${landscapeBreakPoint(theme)}`]: {
            width: "100%",
        },
    },
    uploadingMsg: {
        fontStyle: "italic",
        color: theme.palette.primary.main,
    },
    uploadCompleteMsg: {
        fontStyle: "default",
        color: theme.palette.primary.main,
    },
    uploadingSuccessMsg: {
        fontSize: "14px",
    },
    cancelIcon: {
        cursor: "pointer",
        marginRight: "18px",
        opacity: "0.8",
        transition: "0.2s ease-out",
        "&:hover": {
            opacity: "1",
        },
    },
    statusContainer: {
        display: 'flex', 
        flexDirection: "column",
        [`${landscapeBreakPoint(theme)}`]: {
            height: '100%'
        },
    }
}));


const UploadStatus = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const uploader = useSelector(state => state.uploader.uploads);

    const cancelUpload = (bar) => {
        bar?.cancelSource.cancel("Canceled file upload");
        dispatch(removeProgressBar(bar))
    };


    return (
        <Grid item xs={10}>
            <Grid container justifyContent='center' style={{ height: '100%' }}>
                <Grid item xs={12}>
                    <div className={classes.uploadHeading}>
                        <Typography variant="h4" className={classes.progressHeading}>
                            Uploads in progress
                        </Typography>
                    </div>
                    {/* <div className={classes.statusContainer}> */}
                        {/* <div className={classes.progressSection}> */}
                            {Object.entries(uploader).length <= 0 ? (
                                <p className={classes.noUploadsText}>
                                    There are currently no uploads in progress
                                </p>
                            ) : (
                                <Grid container justifyContent='flex-start'>
                                    {Object.values(uploader).map((bar, index) => (
                                        <Grid container justifyContent='flex-start' id={bar.id} key={bar.id} className={classes.uploadBarAndMsg}>
                                            <Grid item xs={12} lg={6} className={classes.progressBarAndUploadInfo}>
                                                <div style={{ flexGrow: 1 }}>
                                                    <ProgressBar value={bar.transcoder_percentage || bar.percentage} />

                                                    <Typography variant="h5">
                                                        <ul className={classes.uploadInfo}>
                                                            <li>{bar.type ? bar.type : "Upload"}</li>
                                                            <li className={classes.uploadInfoDivider}>
                                                                |
                                                            </li>
                                                            <li>
                                                                {/* {!isMobile */}
                                                                    { bar.title.length > 16
                                                                        ? bar.title.slice(0, 13) +
                                                                        "..."
                                                                        : bar.title
                                                                    }
                                                                    {/* : bar.title.length > 22
                                                                        ? bar.title.slice(0, 19) + "..."
                                                                        : bar.title */}
                                                                    {/* } */}
                                                            </li>
                                                            <li className={classes.uploadInfoDivider}>
                                                                |
                                                            </li>
                                                            <li>
                                                                {bar.name.length > 18
                                                                    ? "..." + bar.name.slice(-12)
                                                                    : bar.name}
                                                            </li>
                                                        </ul>
                                                    </Typography>
                                                </div>
                                                {
                                                    bar.percentage !== 100 &&
                                                    <CancelIcon
                                                        className={classes.cancelIcon}
                                                        onClick={() => { cancelUpload(bar) }}
                                                    />
                                                }
                                            </Grid>


                                            <Grid item xs={12} lg={2} xl={2} className={classes.uploadMsgs}>
                                                {bar.percentage !== 100 ? (
                                                    <Typography
                                                        className={classes.uploadingMsg}
                                                        variant="subtitle1"
                                                    >
                                                        Uploading...
                                                    </Typography>
                                                ) : (
                                                    <>
                                                        <Typography
                                                            className={classes.uploadCompleteMsg}
                                                            variant="subtitle1"
                                                        >
                                                            Uploaded!
                                                        </Typography>
                                                        <Typography
                                                            className={
                                                                classes.uploadingSuccessMsg
                                                            }
                                                            variant="body1"
                                                        >
                                                            File transfer complete. We'll
                                                            need a moment to process it on our
                                                            end!
                                                        </Typography>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        {/* </div> */}
                    {/* </div> */}
                </Grid>
            </Grid>
        </Grid>
    )
}


export default UploadStatus
