import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Typography, IconButton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Skeleton from '@mui/material/Skeleton';
import { viewCollections } from "../../../actions/CollectionAction";
import { makeCollectionFavorite, disLikeCollection } from "../../../actions/CollectionAction";
import CircularProgress from "@mui/material/CircularProgress";
import ImgIcons from "../../../components/ImgIcons";
import { useViewport } from "../../contextComponent/viewportContext";
import CustomTooltip from "../../../components/CustomTooltip";
import CollectionMenu from './CollectionMenu';
import CollectionDrawer from './CollectionDrawer';
import CollectionRenameDialog from './CollectionRenameDialog';
import {COLLECTIONS_MENU_BLACKLIST} from "../../../constant/index"
import { useSnackbar } from "../../../hooks/useCustomSnackbar";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        cursor: "pointer",
        flexDirection: "column",
        margin: "5px",
        WebkitTapHighlightColor: 'transparent',
    },
    imageSection: {
        flexBasis: "85%",
        border: "1px solid white",
        borderRadius: 5,
    },
    imageBorder: {
        border: `1px solid ${theme.palette.border}`,
        background: "#afafafb5",
        verticalAlign: 'middle',
        display: 'block',
        padding:1
    },
    title: {
        padding: "0 5px",
        alignSelf: 'center',
        width:'calc(100% - 30px)',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.primary.dark,
    },
    meetingTitle: {
        fontSize: "14px",
    },
    container: {
        flex: 0,
        flexGrow: 1,
    },
    activeBorder: {
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        boxShadow: `1px 1px 2px ${theme.palette.text.medium}`,
    },
    cardText: {
        alignSelf: "center",
        textAlign: "center",
        width: 'calc(100% - 30px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    collectionImageHolder: {
        display: "flex",
        cursor: "pointer",
        flexDirection: "column",
        margin: "5px",
        position: "relative",
    },
    favoriteIcon: {
        color: theme.palette.primary.main,
        fontSize: "30px",
        position: "absolute",
        bottom: "5%",
        right: "4%",
    },
    folderIcon: {
        color: theme.palette.primary.main,
        fontSize: "30px",
        position: "absolute",
        bottom: "5%",
        left: "4%",
    },
    loader: {
        position: "absolute",
        top: "calc(50% - 20px)",
        left: "calc(50% - 20px)",
    },
    mask: theme.grid.mask,
    menuItemIcon: {
        height: "20px",
        position: "absolute",
    },
}));

export const Collection = ({ collection, onSelectCollection, selectedTab }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const anchorRef = React.useRef(null);
    const { isMobile } = useViewport();
    const { enqueueSnackbar } = useSnackbar();
    const [imageLoad, setIsImageLoad] = React.useState(false);
    const [imageHeight, setImageHeight] = React.useState(230);
    const [openToggle, setOpenToggle] = React.useState(false);
    const [isCollectionLiked, setIsCollectionLiked] = React.useState(collection.isFavMarked);
    const [isLoading, setIsLoading] = React.useState(false);
    const meetingView = useSelector((state) => state.session.isMeetingView);
    const [brokenImageLink, setBrokenImageLink] = React.useState(false); //watches for broken image links
    const [showRenameDialogBox, setShowRenameDialogBox] = React.useState(false);
    const [ isEllipsis, setIsEllipsis ] = React.useState(true);

    let count=0;

    const handleMouseEnter=(event)=>{
        setIsEllipsis(event.target.scrollWidth > event.target.offsetWidth);
    }

    const closeRenamePopUp = () => {
        setShowRenameDialogBox(false);
    };

    const handleImageLoaded = () => {
        setIsImageLoad(true);
    };

    const onClickCollection = (event) => {
        count=event.detail;
        setTimeout(()=>{
            if(count === 1){
                dispatch(viewCollections(collection.id));
                onSelectCollection();
            }else{
                onClickFavorateIcon(event);
            }
            count=0;
        },300);
    };

    React.useEffect(() => {
        setIsCollectionLiked(collection.isFavMarked);
        //console.log(collection);
    }, [collection]);

    // Sets the thumbnail image height based on if user views from meeting or main dashboard
    React.useEffect(() => {
        if (meetingView) setImageHeight(100);
        if (!meetingView) setImageHeight(230);
    }, [meetingView]);

    const onClickFavorateIcon = async (e) => {
        e.stopPropagation();
        setIsLoading(true);
        dispatch(makeCollectionFavorite(collection.id))
            .then(() => {
                if (collection.isFavMarked && selectedTab === "favourite") {
                    dispatch(disLikeCollection(collection.id));
                }
                setIsCollectionLiked(!isCollectionLiked);
            })
            .catch(err => {
                enqueueSnackbar({message: err.data.message, variant: "error"});
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const handleToggle = (event) => {
        event.stopPropagation();       
        setOpenToggle(!openToggle);
    };

    const isMenuEnable=() => {
        let blacklist;
        if(window.location.hostname === 'platform.viewlabs.com'){
            blacklist = COLLECTIONS_MENU_BLACKLIST.com;
        }else{
            blacklist = COLLECTIONS_MENU_BLACKLIST.org;
        }
        return !blacklist.includes(collection.id);
    }

    return (
        <>
        <Grid
            item
            key={collection?.id}
            xs={12}
            sm={meetingView ? 12 : 6}
            md={meetingView ? 12 : 4}
            lg={meetingView ? 12 : 3}
            style={{ padding: !meetingView ? isMobile && "16px 16px 0px 16px" : "2px" }}
        >
            <div className={classes.root} onClick={onClickCollection}>
                <div className={classes.collectionImageHolder}>
                    {!collection.thumbnail_url || brokenImageLink ? (
                        <div
                            className={classes.imageBorder}
                            style={{
                                height: imageHeight,
                                maxWidth: isMobile && "calc(100vw - 38px)",
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                borderRadius: 12,
                                background: 'white'
                            }}
                        >
                            <CustomTooltip title={collection?.title} isEllipsis={isEllipsis}>
                                <Typography className={classes.cardText} onMouseEnter={handleMouseEnter} color={"textSecondary"}>
                                    {collection?.title}
                                </Typography>
                            </CustomTooltip>
                        </div>
                    ) : (
                        <React.Fragment>
                            {!imageLoad && (
                                <Skeleton
                                    variant="rectangular"
                                    width={ !meetingView && isMobile ? "calc(100vw - 38px)":null}
                                    height={imageHeight}
                                    style={{ borderRadius: 12 }}
                                />
                            )}
                            <img
                                alt={collection?.title}
                                src={`${collection.thumbnail_url}`}
                                className={imageLoad ? classes.imageBorder:''}
                                style={{
                                    height: !imageLoad ? 0 : imageHeight,
                                    maxWidth: isMobile && "calc(100vw - 38px)",
                                    objectFit: "cover",
                                    borderRadius: 12,
                                }}
                                onLoad={handleImageLoaded}
                                onError={() => {
                                    setBrokenImageLink(true);
                                }}
                            />
                            <div className={classes.mask}/>
                        </React.Fragment>
                    )}
                    {isLoading && <CircularProgress className={classes.loader} />}
                    <ImgIcons iconName="Favorite" selected={isCollectionLiked} className={classes.favoriteIcon} onClick={onClickFavorateIcon} />
                    <ImgIcons iconName="Folder" className={classes.folderIcon} />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <CustomTooltip title={collection?.title} isEllipsis={isEllipsis}>
                        <Typography
                            variant="h4"
                            onMouseEnter={handleMouseEnter}
                            className={meetingView ? `${classes.title} ${classes.meetingTitle}` : classes.title}
                        >
                            {collection?.title}
                        </Typography>
                    </CustomTooltip>
                    {/* bottom right popup menu */}
                    { isMenuEnable() &&
                        <div>
                            <IconButton
                                key={collection?.id}
                                ref={anchorRef}
                                color="primary"
                                size="small"
                                onClick={handleToggle}
                            >
                                <ImgIcons iconName="Menu" className={classes.menuItemIcon} selected={openToggle} />
                            </IconButton>
                            { !isMobile ?
                                <CollectionMenu anchorRef={anchorRef} collection={collection} openToggle={openToggle} setOpenToggle={setOpenToggle} setShowRenameDialogBox={setShowRenameDialogBox} />
                                :
                                <CollectionDrawer anchorRef={anchorRef} collection={collection} openToggle={openToggle} setOpenToggle={setOpenToggle} setShowRenameDialogBox={setShowRenameDialogBox} />
                            }
                        </div>
                    }
                </div>
            </div>
        </Grid>
        <CollectionRenameDialog
        collection={collection}
        showRenameDialogBox={showRenameDialogBox}
        closeRenamePopUp={closeRenamePopUp}
        />
        </>
    );
};

export default Collection;
