import React from 'react';
import { IconButton, Button } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router';
import ProfilePopup from './ProfilePopup';
import ImgIcons from "../../components/ImgIcons";
import { useViewport, portraitBreakPoint, landscapeBreakPoint} from "../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 12px',
        margin: "10px 0",
        width: 'calc(100% - 20px)',
        flex: 1,
        justifyContent: 'space-between',
        // gap: '4px',
        '& .MuiIconButton-root': {
            padding: '2px'
        },
        [`${portraitBreakPoint(theme)}`]: {
            margin: "0",
            padding: "10px",
            ...theme.typography.divider
        },
        [`${landscapeBreakPoint(theme)}`]: {
            padding: "10px",
            ...theme.typography.divider
        },
    },
    notificationIcon: {
        padding: '2px',
        borderRadius: '33px',
        transition: '0.2s ease-in-out',
        '&:hover': {
            opacity: '0.85'
        },
    },
    buttons:{
        display: 'flex',
        alignItems: 'center',
        [`${landscapeBreakPoint(theme)}`]: {
            alignSelf: 'center',
        }
        
    },
    bookNowButton: {
        marginRight: '10px',
        whiteSpace: 'nowrap',
        fontSize: "16px",
        padding: "8px 30px",
        lineHeight: "1.0",
        fontFamily: "NeueHaasDisplayMedium",
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.main
        },
        [`${portraitBreakPoint(theme)}`]: {
            fontSize: "12px",
            padding: "8px 24px",
        },
        [`${landscapeBreakPoint(theme)}`]: {
            fontSize: "12px",
            padding: "8px 24px",
        },
    },
    contentTitle: {
        display: 'flex', 
        alignItems: 'center',
        "& .MuiTypography-root": {
            color: theme.palette.text.main,
            fontSize: "22px",
            marginRight: "auto",
            padding: "4px 6px",
            fontFamily: "NeueHaasDisplayBold",
            whiteSpace: "nowrap",
        } 
    },
}));

export default function ContentHeader({ children, setSort, setSearch, isOpen, setIsOpen, iconMode }) {
    const classes = useStyles();
    const history = useHistory();
    const { isMobile, isMobileLandscape } = useViewport();
    const [open, setOpen] = React.useState(false);
    const [popperAnchor, setPopperAnchor] = React.useState('');
    const hidden = true;

    const onClickHelp = (event) => {
        history.push('/help');
    }

    const onClickOpen = (event) => {
        setOpen(true);
        setPopperAnchor(event.currentTarget)
    };

    const onClickClose = () => {
        setOpen(false);
    };

    const onClickBookNow = () => {
        window.open("https://www.viewlabs.com/orderform", '')
    }

    return (
        <div className={classes.contentHeader}>
            <div id="content-header-children" className={classes.contentTitle}>
                {children}
            </div>
            <div className={classes.buttons}>
                <Button variant="contained" onClick={onClickBookNow} className={classes.bookNowButton}>
                    Book Now
                </Button>
                {(isMobile || isMobileLandscape) ?
                    <>
                        {!history.location.pathname.includes('help') &&
                            <IconButton onClick={onClickHelp} size="large">
                                <ImgIcons iconName="Help" />
                            </IconButton>
                        }
                    </>
                    :
                    <>
                        {!hidden && (
                            <IconButton disableElevation={true} size="large">
                                <ImgIcons iconName="Notifications" className={classes.notificationIcon} />
                            </IconButton>
                        )}
                    </>
                }

                <IconButton onClick={onClickOpen} size="large">
                    <ImgIcons iconName="Profile" selected={open} />
                </IconButton>
                <ProfilePopup open={open} anchor={popperAnchor} onClickClose={onClickClose} />
            </div>
        </div>
    );
}
