import { Box } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React from 'react';
import Page from '../../components/Page';
import { portraitBreakPoint, landscapeBreakPoint } from "../../views/contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: theme.palette.primary.main,
        width: '100%',
    },
    loginHolder: {
        display: 'flex',
        borderRadius: 15,
        padding: '20px 50px',
        width: '60%',
        [`${portraitBreakPoint(theme)}`]: {
            height: '100%',
            width: '80%',
            padding: '0 20px',
            borderRadius: 0,
        },
        [`${landscapeBreakPoint(theme)}`]: {
            height: '100%',
            width: '100%',
            padding: '0 50px',
            borderRadius: 0,
            overflow: 'auto',
        }
    }
}));


const MiddleForm = ({ width, children, title }) => {
    const classes = useStyles();


    return (
        <Page className={classes.root} title={title}>
            <Box className={classes.loginHolder}>
                {children}
            </Box>
        </Page>
    )
}

export default MiddleForm;
