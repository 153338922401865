import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from "@mui/styles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateContents } from "../../../actions";
import Content from "./Content";
import AddNewContent from './AddNewContent';
import { useViewport, portraitBreakPoint, landscapeBreakPoint} from "../../contextComponent/viewportContext";
import ContentPreview from './ContentPreview';
import { useLocation } from 'react-router-dom';
import {
    viewContents,
} from "../../../actions/CollectionAction";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflow: "unset !important",
        margin: '0 30px',
        [`${portraitBreakPoint(theme)}`]: {
            margin: '0 5px',
        },
        [`${landscapeBreakPoint(theme)}`]: {
            margin: '0 15px',
        }
    },
    gridList: {
        flexWrap: "wrap",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
        height: "100%",
        width: "100%",
        margin: "0 !important",
    },
    iconButton: {
        // backgroundColor: theme.palette.text.secondary,
        padding: "2px",
        // color: theme.palette.text.main,
        margin: "0 5px",
        "&:hover": {
            // backgroundColor: theme.palette.text.secondary,
        },
    },
    backButton: {
        backgroundColor: "transparent",
        padding: "2px",
        margin: 0,
        color: theme.palette.text.secondary,
        "&:hover": {
            backgroundColor: "transparent",
        },
        [`${portraitBreakPoint(theme)}`]: {
            width: "80%",
        },
    },
    sortButton: {
        padding: "3px 12px",
        borderRadius: "25px",
        color: theme.palette.text.main,
        fontWeight: "bold",
        margin: "0 5px",
        backgroundColor: theme.palette.secondary.contrastText,
        [`${portraitBreakPoint(theme)}`]: {
            margin: "0px",
        },
    },
    activeButton: {
        padding: "3px 12px",
        borderRadius: "25px",
        color: theme.palette.text.contrastText,
        fontWeight: "bold",
        margin: "0 5px",
        backgroundColor: theme.palette.primary.main,
        "&.MuiButton-text:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    sectionFirst: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "55%",
        [`${portraitBreakPoint(theme)}`]: {
            margin: 0,
            flexWrap: "wrap",
        },
    },
    sectionSecond: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "45%",
        [`${portraitBreakPoint(theme)}`]: {
            margin: "10px 0",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "space-between",
        },
    },
    title: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        color: theme.palette.text.secondary,
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: "32px",
    },
    container: {
        display: "flex",
        flex: 1,
        overflowY: "auto",
        // '&::after': {
        //     flexGrow: 100000,
        //     content: "''"
        // }
    },
    disableIcon: {
        cursor: "default",
        opacity: 0.5,
    },
}));

const Contents = ({
    contents,
    collectionName,
    setOpenSecondRightMenu,
    isSessionView,
    selectedCollection,
    setEnableContentViewId,
    videoPath,
    selectedTab,
    showAddNewContent,
    isFetchContentsEnd
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const meetingView = useSelector((state) => state.session.isMeetingView);
    const { isMobileLandscape } = useViewport();
    const [open, setOpen] = React.useState(false);
    const [videoUUID, setVideoUUID] = React.useState(null);
    const [videoVersion, setVideoVersion] = React.useState(null);
    const [pdfOptions, setPdfOptions] = React.useState(null);
    const [galleryOptions, setGalleryOptions] = React.useState(null);
    const [currentVersion,setCurrentVersion] = React.useState();
    const [ contentUUID, setContentUUID] = React.useState(null);
    const [ previewContent, setPreviewContent] = React.useState(null);
    const [ autoOpen, setAutoOpen] = React.useState(true);
    const query = new URLSearchParams(useLocation().search);
    const preview_uuid = query.get('preview_uuid');
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        if(preview_uuid){
            setContentUUID(preview_uuid);
        }
    },[preview_uuid])

    React.useEffect(() => {
        const fetchData = async ()=>{
            let preview_content = contents.items.find((item) => item.uuid === contentUUID);
            if(!preview_content && contents.count > contents.items.length){
                await dispatch(updateContents({ page: { ...contents.page, size: contents.count } }));
                preview_content = contents.items.find((item) => item.uuid === contentUUID);
            }else if(autoOpen){
                if(preview_content){
                    setPreviewContent(preview_content);
                    showContent(preview_content);
                    setAutoOpen(false);
                }else if(!preview_content){
                    enqueueSnackbar({message: "This content could not be found, please enter the correct UUID.", variant: "error"});
                }
            }
        }

       if(contentUUID && contents && contents.count > 0){
        fetchData();
       } 
    },[contentUUID, contents, dispatch])

    const handleClose = () => {
        if (window.removepano) {
            window.removepano("ViewLabs");
            document.getElementById("pano")?.remove();
        }
        setOpen(false);
        setVideoUUID(null);
        setVideoVersion(null);
    };

    const loadPlayerContent = (videoUUID, videoVersion) => {
        setVideoUUID(videoUUID);
        setVideoVersion(videoVersion);
        setOpen(true);
    };

    const loadContent = (options, contentVersion) => {
        options.isSessionView = false;
        options.uuid = contentUUID;
        options.pageNum = 1;
        options.contentVersion = contentVersion;

        if (options.type === "pdf") {
            setPdfOptions(options);
        } else if (options.type === "gallery") {
            setGalleryOptions(options);
        }
        setOpen(true);
    };

    const showContent = (content) => {
        dispatch(viewContents(content.id));
        setContentUUID(content.uuid);
        if (window.gtagThelab) {
            window.gtagThelab('event', 'content_preview', {
                content_title: content.title,
                content_uuid: content.uuid
            });
        }

        const contentVersion = content.final_version || content.latest_version;
        setCurrentVersion(contentVersion);
        switch (content.type_id) {
            case 1: // 360 Video
            case 2: {
                // Standard Video
                loadPlayerContent(content.uuid, contentVersion);
                break;
            }
            case 3: {
                // PDFs
                const options = {
                    ...content,
                    type: "pdf",
                };
                loadContent(options, contentVersion);
                break;
            }
            case 4: {
                // Image Galleries
                const options = {
                    ...content,
                    type: "gallery",
                };
                loadContent(options, contentVersion);
                break;
            }
            default:
                loadPlayerContent(content.uuid, contentVersion);
        }
    };

    const loadMore = () => {
        dispatch(updateContents({ page: { ...contents.page, size: contents.page.size + 10 } }));
    };

    if (contents && contents.items.length > 1) {
        const index = contents.items.findIndex((x) => x.path === videoPath);
        if (index !== -1) {
            document.getElementById(contents.items[index].id)?.scrollIntoView({
                behavior: "smooth",
            });
        }
    }

    const contentProps = {
        setCurrentVersion, 
        loadPlayerContent, 
        loadContent, 
        setOpen, 
        handleClose,
        showContent,
    }

    const contentPreviewProps = {
        content: previewContent,
        collectionName,
        open,
        videoUUID,
        videoVersion,
        pdfOptions,
        galleryOptions,
        currentVersion,
        setPreviewContent,
        handleClose,
    };

    return ((!contents.items || contents.items.length === 0) && !showAddNewContent) ? (
        <Typography variant={"h3"} style={{ textAlign: "center", margin: "auto" }}>No results found.</Typography>
    ) : (
        <>
            <div style={{ flexGrow: 1, height: 0 }}>
                <div
                    style={{
                        margin: "0",
                        width: "calc(100%- 40px)",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div id="scrollDiv" style={{ overflowY: "auto", overflowX: "hidden", height: "100%" }}>
                        <InfiniteScroll
                            className={classes.root}
                            dataLength={contents.items?.length} //This is important field to render the next data
                            next={loadMore}
                            hasMore={contents.count !== contents.items?.length}
                            loader={<Typography align="center">Loading...</Typography>}
                            // below props only if you need pull down functionality
                            scrollableTarget="scrollDiv"
                            refreshFunction={() => { }}
                            pullDownToRefresh={false}
                            pullDownToRefreshThreshold={50}
                            pullDownToRefreshContent={
                                <h3 style={{ textAlign: "center" }}>&#8595; Pull down to load data</h3>
                            }
                            releaseToRefreshContent={
                                <h3 style={{ textAlign: "center" }}>&#8593; Release to load data</h3>
                            }
                        >
                            {!meetingView && (
                                <Grid container spacing={2} className={classes.container} style={{ flexDirection: contents.items.length === 0 ? "column" : "row" }}>
                                    {showAddNewContent && <AddNewContent />}
                                    {contents.items.length > 0 || !isFetchContentsEnd ?
                                        contents.items.map((content) => (
                                            <Content
                                                content={content}
                                                setOpenSecondRightMenu={setOpenSecondRightMenu}
                                                isSessionView={isSessionView}
                                                collectionName={selectedCollection?.title}
                                                fromMeeting={false}
                                                key={content?.id}
                                                selectedTab={selectedTab}
                                                {...contentProps}
                                            />
                                        ))
                                        :
                                        <Typography variant={"h3"} style={{ textAlign: "center", margin: "auto" }}>No results found.</Typography>
                                    }
                                </Grid>
                            )}
                            {meetingView && (
                                <Grid container className={classes.container} style={{ flexDirection: contents.items.length === 0 ? "column" : "row" }}>
                                    {contents.items.length > 0 ?
                                        contents.items.map((content) => (
                                            <Grid item xs={isMobileLandscape ? 3 : 6} sm={isMobileLandscape ? 3 : 4} md={isMobileLandscape ? 3 : 3} lg={isMobileLandscape ? 3 : 3} key={content?.id}>
                                                <Content
                                                    content={content}
                                                    setOpenSecondRightMenu={setOpenSecondRightMenu}
                                                    isSessionView={isSessionView}
                                                    collectionName={selectedCollection?.title}
                                                    fromMeeting={true}
                                                    selectedTab={selectedTab}
                                                    {...contentProps}
                                                />
                                            </Grid>
                                        ))
                                        :
                                        <Typography variant={"h3"} style={{ textAlign: "center", margin: "auto" }}>No results found.</Typography>
                                    }
                                </Grid>
                            )}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
            <ContentPreview {...contentPreviewProps}/>
        </>
    )
};

const mapStateToProps = (state) => ({
    contents: state.collections.contents,
    selectionCollectionName: state.collections.selectionCollectionName,
    selectedCollection: state.collections.selectedCollection,
    videoPath: state.meeting.changedVideoParameters?.videoPath ?? "",
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Contents);
