import * as actionTypes from '../actions';
import moment from 'moment';

const initialState = {
    startDate: moment().utc().startOf('month'),
	endDate: moment().utc().endOf('month'),
	collectionOverview: [
		{ title: 'Collections', value: 200 },
        { title: 'Total Views', value: 354 },
        { title: 'Unique Viewers', value: 214 },
        { title: 'Direct Referral', value: "90%" },
        { title: 'Organice Referral', value: "10%" },
	],
	collections: {
        items: [],
        count: 0,
        sort: 'a',
        page: { size: 16, number: 0 },
        search: '',
        refreshToggle: false,
    },
};

const analyticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_START_DATE: {
			return {
				...state,
				startDate: action.payload
			}
		}
		case actionTypes.CHANGE_END_DATE: {
			return {
				...state,
				endDate: action.payload
			}
		}
		case actionTypes.FETCH_OVERVIEW: {
			return {
				...state,
				collectionOverview: action.payload
			}
		}
        default: {
            return state;
        }
    }
};

export default analyticsReducer;
