import axios from '../../utils/axios';


// Content types
export const getContentTypesAPI = () => {
  return axios.get('/api/user/content-types');
}



// Fifth call in the upload process. Sends upload = true param.
export const sendUploadCompleteAPI = (collectionID, contentType, uploadID) => {
  return axios.patch(`/api/user/collections/${collectionID}/content/${uploadID}/end_upload`)
}



// First call in upload process. Gets a presigned url from aws
export const getSignedUrlAPI = (collectionID, data) => {
  return axios.post(`/api/user/collections/${collectionID}/content/initialize_upload`, data)
};

