import React from 'react'
import { Divider, List, ListItem, Drawer } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    drawer:{
        "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
            borderTop: "1px solid #bdbdbd"
        },
        "& .MuiListItem-root": {
            color: theme.palette.primary.dark,
            justifyContent: "center !important",
        },
        "& .MuiBackdrop-root": {
            backgroundColor: "transparent"
        }
    },
}));



const FilterComponent = ({handleMenuItemClick,  open, onClose}) => {
    const classes = useStyles();

    return (
        <Drawer anchor="bottom" className={classes.drawer} open={open} onClose={onClose}>
            <List>
                <ListItem button onClick={(e) => handleMenuItemClick(e, 0)}>
                    Sort A&nbsp;&nbsp;&nbsp; <ArrowRightAltIcon /> &nbsp;&nbsp;Z
                </ListItem>
                <Divider />
                <ListItem button onClick={(e) => handleMenuItemClick(e, 1)}>
                    Sort Z&nbsp;&nbsp;&nbsp; <ArrowRightAltIcon /> &nbsp;&nbsp;A
                </ListItem>
                <Divider />
                <ListItem button onClick={(e) => handleMenuItemClick(e, 2)}>
                    Sort Date &nbsp;<ArrowDropUpIcon />
                </ListItem>
                <Divider />
                <ListItem button onClick={(e) => handleMenuItemClick(e, 3)}>
                    Sort Date &nbsp;<ArrowDropDownIcon />
                </ListItem>
            </List>
        </Drawer>
    )
}

export default FilterComponent;
