import { Fade, MenuItem, Paper, ClickAwayListener } from "@mui/material";
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles, styled } from "@mui/styles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganiations, setSelectedOrg } from "../../../actions/CollectionAction";
import { useHistory } from "react-router";
import OrgSortFilter from "./SortFilterComponent"
import { useSnackbar } from '../../../hooks/useCustomSnackbar';

const StyledTextField = styled(TextField)((props) => ({
    '& .MuiInputBase-input': {
        marginLeft: '10px',
        cursor: 'pointer',
    },
    '& .MuiInput-underline': {
        border: 'none',
        borderBottom: 'none !important',
        '&:before, &:after, &:hover, &:focus': {
            border: 'none',
            borderBottom: 'none !important',
        }
    },
    "&:after, &:before": {
        borderBottom: 'none !important',
    },
    "&:hover": {
        border: 'none !important',
    },
}));

const useStyles = makeStyles((theme) => ({
    collectionPaper: {
        textAlign: 'center',
        borderRadius: '8px',
        position: 'relative',
        maxWidth: "280px",
        border: `1px solid ${theme.palette.background.gray}`,
        backgroundColor: theme.palette.background.translucent,
        "& .MuiMenuItem-root":{
            color: theme.palette.text.navy,
            fontFamily: "NeueHaasDisplayRoman",
            padding: "10px 30px",
            borderBottom: `1px solid ${theme.palette.border}`
        }
    },
    dropDownIcon: {
        color: theme.palette.text.navy,
        fontSize: "22px",
        marginRight: '8px'
    },
    inputText: {
        WebkitTextFillColor: theme.palette.text.navy,
    },
    orgFilterHolder: {
        border: `1px solid ${theme.palette.border}`,
        borderRadius: "33px",
        padding: "0px 8px",
        marginLeft: "15px",
        height: "30px",
        "& .MuiInputBase-input": {
            color: theme.palette.text.main,
        },
        "& div":{
            height: "100%"
        }
    },
    orgSortFilter: {
        margin:"10px"
    }
}));

export const OrganisationFilter = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [page, setPage] = React.useState({ size: 16, number: 0 });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [sort, setSort] = React.useState('a')
    const [search, setSearch] = React.useState('')
    const organisations = useSelector((state) => state.collections.organisation);
    const selectedOrg = useSelector(state => state.collections.selectedOrganisation);
    const { enqueueSnackbar } = useSnackbar();

    const onSelectionHandler = (organisation) => {
        setAnchorEl(null);
        dispatch(setSelectedOrg(organisation))
        history.replace({ search: organisation ? `?orgId=${organisation.id}` : '' })
    }

    React.useEffect(() => {
        dispatch(fetchOrganiations(page.number, page.size, sort, search))
        .catch((err) => {
            enqueueSnackbar({ message: err.data.message, variant: 'error' });
        })
    }, [page,sort,search, dispatch]);

    const loadMore = () => {
        setPage({ ...page, size: page.size + 10 });
    };

    const handleOpen = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    }

    const handleclose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    }

    const id = Boolean(anchorEl) ? 'transitions-popper-org-selector' : undefined;

    return (
        <ClickAwayListener onClickAway={handleclose}>
            <div id={'org-dropdown'} className={classes.orgFilterHolder}>
                <div onClick={handleOpen}>
                <StyledTextField
                    id={id}
                    fullWidth
                    value={selectedOrg?.title ?? 'Select Organization'}
                    className={classes.inputText}
                    placeholder="Select Organization"
                    InputProps={{
                        endAdornment: <ExpandMoreIcon className={classes.dropDownIcon} />,
                        readOnly: true
                    }}
                    variant="standard"
                />
                </div>
                <Popper
                    id={id}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    placement={'bottom-end'}
                    transition
                    style={{ position: 'absolute' }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} >
                            <Paper className={classes.collectionPaper}>
                                <div className={classes.orgSortFilter}>
                                    <OrgSortFilter setSort={setSort} search={search} setSearch={setSearch} />
                                </div>
                                <div id="infi-scorll-org-selector"
                                    style={{ display: 'flex', flexDirection: 'column', height: '326px', overflow: "auto", width: '100%' }}
                                >
                                    <InfiniteScroll
                                        scrollableTarget="infi-scorll-org-selector"
                                        dataLength={organisations.items}
                                        next={loadMore}
                                        hasMore={organisations.items.length < organisations.count}
                                        options={organisations.items}
                                        loader={
                                            <p style={{ textAlign: "center", backgroundColor: "#f9dc01" }}>
                                                <b>Loading...</b>
                                            </p>
                                        }
                                    >
                                        <MenuItem value={''} onClick={() => onSelectionHandler(null)} >Select Organization</MenuItem>
                                        {organisations.items.map(option => {
                                            return (
                                                <MenuItem key={option.id} value={option.title} onClick={() => onSelectionHandler(option)} >
                                                    <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{option.title}</span>
                                                </MenuItem>
                                            )
                                        })}
                                    </InfiniteScroll>
                                </div>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    )
};


export default OrganisationFilter;
