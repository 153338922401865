import palette from '../palette';
const MuiInputBase={
  root: {
    fontSize:16,
    height: 40,
    fontFamily: "NeueHaasDisplayRoman",
    "&::placeholder":{
      color: 'white',
    }
  },

  input: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0px',
    lineHeight: '21px',
    '&::placeholder': {
      color: palette.text.main,
      opacity:1,
      fontFamily: "NeueHaasDisplayRoman",
      fontWeight: 400,
      fontSize: '16px',
      letterSpacing: '0px',
      lineHeight: '21px',
    }
  }
}
export default MuiInputBase;
