import React, { Suspense, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import { checkIfLoggedIn, logout, setDeviceInfo, setOpenNavBarMobile, setOpenRightMenu, isMeetingView } from "./actions";
import PrivateRoute from "./PrivateRoute";
import InvalidSessionId from "./views/Error/InvalidSessionId";
import axios from "./utils/axios";
import ErrorPage from "./views/Error/ErrorPage";
import Dashboard from "./layouts/Dashboard";
import LoadingSpinner from "./components/LoadingSpinner";
import OptInPage from './views/Opt/OptInPage'
import ChangeEmailConfirmation from './views/ChangeEmail/ChangeEmailConfirmation'
import ChangeEmailCancellation from './views/ChangeEmail/ChangeEmailCancellation'
import ChangeOwnerConfirmation from './views/ChangeOwner/ChangeOwnerConfirmation'
import ChangeOwnerCancellation from './views/ChangeOwner/ChangeOwnerCancellation'
import ContentViewer from './views/ContentViewer';
import Feedback from "./views/Dashboard/Feedback/Feedback";
import QRValidation from './views/DemoRegister/QRValidation';
import DemoRegister from './views/DemoRegister/DemoRegister';
import { useSnackbar } from './hooks/useCustomSnackbar';

const useStyles = makeStyles((theme) => ({
    "@global": {
        scrollbarColor: `${theme.palette.primary.main} transparent !important`,
        scrollbarWidth: 'thin !important' ,
        "*::-webkit-scrollbar": {
            width: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            outline: "none",
            borderRadius: "5px",
        },
    },
    content: {
        display: "flex",
        flexGrow: 1,
    },
    parentBody: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        height: "100%",
        backgroundColor: theme.palette.background.main,
    },
}));

const getOSVersion = () => {
    const nAgt = navigator.userAgent;
    let os = "unknown";
    let clientStrings = [
        { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
        { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
        { s: "Windows Vista", r: /Windows NT 6.0/ },
        { s: "Windows Server 2003", r: /Windows NT 5.2/ },
        { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
        { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
        { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
        { s: "Windows 98", r: /(Windows 98|Win98)/ },
        { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
        { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: "Windows CE", r: /Windows CE/ },
        { s: "Windows 3.11", r: /Win16/ },
        { s: "Android", r: /Android/ },
        { s: "Open BSD", r: /OpenBSD/ },
        { s: "Sun OS", r: /SunOS/ },
        { s: "Chrome OS", r: /CrOS/ },
        { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
        { s: "iOS", r: /(iPhone|iPad|iPod)/ },
        { s: "Mac OS X", r: /Mac OS X/ },
        { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: "QNX", r: /QNX/ },
        { s: "UNIX", r: /UNIX/ },
        { s: "BeOS", r: /BeOS/ },
        { s: "OS/2", r: /OS\/2/ },
        {
            s: "Search Bot",
            r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
        },
    ];
    for (let id in clientStrings) {
        let cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
            os = cs.s;
            break;
        }
    }

    // let osVersion = "unknown";

    if (/Windows/.test(os)) {
        // osVersion = /Windows (.*)/.exec(os)[1];
        os = "Windows";
    }
    return os;
};

const RouteComponent = ({ width, loading, isUserLoggedIn, checkIfLoggedIn, setDeviceInfo }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState(false);
    const isLoading = useSelector(state => state.application?.loading);
    const { enqueueSnackbar } = useSnackbar();

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            const statusCode = error.response.status;

            if (statusCode === 504 || statusCode === 500) {
                setError(true); // workaround for production and development differences in routing
            } else if (statusCode === 401) {
                dispatch(logout());
                if (!isNotPrivateRoute.includes(history.location.pathname)) {
                    if(history.location.pathname !== "/") {
                        history.push(`/login?redirect=/dashboard/web${history.location.pathname}`);
                    } else {
                        history.push('/login');
                    }
                }
            }
            throw error;
        }
    );

    useEffect(() => {
        let pathname=history.location.pathname;
        if(pathname.indexOf('/thelab/web') === 0){
            pathname=pathname.replace('/thelab/web','');
            history.replace({ ...history.location, pathname });  
        }
        
        const checkUserLoggedIn = async () => {
            try {
                // await getMainRelease();
                if(history.location.pathname !== "/email-change-confirmation") {
                    await checkIfLoggedIn();
                }
            } catch (error) {
                console.log(error);
                if(history.location.pathname === "/email-change-confirmation") {
                    enqueueSnackbar({message: error.data.message, variant:'error'});
                }
            }
        };
        checkUserLoggedIn();
        if (window.frameElement?.id === "contentIframe" || (window.parent !== window && (!window.parent.location.then || window.parent.location.pathname === '/session') ) ) {            
            dispatch(isMeetingView(true));
        }
    }, [checkIfLoggedIn, history, enqueueSnackbar, dispatch]);

    // triggered from the error page when going from offline to online
    const setOnline = () => {
        setError(false);
    };

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (isIE11) {
        return (
            <>
                <div className={"parent-body"}>
                    <div
                        className={classes.content}
                        style={{ display: "flex", justifyContent: "center", padding: 60 }}
                    >
                        <Typography variant={"h3"} style={{ textAlign: "center" }}>
                            This web site is not compatible with internet explorer version you are using.
                            Please use another browser.
                        </Typography>
                    </div>
                </div>
            </>
        );
    }

    const os = getOSVersion();
    setDeviceInfo(os);

    // let panelHeight = "100%";
    if (os && "Android" === os.trim()) {
    } else if (os && "iOS" === os.trim()) {
        // panelHeight = "-webkit-fill-available";
    }

    if (error) {
        return <ErrorPage online={setOnline} />;
    }

    const isNotPrivateRoute = ["/login", "/forgot-password", "/reset-password", "/feedback", '/welcome', "/error", "/error/invalid-session-id", 
    "/notifications/opt-in","/email-change-confirmation","/email-change-cancellation", "/content-viewer", "/qr/:id", "/register/demo-account","/org-owner-change-confirmation","/org-owner-change-cancellation"]

    return (
        <div className={classes.parentBody}>
            { isLoading && <LoadingSpinner /> }
            <Suspense fallback={<LoadingSpinner />}>
                <Switch>
                    <Route
                        path={isNotPrivateRoute[0]}
                        exact
                        component={React.lazy(() => import("./views/LoginScreen"))}
                    />
                    <Route
                        path={isNotPrivateRoute[1]}
                        exact
                        component={React.lazy(() => import("./views/ForgotPassword/index"))}
                    />
                    <Route
                        path={isNotPrivateRoute[2]}
                        exact
                        component={React.lazy(() => import("./views/ResetPassword/index"))}
                    />
                    <Route
                        path={isNotPrivateRoute[3]}
                        exact
                        component={Feedback}
                    />
                    <Route
                        path={isNotPrivateRoute[4]}
                        exact
                        component={React.lazy(() => import('./views/Welcome'))} />
                    <Route path={isNotPrivateRoute[5]} exact component={ErrorPage} />
                    <Route path={isNotPrivateRoute[6]} exact component={InvalidSessionId} />
                    <Route path={isNotPrivateRoute[7]} exact component={OptInPage} />
                    <Route path={isNotPrivateRoute[8]} component={ChangeEmailConfirmation} />
                    <Route path={isNotPrivateRoute[9]} component={ChangeEmailCancellation} />
                    <Route path={isNotPrivateRoute[10]} exact component={ContentViewer} />
                    <Route path={isNotPrivateRoute[11]} exact component={QRValidation} />
                    <Route path={isNotPrivateRoute[12]} exact component={DemoRegister} />
                    <Route path={isNotPrivateRoute[13]} component={ChangeOwnerConfirmation} />
                    <Route path={isNotPrivateRoute[14]} component={ChangeOwnerCancellation} />
                    <PrivateRoute authed={isUserLoggedIn} path="/" component={Dashboard}></PrivateRoute>
                </Switch>
            </Suspense>
        </div>
    );
};

const mapStateToProps = (state) => ({
    openNavBarMobile: state.session.openNavBarMobile,
    loggedinData: state.session.loggedinData,
    loading: state.application.loading,
    isUserLoggedIn: state.session.isUserLoggedIn,
});

export default connect(mapStateToProps, {
        setOpenNavBarMobile,
        setOpenRightMenu,
        checkIfLoggedIn,
        setDeviceInfo,
    })(RouteComponent);
