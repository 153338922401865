import palette from '../palette';
const MuiIconButton={
  root: {
    color: palette.primary.dark,
    padding: 12,
    margin: 5,
    // backgroundColor: palette.primary.main,
    '&:hover': {
      // backgroundColor: palette.primary.contrastText,
      // backgroundColor: "#fff",
    }
  }
}
export default MuiIconButton;
