import { getCollections } from '../actions/api/CollectionAPI';


export const CHANGE_START_DATE = "CHANGE_START_DATE";
export const CHANGE_END_DATE = "CHANGE_END_DATE";
export const FETCH_OVERVIEW = "FETCH_OVERVIEW";


export const onChangeStartDate = (data) => {
    return {
        type: CHANGE_START_DATE,
        payload: data
    }
};

export const onChangeEndDate = (data) => {
    return {
        type: CHANGE_END_DATE,
        payload: data
    }
};

export const onFetchCollectionOverview = (data) => {
    return {
        type: FETCH_OVERVIEW,
        payload: data
    }
};

export const fetchCollectionAnalytics = ({pageSize, pageNumber, sortType, search, filterType, orgId}) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            // dispatch({ type: ON_LOADING_START });
            getCollections(pageSize, pageNumber, sortType, search, filterType, orgId).then(response => {
                if (response.status === 200) {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
            })
            .finally(() => {
                // dispatch({ type: ON_LOADING_FINISHED });
            }) 
        });       
    };
}
