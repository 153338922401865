import * as actionTypes from '../actions';

const initialState = {
    loading: false,
    mainReleaseId: -1,
    userDetails: null,
    qrCode: '',
    showWelcomeBanner: false,
    routerState: {
        page: '/',
        uploaderBack: '/'
    }
};

const applicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ON_LOADING_START: {
            return {
                ...state,
                loading: true
            };
        }

        case actionTypes.ON_LOADING_FINISHED: {
            return {
                ...state,
                loading: false
            };
        }
        case actionTypes.FETCH_MAIN_RELEASE_ID: {
            return {
                ...state,
                mainReleaseId: action.data
            }
        }
        case actionTypes.ON_USER_DETAILS: {
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    ...action.data
                }
            }
        }
        case actionTypes.CLEAR_USER_DETAILS: {
            return {
                ...state,
                userDetails: null,
            }
        }
        case actionTypes.VALID_QR_CODE: {
            return {
                ...state,
                qrCode: action.payload
            }
        }
        case actionTypes.SHOW_WELCOME_BANNER: {
            return {
                ...state,
                showWelcomeBanner: !state.showWelcomeBanner
            }
        }
        case actionTypes.ROUTER_STATE: {
            return {
                ...state,
                routerState: action.routerState
            }
        }
        default: {
            return state;
        }
    }
};

export default applicationReducer;
