import React from 'react'
import { Box, Button, Divider, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { fetchPaymentLink } from '../../../actions';
import { useViewport } from "../../contextComponent/viewportContext";
import CustomTooltip from "../../../components/CustomTooltip";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const useStyles = makeStyles((theme) => ({
    title: {
        paddingBottom: theme.spacing(1)
    },
    titleHolder: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    divider: {
        borderColor: theme.palette.text.light,
    },
    

}))

const StripePayment = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isMobile } = useViewport();


    const onClickPayment = async () => {
        const response = await dispatch(fetchPaymentLink());
        console.log(response)
        window.open(response.url);
    }

    const tooltip="This will open a secure Stripe payment portal where you can customize your subscription and billing settings.";

    return (
        <Box id="stripe-payment" pt={isMobile ? 1 : 2} pb={isMobile ? 1 : 2} width={isMobile ? "100%" : "60%"}>
            <div className={classes.titleHolder}>
                <Typography variant='h4' className={classes.title}>Payment</Typography>
            </div>
            <Divider varient="middle" className={classes.divider} />
            
            <Box display={"flex"} alignItems="center" style={{ gap: '25px' }} pt={3}>
                <Typography variant='h4' color='textSecondary'>Stripe</Typography>
                <Button variant='outlined' style={{ height: '30px', padding: '0 30px' }} onClick={onClickPayment}>
                    Open Payment Portal
                    <CustomTooltip title={tooltip} isEllipsis={true}>
                        <HelpOutlineOutlinedIcon style={{paddingLeft: '10px', fontSize: '1.3rem'}} />
                    </CustomTooltip>
                </Button>
            </Box>
        </Box>
    )
}


export default StripePayment;
