import palette from '../palette';
const MuiTableRow={
  root: {
    borderBottom: `1px solid ${palette.text.light}`,
    backgroundColor: palette.background.default,
    cursor: 'pointer',
    '&$selected': {
      backgroundColor: palette.background.default
    },
    '&:hover': {
      backgroundColor: palette.background.main
    }
  },
  head: {
    cursor: 'default',
  }
}
export default MuiTableRow;
