import { createTheme } from '@mui/material';
import palette from './palette';
import typography from './typography';
import components from './components';
import grid from './grid';

const baseTheme = {
  palette,
  typography,
  components,
  grid,
  sidebar: {}
};

export const theme = createTheme(baseTheme);
// export const themeWithRtl = createTheme({ ...baseTheme, direction: 'rtl' ,shadows: ["none"]});

export const globalTheme = baseTheme;
