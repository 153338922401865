import React from "react";
import {
    Typography,
    DialogTitle,
    Button,
    Popover,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InviteLogic from "./InviteLogic";
import DialogMigrate from "../../../components/DialogMigrate";
import { portraitBreakPoint } from "../../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        borderRadius: "11px",
        "& .MuiDialogTitle-root": {
            padding: 0,
        }
    },
    top: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap-reverse",
        padding: "16px 24px 8px",
        "& CopyToClipBoard": {
            display: "flex",
            justifyContent: "flex-end",
        }
    },
    button: {
        height: "30px",
        color: theme.palette.text.contrastText,
        borderRadius: 30,
        padding: "10px 30px",
        fontWeight: "bold",
        [`${portraitBreakPoint(theme)}`]: {
            padding: "0 10px",
        },
    },
    actionButtons: {
        borderRadius: 30,
        height: 32,
        fontFamily: "NeueHaasDisplayBold",
        whiteSpace: "nowrap",
        padding: "5px 25px",
        "&:hover": {
            borderRadius: 30,
        },
    },
    copy: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
    },
    input: {
        boxSizing: "border-box",
        padding: "2px 10px",
        border: "2px solid #DEDEDE",
        borderRadius: "10px",
        "& .MuiInputBase-input": {
            color: theme.palette.text.main,
        },
        "&::placeholder": {
            color: theme.palette.text.main,
        },
    },
    chip: {
        margin: "2px",
        padding: "2px",
        maxWidth: "95%",
        height: "100%",
        "& .MuiChip-label": {
            overflowWrap: "break-word",
            whiteSpace: "normal",
        },
    },
    typography: {
        padding: theme.spacing(1),
        fontWeight: "bold",
    },
}));

const defaultState = {
    items: [],
    value: "",
    error: null,
    open: false,
};

const Invite = ({
    inviteUsers,
    disable,
    isSessionView,
    setEnableInviteGuest,
    enablePersonInvitePopup,
    setEnablePersonInvitePopup,
    selectedSessionId,
    setOpenMessage,
    setSeverity,
    setAlertText,
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [state, setState] = React.useState(
        !isSessionView && !enablePersonInvitePopup
            ? defaultState
            : {
                  items: [],
                  value: "",
                  error: null,
                  open: true,
              }
    );

    const handleClose = () => {
        setState(defaultState);
        isSessionView && setEnableInviteGuest(false);
        if (enablePersonInvitePopup) {
            setEnablePersonInvitePopup(false);
        }
    };

    const handleOpen = () => {
        setState({
            ...state,
            open: true,
        });
    };

    const copyToClipboard = (event) => {
        setAnchorEl(event.currentTarget);
        navigator.clipboard.writeText(sessionUrl);
        setTimeout(handleCloseCopyLink, 600);
    };

    const handleCloseCopyLink = () => {
        setAnchorEl(null);
        setOpenMessage(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const sessionUrl = window.location.origin + '/session?id=' + selectedSessionId;

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                className={classes.button}
                endIcon={<PersonAddIcon />}
                onClick={handleOpen}
                disabled={disable}
                style={{ display: (isSessionView || enablePersonInvitePopup) && "none" }}
            >
                Invite
            </Button>
            <DialogMigrate
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={state.open}
                fullWidth
                maxWidth={enablePersonInvitePopup ? "sm" : "md"}
            >
                <div className={classes.root}>
                    <div className={classes.top}>
                        <DialogTitle variant="h3" component="span" id="simple-dialog-title">
                            Invite Participants
                        </DialogTitle>
                        <div className={classes.copy}>
                            <CopyToClipboard text={sessionUrl}>
                                <Button
                                    variant="outlined"
                                    onClick={copyToClipboard}
                                    className={classes.actionButtons}
                                >
                                    Copy Meeting Link
                                </Button>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <InviteLogic
                        inviteUsers={inviteUsers}
                        enablePersonInvitePopup
                        setEnablePersonInvitePopup={setEnablePersonInvitePopup}
                    />
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseCopyLink}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                >
                    <Typography className={classes.typography}>Copied!</Typography>
                </Popover>
            </DialogMigrate>
        </React.Fragment>
    );
};

export default Invite;

// const mapStateToProps = (state) => ({

// })

// const mapDispatchToProps = {

// }

// export default connect(mapStateToProps, mapDispatchToProps)(Invite)
