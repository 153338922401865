import { useEffect, useState } from "react";
import { Button, FormControl, IconButton, Input, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "../../../hooks/useCustomSnackbar";
import { patchContentNoteAPI } from "../../../actions/api/CollectionAPI";
import { getTokenData } from "../../../actions/util";
import { useViewport } from "../../../views/contextComponent/viewportContext";
import ContentNoteDeleteDialog from "./ContentNoteDeleteDialog";
import CustomTooltip from "../../../components/CustomTooltip";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
    note: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '16px',
        '& > *':{
            marginBottom: '4px',
        }
    },
    nameDateContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dateTime: {
        fontSize: '14px',
        fontStyle: 'italic',
        color: theme.palette.text.medium,
        marginRight: '5px',
    },
    noteName: {
        color: theme.palette.text.primary,
        fontSize: '16px',
        fontWeight: 'bold',
    },
    noteEdit: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
        borderRadius: '5px',
        border: `1px solid ${theme.palette.border}`,
        padding: '5px',
        background: theme.palette.background.main,
    },
    input: {
        boxSizing: 'border-box',
        flex: 1,
        height: 'auto',
        color: theme.palette.text.main,
        paddingLeft: '5px',
    },
    editNoteIcon: {
        padding: 0,
    },
    noteText: {
        background: theme.palette.background.main,
        border: `1px solid ${theme.palette.border}`,
        padding: '5px 10px',
        fontSize: '15px',
        borderRadius: '3px',
        wordBreak: 'break-word',
        fontFamily: 'NeueHaasDisplayRoman',
    },
    actionButtons: {
        display: 'flex',
    },
    buttons: {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        padding: '2px',
        minWidth: 0,
        marginRight: '5px',
        fontSize: '14px',
    },
    timestampLink: {
        color: theme.palette.link,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        }
    }
}));

const ContentPreviewNote = ({ note, contentId, handleRefreshData, handleNotesClose }) => {
    const { isMobile } = useViewport();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [input, setInput] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [tokenData, setTokenData] = useState(getTokenData())
    const [message, setMessage] = useState("");
    const [openDelete, setOpenDelete] = useState(false);

    useEffect(() => {
        setTokenData(getTokenData());
        setInput(note.message);
        getMessageTimestamps();
    }, [note.message, isMobile])

    const handleEdit = () => {
        setEditMode(prev => {
            if(prev === true) {
                setInput(note.message);
            }
            return !prev;
        });
    }

    const handleUpdateNote = () => {
        if(input.length > 0 && input !== note.message) {
            patchContentNoteAPI(contentId, note.id, input)
                .then(() => {
                    enqueueSnackbar({message: "Note successfully updated!", variant: "success"});
                    handleRefreshData();
                    setEditMode(false);
                })
                .catch((error) => {
                    enqueueSnackbar({message: error.response.data.message, variant: "error"});
                })
        } else if(input === note.message) {
            setEditMode(false);
        } else {
            enqueueSnackbar({message: "Note cannot be empty.", variant: "error"});
        }
    }

    const handleChange = (event) => {
        setInput(event.target.value)
    };

    const handleEnter = (event) => {
        if(event.key === 'Enter') {
            handleUpdateNote();
        }
    }

    const handleDelete = () => {
        setOpenDelete(true);
    }

    const closePopUp = () => {
        setOpenDelete(false);
    }

    const goToTimestamp = (time) => {
        window.krpano.call(`plugin[video].seek(${time});`);
        if(isMobile) {
            handleNotesClose();
        }
    }

    const getMessageTimestamps = () => {
        let newMessage = wrapTags(note.message);
        newMessage = newMessage.reduce((prev, cur) => {
            return [prev, ' ', cur]
        })
        setMessage(newMessage);
    }

    const wrapTags = (text) => {
        const timePattern = /((\s+|^)(([0-1]?[0-9]):)?([0-5]?[0-9]):([0-5][0-9]))/m;
        const textArray = text.split(" ");
        return textArray.map((str, i) => {
          if (timePattern.test(str)) {
            let seconds = convertToSeconds(str);
            return <span key={i} className={classes.timestampLink} onClick={() => goToTimestamp(seconds)}>{str}</span>;
          }
          return str;
        });
    };

    const convertToSeconds = (time) => {
        time = time.replace(/[^0-9:]/g, "");
        return time.split(':').reduce((acc,time) => (60 * acc) + Number(time));
    }

    return (
        <>
            <div className={classes.note}>
                <div className={classes.nameDateContainer}>
                    <div className={classes.noteName}>{note.author}</div>

                    {(note.created_at_unix_time !== note.updated_at_unix_time) ?
                        <CustomTooltip title={`Last edited ${moment.unix(note.updated_at_unix_time).format('MM/DD/YYYY hh:mm a')}`}>
                            <div className={classes.dateTime}>{moment.unix(note.created_at_unix_time).format('MM/DD/YYYY hh:mm a')}*</div>
                        </CustomTooltip>
                    :
                        <div className={classes.dateTime}>{moment.unix(note.created_at_unix_time).format('MM/DD/YYYY hh:mm a')}</div>
                    }
                </div>
                {editMode ?
                    <FormControl variant="standard" className={classes.noteEdit}>
                        <Input 
                            className={classes.input}
                            placeholder="Edit note here"
                            value={input || ""}
                            onChange={handleChange}
                            onKeyPress={handleEnter}
                            multiline
                            disableUnderline
                        />
                        <div className={classes.editButtons}>
                            <IconButton className={classes.editNoteIcon} onClick={handleUpdateNote}>
                                <CheckIcon titleAccess="Save" />
                            </IconButton>
                            <IconButton className={classes.editNoteIcon} onClick={handleEdit}>
                                <ClearIcon titleAccess="Close" />
                            </IconButton>
                        </div>
                    </FormControl>
                :
                    <Typography className={classes.noteText}>{message}</Typography>
                }
                {tokenData.id === note.author_id &&
                    <div className={classes.actionButtons}>
                        <Button className={classes.buttons} onClick={handleEdit}>Edit</Button>
                        <Button className={classes.buttons} onClick={handleDelete}>Delete</Button>
                    </div>
                }
            </div>

            <ContentNoteDeleteDialog note={note} contentId={contentId} open={openDelete} closePopUp={closePopUp} handleRefreshData={handleRefreshData} />
        </>
    )
}

export default ContentPreviewNote;
