import { Autocomplete, TextField } from '@mui/material';
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCollections, setSelectedCollection } from "../../../actions/CollectionAction";

const useStyles = makeStyles((theme) => ({
  listbox:{
    overflowX: 'hidden',
  }
}));

export const CollectionSelector = ({ showCanUploadContent }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [list, setList] = React.useState([]);
  const [value, setValue] = React.useState(null);

  const collections = useSelector((state) => state.collections.collections);
  const selectedCollection = useSelector(state => state.collections.selectedCollection);

  React.useEffect(() => {
    dispatch(fetchCollections(collections.count || 1, 0 , 'a', "", ""));
  }, [collections.count, dispatch]);

  React.useEffect(() => {
    if(collections.count > 0){
      let items = collections.items;
      if (showCanUploadContent) {
        items = collections.items.filter(x => x.canUploadContent);
      }
      const newList = items.map((option) => ({
        ...option,
        label:option.title
      }));
      setList(newList);
    
      if(selectedCollection && selectedCollection.canUploadContent){
        setValue({ 
          ...selectedCollection,
          label: selectedCollection.title
        });
      }else if(newList.length > 0){
        dispatch(setSelectedCollection({ 
          ...newList[0]
        }));
      }else{
        setValue({});
      }
    }
  }, [dispatch, collections.items, selectedCollection, showCanUploadContent, collections.count]);

  const onSelectionChange = (event, value) => {
    if(value){
      dispatch(setSelectedCollection({
        ...value,
        title: value.label,
      }));
    }
  }


  return (
    <Autocomplete
        id="timezone-id"
        options={list}
        autoHighlight
        clearOnBlur
        classes={{ listbox : classes.listbox }}
        value={ value }
        onChange={onSelectionChange}
        renderInput={(params) => <TextField variant="standard" {...params} label="" />}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        >
    </Autocomplete>
  )
};


export default CollectionSelector;
