import React from 'react';

export const LoadingIcon = ({ delay }) => {
  const [ visible, setVisible ] = React.useState(true);

  React.useEffect(() => {
    if (delay > 0) {
      setVisible(false);
      const timeout = window.setTimeout(() => setVisible(true), delay);
      return () => window.clearTimeout(timeout);
    }
  }, [delay])

  if (!visible) return <></>;
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div className="loader" 
        style={{
          width: '200px',
          height: '200px',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%'
        }}
      >
        <svg id="b-rotate" className="circular-loader" viewBox="25 25 50 50" >
          <circle id="buffer" className="loader-path" cx="50" cy="50" r="12" fill="none" stroke="#5100FF" strokeWidth="1.2" />
        </svg>
      </div>
    </div>
  )
}
