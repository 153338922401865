import {
    Button,
    Chip, DialogActions, DialogContent, TextField
} from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import React from "react";
import { portraitBreakPoint } from "../../contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    button: {
        height: "30px",
        color: theme.palette.text.contrastText,
        borderRadius: 30,
        padding: "10px 30px",
        fontWeight: "bold",
        [`${portraitBreakPoint(theme)}`]: {
            padding: "0 10px",
        },
    },
    inputContainer: {
        padding: "2px 0",
    },
    input: {
        boxSizing: "border-box",
        padding: "2px 10px",
        border: "2px solid #DEDEDE",
        borderRadius: "10px",
        height: "100px",
        "& .MuiInputBase-input": {
            color: theme.palette.text.main,
        },
        "& ::placeholder": {
            color: theme.palette.text.medium,
            fontSize: '16px'
        },
        "& ::-webkit-input-placeholder": {
            color: theme.palette.text.medium,
            fontSize: '16px'
        },
    },
    buttonContainer: {
        padding: "8px 24px 14px",
    },
    buttonContainerMeeting: {
        padding: "12px 0 24px",
    },
    actionButtons: {
        // backgroundColor: theme.palette.text.secondary,
        // color: theme.palette.text.main,
        borderRadius: 30,
        height: 32,
        fontFamily: "NeueHaasDisplayMedium",
        padding: "5px 25px",
        "&:hover": {
            // backgroundColor: theme.palette.text.secondary,
            // color: theme.palette.text.main,
            borderRadius: 30,
        },
        [`${portraitBreakPoint(theme)}`]:{
            fontSize:'14px',
            padding:'2px 8px',
        }
    },
    chip: {
        margin: "2px",
        padding: "2px",
        maxWidth: "95%",
        height: "100%",
        "& .MuiChip-label": {
            overflowWrap: "break-word",
            whiteSpace: "normal",
        },
    },
}));

const defaultState = {
    items: [],
    value: "",
    error: null,
    open: false,
};

const InviteLogic = ({
    inviteUsers,
    enablePersonInvitePopup,
    setEnablePersonInvitePopup,
    isMeetingDetails,
    closeMeetingDetails,
    placeHolder,
    actionButtonLabel,
    closeButtonLabel,
    onCloseHandler
}) => {
    const classes = useStyles();

    const [state, setState] = React.useState({
        items: [],
        value: "",
        error: null,
        open: true,
    });

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        setState(defaultState);
        if (enablePersonInvitePopup) {
            setEnablePersonInvitePopup(false);
        }
        if (isMeetingDetails) {
            closeMeetingDetails();
        }
        if (onCloseHandler) {
            onCloseHandler();
        }
    };

    const onInvite = () => {
        if (state.items.length > 0 || (state.value.length > 0 && isEmail(state.value))) {
            try {
                let emails = [...state.items];
                if (state.value.length > 0 && isEmail(state.value)) {
                    emails = [...emails, state.value];
                }
                inviteUsers(emails.join(","));
                handleClose();
                // enqueueSnackbar({ message: "Invitation sent successfully!", variant: 'success' });
            } catch (e) {
                enqueueSnackbar({ message: "Something went wrong!...Please try again.", variant: 'error' });
            }
        } else {
            setState({ ...state, error: "Please enter email address(es)" });
        }
    };

    const handleKeyDown = (evt) => {
        if (evt.key === "Backspace") {
            evt.preventDefault();
            evt.stopPropagation();
            setState({
                ...state,
                value: state.value.substring(0, state.value.length - 1),
                error: null,
            });
        }
        if (["Enter", "Tab", ",", " ", "Spacebar"].includes(evt.key)) {
            evt.preventDefault();
            var value = state.value.trim().toLowerCase();

            if (value && isValid(value)) {
                setState({
                    ...state,
                    items: [...state.items, value],
                    value: "",
                });
            }
        }
    };

    const handleChange = (evt) => {
        setState({
            ...state,
            value: evt.target.value,
            error: null,
        });
    };

    const handleDelete = (item) => {
        setState({
            ...state,
            items: state.items.filter((i) => i !== item),
        });
    };

    const handlePaste = (evt) => {
        evt.preventDefault();

        var paste = evt.clipboardData.getData("text").toLowerCase();
        var emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

        if (emails) {
            let filteredEmails = [...new Set(emails)];
            var toBeAdded = filteredEmails.filter((email) => !isInList(email));

            setState({
                ...state,
                items: [...state.items, ...toBeAdded],
            });
        }
    };

    const isValid = (email) => {
        let error = null;

        if (isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            setState({ ...state, error });

            return false;
        }

        return true;
    };

    const isInList = (email) => {
        return state.items.includes(email);
    };

    const isEmail = (email) => {
        return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
    };

    return (
        <React.Fragment>
            <DialogContent className={isMeetingDetails ? classes.inputContainer : null}>
                {state.items.map((item, index) => (
                    <Chip
                        key={index}
                        icon={<FaceIcon />}
                        label={item}
                        onDelete={() => handleDelete(item)}
                        className={classes.chip}
                        variant="outlined"
                        color="primary"
                    />
                ))}

                <TextField
                    variant="standard"
                    className={classes.input + " " + (state.error && " has-error")}
                    value={state.value}
                    fullWidth
                    autoComplete="email"
                    onKeyDown={handleKeyDown}
                    placeholder={placeHolder ?? "Enter or paste emails here"}
                    onChange={handleChange}
                    onPaste={handlePaste}
                    InputProps={{
                        disableUnderline: true,
                    }} />

                {state.error && <p className="error">{state.error}</p>}
            </DialogContent>
            <DialogActions
                className={isMeetingDetails ? classes.buttonContainerMeeting : classes.buttonContainer}
            >
                <Button variant="outlined" onClick={handleClose} className={classes.actionButtons}>
                    {closeButtonLabel ?? "Cancel"}
                </Button>
                <Button variant="outlined" onClick={onInvite} className={classes.actionButtons}>
                    {actionButtonLabel ?? "Send"}
                </Button>
            </DialogActions>
        </React.Fragment>
    );
};

export default InviteLogic;
