const gird={
  mask:{
    position: 'absolute',
    background: 'rgb(256,256,256,0.6)',
    opacity: 0,
    top: 0,
    right: '-2px',
    width: '100%',
    height: '100%',
    "@media (hover: hover)":{
      "&:hover":{
        opacity: 1,
      }
    }
  }
}
export default gird;
