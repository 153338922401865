import overrides from './overrides';

const components = {
    MuiAlert: {
        styleOverrides: overrides.MuiAlert,
    },
    MuiButton: {
        styleOverrides: overrides.MuiButton,
    },
    MuiCardActions: {
        styleOverrides: overrides.MuiCardActions,
    },
    MuiCardContent: {
        styleOverrides: overrides.MuiCardContent,
    },
    MuiCardHeader: {
        styleOverrides: overrides.MuiCardHeader,
    },
    MuiChip: {
        styleOverrides: overrides.MuiChip,
    },
    MuiContainer: {
        styleOverrides: overrides.MuiContainer,
    },
    MuiDialog: {
        styleOverrides: overrides.MuiDialog,
    },
    MuiDialogContent: {
        styleOverrides: overrides.MuiDialogContent,
    },
    MuiExpansionPanel: {
        styleOverrides: overrides.MuiExpansionPanel,
    },
    MuiExpansionPanelSummary: {
        styleOverrides: overrides.MuiExpansionPanelSummary,
    },
    MuiFormControlLabel: {
        styleOverrides: overrides.MuiFormControlLabel,
    },
    MuiFormLabel: {
        styleOverrides: overrides.MuiFormLabel,
    },
    MuiIconButton: {
        styleOverrides: overrides.MuiIconButton,
    },
    MuiInputBase: {
        styleOverrides: overrides.MuiInputBase,
    },
    MuiInputLabel: {
        styleOverrides: overrides.MuiInputLabel,
    },
    MuiLinearProgress: {
        styleOverrides: overrides.MuiLinearProgress,
    },
    MuiListItem: {
        styleOverrides: overrides.MuiListItem,
    },
    MuiListItemIcon: {
        styleOverrides: overrides.MuiListItemIcon,
    },
    MuiMenuItem: {
        styleOverrides: overrides.MuiMenuItem,
    },
    MuiOutlinedInput: {
        styleOverrides: overrides.MuiOutlinedInput,
    },
    MuiPaper: {
        styleOverrides: overrides.MuiPaper,
    },
    MuiPopover: {
        styleOverrides: overrides.MuiPopover,
    },
    MuiSelect: {
        styleOverrides: overrides.MuiSelect,
    },
    MuiTab: {
        styleOverrides: overrides.MuiTab,
    },
    MuiTableCell: {
        styleOverrides: overrides.MuiTableCell,
    },
    MuiTableHead: {
        styleOverrides: overrides.MuiTableHead,
    },
    MuiTableRow: {
        styleOverrides: overrides.MuiTableRow,
    },
    MuiToggleButton: {
        styleOverrides: overrides.MuiToggleButton,
    },
    MuiTypography: {
        styleOverrides: overrides.MuiTypography,
    },
}

export default components;
