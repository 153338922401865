import { Popover, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { connect } from "react-redux";
import { createSession, inviteUsersToSession } from "../../../actions/sessionActions";
import { useViewport, portraitBreakPoint } from "../../contextComponent/viewportContext";

const Alert = (props) => {
    return <MuiAlert style={{ boxShadow: "none" }} elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        // LOOK FOR THIS PADDING
    },
    contentHeader: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "12px 12px 0 12px",
    },
    tabTitle: {
        color: theme.palette.text.main,
        fontSize: "22px",
        marginRight: "auto",
        padding: "4px 10px"
    },
    generateButton: {
        borderRadius: "33px",
        padding: "1px 8px",
        marginRight: "8px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "200px",
        fontWeight: "bold",
        [`${portraitBreakPoint(theme)}`]: {
            width: "55%",
            maxWidth: "210px",
            alignItems: "center",
            marginRight: "auto",
            marginLeft: "auto",
            marginTop: "4px",
        },
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.contrastText,
        },
    },
    button: {
        height: "30px",
        color: theme.palette.text.contrastText,
        borderRadius: 30,
        margin: "6px 0px",
        padding: "10px 30px",
        fontWeight: "bold",
        [`${portraitBreakPoint(theme)}`]: {
            padding: "0 0px",
        },
    },
    comment: {
        color: theme.palette.text.secondary,
        fontStyle: "italic",
    },
    typography: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        fontWeight: "bold",
    },
    buttonGroup: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "15px",
        flexWrap: "wrap",
        "& button": {
            marginRight: "4px",
        },
        [`${portraitBreakPoint(theme)}`]: {
            alignItems: "unset",
            marginLeft: "auto",
            marginRight: "auto",
            flexDirection: "column",
            width: "50%",
            maxWidth: "200px",
            justifyContent: "flex-start",
        },
    },
}));

const SessionGenerator = ({ sessionLink, requestRefetchData, setSort, setSearch, setSelectedTab, ...props }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { isMobile } = useViewport();
    const [openMessage, setOpenMessage] = useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [alertText, setAlertText] = React.useState("");

    // const copyToClipboard = (event) => {
    //     setAnchorEl(event.currentTarget);
    //     setTimeout(handleClose, 600);
    //     const textField = document.createElement('textarea');
    //     textField.innerText = sessionLink;
    //     document.body.appendChild(textField);
    //     textField.select();
    //     document.execCommand('copy');
    //     textField.remove();
    // };

    // const onStartHostSession = () => {
    //     window.open(sessionLink);
    // };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMessage(false);
    };
    const handleSnackClose = () => {
        setSeverity("success");
        setOpenMessage(false);
        setAlertText("");
    };

    // const inviteUsers = (invitees) => {
    //     const paramString = sessionLink.split("?")[1];
    //     const params = queryString.parse(paramString);
    //     const id = params.id;
    //     props.inviteUsers(id, invitees);
    // };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <React.Fragment>
            <Snackbar
                open={openMessage}
                autoHideDuration={3000}
                onClose={handleClose}
                style={{ marginTop: isMobile && 15 }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Alert onClose={handleSnackClose} severity={severity}>
                    {alertText}
                </Alert>
            </Snackbar>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Typography className={classes.typography}>Copied!</Typography>
            </Popover>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    sessionLink: state.session.sessionLink,
});

const mapDispatchToProps = (dispatch) => {
    return {
        createSession: () => dispatch(createSession()),
        inviteUsers: (sessionId, invitees) => dispatch(inviteUsersToSession(sessionId, invitees)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionGenerator);
