import { getCollections, getContents, getContentsFilter, getCollectionDetail, viewCollection, viewContent, patchFavoriteCollection, patchFavoriteContent, deleleContentAPI, renameContentTitle, addNewCollectionAPI, getOrganiations, putContentToCollection, getContentDetail, removeContentAPI, renameCollectionTitle, postContentNoteAPI } from './api/CollectionAPI'
import {ON_LOADING_START, ON_LOADING_FINISHED } from './sessionActions';


export const FETCH_COLLECTIONS = "FETCH_COLLECTIONS";
export const FETCH_CONTENTS = "FETCH_CONTENTS";
export const ON_COLLECTION_NAME_SET = "ON_COLLECTION_NAME_SET";
export const ON_HIDE_MENU_ICONS_ON_VIDEO_SELECT = "ON_HIDE_MENU_ICONS_ON_VIDEO_SELECT";
export const SELECTED_COLLECTION = "SELECTED_COLLECTION";
export const DISLIKE_COLLECTION = "DISLIKE_COLLECTION";
export const REMOVE_CONTENT = "REMOVE_CONTENT";
export const RENAME_CONTENT = "RENAME_CONTENT";
export const RE_FETCH_COLLECTIONS = 'RE_FETCH_COLLECTIONS';
export const FETCH_ORGANISATIONS = 'FETCH_ORGANISATIONS';
export const SELECTED_ORG = "SELECTED_ORG";
export const ON_UPDATE_COLLECTIONS = "ON_UPDATE_COLLECTIONS";
export const ON_UPDATE_CONTENTS = "ON_UPDATE_CONTENTS";
export const CURRENT_ORG = "CURRENT_ORG";
export const RENAME_COLLECTION = "RENAME_COLLECTION";

export const updateCollections = (collections) => {
    return {
        type: ON_UPDATE_COLLECTIONS,
        payload: collections
    }
};

export const updateContents = (contents) => {
    return {
        type: ON_UPDATE_CONTENTS,
        payload: contents
    }
};

export const setSelectedCollection = (collection) => {
    return {
        type: SELECTED_COLLECTION,
        payload: collection
    }
};


export const addOrganisations = (organisations) => {
    return {
        type: FETCH_ORGANISATIONS,
        payload: organisations
    }
};

export const setSelectedOrg = (organisation) => {
    return {
        type: SELECTED_ORG,
        payload: organisation
    }
};

export const setCurrentOrg = (organisation) => {
    return {
        type: CURRENT_ORG,
        payload: organisation
    }
};

export function fetchCollections(pageSize, pageNumber, sortType, search, filterType, orgId) {
    return (dispatch, getState) => {
        const org_id = orgId || (getState().collections?.selectedOrganisation?.id);
        getCollections(pageSize, pageNumber, sortType, search, filterType, org_id).then(response => {
            if (response.status === 200) {
                dispatch(updateCollections(response.data))
            }
        }).catch(error => {
            console.log(error);
        })
    }
}

export function addNewCollection(name) {
    return (dispatch, getState) => {
        const org_id = getState().collections?.selectedOrganisation?.id;
        addNewCollectionAPI(name,org_id).then(response => {
            if (response.status === 201) {
                // dispatch(updateCollections(response.data));
                dispatch({ type: RE_FETCH_COLLECTIONS });
            }
        }).catch(error => {
            console.log(error);
        })
    }
}

export function viewCollections(id) {
    return () => {
        return new Promise((resolve, reject) => {
            viewCollection(id).then(response => {
                if (response.status === 200) {
                    resolve(response);
                }
            }).catch(error => {
                reject(error.response);
            })
        });
    }
}

export function fetchCollectionDetail(id) {
    return (dispatch) => {
        getCollectionDetail(id).then(response => {
            if (response.status === 200 && response.data) {
                dispatch(setSelectedCollection(response.data))
            }
        }).catch(error => {
            console.log(error);
        })
    }
}

export function fetchContents(pageSize, pageNumber, sortType, collectionId, search, ignore_user) {
    
    return (dispatch, getState) => {
        const prevCollectionId = getState().collections?.selectedCollection?.id;
        if(prevCollectionId && (prevCollectionId !== parseInt(collectionId))){
            dispatch(updateContents({items:[],count:0}));
        }
        return new Promise((resolve, reject) => {
            const org_id = getState().collections?.selectedOrganisation?.id;
            dispatch({ type: ON_LOADING_START });
            dispatch(fetchCollectionDetail(collectionId))
            getContents(pageSize, pageNumber, sortType, collectionId, search, false, ignore_user, org_id)
            .then(response => {
                if (response.status === 200 && response.data) {
                    dispatch({ type: ON_LOADING_FINISHED });
                    dispatch(updateContents(response.data));
                    resolve(response.data);
                }
            }).catch(error => {
                dispatch({ type: ON_LOADING_FINISHED });
                reject(error.response);
            })
        })
    }
}

export function fetchContentsFilter(pageSize, pageNumber, sortType, search, filterType, ignore_user) {

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const org_id = getState().collections?.selectedOrganisation?.id;
            dispatch({ type: ON_LOADING_START });
            getContentsFilter(pageSize, pageNumber, sortType, search, filterType, ignore_user, org_id).then(response => {
                if (response.status === 200 && response.data) {
                    dispatch({ type: ON_LOADING_FINISHED });
                    dispatch(updateContents(response.data));
                    resolve(response.data)
                }
            }).catch(error => {
                dispatch({ type: ON_LOADING_FINISHED });
                reject(error.response);
            })
        });
    }
}

export function viewContents(id) {
    return () => {
        return new Promise((resolve, reject) => {
            viewContent(id).then(response => {
                if (response.status === 200) {
                    resolve(response);
                }
            }).catch(error => {
                reject(error.response);
            })
        });
    }
}

export function deleteContent(id) {
    return (dispatch) => {
        deleleContentAPI(id).then(response => {
            if (response) {
                dispatch(removeContentFromStore(id))
            }
        }).catch(error => {
            console.log(error);
        })
    }
}

export function removeContent(collection_id, content_id) {
    return (dispatch) => {
        removeContentAPI(collection_id, content_id).then(response => {
            dispatch(removeContentFromStore(content_id));
        }).catch(error => {
            console.log(error);
        })
    }
}

export function renameContent(id, newTitle) {
    return (dispatch) => {
        renameContentTitle(id, newTitle).then(response => {
            if (response.status === 200) {
                dispatch({
                    type: RENAME_CONTENT,
                    id,
                    newTitle,
                })
            }
        }).catch(error => {
            console.log(error);
        })
    }
}

export const setCollectionName = (collectionName) => {
    return {
        type: ON_COLLECTION_NAME_SET,
        payload: collectionName
    }
};

export const onHideMenuIconsOnVideoSelect = (value) => {
    return {
        type: ON_HIDE_MENU_ICONS_ON_VIDEO_SELECT,
        payload: value
    }
};

export function makeCollectionFavorite(collectionId) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            patchFavoriteCollection(collectionId).then(response => {
                if (response) {
                    resolve(response)
                }
            }).catch(error => {
                reject(error.response)
            })
        })
    }
}

export function makeContentFavorite(contentId) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            patchFavoriteContent(contentId).then(response => {
                if (response) {
                    resolve(response)
                }
            }).catch(error => {
                reject(error.response)
            })
        })
    }
}

export const disLikeCollection = (id) => {
    return {
        type: DISLIKE_COLLECTION,
        payload: id
    }
};

export const removeContentFromStore = (id) => {
    return {
        type: REMOVE_CONTENT,
        payload: id
    }
};

export function fetchOrganiations(pageNumber, pageSize, sortType, search) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            getOrganiations(pageNumber, pageSize, sortType, search )
            .then(response => {
                if (response.status === 200) {
                    dispatch(addOrganisations(response.data))
                    resolve(response);
                }
            }).catch(error => {
                reject(error.response)
            })
        })
    }
}

export function assignContentToCollection(collectionId, contentId) {
    return () => {
        return new Promise((resolve, reject) => {
            putContentToCollection(collectionId, contentId).then(response => {
                if (response.status === 201 || response.status === 204) {
                    resolve(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    }
}

export function fetchContent(contentId) {
    return () => {
        return new Promise((resolve, reject) => {
            getContentDetail(contentId).then(response => {
                if (response.status === 200 && response.data) {
                    resolve(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    }
}

export function renameCollection(id, newTitle) {
    return (dispatch) => {
        renameCollectionTitle(id, newTitle).then(response => {
            if (response.status === 200) {
                dispatch({
                    type: RENAME_COLLECTION,
                    id,
                    newTitle,
                })
            }
        }).catch(error => {
            console.log(error);
        })
    }
}

export function addContentNote(content_id, note) {
    return () => {
        return new Promise((resolve, reject) => {
            postContentNoteAPI(content_id, note)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                })
        }) 
    }
}
