import React, { useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { fetchContents, updateContents } from "../../../actions/CollectionAction";
import ContentFilterSortMenu from "../collections/SortFilterComponent";
import ContentHeader from "../ContentHeader";
import FAQsComponent from "../FAQsComponent";
import Contents from "./Contents";
import config from "../../../config";
import { useViewport, portraitBreakPoint, landscapeBreakPoint} from "../../contextComponent/viewportContext";
import { setRouterState } from "../../../actions/ApplicationActions";

const useStyles = makeStyles((theme) => ({
    activeTab: {
        color: theme.palette.primary.main,
    },
    contentHeader: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0 12px",
        [`${portraitBreakPoint(theme)}`]: {
            paddingTop: "12px",
        },
    },
    tabTitle: {
        whiteSpace: 'nowrap',
        color: theme.palette.text.main,
        fontSize: "22px",
        userSelect: 'none',
        [`${portraitBreakPoint(theme)}`]: {
            marginLeft: '5px'
        },
        [`${landscapeBreakPoint(theme)}`]: {
            marginLeft: '10px'
        }
    },
    collectionTitle: {
        display: "inline-flex",
        whiteSpace: 'nowrap',
        alignItems: "center",
        padding: "0 8px",
        margin: "0 10px",
        "& .MuiIconButton-root": {
            padding: '4px 10px 4px 0px',
            marginLeft: 0,
            margin: 0,
            borderRadius: 5,
            fontFamily: "NeueHaasDisplayMedium",
            fontSize: "1rem",
            userSelect: 'none',
            color: theme.palette.text.navy,
        },
        "& .MuiSvgIcon-root": {
            fontSize: "1.8rem"
        },
        "&:hover":{
            cursor: "pointer"
        }
    },
    backHubButton:{
        display: "inline-flex",
        whiteSpace: 'nowrap',
        alignItems: "center",
        borderRadius: "20px",
        border: `1px solid ${theme.palette.border}`,
        padding: "2px 20px 2px 10px",
        margin: "0 10px",
        "& .MuiIconButton-root": {
            padding: 0,
            margin: '0 6px 0 0'
        },
        "& .MuiTypography-root": {
            fontFamily: "NeueHaasDisplayMedium",
            fontSize: "16px",
            padding: 0,
            userSelect: 'none',
            color: theme.palette.text.navy,
        }, 
        "&:hover":{
            cursor: "pointer"
        }
    },
    notifcationIcon: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.text.medium,
        fontSize: "large",
        padding: "2px",
        opacity: "0.65",
        borderRadius: "33px",
        transition: "0.2s ease-in-out",
        "&:hover": {
            opacity: "0.85",
        },
    },
    infoIcon: {
        height: "22px",
        opacity: "0.8",
        transition: "0.2s ease-in-out",
        "&:hover": {
            opacity: "1",
        },
    },
    mobileSearchAndUpload: {
        display: "flex",
        flexDirection: "row",
        // justifyContent: "flex-end",
        padding: '0px 15px 15px',
        margin: "8px 20px 0px 0px",
    },
    searchAndUpload: {
        padding: '0px 15px 15px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    uploadButton: {
        whiteSpace: "nowrap",
        borderRadius: "33px",
        margin:"0 10px",
        padding: '0 30px',
        height: "30px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        // width: "200px",
        fontFamily: "NeueHaasDisplayBold",
        [`${portraitBreakPoint(theme)}`]: {
            fontSize: "14px",
            alignItems: "center",
        },
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.contrastText,
        },
    },
    searchTuneRoundedIcon: {
        color: theme.palette.text.medium,
        padding: "2px",
        border:"1px solid",
        margin:"10px",
        borderRadius: "50%",
        "&:hover": {
            color: theme.palette.text.main,
            cursor: "pointer",
        }
    },
    orgFilterHolder: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '20px'
    },
    searchFilterHolder: {
        display: "flex",
        alignItems: 'center',
        margin: '30px',
        [`${landscapeBreakPoint(theme)}`]: {
            margin: '15px',
        },
        [`${portraitBreakPoint(theme)}`]: {
            margin: '10px',
        }
    },
    divider: theme.typography.divider,
    collectionLabel: {
        "&.MuiTypography-root":{
            fontSize: "1rem !important",
            fontFamily: "NeueHaasDisplayMedium",
            color: theme.palette.text.main,
        },
    }
}));

const ContentDashboard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isMobile, isMobileLandscape } = useViewport();
    const [showFAQ, setShowFAQ] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isFetchContentsEnd, setIsFetchContentsEnd] = React.useState(false);
    const history = useHistory();
    const selectedCollection = useSelector((state) => state.collections.selectedCollection);
    const isViewlabsUser = useSelector(state => state.application.userDetails?.is_viewlabs_user);
    const selectedOrg = useSelector(state => state.collections.selectedOrganisation);
    const sort = useSelector(state => state.collections.contents.sort);
    const page = useSelector(state => state.collections.contents.page);
    const search = useSelector(state => state.collections.contents.search);
    const routerState = useSelector(state => state.application.routerState);
    const isMeetingView = useSelector(state => state.session.isMeetingView);
    const [initRouterState, setInitRouterState] = useState(true);
    const { id } = useParams();

    React.useEffect(() => {
        dispatch(updateContents({ page: { size: 16, number: 0 }, search: '' }))
    }, [dispatch])

    const onClickBack = () => {
        history.push('/');
    };

    const onClickFAQ = () => {
        setShowFAQ(!showFAQ);
    };


    const goToUploader = () => {
        history.push("/uploadContent");
    };


    const setSort = (sort) => {
        dispatch(updateContents({ sort }))
    }

    const setSearch = (search) => {
        dispatch(updateContents({ search }))
    }

    React.useEffect(() => {
        if(initRouterState){
            setInitRouterState(false);
            dispatch(setRouterState({
                ...routerState,
                page: history.location.pathname,
                uploaderBack: history.location.pathname,
            }));
        }
    }, [dispatch, history.location.pathname, initRouterState, routerState]);

    React.useEffect(() => {
        const ignoreUser = !!selectedOrg;
        setIsFetchContentsEnd(false);
        dispatch(fetchContents(page.size, page.number, sort, id, search, ignoreUser))
        .then(()=>{
            setIsFetchContentsEnd(true);
        })
        .catch((error) => {
            if (error.status === 403) {
                history.push("/");
            }
        });
    }, [page, sort, search, id, selectedOrg, dispatch, history]);

    if (showFAQ) {
        return <FAQsComponent onClickBack={onClickFAQ} />;
    }

    return (
        <Box height="100%" id="collectionDashboard" display={"flex"} flexDirection={"column"}>
            <Box
                className={!isMobile && !isMobileLandscape ? classes.divider : null}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                flexWrap={isMobile ? "wrap-reverse" : "wrap"}
            >
               
                {!isMeetingView && (
                    <ContentHeader setSort={setSort} setSearch={setSearch} isOpen={isOpen} setIsOpen={setIsOpen} iconMode>
                        {isMobile || isMobileLandscape ? 
                            <div style={{ display: 'flex', gap: '10px', alignItems:'center' }}>
                                <Typography className={classes.tabTitle} variant="h4">
                                    Content Hub
                                </Typography>
                            </div>
                            :
                            <div className={classes.collectionTitle} onClick={onClickBack}>
                                <IconButton size="large">
                                    <ChevronLeftIcon />
                                    Back to Hub
                                </IconButton>
                                <Typography className={classes.collectionLabel} variant="h4">
                                    Collection: {selectedCollection?.title}
                                </Typography>
                            </div>
                        }
                    </ContentHeader>
                )}
            </Box>
            <div className={classes.searchFilterHolder}>
                { (isMeetingView || isMobile || isMobileLandscape) &&
                        <div className={classes.backHubButton} onClick={onClickBack}>
                            <IconButton size="large">
                                <ChevronLeftIcon />
                            </IconButton>
                            <Typography>
                                Back to Hub
                            </Typography>
                        </div>
                }
                { !isMeetingView && !isMobile && !isMobileLandscape &&
                    (config.ENABLE_UPLOADER || (config.ENABLE_UPLOADER_FOR_VL && isViewlabsUser)) && 
                        <Button
                            variant="text"
                            className={classes.uploadButton}
                            onClick={goToUploader}
                        >
                            Upload Content
                        </Button>
                }    
                <ContentFilterSortMenu setSort={setSort} setSearch={setSearch} />
                
            </div>
            { (isMeetingView || isMobile || isMobileLandscape) &&
                <Typography className={classes.collectionLabel} style={{padding: "0 0 10px 20px"}} variant="h4">
                    Collection: {selectedCollection?.title}
                </Typography>
            }
            <Contents showAddNewContent={search ? false : (config.ENABLE_UPLOADER || (config.ENABLE_UPLOADER_FOR_VL && isViewlabsUser)) && selectedCollection?.canUploadContent} isFetchContentsEnd={isFetchContentsEnd} />
        </Box>
    );
};

export default ContentDashboard;
