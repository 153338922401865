const MuiExpansionPanelSummary={
    root:{
        minHeight: "51px !important",
    },
    expandIcon: {
       right: 4
    },
    content:{
        margin: "8px 0px !important"
    }
}
export default MuiExpansionPanelSummary;
