import DialogMigrate from '../../components/DialogMigrate';
import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@mui/styles";
import { showWelcomeBanner } from '../../actions';
import { portraitBreakPoint } from "../../views/contextComponent/viewportContext";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.primary.main,
    },
    title: {
        color: theme.palette.text.contrastText,
        [`${portraitBreakPoint(theme)}`]: {
            fontSize: '25px',
            lineHeight: '30px'
        },
    },
    textContent: {
        color: theme.palette.text.contrastText,
        fontFamily: 'NeueHaasDisplayRoman',
        wordSpacing: '4px'
    }
}));


const WelcomeBanner = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const showBanner = useSelector(state => state.application.showWelcomeBanner);

    const onClose = () => {
        dispatch(showWelcomeBanner());
    }

    return (
        <DialogMigrate open={showBanner} onClose={onClose} maxWidth="sm" fullWidth classes={{ 
            paper: classes.root
        }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={onClose} size="large">
                    <CloseIcon className={classes.title} />
                </IconButton>
            </div>
            
            <div style={{ display: 'flex', justifyContent: 'center', margin: '0 30px 20px 30px' }}>
                <Typography align='center' variant='h3' className={classes.title}>Welcome to your new account!</Typography>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', margin: '0px 40px 40px 50px' }}>
                <Typography align='center' variant='h4' className={classes.textContent}>
                    From here you can check out examples of our video marketing services in the Content Hub, 
                    upload your content, and schedule your own 360 Meet sessions!
                </Typography>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
                <Typography align='center' variant='h4' className={classes.textContent}>
                    <a className={classes.textContent} href="https://platform.viewlabs.com/player?uuid=80c00c3d-1c18-4559-9d9b-7a659aba49a2&ap=true" target="_blank" rel="noreferrer">WATCH TUTORIAL VIDEO</a>
                </Typography>
            </div>

        </DialogMigrate>
    );
}

export default WelcomeBanner;
