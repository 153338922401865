import { Box, Button, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles, styled } from "@mui/styles";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onUpdateUserName } from '../../../actions/sessionActions';
import { useSnackbar } from '../../../hooks/useCustomSnackbar';
import { portraitBreakPoint, landscapeBreakPoint} from "../../contextComponent/viewportContext";
import { USER_NAME_MAX_CHARS } from "../../../constant";

const useStyles = makeStyles((theme) => ({
    margin: {
        marginTop: "15px",
        width: '50%',
        marginRight: "15px",
        [`${landscapeBreakPoint(theme)}`]: {
            width: '50%',
        },
        [`${portraitBreakPoint(theme)}`]: {
            width: '100%',
        },
        "& .MuiInput-formControl":{
            marginTop: '10px'
        }
    },
    form: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    formLabel: {
        transform: 'none',
        position: 'relative',
        fontSize: "18px",
        "& .MuiTypography-h4":{
            [`${portraitBreakPoint(theme)}`]: {
                fontSize: "16px",
            },
            [`${landscapeBreakPoint(theme)}`]: {
                fontSize: "16px",
            }
        }
    },
    nameFields: {
        display: 'flex',
        flexDirection: 'row',
        [`${portraitBreakPoint(theme)}`]: {
            flexDirection: 'column'
        },
    }
}))

const StyledInput = styled(Input)((props) => ({
    border: props.error ? '2px solid red' : '1px solid',
    borderRadius: 25,
    fontFamily: "NeueHaasDisplayRoman",
    color: "black",
    height: "auto",
    '& .MuiInput-underline': {
        border: 'none',
        '&:hover, &:focus': {
            border: 'none',
        }
    },
    '& input': {
        padding: "6px 20px"
    },
    "&:after, &:before": {
        borderBottom: 'none !important',
    },
    "&:hover": {
        border: props.error ? '2px solid red' : '2px solid',
    }
}));

const SaveButton = styled(Button)(({ theme }) => ({
    background: theme.palette.text.contrastText,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    padding: '2px 35px',
    lineHeight: "1.4",
    "@media (hover: hover)":{
        "&:hover": {
            background: theme.palette.primary.main,
            color: theme.palette.text.contrastText
        }
    }
}));


const UserNameForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();


    const userDetails = useSelector(state => state.application?.userDetails);
    const [name, setName] = React.useState({
        firstName: "",
        lastName: ""
    });
    const [error, setError] = React.useState({
        firstName: false,
        lastName: false
    });
    const [isDisabled,setIsDisabled] = React.useState(true);


    const onFirstNameChange = (e) => {
        const maxChars = USER_NAME_MAX_CHARS;
        if (e.target.value.length > maxChars) {
            enqueueSnackbar({message:`User name cannot be more than ${maxChars} characters.`, variant:'error'});
        } else {
            const firstName = e.target.value;
            setName({
                ...name,
                firstName
            });
            validattion(firstName, name.lastName);
        }
    }

    const onLastNameChange = (e) => {
        const maxChars = USER_NAME_MAX_CHARS;
        if (e.target.value.length > maxChars) {
            enqueueSnackbar({message:`User name cannot be more than ${maxChars} characters.`, variant:'error'});
        } else {
            const lastName = e.target.value;
            setName({
                ...name,
                lastName
            });
            validattion(name.firstName, lastName);
        }
    }

    const isDisableButton = (firstName, lastName) => {
        const isDisabled = userDetails?.first_name === firstName && userDetails?.last_name === lastName;
        setIsDisabled(isDisabled);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (validattion(name.firstName, name.lastName)) {
            dispatch(onUpdateUserName({
                first_name: name.firstName,
                last_name: name.lastName
            })).then(res => {
                enqueueSnackbar({message:"Name updated successfully!", variant:'success'});
            })
        }
    }

    const validattion = (firstName, lastName) => {
        isDisableButton(firstName, lastName);
        let newErrorState = {};
        if (!firstName) {
            newErrorState.firstName = true;
        }
        if (!lastName) {
            newErrorState.lastName = true;
        }
        setError(newErrorState);
        return !newErrorState.firstName && !newErrorState.lastName
    }

    React.useEffect(() => {
        setName(name => ({
            ...name,
            firstName: userDetails?userDetails.first_name:"",
            lastName: userDetails?userDetails.last_name:""
        }))
    }, [userDetails])

    return (
        <Box id="user-name-form" >
            <form onSubmit={onSubmit} className={classes.form}>
                <div className={classes.nameFields}>
                    <FormControl variant="standard" className={classes.margin}>
                        <InputLabel htmlFor="first-name" className={classes.formLabel}>
                            <Typography variant='h4' color='textSecondary'>First Name</Typography>
                        </InputLabel>
                        <StyledInput id="first-name" error={error.firstName} value={name.firstName} onChange={onFirstNameChange} />
                    </FormControl>
                    <FormControl variant="standard" className={classes.margin}>
                        <InputLabel htmlFor="last-name" className={classes.formLabel}>
                            <Typography variant='h4' color='textSecondary'>Last Name</Typography>
                        </InputLabel>
                        <StyledInput id="last-name" error={error.lastName} value={name.lastName} onChange={onLastNameChange} />
                    </FormControl>
                </div>
              
                <div style={{ padding: "2px 15px" }}>
                    <SaveButton type='submit' variant="outlined" disabled={isDisabled}>Save</SaveButton>
                </div>         
            </form>
        </Box>
    );
}

export default UserNameForm;
