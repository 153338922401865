import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingIcon } from './components/LoadingIcon';

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
    const loginLoading = useSelector((state) => state.session.loginLoading);
    return (
        <Route
            {...rest}
            render={props => (
                authed === true && loginLoading === false
                    ? <Component {...props} />
                    : loginLoading === true
                        ? <LoadingIcon />
                        : <Redirect to={`/login`}/>
            )}
        />
    );
}

export default PrivateRoute;
