import React from "react";
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";

const StyledTooltip = withStyles((theme) => ({
    tooltip: {
        margin: "0px",
        maxWidth: "400px",
        backgroundColor: theme.palette.background.main,
        fontSize: "12px",
        boxShadow: `0px 0px 4px 0px ${theme.palette.text.medium}`,
    },
    tooltipPlacementTop: {
        margin: "2px 0",
    },
}))(Tooltip);

const CustomTooltip = ({children, isEllipsis, ...rest}) => {  
    return <StyledTooltip disableHoverListener={isEllipsis === false} {...rest}>
                {children}
            </StyledTooltip>
}
export default CustomTooltip;
