import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useParams, withRouter, useHistory } from 'react-router-dom';
import { validateQrCodeLink } from '../../actions/ApplicationActions';
import Page from "../../components/Page";
import MiddleForm from '../LoginScreen/MiddleForm';
import { useSnackbar } from "../../hooks/useCustomSnackbar";
import { Box, Typography } from '@mui/material';



const QRValidation = (props) => {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [error, setError] = useState(false);

    React.useEffect(() => {
        dispatch(validateQrCodeLink(id)).then((response) => {
            history.push(response.url.replace(/^.*\/\/[^/]+/, '').replace("/dashboard/web", ""))
        }).catch(() => {
            setError(true);
            enqueueSnackbar({ message: 'Invalid QR code. Please re-scan QR code again.', variant: 'error' });
        })
    }, []);

    return (
        <Page id='page-qa-validation' style={{
            height: '100vh',
            display: 'flex',
            width: '100vw',
            overflow: "hidden"
        }} title="qa-validation">
            <MiddleForm title="QR validation">
                {
                    !error ? 
                        <Box display={'flex'} justifyContent='center' width={'100%'}>
                            <Typography variant='h3' color='textSecondary'>Validating QR code...</Typography>
                        </Box>
                        :
                        <Box display={'flex'} justifyContent='center' width={'100%'}>
                            <Typography variant='h3' color='textSecondary'>Invalid QR code</Typography>
                        </Box>
                }
            </MiddleForm>
        </Page>
    )
}


export default withRouter(QRValidation);
